define("apollo/pods/mode-of-transportation/create/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_CAN_ADD_MOT'],

    beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },

    model() {
      const owner = this.get('sessionAccount.currentUser.company');
      const truck = this.get('store').createRecord('truck', {
        owner: owner,
        enabled: true,
        isModeOfTransportation: true,
        verificationCompleted: false
      });
      const mainSection = this.get('store').createRecord('vehicle-main-section', {
        truck: truck,
        name: 'VEHICLE_MAIN_DATA_TYPE'
      });
      return Ember.RSVP.hash({
        truck: truck,
        mainSection: mainSection
      });
    }

  });

  _exports.default = _default;
});