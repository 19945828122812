define("apollo/pods/components/transport/delivery-section/delivery-packaging-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    modalService: Ember.inject.service('modal-service'),
    sessionAccount: Ember.inject.service('session-account'),
    authorityCheckerService: Ember.inject.service('authority.authority-checker-service'),
    showAddDeliveryPackagesSection: Ember.computed('delivery.transportType.id', function () {
      return this._checkAuthority('ROLE_ADD_DELIVERY_PACKAGING', this.get('delivery.transportType.id'));
    }),
    deliveryPackagingError: Ember.computed('delivery.{packaging.errors.[],errors.packaging}', function () {
      if (this.get('delivery.errors.packaging')) {
        return this.get('delivery.errors.packaging');
      }

      return this.get('delivery.packaging.errors');
    }),
    isEditionDisabled: Ember.computed('delivery.{id,transportType.id}', function () {
      return !this._checkAuthority('ROLE_EDIT_DELIVERY_PACKAGING', this.get('delivery.transportType.id'));
    }),
    isDeletionAllowed: Ember.computed('delivery.{id,transportType.id}', function () {
      if (!this.get('delivery.id')) {
        return false;
      }

      return this._checkAuthority('ROLE_DELETE_DELIVERY_PACKAGING', this.get('delivery.transportType.id'));
    }),
    _checkAuthority: function (name, ttId) {
      return this.authorityCheckerService.findPositiveAuthorityRestrictionsByActionType(name, ttId);
    },
    editionOrDeletionIsAllowed: Ember.computed('isDeletionAllowed', 'isEditionDisabled', function () {
      return !this.get('isEditionDisabled') || this.get('isDeletionAllowed');
    }),
    enabledPackaging: Ember.computed('delivery.packaging.@each.enabled', function () {
      return this.get('delivery.packaging').filterBy('enabled');
    }),
    _prepareModel: function () {
      const deliveryPackaging = this.get('store').createRecord('deliveryPackaging', {
        loadStep: this.get('delivery.loadSteps.firstObject'),
        unloadStep: this.get('delivery.unloadSteps.firstObject')
      });

      const model = this._createModel(deliveryPackaging);

      this.get('delivery.packaging').addObject(deliveryPackaging);
      return model;
    },
    _createModel: function (dp) {
      return {
        packageTypes: this.get('packageTypes'),
        deliveryPackaging: dp,
        delivery: this.get('delivery')
      };
    },
    actions: {
      togglePanel() {
        this.toggleProperty('isOpened');
      },

      addPackaging() {
        this.get('modalService').showModal(this.get('modalService').MODALS.DELIVERY_PACKAGING.FORM, this._prepareModel(), 'sub-modal');
      },

      setPackageType(pckg, packageType) {
        pckg.set('packageType', packageType);
      },

      editPackaging(packaging) {
        const self = this;
        this.get('modalService').showModal(this.get('modalService').MODALS.DELIVERY_PACKAGING.FORM, self._createModel(packaging), 'sub-modal');
      },

      disablePackaging(pckg) {
        const msg = this.get('intl').t('courier.COURIER_PACKAGE.disableConfirm');

        if (!confirm(msg)) {
          return;
        }

        if (pckg.get('id')) {
          pckg.set('enabled', false);
        } else {
          this.get('delivery.packaging').removeObject(pckg);
          pckg.unloadRecord();
        }
      }

    }
  });

  _exports.default = _default;
});