define("apollo/services/delivery-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    store: Ember.inject.service(),

    createNewBasicTransport(customFields, transportTypeId) {
      const values = customFields.map(pair => pair.value);
      return this.apolloApiService.callApolloApi(this.apolloApiService.APOLLO_API.PLANNING.CREATE_BASIC_TRANSPORT, null, {
        body: JSON.stringify({
          values,
          transportTypeId
        })
      });
    },

    handleUpdatedDeliveryPackaging(delivery) {
      if (!delivery.get('packaging.length')) {
        return;
      }

      delivery.get('packaging').then(pck => {
        const cpCopy = pck.slice(0);
        cpCopy.forEach(cp => {
          if (cp !== undefined && !cp.get('id')) {
            console.debug(`Unloading redundant delivery packaging for delivery ${delivery.get('id')}..`);
            delivery.get('packaging').removeObject(cp);
            cp.unloadRecord();
          } else {
            cp.reload();
          }
        });
      });
    }

  });

  _exports.default = _default;
});