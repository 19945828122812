define("apollo/pods/planning/modals/create-from-transport/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VifrktQB",
    "block": "{\"symbols\":[],\"statements\":[[4,\"custom-modal\",null,[[\"classNames\",\"title\",\"showCancelButton\",\"cancel\"],[\"transport-modal\",[23,[\"modalTitle\"]],true,[27,\"action\",[[22,0,[]],\"cancel\"],null]]],{\"statements\":[[1,[27,\"transport/create-delivery-from-transport\",null,[[\"customFieldsList\",\"packageTypes\",\"delivery\",\"transportType\",\"steps\",\"disabled\"],[[23,[\"customFieldsList\"]],[23,[\"packageTypes\"]],[23,[\"model\",\"delivery\"]],[23,[\"model\",\"transportType\"]],[23,[\"model\",\"transportSteps\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/planning/modals/create-from-transport/template.hbs"
    }
  });

  _exports.default = _default;
});