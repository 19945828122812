define("apollo/pods/components/transport/delivery-section/delivery-top-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    authorityCheckerService: Ember.inject.service('authority.authority-checker-service'),
    enums: Ember.inject.service('enum-service'),
    tagName: '',
    incoterms: Ember.computed('', function () {
      return this.get('enums').get('incoterms');
    }),
    transportTypeId: Ember.computed('delivery.transportType.id', function () {
      return this.get('delivery.transportType.id');
    }),
    showSimplePackaging: Ember.computed('delivery.deliverySetting.showSimplePackagingSection', function () {
      return !!this.get('delivery.deliverySetting.showSimplePackagingSection');
    }),
    currencies: Ember.computed('', function () {
      return this.get('store').peekAll('currency');
    }),
    showDeliveryNumber: Ember.computed('transportTypeId', function () {
      return this.authorityCheckerService.findPositiveAuthorityRestrictionsByActionType('ROLE_ACCESS_DELIVERY_DELIVERY_NUMBER', this.get('transportTypeId'));
    }),
    showDeliveryInvoiceNumber: Ember.computed('transportTypeId', function () {
      return this.authorityCheckerService.findPositiveAuthorityRestrictionsByActionType('ROLE_ACCESS_DELIVERY_INVOICE_NUMBER', this.get('transportTypeId'));
    }),
    showNetWeight: Ember.computed('transportTypeId', function () {
      return this.authorityCheckerService.findPositiveAuthorityRestrictionsByActionType('ROLE_ACCESS_DELIVERY_NET_WEIGHT', this.get('transportTypeId'));
    }),
    showGrossWeight: Ember.computed('transportTypeId', function () {
      return this.authorityCheckerService.findPositiveAuthorityRestrictionsByActionType('ROLE_ACCESS_DELIVERY_GROSS_WEIGHT', this.get('transportTypeId'));
    }),
    showDescription: Ember.computed('transportTypeId', function () {
      return this.authorityCheckerService.findPositiveAuthorityRestrictionsByActionType('ROLE_ACCESS_DELIVERY_DESCRIPTION', this.get('transportTypeId'));
    }),
    showFreightCost: Ember.computed('transportTypeId', function () {
      return this.authorityCheckerService.findPositiveAuthorityRestrictionsByActionType('ROLE_ACCESS_DELIVERY_FREIGHT_COST', this.get('transportTypeId'));
    })
  });

  _exports.default = _default;
});