define("apollo/pods/components/transports/transports-table/intable-column-names/component", ["exports", "apollo/mixins/form-utils-mixin"], function (_exports, _formUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Component.extend(_formUtilsMixin.default, {
    sessionAccount: service('session-account'),
    // Używane w hbs.
    tagName: 'tr',
    classNames: ['column-names'],
    actions: {}
  });

  _exports.default = _default;
});