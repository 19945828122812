define("apollo/translations/de", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "CARRIER": {
      "label": "Frachtführer",
      "panel": {
        "label": "Registerkarte \"Frachtführer\""
      }
    },
    "CLIENT": {
      "panel": {
        "label": "Registerkarte \"Kunde\""
      }
    },
    "COMPANY": {
      "panel": {
        "label": "Registerkarte \"Unternehmen\""
      }
    },
    "OPERATOR": {
      "label": "Betreiber",
      "panel": {
        "label": "Registerkarte \"Betreiber\""
      }
    },
    "SERVICE": {
      "panel": {
        "label": "Registerkarte \"Dienstleistung\""
      }
    },
    "SERVICEMAN": {
      "panel": {
        "label": "Registerkarte \"Dienstleister\""
      }
    },
    "SUPPLIER": {
      "label": "Lieferant",
      "panel": {
        "label": "Registerkarte \"Lieferant\""
      }
    },
    "address": {
      "addNewAddress": "Neue Adresse hinzufügen",
      "autoCompletePlaceholder": "Beginnen Sie mit der Eingabe, um nach einem Ort, einer Straße usw. zu suchen.",
      "chooseCompanyFirst": "Wählen Sie zuerst ein Unternehmen aus.",
      "city": "Stadt",
      "country": "Land",
      "deleteAddress": "Möchten Sie diese Adresse wirklich löschen?",
      "errors": {},
      "label": "Adresse",
      "newAddress": "Neue Adresse",
      "postal": "Postleitzahl",
      "province": "Bundesland",
      "recipient": "Empfänger",
      "removeAddress": "Adresse entfernen",
      "street": "Straße und Hausnummer",
      "voivodeship": "Woiwodschaft"
    },
    "administration": {
      "newPrincipal": {
        "aliasHint": "Der Alias muss aus einem Wort (vorzugsweise dem Namen des Kunden) in Kleinbuchstaben bestehen (z. B. logintegra).",
        "baseData": "Grundlegende Angaben zum Unternehmen",
        "companyAddress": "Hauptsitz",
        "create": "Neuen Auftraggeber erstellen",
        "creation": "Neuer Auftraggeber wird erstellt",
        "successMsg": "Der neue Auftraggeber wurde gespeichert. Sie können sich nun bei folgendem Konto anmelden: {username}",
        "userData": "Benutzerdaten (SUPER_ADMIN)"
      }
    },
    "advice": {
      "add": "Lieferavis hinzufügen",
      "carRegistrationNumber.placeholder": "z. B. GDA12345",
      "carRegistrationNumberLabel": {
        "TRUCK": "Fahrzeugkennzeichen",
        "VEHICLE": "Fahrzeugkennzeichen"
      },
      "carRegistrationNumberSimple": "Registrierungsnummer",
      "creator": "Ersteller eines Lieferavis",
      "dateCreated": "Erstellt am",
      "documentNumber": "Dokumentennummer des Fahrers",
      "documentNumber.placeholder": "z. B. 78646003",
      "documentType": {
        "ID_CARD": "Personalausweis-Nr.",
        "PASSPORT": "Reisepass"
      },
      "driverId": "ID-Nummer des Fahrers",
      "driverLanguage": "Sprache des Fahrers",
      "driverMobilePhoneNumber": "Mobilfunknummer",
      "driverMobilePhonePrefix": "Vorwahl der Mobilfunknummer des Fahrers",
      "driverName": "Vor- und Nachname des Fahrers",
      "driverName.placeholder": "z. B. Jan Kowalski",
      "errors": {
        "carRegistrationNumber": {
          "regex": "Die Registrierungsnummer muss zwischen 4 und 50 Zeichen lang sein und darf nur Buchstaben, Zahlen und die Zeichen . , ; / \\\\ & _ enthalten."
        },
        "driverMobilePhoneNumber": {
          "regex": "Die Telefonnummer in Polen muss neunstellig sein."
        },
        "rodoAccepted": {
          "notSelected": "Die Daten müssen legal verarbeitet werden"
        },
        "saveAsResource": {
          "info": "Die Pflichtfelder im Abschnitt für das Lieferavis müssen ausgefüllt werden, damit der Fahrer in der Ressourcenliste gespeichert werden kann."
        },
        "termsOfConditionsAccepted": {
          "notSelected": "Sie müssen den allgemeinen Geschäftsbedingungen zustimmen, bevor Sie ein Lieferavis erstellen können."
        },
        "trailerRegistrationNumber": {
          "regex": "Das Kennzeichen eines Anhängers/Sattelaufliegers muss zwischen 4 und 50 Zeichen lang sein und darf nur Buchstaben, Zahlen und die Zeichen . , ; / \\\\ & _ enthalten."
        }
      },
      "label": "Lieferavis",
      "pasteFromExcel": "Lieferavisdaten einfügen",
      "rodo": "Logintegra sp. z o.o. ist berechtigt, in das Formular eingegebene Daten zu verarbeiten.",
      "saveAsResource": "Fahrer- und Fahrzeugdaten in der Ressourcenliste speichern",
      "termsOfConditionsAccepted": "Ich stimme Folgendem zu: ",
      "trailerRegistrationNumber": "Kennzeichen des Anhängers/Sattelaufliegers",
      "trailerRegistrationNumber.placeholder": "z. B. GDA6789",
      "transport": "Transport"
    },
    "afterRegistration": {
      "addWarehouseButton": "Lager hinzufügen",
      "info": "Eröffnen Sie Ihr eigenes Lager und laden Sie Mitarbeiter zur Planung von Transporten und Lieferavisen ein.",
      "invitationButton": "Einladungsliste",
      "label": "Herzlich willkommen!",
      "title": "Sie sind auf dem besten Weg, sich von Warteschlangen zu verabschieden!"
    },
    "algorithmSettings": {
      "deleteSetting": "Möchten Sie diese Einstellung wirklich löschen?",
      "deletionSuccessMessage": "Die Algorithmuseinstellungen für {n} Paletten wurden gelöscht.",
      "label": "Einstellungen des Algorithmus zur Berechnung der Zeitfenstergröße",
      "loadTimeInMinutes": "Beladedauer in Minuten",
      "numberOfPallets": "Anzahl der Paletten (Obergrenze)",
      "saveSuccessMessage": "Die Algorithmuseinstellungen wurden gespeichert."
    },
    "announcement": {
      "errors": {
        "companies": {
          "chooseAtLeastOneCompany": "Wählen Sie mindestens ein Unternehmen aus."
        },
        "roleGroups": {
          "chooseAtLeastOneRoleGroup": "Wählen Sie mindestens eine Rollengruppe aus."
        }
      }
    },
    "announcements": {
      "add": "Ankündigung hinzufügen",
      "announcementInEnglish": "Ankündigung (Englisch)",
      "companies": "Die Unternehmen, denen die Ankündigung angezeigt werden:",
      "content": "Inhalt der Nachricht",
      "contentEn": "Inhalt der Nachricht (EN)",
      "dateCreated": "Datum der Erstellung",
      "dateFrom": "Gültigkeitsdatum (von)",
      "dateTo": "Gültigkeitsdatum (bis)",
      "delete": "Möchten Sie diese Ankündigung wirklich löschen?",
      "edit": "Ankündigung bearbeiten",
      "expirationDates": "Gültigkeitsdaten",
      "label": "Ankündigungen",
      "lastEditor": "Zuletzt bearbeitet von",
      "lastUpdated": "Zuletzt bearbeitet am",
      "manage": "Ankündigungen verwalten",
      "noActiveAnnouncements": "Derzeit sind keine aktiven Ankündigungen vorhanden.",
      "noAnnouncements": "Keine Ankündigungen",
      "range": "Benutzerauswahl",
      "ranges": {
        "COMPANY": "Je nach Unternehmen der Benutzer",
        "ROLE_GROUP": "Nach den Rollen der Benutzer"
      },
      "roleGroups": "Die Benutzer mit den entsprechenden Rollen, denen die Ankündigung angezeigt werden:",
      "subject": "Betreff der Nachricht",
      "subjectEn": "Betreff der Nachricht (EN)",
      "type": "Administrative Ankündigung"
    },
    "anti-corruption-section": {
      "errors": {
        "signedFiles": "Einem Verifizierungsantrag muss eine unterzeichnete Antikorruptionserklärung hinzugefügt werden."
      },
      "internalNotes": "Anmerkungen",
      "patternFileDescription": "Formularvorlage",
      "signedFile": "Signierte Datei hinzufügen",
      "statementFileDescription": "Von {company} signierte Erklärung zum Download"
    },
    "apiIntegrationLogs": {
      "event": {
        "CHECKPOINT_CONFIRMED": "Kontrollpunkt bestätigt",
        "TRANSPORT_CREATED": "Transport durch öffentliche API erstellen",
        "TRANSPORT_UPDATED": "Transport durch öffentliche API aktualisieren",
        "TRANSPORT_UPDATED_MANUAL": "Transport aktualisieren"
      }
    },
    "appliesSettings": {
      "add": "Bewerbungseinstellungen hinzufügen",
      "description": {
        "createAntiCorruptionSection": "Abschnitt zur Korruptionsbekämpfung zu neuen Bewerbungen hinzufügen",
        "showPatternToDownload": "Formular zum Herunterladen anzeigen",
        "showStatementToDownload": "Unterzeichnete Erklärung des Auftraggebers zum Herunterladen anzeigen"
      },
      "label": "Bewerbungseinstellungen",
      "noSettings": "Noch keine Bewerbungseinstellungen vorhanden"
    },
    "assortmentGroup": {
      "add": "Sortimentsgruppe hinzufügen",
      "adviceFromTime": "Lieferavis von",
      "adviceToTime": "Lieferavis bis",
      "confirmDeletion": "Möchten Sie die Sortimentsgruppe wirklich löschen?",
      "confirmEdition": "Anmerkung. Die Zeiten für das Lieferavis zu Sortimentsgruppen wurden geändert. Möchten Sie wirklich fortfahren?",
      "errors": {
        "adviceFromTime": {
          "invalidAssortmentGroupAdviceFromTime": "Das Lieferavis muss innerhalb der Arbeitszeiten des Lagers liegen."
        },
        "adviceToTime": {
          "invalidAssortmentGroupAdviceToTime": "Das Lieferavis muss innerhalb der Arbeitszeiten des Lagers liegen."
        },
        "nameEn": {
          "minLength": "Diese Eingabe muss mindestens drei Zeichen enthalten."
        },
        "namePl": {
          "minLength": "Diese Eingabe muss mindestens drei Zeichen enthalten."
        }
      },
      "label": "Sortimentsgruppe",
      "listLabel": "Sortimentsgruppen",
      "transportTypes": "Transportarten, für die die Sortimentsgruppe verfügbar ist"
    },
    "auction": {
      "AUCTION": {
        "actualFinishTime": "Tatsächliches Ende der Auktion (Uhrzeit)",
        "buttonLabel": "+ Auktion",
        "creator": "Ersteller der Auktion",
        "currency": "Auktionswährung",
        "dateCreated": "Auktion erstellt am",
        "endedWithoutOffers": "Auktion wurde ohne Gebote beendet",
        "finish": "Auktion beenden",
        "inProgress": "Auktion endet am",
        "initialFinishTime": "Geplantes Ende der Auktion (Uhrzeit)",
        "label": "Auktion",
        "noPreviousAuctions": "Keine vorherigen Auktionen",
        "participants": "An der Auktion teilnehmende Unternehmen",
        "plannedFinishTime": "Das Enddatum der Auktion",
        "previousAuctions": "Vorherige Auktionen",
        "reject": {
          "alert": "Möchten Sie diese Auktion wirklich ablehnen?",
          "fail": {
            "CannotRejectOffersExist": "Sie können diese Auktion nicht ablehnen. Ihr Unternehmen hat bereits ein Gebot abgegeben."
          },
          "label": "Kein Interesse",
          "success": "Auktion abgelehnt"
        },
        "save": "Auktion speichern",
        "savingInProgress": "Auktion wird gespeichert ...",
        "selectParticipants": "Auktionsteilnehmer auswählen"
      },
      "INQUIRY": {
        "actualFinishTime": "Tatsächliches Ende (Uhrzeit)",
        "buttonLabel": "Angebotsanfrage",
        "creator": "Ersteller der Angebotsanfrage",
        "currency": "Währung",
        "dateCreated": "Erstellt am",
        "endedWithoutOffers": "Angebotsanfrage ohne Angebote beendet",
        "finish": "Angebotsanfrage beenden",
        "inProgress": "Ablaufdatum der Angebotsanfrage",
        "initialFinishTime": "Geplantes Ende (Uhrzeit)",
        "label": "Angebotsanfrage",
        "noPreviousAuctions": "Keine vorherigen Angebotsanfragen",
        "participants": "An der Angebotsanfrage teilnehmende Unternehmen",
        "plannedFinishTime": "Enddatum der Angebotsanfrage",
        "previousAuctions": "Vorherige Angebotsanfragen",
        "reject": {
          "alert": "Möchten Sie diese Angebotsanfrage wirklich ablehnen?",
          "fail": {
            "CannotRejectOffersExist": "Sie können diese Angebotsanfrage nicht ablehnen. Ihr Unternehmen hat bereits ein Gebot abgegeben."
          },
          "label": "Kein Interesse",
          "success": "Angebotsanfrage abgelehnt"
        },
        "save": "Angebotsanfrage erstellen",
        "savingInProgress": "Angebotsanfrage wird gespeichert ...",
        "selectParticipants": "Teilnehmer für die Angebotsanfrage auswählen"
      },
      "VALUATION": {
        "actualFinishTime": "Endgültiger Schlusstermin für die Gebotsabgabe",
        "buttonLabel": "+ Bewertung",
        "creator": "Ersteller der Bewertung",
        "currency": "Währung",
        "dateCreated": "Erstellt am",
        "endedWithoutOffers": "Bewertung ohne Angebote beendet",
        "finish": "Bewertung beenden",
        "inProgress": "Bewertung endet am",
        "initialFinishTime": "Geplanter Schlusstermin für die Gebotsabgabe",
        "label": "Bewertung",
        "noPreviousAuctions": "Keine vorherigen Bewertungen",
        "participants": "Am Bieterverfahren teilnehmende Unternehmen",
        "plannedFinishTime": "Das Enddatum für die Gebotsabgabe",
        "previousAuctions": "Vorherige Bewertungen",
        "reject": {
          "alert": "Möchten Sie diese Bewertung wirklich ablehnen?",
          "fail": {
            "CannotRejectOffersExist": "Sie können diese Bewertung nicht ablehnen. Ihr Unternehmen hat bereits ein Gebot abgegeben."
          },
          "label": "Kein Interesse",
          "success": "Bewertung abgelehnt"
        },
        "save": "Bewertung speichern",
        "savingInProgress": "Bewertung wird gespeichert ...",
        "selectParticipants": "Teilnehmer für das Bieterverfahren auswählen"
      },
      "bid": "Gebot",
      "blockedCarriers": "Geblockte Frachtführerunternehmen",
      "buttonLabel": "+ Auktion",
      "buyNow": "Annehmen",
      "buyNowConfirmation": "Sind Sie sicher, dass Sie den Auftrag {transportIdentifier} zum Tarif von {minPrice} {currencySymbol} annehmen möchten?",
      "buyNowHeader": "Angebot sofort annehmen und Auftrag für den Mindesttarif erhalten",
      "buyNowLabel": "Mindestangebot",
      "create": "Auktion erstellen",
      "createTitle": "Auktion für einen Transport erstellen {transportIdentifier}",
      "currentOffer": "Aktuelles Angebot",
      "details": "Verlauf",
      "errors": {
        "companies": {
          "empty": "Wählen Sie mindestens ein Unternehmen aus."
        },
        "maxPrice": {
          "invalidNumber": "Der Höchstpreis muss eine Zahl größer als null sein.",
          "maxPriceLowerThanMinPrice": "Der Höchstpreis darf nicht niedriger als der Mindestpreis sein."
        },
        "minPrice": {
          "invalidNumber": "Der Mindestpreis muss eine Zahl größer oder gleich null sein."
        },
        "plannedFinishTime": {
          "isInPast": "Das Enddatum muss in der Zukunft liegen."
        }
      },
      "finishTimeChanged": "Das Enddatum {date} wurde geändert.",
      "label": "Auktion",
      "lastUpdated": "Zuletzt aktualisiert",
      "maxPrice": "Max. Preis",
      "minPrice": "Min. Preis",
      "noOffers": "Keine gültigen Angebote",
      "plannedFinishTimeAbbr": "Gepl.",
      "priceRange": "Preisspanne",
      "selectGroup": "Gruppe auswählen ",
      "transport": "Transport"
    },
    "auth": {
      "accountActivated": "Das Konto wurde aktiviert.",
      "accountExpired": "Ihr Konto wurde aufgrund längerer Inaktivität gesperrt. Der Link zur Aktivierung des Kontos wurde an die mit dem Konto verknüpfte E-Mail-Adresse gesendet. Bei Problemen wenden Sie sich bitte unter {supportEmail} an uns oder rufen Sie uns unter der folgenden Nummer an: {supportPhone}.",
      "accountLocked": "Aufgrund mehrerer fehlgeschlagener Anmeldeversuche wurde Ihr Konto für {arg} Minuten gesperrt. Versuchen Sie es nach {arg} Minuten erneut oder setzen Sie Ihr Passwort zurück.",
      "accountLockedByAdmin": "Ihr Konto wurde vom Systemadministrator gesperrt.",
      "activateAccount": "Konto aktivieren",
      "alreadyRegistered": "Sie haben bereits ein Konto?",
      "changePassword": {
        "false": "Passwort ändern",
        "true": "Passwort nicht ändern"
      },
      "confirmPassword": "Passwort bestätigen",
      "description": "Logistikplattform",
      "email": "E-Mail-Adresse",
      "emailPlaceholder": "E-Mail-Adresse eingeben",
      "fail": "Protokollierung fehlgeschlagen",
      "footer": "Ausgefertigt von",
      "impersonate": "Beim Benutzerkonto anmelden",
      "invalidLoginOrPassword": "Benutzername oder Passwort ungültig.",
      "login": "Benutzername",
      "loginPlaceholder": "E-Mail-Adresse eingeben",
      "loginWithActiveDirectory": "Mit Active Directory anmelden",
      "logout": "Abmelden",
      "logoutByConcurrentSession": "Sie wurden abgemeldet, weil Sie dieses Konto auf einem anderen Gerät verwenden.",
      "logoutByService": "Sie wurden aus administrativen Gründen abgemeldet oder weil Sie das System längere Zeit nicht verwendet haben.",
      "newPassword": "Neues Passwort",
      "noLoginAndPassword": "Bitte geben Sie Ihren Benutzernamen und Ihr Passwort ein.",
      "notRegistered": "Sie haben noch kein Konto?",
      "oldPassword": "Altes Passwort",
      "or": "Oder",
      "password": "Passwort",
      "passwordChanged": "Passwort für Benutzer {username} wurde geändert.",
      "passwordPlaceholder": "Passwort eingeben",
      "passwordRemindedMessage": "Wenn die angegebene Adresse {email} vorhanden ist, wird eine Nachricht gesendet.",
      "remind": "Erinnern",
      "remindEmail": "Geben Sie die mit Ihrem Konto verknüpfte E-Mail-Adresse ein. Wir senden an diese einen Link zum Zurücksetzen des Passworts:",
      "remindFail": "Vorgang fehlgeschlagen",
      "remindHeader": "Passworterinnerung erhalten",
      "remindLink": "Passwort vergessen?",
      "remindingInProgress": "Die E-Mail wird gesendet.",
      "repeatNewPassword": "Neues Passwort wiederholen",
      "repeatPassword": "Passwort wiederholen",
      "serverError": "Der Server antwortet nicht. Versuchen Sie es in einigen Minuten erneut oder wenden Sie sich unter info@logintegra.com an uns.",
      "signin": "Anmelden",
      "username": "Benutzername"
    },
    "authorityGroup": {
      "ADVICE": "Lieferavis",
      "ANNOUNCEMENT": "Ankündigungen",
      "API": "API",
      "APPLIES": "Frachtführeranwendungen",
      "ASSORTMENT_GROUP": "Sortimentsgruppen",
      "AUCTION": "Auftragsübergabe, Auktionen",
      "COMPANY": "Unternehmen",
      "COURIER": "Kuriere",
      "CUSTOM_FIELD": "Benutzerdefinierte Felder",
      "DELIVERY": "Versandplanung",
      "INDEX": "Indizes",
      "MAIN_TRANSPORT_DATA": "Die Hauptfelder des Auftragsformulars",
      "MODE_OF_TRANSPORTATION": "Überprüfung des Verkehrsträgers",
      "NOTES_AND_DOCUMENTS": "Anmerkungen, Dokumente",
      "OTHERS": "Sonstiges",
      "PACKAGE": "Pakete",
      "PRICE_LIST": "Preislisten",
      "PRODUCT": "Produkte",
      "QUEUE": "Warteschlangen",
      "SETTINGS": "Einstellungen",
      "TASKS": "Kontrollpunkte",
      "TIME_WINDOW": "Zeitfenster",
      "TRANSPORT": "Transport, Auftrag",
      "USER": "Benutzer",
      "WAREHOUSE": "Lager",
      "WEIGHT": "Wiegen"
    },
    "blockades": {
      "invalidSection": "Abgelehnter oder ungültiger Abschnitt",
      "noActiveBlockades": "Keine aktiven Blockaden"
    },
    "checkpoint": {
      "actualExecutionDate": "Tatsächliches Bestätigungsdatum",
      "actualExecutionDateLabel": "Geben Sie das tatsächliche Datum an, an dem der Status bestätigt wurde.",
      "blockLabel": "Sperren",
      "blockText": "Dieser Status wurde gesperrt und kann nicht bestätigt werden. {name}",
      "blockTextShort": "Gesperrt von {name}",
      "confirmator": "Bestätigt durch",
      "errors": {
        "blockadeReason": {
          "length": "Die maximale Zeichenanzahl beträgt 255."
        },
        "executionDate": {
          "isInPast": "Das angegebene Datum muss in der Vergangenheit liegen und darf nicht vor dem Bestätigungsdatum des vorherigen Status liegen."
        }
      },
      "executionDate": "Datum der Bestätigung des Status",
      "reject": "Ablehnen",
      "rejectConfirm": "Möchten Sie diesen Status wirklich ablehnen? Wenn Sie die Ablehnung bestätigen, wird der Fahrer zurückgeschickt und an den Kunden und den Frachtführer wird eine E-Mail-Benachrichtigung gesendet.",
      "rejectionDate": "Letztes Ablehnungsdatum: ",
      "revokeAdviceFirst": "Um diesen Schritt zu widerrufen, müssen Sie zunächst die mit dem Hinweis und/oder dem Zeitfenster verbundenen Schritte widerrufen",
      "unblockLabel": "Entsperren"
    },
    "checkpoints": {
      "confirm": "Bestätigen",
      "confirmAfterTime": "Status mit Verzögerung bestätigt",
      "confirmBeforeTime": "Status vor Fälligkeit bestätigt",
      "confirmStatus": "Status bestätigen",
      "confirmedMessage": "Best. am {date}",
      "confirmedMessageBy": "von {person}",
      "empty": "Keine Status",
      "plan": "Gepl.",
      "processFinished": "Der Vorgang ist abgeschlossen.",
      "revokeConfirm": "Möchten Sie die Bestätigung dieses Status wirklich widerrufen?",
      "revokeManyConfirm": "Anmerkung: Da es sich nicht um den letzten bestätigten Status handelt, werden wir auch die Bestätigungen aller späteren Status widerrufen. Möchten Sie wirklich fortfahren?"
    },
    "classifications": {
      "successMessage": "Das Fahrzeug {registrationNumber} wurde folgender Warteschlange zugewiesen: {squareName} - {queueName}"
    },
    "cmr-setting": {
      "errors": {
        "senderInstructionText": {
          "maxLineCountIs8": "In diesem Feld können maximal 8 Zeilen angezeigt werden."
        }
      }
    },
    "cmrSettings": {
      "add": "CMR-Einstellungen hinzufügen",
      "fields": {
        "appendedDocsSpecificationCustomField": "Benutzerdefinierten Wert in Feld Nr. 5 (Angefügte Dokumente) hinzufügen",
        "appendedDocsSpecificationNumber": "Nummer in Feld Nr. 5 (Angefügte Dokumente) hinzufügen",
        "appendedDocsText": "Fester Text in Feld Nr. 5 (Angefügte Dokumente)",
        "featuresAndNumbersCustomField": "Benutzerdefinierten Wert in Feld Nr. 6 (Markierungen und Nummern) hinzufügen",
        "featuresAndNumbersText": "Fester Text in Feld Nr. 6 (Markierungen und Nummern)",
        "packageTypeText": "Fester Text in Feld Nr. 8 (Art der Verpackung)",
        "quantityCustomField": "Benutzerdefinierten Wert in Feld Nr. 7 (Anzahl der Pakete) hinzufügen",
        "quantityText": "Fester Text in Feld Nr. 7 (Anzahl der Pakete)",
        "senderInstructionCustomField": "Benutzerdefinierten Wert in Feld Nr. 13 (Anweisungen des Absenders) hinzufügen",
        "senderInstructionText": "Fester Text im Feld Nr. 13 (Anweisungen des Absenders)",
        "showPrincipalName": "In Feld Nr. 1 (Absender) den Namen des Auftraggebers anzeigen",
        "showWarehouseName": "In Feld Nr. 1 (Absender) den Namen des Lagers anzeigen",
        "weightGrossCustomField": "Benutzerdefinierten Wert in Feld Nr. 11 (Bruttogewicht in kg) hinzufügen"
      },
      "label": "CMR-Einstellungen",
      "noSettings": "Noch keine CMR-Einstellungen vorhanden",
      "senderInstructionTextHint": "Dieses Feld darf maximal 8 Zeilen enthalten. Die maximale Länge der Zeilen ist begrenzt. Bei einer Überschreitung sind diese auf dem gedruckten CMR nicht vollständig sichtbar."
    },
    "columns": {
      "ADVICE": "Lieferavis",
      "ALT_IDENTIFIER": "Alternative Kennung",
      "ARRIVAL_DATE": "Lieferung",
      "CAR": "Fahrzeug",
      "CARRIER": "Frachtführer",
      "CONTRACTOR": "Auftragnehmer",
      "CONTRACTOR_FULL_NAME": "Auftragnehmer – vollständige Bezeichnung",
      "CONTRACTOR_SHORT_NAME": "Auftragnehmer – Kurzbezeichnung",
      "CREATION_DATE": "Erstellungsdatum",
      "CUSTOM_FIELDS": "Benutzerdefinierte Felder",
      "DELIVERY_INCOTERMS": "Incoterms",
      "DELIVERY_INVOICE": "Lieferrechnung",
      "DELIVERY_NETTO_WEIGHT": "Nettogewicht [kg]",
      "DELIVERY_NUMBER": "Lieferungsnummer",
      "DOCUMENTS": "Dokumente",
      "DOCUMENT_IDENTIFIER": "Dokument-ID",
      "DRIVER": "Fahrer",
      "DRIVER_IDENTIFIER": "Fahrer-ID",
      "DRIVER_PHONE": "Telefonnummer",
      "EMAIL_ADVICE": "E-Mail-Lieferavis",
      "EMAIL_ADVICE_EMAIL_ADDRESS": "E-Mail-Adresse",
      "IDENTIFIER": "ID",
      "INDEX_DOCUMENT_NUMBER": "Auftragsnummer",
      "INDEX_PRODUCTS": "Produkte",
      "INDEX_TRANSPORT_NUMBER": "Transport-Nr.",
      "INVOICE": "Rechnung",
      "LOAD_CITY": "Stadt",
      "LOAD_COUNTRY": "Land",
      "LOAD_PLACE": "Beladeplatz",
      "LOAD_QUANTITY": "Anzahl der geladenen Paletten",
      "LOAD_TIME_WINDOW": "Beladen",
      "LOAD_WAREHOUSE": "Lager",
      "LOCATION": "Standort",
      "NOTES": "Anmerkungen",
      "PERIODIC_TRANSPORT_INTERNAL_INDEX": "Nr. des periodischen Lieferavis",
      "PRICE": "Preis",
      "QUANTITY": "Anzahl der Paletten",
      "RAMP": "Rampen-Nr.",
      "SERVICE_COMPANY_SHORT_NAME": "Service",
      "STATUS": "Status",
      "STORAGE_ZONE": "Typ",
      "SUM_LOAD_QUANTITY": "Summe der geladenen Paletten",
      "SUM_UNLOAD_QUANTITY": "Summe der entladenen Paletten",
      "TOTAL_WEIGHT": "Gewicht[kg]",
      "TRAILER": "Anhänger/Sattelauflieger",
      "TRANSPORT_DESCRIPTION": "Anmerkungen",
      "TRANSPORT_RELATION": "Beziehungsnummer",
      "TRANSPORT_SUPERVISOR": "Ansprechpartner",
      "TRUCK_TYPE": "Transportart",
      "UNLOAD_CITY": "Stadt",
      "UNLOAD_COUNTRY": "Land",
      "UNLOAD_PLACE": "Ort der Lieferung",
      "UNLOAD_QUANTITY": "Anzahl der entladenen Paletten",
      "UNLOAD_TIME_WINDOW": "Entladen",
      "UNLOAD_WAREHOUSE": "Lager",
      "VEHICLE_TRUCK_TYPE": "Fahrzeugtyp",
      "WORKER": "Mitarbeiter",
      "disableCustomFieldInfo": "Durch Aktivieren/Deaktivieren dieses Elements werden alle in der Tabelle angezeigten customField-Felder aktiviert/deaktiviert.",
      "label": "Anzeige der Spalten"
    },
    "common": {
      "accept": "Annehmen",
      "accepted": "Angenommen",
      "active": "Aktiv",
      "add": "Hinzufügen",
      "addedBy": "hinzugefügt von",
      "address": "Adresse",
      "allRightsReserved": "Alle Rechte vorbehalten",
      "and": "und",
      "andBetween": "und",
      "appVersion": "Build",
      "autoCompleteBasicPlaceholder": "Schreiben Sie an die Suche...",
      "back": "Zurück",
      "browseAndUploadDocuments": "Dokumente durchsuchen und hochladen",
      "by": "von",
      "byYou": "von Ihnen",
      "cancel": "Abbrechen",
      "cancelProcess": "Vorgang abbrechen",
      "changeOfField": "Feldänderung",
      "check": "prüfen",
      "choosingReason": "Grund",
      "clear": "Löschen",
      "clearFilters": "Filter löschen",
      "clearTextArea": "Inhalte dieses Textbereichs löschen",
      "client": "Kunde",
      "close": "Schließen",
      "company": "Unternehmen",
      "confirmMessage": "Sind Sie sicher?",
      "createSimilar": "Ähnliche erstellen",
      "currency": "Währung",
      "dateCreated": "Erstellt am",
      "day": "Tag",
      "days2": "2 Tage",
      "days4": "4 Tage",
      "daysOfWeek": {
        "1": "Mo",
        "2": "Di",
        "3": "Mi",
        "4": "Do",
        "5": "Fr",
        "6": "Sa",
        "7": "So",
        "label": "für Tage"
      },
      "dedicatedWindow": "Fest zugewiesenes Zeitfenster",
      "delete": "Löschen",
      "deleteTitle": "Diesen Transport löschen (Gelöschte Transporte werden im Archiv angezeigt.)",
      "deletingProgress": "wird gelöscht",
      "desc": "Beschreibung",
      "description": "Beschreibung",
      "details": "Details",
      "disable": "Deaktivieren",
      "disableXLS": "Geben Sie den Datumsbereich an oder filtern Sie die Suchergebnisse, um die Datei im XLS-Format zu exportieren.",
      "download": "Herunterladen {name}",
      "downloadFile": "Datei herunterladen",
      "downloadManualHeader": "Manuell herunterladen",
      "driverMobilePhoneNumber.placeholder": "z. B. 111222333",
      "driverMobilePhonePrefix.placeholder": "z. B. 48",
      "edit": "Bearbeiten",
      "enable": "Aktivieren",
      "english": {
        "abbr": "eng.",
        "label": "englisch"
      },
      "error": "Fehler",
      "errorCode": "Fehlercode",
      "errorList": "Fehlerliste",
      "export": "Exportieren",
      "exportAsXLS": "Export als XLS.",
      "finishedWindow": "Prozess abgeschlossen",
      "forDateRange": {
        "M": "Monat",
        "Y": "Jahr"
      },
      "formShouldNotBeEmpty": "In das Formular müssen Daten eingegeben werden!",
      "from": "von",
      "fromCompany": "vom Unternehmen",
      "further": "Weiter",
      "grayWindow": "Geblocktes Zeitfenster",
      "grossWeightKg": "Bruttogewicht [kg]",
      "hint": "Hinweis:",
      "hour": "Stunde",
      "id": "ID",
      "idx": "Index",
      "instructionalVideo": "Anleitungsvideo",
      "interval": {
        "M": "{count} {count, plural, one {month} other {months}}",
        "d": "{count} {count, plural, one {day} other {days}}"
      },
      "language": {
        "ENG": "ENG",
        "PL": "DE"
      },
      "lastUpdated": "Letzte Änderung",
      "lateWindow": "Verspätetes Zeitfenster",
      "legend": "Legende",
      "loadingData": "Daten werden geladen ...",
      "max": "Max.",
      "maxDate": "Max. Datum",
      "message": "Mitteilung",
      "min": "Min.",
      "minDate": "Min. Datum",
      "more": "Mehr",
      "name": "Bezeichnung",
      "nameEn": "Englische Bezeichnung",
      "namePl": "Deutsche Bezeichnung",
      "netWeightKg": "Nettogewicht [kg]",
      "newMessagesTitle": "Sie haben neue Nachrichten.",
      "next": "Weiter",
      "no": "Nein",
      "noAdviceAddedYet": "Noch kein Lieferavis hinzugefügt",
      "noAuthority": "Sie sind nicht berechtigt, diese Aktion durchzuführen.",
      "noData": "Keine Daten",
      "noResults": "Ihre Anfrage hat keine Ergebnisse ergeben.",
      "notSpecified": "Nicht angegeben",
      "ok": "OK",
      "oldBrowser": "Sie verwenden einen veralteten Browser. Unsere App funktioniert besser mit einem modernen Browser wie Google Chrome, Firefox, Opera, Vivaldi, Safari oder IE Edge.",
      "onDay": "am",
      "operationInProgress": "Vorgang läuft ...",
      "options": "Optionen",
      "or": "oder",
      "order": "Bestellung",
      "orderNumber": "Auftragsnr.",
      "other": "Sonstiges",
      "otherRequirements": "Sonstige Anforderungen",
      "otherRequirementsVisibleToCarrier": "Sonstige Anforderungen (Das Feld wird dem Frachtführer angezeigt.)",
      "outOf": "von",
      "perPage": "pro Seite",
      "phone": "Telefonnummer",
      "plan": "Planen",
      "pleaseWait": "Bitte warten ...",
      "previous": "Zurück",
      "recipient": "Empfänger",
      "refresh": "Aktualisieren",
      "register": "Beitreten",
      "reject": "Ablehnen",
      "rejected": "Abgelehnt",
      "rememberFilters": "Filter speichern",
      "resend": "Erneut senden",
      "reset": "Zurücksetzen",
      "restore": "Wiederherstellen",
      "role": "Rolle",
      "save": "Speichern",
      "saveAndClose": "Speichern und schließen",
      "saveSuccessAdditionalMessage": "Seite aktualisieren, um Änderungen anzuzeigen",
      "saveSuccessMessage": "Änderungen erfolgreich gespeichert",
      "saved": "Gespeichert",
      "saving": "Speichern",
      "scrollToClosestTransport": "Zum Transport mit dem nächstgelegenen Datum scrollen",
      "search": "Suche",
      "selectDate": "Datum auswählen",
      "selectDeselectAll": "Alle auswählen/abwählen",
      "selectValue": "Wert auswählen",
      "send": "Senden",
      "settings": "Einstellungen",
      "shortName": "Kurzbezeichnung",
      "shortNameEn": "Englische Kurzbezeichnung",
      "shortNamePl": "Deutsche Kurzbezeichnung",
      "start": "Start",
      "stop": "Stopp",
      "sum": "Gesamt",
      "sure": "Fortfahren?",
      "synchroniseCarriersData": "Trägerdaten synchronisieren",
      "timeSign": {
        "after": "nach",
        "before": "vor"
      },
      "timeUnit": {
        "d": "Tage",
        "h": "Stunden",
        "m": "Minuten",
        "s": "Sekunden"
      },
      "to": "bis",
      "today": "Heute",
      "transportDeletionDisabled": "Sie können diesen Transport nicht löschen. Wenden Sie sich an den Administrator.",
      "transportEditionDisabled": "Sie dürfen diesen Transport nicht bearbeiten.",
      "type": "Typ",
      "update": "Aktualisieren",
      "uploadFile": "Datei hochladen",
      "value": "Wert",
      "waiting": "Ausstehend",
      "week": "Woche",
      "weightAndSave": "Wiegen und speichern",
      "windowMovedAutomatically": "Aufgrund der Verzögerung wurde das Zeitfenster automatisch verschoben.",
      "yes": "Ja"
    },
    "company": {
      "address": "Adresse",
      "addresses": "Adressen",
      "alias": "Alias",
      "allowedDomains": {
        "emailNotMatch": "Sie können nur Personen zu diesem Unternehmen einladen, deren E-Mail-Adresse zu den folgenden Domains gehört: {allowedDomains}",
        "header": "Zulässige Domains für Unternehmen {companyName}",
        "info": "Nur Personen mit einer der zulässigen Domains aus der folgenden Liste können zum Unternehmen eingeladen werden.",
        "label": "Zulässige Domains",
        "list": "Liste der zulässigen Domains:",
        "successDeleteMsg": "Domain wurde gelöscht",
        "successSaveMsg": "Domains wurden gespeichert"
      },
      "chooseCompanyTypeHint": "Geben Sie den Unternehmenstyp an.",
      "chooseCountryHint": "Wählen Sie zuerst das Land aus.",
      "contractorNumber": "Auftragnehmernummer",
      "country": "Land",
      "createCompany": "Unternehmen erstellen",
      "deleteConfirm": "Möchten Sie die Partnerschaft zwischen den Unternehmen {companyName} und {principalName} wirklich löschen? Im Anschluss an diesen Vorgang hat {companyName} keinen Zugang mehr zur {principalName}-Plattform.",
      "deletedMessage": "\"{companyName}\" wurde gelöscht.",
      "descriptionOfTheFleet": "Beschreibung der Flotte",
      "editCompany": "Unternehmen bearbeiten",
      "employees": "Mitarbeiter",
      "errors": {},
      "existingCompanyName": "Das Unternehmen ist bereits auf der Plattform vorhanden. Sie können den Namen nicht bearbeiten.",
      "gusHasProblem": "Keine Verbindung zur Datenbank des Statistischen Hauptamts (GUS). Das Unternehmen wird anhand der Daten des Statistischen Hauptamts (GUS) überprüft, nachdem die Verbindung wiederhergestellt wurde.",
      "krs": "KRS/CEiDG",
      "label": "Unternehmen",
      "legalStatus": {
        "businessActivity": "Geschäftliche Tätigkeit",
        "label": "Rechtsform",
        "limitedCompany": "Unternehmen"
      },
      "limitedDataHint": "Die Anzeige und Bearbeitung bestimmter Details des Unternehmens {company} sind eingeschränkt. Änderungen sind nur durch Administratoren des Unternehmens {company} möglich.",
      "list": "Liste der Unternehmen",
      "maxNumberOfAccounts": "Maximale Anzahl von Konten",
      "maxNumberOfAccountsHint": "Wenn die maximale Anzahl von Konten erreicht ist, können keine neuen Benutzer mehr zu diesem Unternehmen eingeladen werden. Wenn die maximale Anzahl der Konten nicht festgelegt ist, gibt es keine Begrenzung für die Anzahl der Konten.",
      "name": "Bezeichnung",
      "noEmployees": "Dieses Unternehmen hat keine Mitarbeiter.",
      "noPartnershipWarning": "Ihr Unternehmen verfügt nicht über Partnerschaften. Dies kann zu Problemen bei der Auswahl von Auftragnehmern führen.",
      "partnershipStatus": {
        "ACCEPTED": "Angenommen",
        "AWAITING_VERIFICATION": "Verifizierung ausstehend",
        "BLOCKED": "Geblockt",
        "CREATED_FROM_API": "Automatisch hinzugefügt",
        "NO_INVITATION": "Keine Einladung",
        "REGISTRATION_IS_IN_PROGRESS": "Registrierung läuft",
        "REJECTED": "Abgelehnt",
        "UPDATED": "Aktualisiert",
        "WAITING": "Ausstehend",
        "label": "Status"
      },
      "partnershipTable": {
        "awaitingLabel": "Liste der ausstehenden Einladungen",
        "companyName": "Unternehmensname",
        "label": "Liste der verbundenen Unternehmen"
      },
      "patron": "Kunde",
      "patronWillChange": "Der Kunde wird in den Aufträgen {transportIds} geändert. Sind Sie sicher? Wenn Sie \"Abbrechen\" auswählen, werden die Änderungen nicht gespeichert.",
      "possessedCertificates": "Vorhandene Zertifikate",
      "pricingPlan": "Abonnementoption für das Unternehmen",
      "rampEdition": {
        "header": "Rampen für Benutzer des Unternehmens {companyName} bearbeiten",
        "label": "Rampen bearbeiten",
        "updateForAllUsers": "Rampen für alle Benutzer dieses Unternehmens aktualisieren"
      },
      "registrationDate": "Datum der Registrierung",
      "regon": "REGON",
      "saveSuccessMessage": "Unternehmen wurde gespeichert.",
      "sections": "Abschnitte",
      "selectCountry": "Land auswählen",
      "selectType": "Typ auswählen",
      "selectVoivodeship": "Woiwodschaft auswählen",
      "shortName": "Kurzbezeichnung",
      "taxIdNumber": "Steuerzahleridentifikationsnummer (NIP)",
      "type": {
        "CARRIER": "Frachtführer",
        "CLIENT": "Kunde",
        "OPERATOR": "Betreiber",
        "SERVICEMAN": "Dienstleister",
        "SUPPLIER": "Lieferant \\\\ Empfänger",
        "SUPPLIER.CLIENT": "Kunde",
        "SUPPLIER.COLLECTION": "Empfänger",
        "SUPPLIER.COMPANY": "Unternehmen",
        "SUPPLIER.COURIER": "Kurier",
        "SUPPLIER.DELIVERY": "Lieferant",
        "label": "Typ",
        "producer": "Produzent"
      },
      "typeNipHint": "Geben Sie zuerst die NIP ein.",
      "update": "Aktualisieren",
      "verifiedByGus": "Das Unternehmen wurde vom Statistischen Hauptamt (GUS) erfolgreich verifiziert. Sie können den Namen nicht bearbeiten."
    },
    "companyDisabledIcon": {
      "bothInsurancesMissing": "Gesperrt. Keine aktive OCP- und OCS-Versicherung",
      "insuranceAmountTooLow": "Gesperrt. Höhe der Deckung "
    },
    "companyRamp": {
      "notExists": "Dem Unternehmen {companyName} ist noch kein Standardrampenzugriff zugewiesen.",
      "updateForAllUsersConfirmMsg": "Möchten Sie wirklich den Zugriff auf Rampen für alle Benutzer dieses Unternehmens überschreiben?",
      "usersWithIndividualRampAccess": "Liste der Benutzer mit abweichendem Rampenzugriff"
    },
    "contractors-group": {
      "addGroup": "Gruppe hinzufügen",
      "description": {
        "details": "Details",
        "ifYouDoNotHaveDocument": "Deaktivieren Sie die ausgewählte Gruppe, wenn eines der erforderlichen Dokumente nicht vorhanden ist.",
        "label": "Beim Auswählen einer Gruppe müssen die entsprechenden Lizenz- oder Versicherungsdokumente hinzugefügt werden.",
        "notApplicable": "Nicht zutreffend",
        "optionalDocuments": "und eines der folgenden Dokumente:"
      },
      "errors": {
        "obligatoryDocuments": "Für die Zugehörigkeit zur Gruppe {groupName} muss Ihr Unternehmen über folgende Dokumente verfügen: {documents}.",
        "optionalDocuments": "Für die Zugehörigkeit zur Gruppe {groupName} muss Ihr Unternehmen über eines der folgenden Dokumente verfügen: {documents}."
      },
      "label": "Gruppierung",
      "name": "Bezeichnung",
      "nameEn": "Bezeichnung (Englisch)",
      "selectADRInfo": "Nach Auswahl dieser Option wird das Unternehmen in die Gruppe der Frachtführer für Gefahrguttransporte aufgenommen. Stellen Sie sicher, dass Ihr Unternehmen befugt ist, Gefahrgut zu transportieren.",
      "shortName": "Kurzbezeichnung",
      "shortNameEn": "Kurzbezeichnung (Englisch)",
      "unSelectADRInfo": "Nach Deaktivierung dieser Option wird das Unternehmen aus der Gruppe der Frachtführer für Gefahrguttransporte entfernt. Möchten Sie die ADR-Gruppe wirklich verlassen?"
    },
    "contractors-group-type": {
      "ADR": "Einteilung nach Waren- und Gefahrguttransporten",
      "AVAILABLE_VEHICLES": "Einteilung nach verfügbaren Fahrzeugen",
      "PRIVATE": "Private Gruppen, werden dem Frachtführer nicht angezeigt",
      "SUPPORTED_REGIONS": "Einteilung nach unterstützten Regionen",
      "SUPPORTED_VOIVODESHIPS": "Aufteilung nach unterstützten Woiwodschafte",
      "TERRITORIAL_DIVISION": "Territoriale Aufteilung",
      "TYPES_OF_BUSINESS": "Einteilung nach Unternehmenstätigkeit",
      "TYPES_OF_TRANSPORT": "Einteilung nach unterstützten Transportarten",
      "errors": {
        "canSelectOnlyOneContractorsGroup": "Nur eine Gruppe auswählen",
        "requiresContractorsGroup": "Bitte wählen Sie mindestens eine Gruppe aus dem Abschnitt \"{groupType}\" aus."
      }
    },
    "courier": {
      "COURIER_PACKAGE": {
        "add": "Hinzufügen",
        "description": "Inhalt",
        "disableConfirm": "Möchten Sie diesen Artikel wirklich löschen?",
        "edit": "Bearbeiten",
        "height": "Höhe [cm]",
        "internalId": "ID",
        "length": "Länge [cm]",
        "numberOfPackagesTotal": "Gesamtbetrag:",
        "packageIdPlaceholder": "ID",
        "packageType": "Typ",
        "packageTypePlaceholder": "Typ",
        "packageValue": "Warenwert (pro Stück)",
        "packageValueLine": "Warenwert (pro Zeile)",
        "packageWeightTotal": "Gesamtgewicht:",
        "pallet": "PALETTE",
        "parcel": "PAKET",
        "quantity": "Menge",
        "sectionLabel": "Pakete",
        "sum": "Summe:",
        "weight": "Gewicht (pro Stück) [kg]",
        "width": "Breite [cm]"
      },
      "documents": {
        "connote": "Frachtbrief",
        "label": "Beschriftung",
        "manifest": "Ladungsverzeichnis"
      },
      "validation.float.range": "Bei dem Wert muss es sich um eine Zahl zwischen 0,001 und 999999999999 handeln.",
      "validation.integer.range": "Bei dem Wert muss es sich um eine Ganzzahl zwischen 1 und 9999999 handeln.",
      "validation.minimumOnePackage": "Sie müssen mindestens ein gültiges Paket hinzufügen.",
      "validation.range": "Bei dem Wert muss es sich um eine Zahl zwischen 1 und 9999999 handeln."
    },
    "courierSettings": {
      "add": "Einstellungen für Kuriere hinzufügen",
      "description": {
        "isGetCourierQuotesFieldVisibility": "Feld \"Kurierangebote einholen\" anzeigen",
        "shouldGetCourierQuotesByDefault": " Kurierangebote standardmäßig einholen",
        "transportType": "Transportart"
      },
      "label": "Einstellungen für Kuriere",
      "noSettings": "Noch keine Einstellungen für Kuriere vorhanden"
    },
    "custom-field-definition": {
      "addNewField": "Neues Feld hinzufügen",
      "alias": "Alias",
      "amountOfDelayedTimeForTaskToBeRequired": "Betrag der Verzögerungszeit der Etappe, aufgrund derer das Feld erforderlich ist",
      "autocompleteEnabled": "Autovervollständigen aktiviert",
      "cancel": "Möchten Sie die Aktualisierungen wirklich verwerfen?",
      "create": "Formularfelddefinition erstellen",
      "defaultOption": {
        "hint": "Die angegebene Option wird als Standardwert verwendet.",
        "label": "Bezeichnung der Standardoption",
        "placeholder": "Wählen Sie eine der verfügbaren Optionen aus.",
        "title": "Standardoption"
      },
      "delete": "Möchten Sie dieses Feld wirklich löschen?",
      "deleteSuccessMessage": "Die Feldeinstellung wurde gelöscht.",
      "deliveryAlias": "Alias für die MSP-Integration",
      "englishName": "Englische Bezeichnung",
      "errors": {
        "maxValue": {
          "dotSeparator": "Das Dezimaltrennzeichen muss ein Punkt \".\" sein.",
          "maxLowerThenMin": "Der Maximalwert darf nicht kleiner als der Mindestwert sein."
        },
        "minValue": {
          "dotSeparator": "Das Dezimaltrennzeichen muss ein Punkt \".\" sein.",
          "minHigherThanMax": "Der Mindestwert darf nicht größer als der Höchstwert sein."
        },
        "numberDecimalFormat": {
          "regex": "Das Zahlenformat darf nur folgende Zeichen enthalten: # , . 0 i _"
        },
        "obligatory": {
          "invalid": "Wählen Sie mindestens eine der folgenden Optionen aus, wenn Sie das Feld \"Pflichtfeld\" auswählen: \"Pflichtfeld während und nach der Bestätigung einer Aufgabe\", \"Wird bei der Transporterstellung angezeigt\""
        },
        "options": {
          "empty": "Geben Sie mindestens eine Option an."
        },
        "textAreaRowsNumber": {
          "textAreaSize": "Die Anzahl der Zeilen muss größer als 0 sein."
        }
      },
      "filterInArchive": "Filter auf Archiv anwenden",
      "filterLabel": "Benutzerdefinierte Filter:",
      "idx": "Idx",
      "includedInPriceList": "In Preisliste einbeziehen",
      "makeFieldDependentOn": {
        "definitionPlaceholder": "Bezeichnung eines abhängigen Felds auswählen",
        "dependsOnTaskInfo": "Dem ausgewählten Feld ist ein Kontrollpunkt zugewiesen",
        "fieldCompleted": "Feld ausgefüllt",
        "hint": {
          "obligatory": "Wenn die Bedingung erfüllt ist, wird das Feld angezeigt und ist erforderlich",
          "voluntary": "Wenn die Bedingung erfüllt ist, wird das Feld angezeigt und ist optional"
        },
        "title": "Feld abhängig vom Wert eines anderen Felds anzeigen"
      },
      "maxValue": "Maximal zulässiger Wert:",
      "minValue": "Minimal zulässiger Wert:",
      "name": "Bezeichnung",
      "noTaskSelected": "Aufgabenunabhängig",
      "numberDecimalFormat": {
        "comma": "Gruppierungstrennzeichen",
        "dot": "Dezimaltrennzeichen oder Dezimaltrennzeichen für Geldbeträge",
        "hash": "Stelle, Null wird nicht angezeigt",
        "label": "Zahlenformat",
        "meaning": "Bedeutung",
        "minus": "Minus-Zeichen",
        "symbol": "Symbol",
        "zero": "Stelle"
      },
      "obligatory": "Pflichtfeld",
      "order": "Auftrag anzeigen (idx)",
      "requiredInDelayedTransport": "Erforderlich, wenn eine Verzögerung auftritt",
      "requiredTaskInDelayedTransport": "Erforderliches Feld bei der Bestätigung einer Etappe, wenn eine Etappe verspätet ist",
      "saveSuccessMessage": "Die Einstellungen für das Feld wurden gespeichert.",
      "saveSuccessMessageForTimeWindow": "Die Einstellungen für die Felder wurden gespeichert. Die Änderungen werden in wenigen Minuten angezeigt.",
      "settingsFields": "Feldeinstellungen",
      "side": "Die Seite, auf der das Feld angezeigt wird, wenn ein Formular mehr als {number} benutzerdefinierte Felder enthält.",
      "sideLeft": "Links",
      "sideRight": "Rechts",
      "sideShort": "Seite",
      "task": "Pflichtfeld während und nach der Bestätigung einer Aufgabe",
      "taskHint": "Um die Felderforderlichkeit \"Aufgabenunabhängig\" festzulegen, klicken Sie auf das Kreuz neben der oben ausgewählten Aufgabe",
      "textAreaRowsNumber": "Anzahl der Zeilen für das Feld",
      "type": {
        "date": "Datum",
        "datetime": "Datum und Uhrzeit",
        "number": "Zahl",
        "select": "Auswählen",
        "text": "Text",
        "text_area": "Textbereich"
      },
      "typeField": "Feldtyp",
      "visibilityDependsOnDefinition": "Bezeichnung eines abhängigen Felds",
      "visibilityDependsOnOption": "Wert eines abhängigen Felds",
      "visibleDuringTransportCreation": "Wird bei der Transporterstellung angezeigt",
      "visibleInAdviceSection": "Wind in Lieferavisformularen angezeigt",
      "visibleInDeliveryDocument": "Auf dem Lieferschein angeben",
      "visibleInDiscrepancyReportSection": "Wird im Abschnitt \"Abweichungsbericht\" angezeigt",
      "visibleInTable": "Wird in Tabellen angezeigt",
      "visibleInTransportWeightReceipt": "Sichtbar in der Transportgewichtsquittung",
      "visibleInWeightSection": "Wird im Abschnitt \"Wiegen\" angezeigt",
      "visibleOnTimeWindow": "Wird in Zeitfenstern angezeigt"
    },
    "custom-field-option": {
      "add": "Option hinzufügen",
      "dependenciesHint": "Wählen Sie bitte das benutzerdefinierte Feld und die Optionen aus, von denen der ausgewählte Schlüssel abhängig sein soll.",
      "editDependencies": "Abhängigkeiten bearbeiten",
      "errors": {},
      "optionKey": "Wert – Schlüssel",
      "relatedDefinition": "Benutzerdefiniertes Feld",
      "relatedOptions": "Zugehörige Optionen",
      "remove": "Option entfernen"
    },
    "custom-field-value": {
      "autoCompletePlaceholder": "Beginnen Sie mit der Eingabe, um nach einer Option zu suchen.",
      "errors": {
        "maxValue": {
          "value": "Der Maximalwert ist {maxValue}"
        },
        "minValue": {
          "value": "Der Mindestwert ist {minValue}"
        },
        "value": {
          "notProperValueType": "Falscher Wertetyp, Zahl erforderlich"
        }
      }
    },
    "dateFilterProperty": {
      "DATE_CREATED": "Datum, an dem der Auftrag erstellt wurde",
      "DEPARTURE_DATE": "Ausgewähltes Lieferdatum (für diesen Schritt gibt es kein Zeitfenster)",
      "LOAD_DATE": "Datum des Beladens",
      "TIME_RANGE_FOR_ADVICE_START": "Beginn der Zeitspanne (für diesen Schritt gibt es kein Zeitfenster)",
      "TIME_RANGE_FOR_ADVICE_STOP": "Ende der Zeitspanne (für diesen Schritt gibt es kein Zeitfenster)",
      "TIME_WINDOW_DATES": "Beginn oder Ende eines Zeitfensters",
      "TIME_WINDOW_START": "Beginn des Zeitfensters",
      "TIME_WINDOW_STOP": "Ende des Zeitfensters",
      "UNLOAD_DATE": "Datum des Entladens",
      "hint": "Wenn für die Transportart ein Auftrag ohne Zeitfenster erstellt werden kann, wird ein solcher Auftrag nicht in den Filtern bezüglich der Zeitfenstereigenschaften (z. B. Beginn des Zeitfensters) berücksichtigt."
    },
    "deliveries": {
      "add": "Lieferung hinzufügen",
      "label": "Lieferungen"
    },
    "delivery": {
      "delete": "Sind Sie sicher, dass Sie den Versand löschen wollen? ",
      "detachAllDeliveries": "Lieferungen abtrennen",
      "detachDeliveriesModalText": "Sind Sie sicher, dass Sie alle Lieferungen vom Transport abkoppeln wollen?",
      "detachFromTransport": "Lieferung abtrennen",
      "label": "Lieferung",
      "weightAutoCreated": "Lieferung: {deliveryNumber} wurde wegen Überschreitung des definierten Gewichts automatisch auf Transport umgestellt: {weight}"
    },
    "deliveryPackaging": {
      "description": "Beschreibung",
      "height": "Höhe [cm]",
      "ldmLabel": "Lademeter (ldm)",
      "length": "Länge [cm]",
      "modalTitle": "Verpackung",
      "packageType": "Verpackungsart",
      "quantity": "Menge",
      "sectionTitle": "Verpackung",
      "stackingFactor": "Stapelbar",
      "weight": "Gewicht (pro Stück) [kg]",
      "width": "Breite [cm]"
    },
    "deliverySettings": {
      "add": "Zustellungseinstellungen hinzufügen",
      "fields": {
        "autoGenerateDeliveryNumber": "Liefernummer automatisch generieren.",
        "requireAtLeastOnePackaging": "Erfordert mindestens eine Verpackung, um die Lieferung zu speichern",
        "requireFreightCost": "Verlangen Sie Frachtkosten.",
        "requireGrossWeight": "Erfordert Bruttogewicht.",
        "requireNetWeight": "Nettogewicht vorgeben.",
        "requireStackingFactor": "Erfordern Stapelbarkeit.",
        "showSimplePackagingSection": "Zeigen Sie eine vereinfachte Ansicht der Verpackung"
      },
      "label": "Einstellungen für die Lieferung",
      "noSettings": "Es gibt noch keine Zustellungseinstellungen"
    },
    "dev": {
      "confirm": "Bestätigen",
      "performSaveAt": "Speichern unter"
    },
    "discrepancyReport": {
      "generate": "Abweichungsbericht generieren",
      "label": "Abweichungsbericht",
      "noAdviceInfo": "Nach Hinzufügen des Lieferavis kann ein Abweichungsbericht erstellt werden.",
      "noDiscrepanciesFound": "Keine Abweichungen gefunden",
      "noDiscrepanciesInfo": "Nach der Ermittlung von Abweichungen kann ein Abweichungsbericht erstellt werden."
    },
    "document": {
      "CERTIFICATE": {
        "expirationDate": "Gültigkeitsdatum des Zertifikats (bis)",
        "number": "Zertifikatnummer"
      },
      "INSURANCE": {
        "create": "Versicherung hinzufügen",
        "expirationDate": "Gültigkeitsdatum der Versicherung (bis)",
        "expirationDateFrom": "Gültigkeitsdatum der Versicherung (von)",
        "new": "Neu {typeLabel}",
        "number": "Versicherungsnummer",
        "save": "Versicherung speichern",
        "savingInProgress": "Versicherung wird gespeichert ...",
        "showArchived": "Archivierte Versicherungen anzeigen"
      },
      "LICENCE": {
        "create": "Lizenz hinzufügen",
        "expirationDate": "Ablaufdatum",
        "new": "Neu {typeLabel}",
        "number": "Lizenznummer",
        "save": "Lizenz speichern",
        "savingInProgress": "Lizenz wird gespeichert ...",
        "showArchived": "Archivierte Lizenzen anzeigen"
      },
      "MAPPING": {
        "expirationDate": "Gültigkeitsdatum des Zertifikats (bis)",
        "expirationDateFrom": "Gültigkeitsdatum des Zertifikats (von)",
        "number": "Mapping-Nummer"
      },
      "amount": "Versicherungsschutz",
      "currency": "Währung",
      "disableConfirm": "Möchten Sie dieses Dokument wirklich archivieren? Die archivierten Dokumente werden bei der Gültigkeitsprüfung nicht berücksichtigt.",
      "documentType": "Umfang",
      "documentType.placeholder": "Umfang auswählen",
      "errors": {
        "expirationDate": {
          "isInPast": "Das Gültigkeitsdatum darf nicht in der Vergangenheit liegen."
        },
        "expirationDateFrom": {
          "afterExpirationDate": "Das Gültigkeitsdatum \"von\" muss vor dem Gültigkeitsdatum \"bis\" liegen."
        }
      },
      "expirationShiftInfo": "Das Dokument gilt {days} Tage vor dem Ablaufdatum der Versicherung als ungültig, z. B. {date}",
      "expired": "Dieses Dokument ist abgelaufen.",
      "files": "Hinzugefügte Dateien",
      "maxCountExceeded": "Sie können maximal {maxCount} Dateien zu einer Anfrage hinzufügen.",
      "noFilesInfo": "Es wurde keine Datei zu diesem Dokument hinzugefügt.",
      "noFilesWarning": "Bitte fügen Sie eine gescannte Dokumentendatei hinzu.",
      "scopeOfInsurance": "Regionale Abdeckung"
    },
    "documentType": {
      "CERTIFICATE": {
        "add": "Kalibrierungszertifikat hinzufügen",
        "expirationDate": "Gültigkeitsdatum (bis)",
        "expirationDateFrom": "Gültigkeitsdatum (von)",
        "expirationDateLabel": "Gültigkeit der Kalibrierung",
        "name": "Sensorkalibrierungszertifikat",
        "number": "Zertifikatnummer"
      },
      "SUMMER_MAPPING": {
        "add": "Sommer-Mapping hinzufügen",
        "expirationDateFrom": "Datum des letzten Mappings",
        "name": "Sommer-Mapping"
      },
      "WINTER_MAPPING": {
        "add": "Winter-Mapping hinzufügen",
        "expirationDateFrom": "Datum des letzten Mappings",
        "name": "Winter-Mapping"
      },
      "isRequiredForModeOfTransportationInfo": "Fehlende Dokumente:"
    },
    "driver": {
      "addDriver": "Fahrer hinzufügen",
      "country": "Land",
      "disableConfirm": "Möchten Sie diesen Fahrer wirklich entfernen?",
      "documentNumber": "Dokumentennummer",
      "documentType": {
        "ID_CARD": "Personalausweis",
        "PASSPORT": "Reisepass",
        "label": "Dokumenttyp"
      },
      "errors": {
        "phoneNumber": {
          "regex": "Die Nummer muss 9 bis 12 Stellen aufweisen."
        }
      },
      "label": "Fahrer",
      "lastUpdated": "Zuletzt aktualisiert",
      "name": "Vor- und Nachname",
      "nationality": "Nationalität",
      "phoneNumber": "Telefonnummer",
      "timeWindow": "Zeitfenster"
    },
    "emailAdvice": {
      "adviceDetails": "Lieferavisdetails",
      "adviceDetailsCf": "Datenvervollständigung",
      "alreadySent": "E-Mail-Anfrage zu Lieferavis bereits gesendet",
      "alreadySentCf": "Anfrage zur Vervollständigung von Daten bereits gesendet",
      "confirm": "Sind Sie sicher, dass Sie die Datenanfrage erneut senden möchten? Der vorherige Link wird deaktiviert.",
      "logLabel": {
        "ADVICE_DATA": "Anfrage zu Lieferavisdetails",
        "CF_DATA": "Anfrage zur Vervollständigung von Daten"
      },
      "noRole": "Sie verfügen nicht über ausreichende Berechtigungen für diese Aktion",
      "title": "Anfrage zu Lieferavisdaten per E-Mail senden",
      "titleCf": "Anfrage zur Vervollständigung von Daten per E-Mail senden"
    },
    "errors": {
      "blank": "Bitte geben Sie einen Wert ein.",
      "blankCheckbox": "Dieses Kontrollkästchen muss aktiviert werden.",
      "blankSelect": "Bitte wählen Sie einen Wert aus.",
      "filterRequestTimeout": "Bei Ihrer Suche ist ein Fehler aufgetreten. Bitte grenzen Sie Ihren Suchzeitraum ein und versuchen Sie es erneut.",
      "invalidDatesSequence": "Das Datum \"Von\" muss vor dem Datum \"Bis\" liegen.",
      "invalidEmail": "Bitte geben Sie eine gültige E-Mail Adresse ein.",
      "invalidQuantity": "Die Anzahl der Paletten muss eine positive Ganzzahl sein.",
      "minimumOnePackage": "Sie müssen mindestens eine gültige Verpackung hinzufügen",
      "noNegativeNumber": "Dieser Eintrag darf keine negative Zahl sein.",
      "number": "Dieser Eintrag darf nur Ziffern enthalten.",
      "orderInTransport": "Die Reihenfolge der Etappen sollte der Reihenfolge der Beförderung folgen.",
      "positiveInteger": "Dieser Eintrag muss eine positive Ganzzahl sein.",
      "positiveNumber": "Dieser Eintrag muss eine positive Zahl sein.",
      "tooLong": "Dieser Eintrag darf höchstens als %@ Zeichen enthalten.",
      "tooShort": "Dieser Eintrag muss mindestens %@ Zeichen enthalten."
    },
    "file": {
      "confirmDeletion": "Möchten Sie diese Datei wirklich löschen?"
    },
    "formChanges": {
      "dirtyValueChanged": "Achtung! Sie haben den Wert in diesem Feld zu \"{yourValue}\" geändert, aber ein anderer Benutzer hat den Feldwert zu \"{newValue}\" geändert und gespeichert.",
      "newValue": "Achtung! Ein anderer Benutzer hat den Wert \"{value}\" in dieses Feld eingegeben.",
      "valueChanged": "Achtung! Ein anderer Benutzer hat den Wert in diesem Feld von \"{oldValue}\" zu \"{newValue}\" geändert.",
      "valueRemoved": "Achtung! Ein anderer Benutzer hat den Wert \"{value}\" aus diesem Feld entfernt."
    },
    "freeSubsequentSpots": {
      "infoAboutCurrentSpot": "Ausgewählte Rampe: <b>{currentRamp}</b>, Uhrzeit: <b>{currentStart}</b>",
      "infoAboutWindowSize": "Das Zeitfenster passt nicht in den ausgewählten Platz. Zum Entladen erforderliche Zeit: <b>{windowSizeInMinutes}</b> Minuten",
      "showSearchSpotsButton": "Verfügbare Termine im Zeitplan anzeigen",
      "suggestionsHeader": "Frühestmöglicher Lieferavistermin:"
    },
    "gates": {
      "artrIdentifier": "ARTR-Kennung",
      "create": "Tor erstellen",
      "gateTypes": {
        "ENTRY_GATE": "Einfahrtstor",
        "EXIT_GATE": "Ausfahrtstor",
        "UNKNOWN": "Unbekannt",
        "label": "Tortyp"
      },
      "label": "Tore"
    },
    "groupTypeSettings": {
      "description": {
        "canSelectOnlyOneContractorsGroup": "Erlaubt die Auswahl von nur einer Gruppe in einem bestimmten Typ",
        "partnershipTypesWithAccess": "Gibt die Partnerschaftstypen an, für die der Gruppentyp sichtbar sein soll",
        "requiresContractorsGroup": "Gibt an, ob mindestens eine Gruppe in der Gruppenart ausgewählt werden muss",
        "visible": "Gibt an, ob der Gruppentyp in Übermittlungen sichtbar sein soll"
      },
      "label": "Gruppentyp-Einstellungen",
      "labels": {
        "canSelectOnlyOneContractorsGroup": "Obligatorisch, um nur eine Gruppe auszuwählen",
        "partnershipTypesWithAccess": "Für welche Arten von Partnerschaften soll der Gruppentyp sichtbar sein",
        "requiresContractorsGroup": "Es muss mindestens eine Gruppe ausgewählt werden",
        "visible": "Sichtbarkeit der Gruppenart in Benachrichtigungen"
      }
    },
    "help": {
      "changePrincipalSpaceInfo": "Anmerkung: Diese Seite wird aktualisiert. Speichern Sie Ihre Änderungen, bevor Sie diese Aktion abschließen.",
      "contactUs": "Logintegra-Helpdesk",
      "contactWithWarehouse": "Lager kontaktieren",
      "label": "Hilfe",
      "newMessagesTitle": "Sie haben %@1 neue Nachricht(en).",
      "selectRamp": "Wählen Sie die Ladungsgruppe aus.",
      "statute": "Erklärung"
    },
    "index": {
      "INDEX": {
        "add": "Index hinzufügen",
        "disableConfirm": "Möchten Sie diesen Index wirklich löschen?",
        "enabled": "Index aktiviert",
        "globalNumber": "Indexnummer",
        "globalNumber.abbr": "Index-Nr.",
        "label": "Index",
        "localNumber": "Lokale Nummer",
        "localNumber.abbr": "Lokale Nr.",
        "name": "Produktbezeichnung",
        "name.abbr": "Bezeichnung",
        "notes": "Indexhinweise",
        "product": "Produkt",
        "recipient": "Empfänger",
        "sectionLabel": "Indizes",
        "step": "Index zu einem Transportschritt zuweisen",
        "version": "Versionsnummer"
      },
      "PALLETS": {
        "add": "Palette hinzufügen",
        "disableConfirm": "Möchten Sie diesen Artikel wirklich löschen?",
        "enabled": "Artikel aktiviert",
        "globalNumber": "Palettennummer",
        "globalNumber.abbr": "Paletten-Nr.",
        "label": "Paletten",
        "localNumber": "Lokale Nummer",
        "localNumber.abbr": "Lokale Nr.",
        "name": "Produktbezeichnung",
        "name.abbr": "Bezeichnung",
        "notes": "Zusätzliche Informationen",
        "product": "Produkt",
        "sectionLabel": "Paletten",
        "shortName": "Kurzbezeichnung",
        "step": "Paletten einem Schritt zuweisen"
      },
      "company": "Auftragnehmer",
      "companyRecipient": "Empfänger des Kunden",
      "dateCreated": "Datum der Erstellung",
      "documentNumber": "Auftragsnummer",
      "documentNumber.abbr": "Auftragsnummer",
      "exportDisabledHint": "Der Export ist nach dem Speichern der Änderungen möglich.",
      "selectCompany": "Kunden auswählen",
      "selectCompanyHint": "Wählen Sie zunächst im Auftragsformular den Kunden aus.",
      "selectRecipient": "Empfänger auswählen",
      "transportNumber": "Auftragstyp",
      "transportNumber.abbr": "Auftragstyp"
    },
    "index-quantity": {
      "errors": {
        "quantity": {
          "conditionallyObligatory": "Geben Sie einen der Werte ein: {names}.",
          "invalidNumber": "Bitte geben Sie die korrekte Ganzzahl ein.",
          "obligatory": "Geben Sie die Menge ein."
        }
      },
      "incompleteLoadingRulesNotMet": "Die Eingabe dieses Werts ist nur bei unvollständiger Beladung möglich."
    },
    "invitation": {
      "acceptedByAnotherPerson": "Die Einladung wurde bereits von einer anderen Person in Ihrem Unternehmen angenommen.",
      "acceptedSummary": "Angenommen",
      "alreadyAccepted": "Die Einladung wurde bereits angenommen.",
      "alreadyHandled": "Diese Einladung wurde bearbeitet.",
      "companyRegistration": "Unternehmen registrieren",
      "createdBy": "{personName} möchte Sie zur system",
      "creator": "Ersteller",
      "email": "E-Mail-Adresse",
      "errors": {},
      "expirationDate": "Ablaufdatum",
      "for": {
        "CARRIER": "Frachtführer",
        "CLIENT": "Kunde",
        "JOIN_COMPANY": "zu Ihrem Unternehmen",
        "OPERATOR": "Betreiber",
        "SERVICEMAN": "Dienstleister",
        "SUPPLIER": "Lieferant \\\\ Empfänger",
        "SUPPLIER.CLIENT": "Kunde"
      },
      "hasExpired": "Abgelaufen",
      "instruction": {
        "label": "Ich habe Folgendes zur Kenntnis genommen:",
        "linkName": "ALLGEMEINE GESCHÄFTSBEDINGUNGEN"
      },
      "invalidTaxIdNumber": {
        "info": "Die Unternehmensdaten stimmen nicht mit den Angaben in der Einladung überein. Wenden Sie sich bitte an Logintegra."
      },
      "invite": "Einladen",
      "inviteToCompany": "Zu Unternehmen einladen",
      "label": "Einladung",
      "notFound": "Die Einladung ist nicht vorhanden oder abgelaufen.",
      "ofCompany": "vom Unternehmen {companyName}",
      "registeredAccount": "Registriertes Konto",
      "registrationComplete": "Vielen Dank. Ihr Konto wurde registriert. Melden Sie sich mit dem Benutzernamen {username} an.",
      "rejectedSummary": "Abgelehnt",
      "sendTo": "Einladung senden",
      "sent": "Einladung gesendet an {email}.",
      "taxIdNumberExist": {
        "info": "Die Steueridentifikationsnummer (NIP) ist bereits in der Datenbank vorhanden. Wenden Sie sich bitte an Logintegra."
      },
      "toCompanyWithRole": "zur Zusammenarbeit mit {companyName} in folgender Rolle einladen: {roleName}",
      "toJoinExistingCompany": "zum Unternehmen {companyName}",
      "toPartnership": "{personName} möchte Sie einladen, mit {companyName} in folgender Rolle tätig zu werden: {roleName}.",
      "type": {
        "CARRIER": "Als Frachtführer beitreten",
        "CLIENT": "Als Kunde beitreten",
        "JOIN_COMPANY": "Einem Unternehmen beitreten",
        "SERVICEMAN": "Als Dienstleister beitreten",
        "SUPPLIER": "Als Lieferant \\\\ Empfänger beitreten",
        "SUPPLIER.CLIENT": "Als Kunde beitreten"
      },
      "userRegistration": "Benutzer registrieren"
    },
    "invitations": {
      "cannotInviteAnyRoleGroup": "Sie verfügen nicht über die Berechtigung, einen Benutzer einzuladen.",
      "emptyReceived": "Sie haben noch keine Einladung erhalten.",
      "emptySent": "Sie haben noch keine Einladung gesendet.",
      "label": "Einladungen",
      "noRoleGroupsFound": "Sie verfügen nicht über die Berechtigung, eine Rolle mit einem ausgewählten Partnerschaftstyp einzuladen.",
      "received": "Erhalten",
      "sent": "Gesendet",
      "types": {
        "ACCEPTED": "Angenommen",
        "EXPIRED": "Abgelaufen",
        "REJECTED": "Abgelehnt",
        "WAITING": "Ausstehend"
      }
    },
    "invoice": {
      "number": "Rechnungsnummer",
      "price": "Betrag"
    },
    "log": {
      "actor": "Geändert von",
      "change": "Änderung",
      "dateCreated": "Datum",
      "fieldChange": "Feldänderung",
      "integration": {
        "method": "Methode",
        "request": "Anfrage",
        "resend": "Erneut senden",
        "resendFailed": "Erneutes Senden der Nachricht fehlgeschlagen",
        "resendSuccess": "Das erneute Senden der Nachricht wurde geplant.",
        "response": "Antwort",
        "sureToResend": "Sind Sie sicher, dass Sie die API-Benachrichtigung erneut senden möchten?"
      },
      "object": "Objekt",
      "partnership": "Partnerschaftsnr.{id}",
      "transport": "Transport"
    },
    "logintegraEmail": "info@logintegra.com",
    "logintegraPhone": "0048 585 357 806",
    "logs": {
      "DELETE": {
        "ActionAuthority": "Benutzerrollenberechtigung abhängig von der Transportart gelöscht",
        "ActiveAuctionRestriction": "Einschränkung gelöscht",
        "ActiveRestriction": "Einschränkung gelöscht",
        "AdviceDatesRestriction": "Einschränkung gelöscht",
        "AfterCheckpointBasedRestriction": "Einschränkung gelöscht",
        "AuthorityRestriction": "Benutzerrollenberechtigung abhängig vom Objekt gelöscht",
        "BeforeCheckpointBasedRestriction": "Einschränkung gelöscht",
        "BlockedRestriction": "Gesperrte Einschränkung gelöscht",
        "CompanyRamp": "Rampenzuordnung für ein Unternehmens gelöscht",
        "CustomFieldValue": "Benutzerdefiniertes Feld aktualisiert",
        "Delivery": "Lieferung gelöscht:",
        "NoRouteOfferRestriction": "Einschränkung gelöscht",
        "OwnCompanyRestriction": "Einschränkung gelöscht",
        "PersonNotification": "Benachrichtigung an Person entfernt",
        "PersonRamp": "Rampenzuordnung für einen Benutzer gelöscht",
        "RoleGroupAuthority": "Benutzerrollenberechtigung gelöscht",
        "Step": "Schritt gelöscht",
        "TimeBasedRestriction": "Einschränkung gelöscht",
        "UnlimitedRestriction": "Einschränkung gelöscht"
      },
      "INSERT": {
        "ActionAuthority": "Benutzerrollenberechtigung abhängig von der Transportart hinzugefügt",
        "ActiveAuctionRestriction": "Einschränkung hinzugefügt",
        "ActiveRestriction": "Einschränkung hinzugefügt",
        "Advice": "Lieferavis erstellt",
        "AdviceDatesRestriction": "Einschränkung hinzugefügt",
        "AfterCheckpointBasedRestriction": "Einschränkung hinzugefügt",
        "Auction": "Auktion erstellt",
        "AuthorityRestriction": "Benutzerrollenberechtigung abhängig vom Objekt hinzugefügt",
        "BeforeCheckpointBasedRestriction": "Einschränkung hinzugefügt",
        "BlockedRestriction": "Gesperrte Einschränkung hinzugefügt",
        "CompanyRamp": "Neuzuordnung von Unternehmensrampen",
        "CustomFieldValue": "Benutzerdefiniertes Feld ausgefüllt",
        "File": "Datei hinzugefügt",
        "Index": "Index hinzugefügt",
        "NoRouteOfferRestriction": "Einschränkung hinzugefügt",
        "Note": "Anmerkung erstellt",
        "Offer": "Angebot hinzugefügt",
        "OwnCompanyRestriction": "Einschränkung hinzugefügt",
        "Person": "Konto erstellt",
        "PersonNotification": "Benachrichtigung an Person hinzugefügt",
        "PersonRamp": "Neuzuordnung von Benutzerrampen",
        "RoleGroupAuthority": "Benutzerrollenberechtigung hinzugefügt",
        "Step": "Schritt hinzugefügt",
        "TimeBasedRestriction": "Einschränkung hinzugefügt",
        "Transaction": "Auftrag übergeben",
        "Transport": "Transport erstellt",
        "UnlimitedRestriction": "Einschränkung hinzugefügt"
      },
      "TYPE_EMAIL": "E-Mail-Benachrichtigung senden",
      "TYPE_SMS": "SMS-Benachrichtigung senden",
      "UPDATE": {
        "ActionAuthority": "Benutzerrollenberechtigung abhängig von der Transportart geändert",
        "Advice": "Lieferavis aktualisiert",
        "Auction": "Auktion aktualisiert",
        "AuthorityRestriction": "Benutzerrollenberechtigung abhängig vom Objekt geändert",
        "Checkpoint": "Statusaktualisierung",
        "CompanyRamp": "Neuzuordnung von Unternehmensrampen",
        "CustomFieldValue": "Benutzerdefiniertes Feld aktualisiert",
        "File": "Datei aktualisiert",
        "Index": "Index aktualisiert",
        "IndexQuantity": "Indexmenge aktualisiert",
        "Note": "Anmerkung aktualisiert",
        "Offer": "Angebot aktualisiert",
        "Person": "Benutzername",
        "PersonRamp": "Neuzuordnung von Benutzerrampen",
        "Step": "Schritt aktualisiert",
        "StepPackageType": "Pakete",
        "TimeWindow": "Zeitfenster aktualisiert",
        "Transaction": "Transaktion aktualisiert",
        "Transport": "Transport aktualisiert"
      },
      "WeightLog": "Erfasstes Gewicht"
    },
    "main-section": {
      "errors": {
        "hasErrors": "Der Primärdatenabschnitt enthält Fehler. Füllen Sie die Pflichtfelder korrekt aus.",
        "krsFile": "Um einen Überprüfungsantrag einzureichen, muss das als KRS-Nachweis erforderliche Dokument hinzugefügt werden.",
        "nipFile": "Um einen Überprüfungsantrag einzureichen, muss das als NIP-Nachweis erforderliche Dokument hinzugefügt werden.",
        "regonFile": "Um einen Überprüfungsantrag einzureichen, muss das als REGON-Nachweis erforderliche Dokument hinzugefügt werden."
      }
    },
    "managableJob": {
      "title": "Job management"
    },
    "max-pallet": {
      "errors": {},
      "label": "Palettenlimit",
      "title": "Palettenlimit pro Tag"
    },
    "modeOfTransportation": {
      "approve": "Verkehrsträger genehmigen",
      "brand": "Marke",
      "create": "Verkehrsträger hinzufügen",
      "disabledReason": {
        "blockedSections": "Geblockte Abschnitte: {blockedSections}",
        "blockingDocument": "Dieser Verkehrsträger kann aufgrund des Gültigkeitsdatums des Dokuments {blockingDocument} ({minValidityDate}) nicht ausgewählt werden und es kann kein entsprechendes Lieferavis versendet werden. Die Gültigkeit endet vor dem Ende des Transports ({maxTransportDate}).",
        "label": "Verkehrsträgerstatus: {status}",
        "rejectedSections": "Abgelehnte Abschnitte: {rejectedSections}"
      },
      "documents": {
        "disabled": "Man kann einem behinderten Verkehrsmittel keine Dokumente hinzufügen"
      },
      "edit": {
        "disable": "Möchten Sie diesen Verkehrsträger wirklich deaktivieren?"
      },
      "label": "{count, plural, one {Mode of transportation} other {Modes of transportation}}",
      "serialNumberOfRecorder": "Rekorderseriennummer",
      "showEnabled": {
        "false": "Nur aktivierte Verkehrsträger anzeigen",
        "true": "Auch deaktivierte Verkehrsträger anzeigen"
      },
      "status": {
        "ACCEPTED": "Angenommen",
        "AWAITING_VERIFICATION": "Nicht angenommen",
        "BLOCKED": "Geblockt",
        "DISABLED": "Deaktiviert",
        "REJECTED": "Abgelehnt",
        "UPDATED": "Aktualisiert"
      },
      "subcontractor": "Unterauftragnehmer",
      "verificationInfo": "Verkehrsträger am {date} genehmigt durch Benutzer {user}"
    },
    "noAccessibleStatisticsReports": "Sie haben keinen Zugriff auf einen Bericht.",
    "noAccessibleTransportTypes": "Sie haben keinen Zugriff auf Transporte.",
    "note": {
      "autoGenerated": "Automatisch erstellt",
      "creator": "Ersteller",
      "dateCreated": "Erstellt am",
      "label": "Anmerkung",
      "note": "Anmerkung",
      "transport": "Transport"
    },
    "notification": {
      "ADVICE_CONFIRMATION": {
        "desc": "Benachrichtigung, die der Ersteller nach dem Speichern eines Lieferavis erhält.",
        "label": "Bestätigung der Erstellung eines Lieferavis: {transportTypeName}",
        "labelShort": "Bestätigung der Erstellung eines Lieferavis"
      },
      "ADVICE_CREATED": {
        "desc": "Benachrichtigung, die nach dem Speichern eines Lieferavis gesendet wird.",
        "label": "Erstellung eines Lieferavis: {transportTypeName}",
        "labelShort": "Erstellung eines Lieferavis"
      },
      "ADVICE_CREATED_SMS": {
        "labelShort": "SMS-Benachrichtigung an den Fahrer nach dem Erstellen eines Lieferavis"
      },
      "ADVICE_UPDATED": {
        "desc": "Benachrichtigung, die nach der Änderung eines Lieferavis an die Beteiligten gesendet wird (wenn es sich beim Ersteller eines Lieferavis um einen Lieferanten \\\\ Empfänger handelt, wird eine E-Mail beispielsweise an Logistiker und Frachtführer gesendet).",
        "label": "Änderung eines Lieferavis: {transportTypeName}",
        "labelShort": "Änderung eines Lieferavis"
      },
      "ADVICE_UPDATED_SMS": {
        "labelShort": "SMS-Benachrichtigung an den Fahrer nach dem Bearbeiten eines Lieferavis"
      },
      "ADVICE_VERIFICATION": {
        "labelShort": "Benachrichtigung über die Überprüfung eines Lieferavis"
      },
      "AUCTION_ENDED_WITHOUT_OFFERS": {
        "desc": "Benachrichtigung, die Benutzer erhalten, die bei einer Auktion bieten können, wenn eine Auktion ohne Angebote beendet wurde.",
        "label": "Bei dieser Auktion wurden keine Gebote abgegeben: {transportTypeName}",
        "labelShort": "Keine Gebote bei der Auktion"
      },
      "AUCTION_LOST": {
        "desc": "Benachrichtigung, die im Anschluss an eine verlorene Auktion gesendet wird.",
        "label": "Auktion für den Transport {transportTypeName} verloren",
        "labelShort": "Auktion verloren"
      },
      "AUCTION_REMINDER": {
        "Shortabel": "Erinnerung an das Ende einer Auktion",
        "desc": "Benachrichtigung mit einer Erinnerung an das bevorstehende Ende einer Auktion.",
        "label": "Erinnerung an das Ende der Auktion für den Transport {transportTypeName}"
      },
      "AUCTION_WON": {
        "desc": "Benachrichtigung, die im Anschluss an eine gewonnene Auktion gesendet wird.",
        "label": "Auktion für den Transport {transportTypeName} gewonnen",
        "labelShort": "Auktion gewonnen"
      },
      "CARRIER_CHOSEN": {
        "desc": "Benachrichtigung, die bei Auswahl oder Wechsel eines Frachtführers gesendet wird",
        "label": "Frachtführer für den Transport {transportTypeName} ausgewählt",
        "labelShort": "Frachtführer ausgewählt"
      },
      "CHECKPOINT_CONFIRMED_SMS": {
        "desc": "SMS-Benachrichtigung, die nach der Bestätigung des Kontrollpunkts an den Fahrer gesendet wird",
        "label": "SMS-Benachrichtigung an den Fahrer nach der Bestätigung eines Kontrollpunkts",
        "labelShort": "SMS nach Bestätigung eines Kontrollpunkts"
      },
      "CONFIRMED_CHECKPOINT": {
        "desc": "Benachrichtigung, die bei Bestätigung des Status {checkpointName} versendet wird.",
        "label": "Bestätigung des Status {checkpointName} für die Transportart {transportTypeName} ",
        "labelShort": "Statusbestätigung"
      },
      "DISCREPANCY_PROTOCOL_CREATED": {
        "desc": "Eine Benachrichtigung, die nach dem Generieren des Abweichungsberichts an die Benutzer gesendet wird, die Zugriff auf den Auftrag haben, dem der Bericht hinzugefügt wurde.",
        "label": "Abweichungsbericht generiert für {transportTypeName}",
        "labelShort": "Abweichungsbericht generiert"
      },
      "LATE_ADVICE": {
        "desc": "Benachrichtigung, die gesendet wird, wenn sich die Ankunft des Fahrers verzögert.",
        "label": "Lieferavis bezüglich Verzögerung: {transportTypeName}",
        "labelShort": "Lieferavis bezüglich Verzögerung"
      },
      "LATE_DOCUMENT_EMAIL": {
        "desc": "Benachrichtigung, die gesendet wird, wenn der Lieferant die Versandunterlagen nicht rechtzeitig hinzufügt.",
        "label": "Verzögerung beim Hinzufügen von Dokumenten: {transportTypeName}, {checkpointName}",
        "labelShort": "Verzögerung beim Hinzufügen von Dokumenten"
      },
      "MODE_OF_TRANSPORTATION_UPDATED": {
        "desc": "Eine Benachrichtigung, die nach der Aktualisierung eines Verkehrsträgers gesendet wird",
        "label": "Verkehrsträger aktualisiert",
        "labelShort": "Verkehrsträger aktualisiert"
      },
      "NEW_ADDED_OFFER": {
        "desc": "Benachrichtigung beim Hinzufügen eines neuen Angebots durch den Frachtführer.",
        "label": "Hinzufügen eines neuen Angebots: {transportTypeName}",
        "labelShort": "Hinzufügen eines neuen Angebots"
      },
      "NEW_AUCTION": {
        "desc": "Benachrichtigung, die Benutzer erhalten, die bei einer Auktion bieten können, sowie die Benutzer der bei der Erstellung der Auktion ausgewählten Unternehmen.",
        "label": "Eine neue Auktion für den Transport {transportTypeName}",
        "labelShort": "Eine neue Auktion"
      },
      "NEW_MODE_OF_TRANSPORTATION": {
        "desc": "Eine Benachrichtigung, die nach dem Hinzufügen eines neuen Verkehrsträgers gesendet wird",
        "label": "Neuer Verkehrsträger",
        "labelShort": "Neuer Verkehrsträger"
      },
      "NEW_OFFER": {
        "desc": "Benachrichtigung, die Benutzer der beim Erstellen der Anfrage ausgewählten Unternehmen erhalten.",
        "label": "Neue Anfrage bezüglich Transport: {transportTypeName}",
        "labelShort": "Neue Anfrage bezüglich Transport"
      },
      "NEW_USER_IN_COMPANY": {
        "desc": "Benachrichtigung, die gesendet wird, wenn ein neuer Benutzer zum Unternehmen hinzugefügt wird",
        "label": "Benachrichtigung, die gesendet wird, wenn ein neuer Benutzer zum Unternehmen hinzugefügt wird",
        "labelShort": "Neuer Unternehmensbenutzer"
      },
      "OFFER_HAS_BEEN_OUTBID": {
        "desc": "Benachrichtigung, die versendet wird, wenn sich der erste Platz bei einer Aktion ändert.",
        "label": "Das Angebot wurde überboten: {transportTypeName}",
        "labelShort": "Das Angebot wurde überboten."
      },
      "OFFER_REJECTED": {
        "desc": "Eine Benachrichtigung an die Benutzer der im Anfrageschritt ausgewählten Unternehmen, deren Gebot nicht angenommen wird",
        "label": "In der Anfrage für den {transportTypeName}-Transport wurde kein Gebot ausgewählt.",
        "labelShort": "In der Anfrage wurde kein Gebot ausgewählt"
      },
      "ORDER_PASSED": {
        "desc": "Benachrichtigung, die an die Benutzer des Frachtführerunternehmens gesendet wird, wenn ein Auftrag an den Frachtführer übergeben wird",
        "label": "Auftrag wurde übergeben: {transportTypeName}",
        "labelShort": "Auftrag wurde übergeben"
      },
      "ORDER_WAITING_FOR_CONFIRMATION": {
        "desc": "Benachrichtigung, die an die Benutzer des Frachtführerunternehmens gesendet wird, wenn der Auftrag an den Frachtführer übergeben wurde und die Bestätigung aussteht",
        "label": "Auftragsbestätigung ausstehend: {transportTypeName}",
        "labelShort": "Auftragsbestätigung ausstehend"
      },
      "REJECTED_CHECKPOINT": {
        "desc": "Benachrichtigung, die bei Ablehnung des Status {checkpointName} versendet wird.",
        "label": "Die Ablehnung des Status {checkpointName} für die Transportart {transportTypeName}",
        "labelShort": "Statusablehnung"
      },
      "TIME_WINDOW_ADDED": {
        "desc": "Eine Benachrichtigung, die nach dem Hinzufügen eines neuen Zeitfensters zum Auftrag gesendet wird",
        "label": "Zeitfenster erstellt",
        "labelShort": "Fenster erstellt"
      },
      "TRANSPORT_CREATED": {
        "desc": "Benachrichtigung, die Auftragnehmer nach dem Speichern eines Transports erhalten.",
        "label": "Transport wird erstellt {transportTypeName}",
        "labelShort": "Transport wird erstellt"
      },
      "TRANSPORT_DELETED_SMS": {
        "labelShort": "SMS-Benachrichtigung nach dem Löschen eines Transports (E-Mail-Lieferavis)"
      },
      "roleGroups": {
        "desc": "Benutzer mit einer bestimmten Rolle können entsprechende Benachrichtigung erhalten und in ihren Kontoeinstellungen aktivieren/deaktivieren.",
        "label": "Benutzerrollen"
      }
    },
    "notificationLog": {
      "TYPE_EMAIL": "E-Mail gesendet",
      "TYPE_SMS": "SMS gesendet"
    },
    "notificationToast": {
      "titles": {
        "error": "Fehler",
        "loading": "Laden",
        "success": "Erfolg"
      },
      "transportCombining": {
        "error": "Fehler beim Zusammenführen von Transporten",
        "loading": "Transportkombination läuft",
        "success": "Transporte wurden zusammengeführt"
      }
    },
    "notifications": {
      "add": "Benachrichtigung hinzufügen",
      "appendTransportFiles": "Angefügte Dateien senden",
      "availableNotifications": "Verfügbare Benachrichtigungen",
      "edit": "Bearbeiten",
      "label": "Benachrichtigungen",
      "legendAdviceUpdated": "Variablen, die für die Benachrichtigung LIEFERAVIS AKTUALISIERT verwendet werden",
      "legendCommon": "Allgemeine Variablen",
      "legendDescriptions": "Sie können im Inhalt der SMS Variablen verwenden. Geben Sie einfach die Bezeichnung der Variablen, abgegrenzt durch \"%\", in den SMS-Text ein. Beispiel für die Verwendung von Variablen: %TRAILER_REG_NUM%",
      "mailNotification": "E-Mail-Benachrichtigung",
      "messageEn": "Englischsprachiger Inhalt",
      "messageInputType": {
        "CAR_REG_NUM": "Kennzeichen des Fahrzeugs",
        "COMPANY_SHORT_NAME": "Kurzbezeichnung des Unternehmens",
        "GATE": "Bezeichnung des Tors am Standort",
        "LOCATION": "Lagerstandort",
        "NEW_ADVICE_DATE": "Neues Lieferavisdatum",
        "OLD_ADVICE_DATE": "Altes Lieferavisdatum",
        "OLD_REGISTRATION_NUMBER": "Altes Fahrzeugkennzeichen",
        "PLACE_NAME": "Bezeichnung des Orts der Warteschlange",
        "QUEUE_NAME": "Bezeichnung der Warteschlange",
        "RAMP_MESSAGE_EN": "Zusätzliche Informationen zu der dem Transport zugeordneten Rampe (EN)",
        "RAMP_MESSAGE_PL": "Zusätzliche Informationen zu der dem Transport zugeordneten Rampe (DE)",
        "RAMP_NUMBER": "Rampennummer",
        "SQUARE_NAME": "Bezeichnung der Lagerfläche",
        "TIME_WINDOW_DATES": "Daten der Zeitfenster",
        "TRAILER_REG_NUM": "Kennzeichen des Anhängers",
        "TRANSPORT_ID": "Transport-ID"
      },
      "messagePl": "Deutschsprachiger Inhalt",
      "new": "Neue Benachrichtigung",
      "noNotificationsForRole": "Für die ausgewählte Rolle sind keine Benachrichtigungen verfügbar.",
      "selectRole": "Wählen Sie zunächst eine Rolle aus.",
      "sureToDelete": "Möchten Sie diese Benachrichtigung wirklich löschen?",
      "task": "Aufgabe",
      "transportType": "Transportart"
    },
    "offer": {
      "auction": "Auktion",
      "company": "Unternehmen",
      "creator": "Benutzer",
      "dateCreated": "Datum der Erstellung",
      "deleted": "Gelöscht",
      "description": "Kommentare zum Angebot",
      "edit": "Bearbeiten",
      "enabled": "Aktiviert",
      "errors": {
        "auction": {
          "hasEnded": "Die Auktion für diesen Auftrag ist leider bereits abgeschlossen. Das Angebot konnte nicht gespeichert werden."
        },
        "price": {
          "higherThanMaximum": "Der eingegebene Preis ist höher als der vom Auftraggeber festgelegte Höchstpreis für diesen Auftrag.",
          "lowerThanMinimum": "Der eingegebene Preis ist niedriger als der vom Auftraggeber festgelegte Mindestpreis für diesen Auftrag.",
          "nonPositiveNumber": "Das Angebot muss eine Zahl größer als null sein.",
          "reductionNotMet": "Leider müssen Sie nach Forderung von {principalCompanyName} Ihr vorheriges Angebot um mindestens {minReductionSize} {currencySymbol} reduzieren."
        }
      },
      "hasWon": "Erfolgreiches Angebot",
      "label": "Angebot",
      "place": "Platz",
      "price": "Ihr Angebot",
      "revoke": {
        "alert": "Möchten Sie das aktuelle Angebot für diesen Auftrag wirklich zurückziehen?",
        "fail": {
          "CannotRevokeOffer": "Sie können dieses Angebot nicht zurückziehen."
        },
        "label": "Aktuelles Angebot zurückziehen",
        "success": "Angebot zurückgezogen"
      },
      "save": "Angebot speichern",
      "savingInProgress": "Angebot wird gespeichert ...",
      "valid": "Gültig"
    },
    "packageTypes": {
      "CARTONS": {
        "label": "Kartons",
        "lowerCase": "Kartons",
        "singular": "Karton"
      },
      "CHEP_PALLETS": {
        "label": "CHEP-Paletten",
        "lowerCase": "CHEP-Paletten",
        "singular": "CHEP-Palette"
      },
      "CUBIC_METERS": {
        "label": "Kubikmeter",
        "lowerCase": "kubikmeter",
        "singular": "Kubikmeter"
      },
      "DISPOSABLE_PALLETS": {
        "label": "Einwegpaletten",
        "lowerCase": "Einwegpaletten",
        "singular": "Einwegpalette"
      },
      "EURO_PALLETS": {
        "label": "EURO-Paletten",
        "lowerCase": "EURO-Paletten",
        "singular": "EURO-Palette"
      },
      "LOADING_PLACES": {
        "label": "Beladeplätze",
        "lowerCase": "Beladeplätze",
        "singular": "Beladeplatz"
      },
      "PALLETS": {
        "label": "Paletten",
        "lowerCase": "Paletten",
        "singular": "Palette"
      },
      "PALLETS_WITH_RETURNED_GOODS": {
        "label": "Palety s vráceným zbožím",
        "lowerCase": "palety s vráceným zbožím",
        "singular": "Paleta s vráceným zbožím"
      },
      "PALLET_PLACES": {
        "label": "Palettenplätze",
        "lowerCase": "Palettenplätze",
        "singular": "Palettenplatz"
      },
      "RETURNABLE_PALLETS": {
        "label": "Mehrwegpaletten",
        "lowerCase": "mehrwegpaletten",
        "singular": "Mehrwegpalette"
      },
      "SPATIAL_METERS": {
        "label": "Raummeter",
        "lowerCase": "raummeter",
        "singular": "Raummeter"
      },
      "THE_NUMBER_OF_ITEMS": {
        "label": "Anzahl der Artikel",
        "lowerCase": "Anzahl der Artikel",
        "singular": "Ein Stück"
      },
      "noRelationsWithPackageTypes": "Keine Verknüpfungen zwischen Transportart und Pakettypen",
      "referenceQuantity": "Menge als {referencePackage}: {referenceQuantity}",
      "transportTypes": {
        "confirmDelete": "Möchten Sie die Verknüpfung zwischen Transportart und Pakettyp wirklich löschen?",
        "defaultValue": "Standardwert",
        "idx": "Auftrag anzeigen",
        "minValue": "Mindestwert",
        "multiplier": "{package} pro {referencePackage}",
        "packageType": "Pakettyp",
        "referencePackage": "Achtung! Sie ändern die Einstellungen des Referenzpakettyps.",
        "referencePackageChkbox": "Referenzpaket",
        "required": "Erforderlich",
        "transportType": "Transportart",
        "usedForMaxPalletLimitChkbox": "In maximale Palettenanzahl einbeziehen",
        "visibleInContractorStep": "Anzeige im Schritt \"Auftragnehmer\"",
        "visibleInWarehouseStep": "Anzeige im Schritt \"Lager\""
      }
    },
    "page": {
      "pageTitle": "Logistikplattform"
    },
    "paidFeaturesInfo": {
      "authorities": {
        "UNLIMITED_ACCESS_TO_ARCHIVED_TRANSPORTS": "Das aktuelle Abonnement Ihres Unternehmens ermöglicht den Zugriff auf die Aufträge der letzten <b>3 Monate</b>."
      },
      "label": "Wir empfehlen Ihnen, sich über die verfügbaren Abonnements für Unternehmen zu informieren."
    },
    "partnership": {
      "ANTI_CORRUPTION_TYPE": "Antikorruptionserklärung",
      "CONTRACTOR_GROUP_ADDED": "Gruppe hinzugefügt",
      "CONTRACTOR_GROUP_REMOVED": "Gruppe entfernt",
      "GROUPS": "Gruppen",
      "INSURANCE_DOMESTIC_OCP": "Frachtführerhaftpflichtversicherung im Inlandsverkehr",
      "INSURANCE_INTERNATIONAL_OCP": "Frachtführerhaftpflichtversicherung im internationalen Verkehr",
      "INSURANCE_OC": "Haftpflichtversicherung",
      "INSURANCE_OCS": "Haftpflichtversicherung des Spediteurs",
      "LICENCE_DOMESTIC": "Inländische Lizenz",
      "LICENCE_FORWARDING": "Speditionslizenz",
      "LICENCE_INTERNATIONAL": "Internationale Lizenz",
      "MAIN_DATA_TYPE": "Primärdaten",
      "SECONDATY_DATA_TYPE": "Sekundärdaten",
      "STATUS_CHANGED": "Statusänderung",
      "TYPE_EMAIL": "E-Mail gesendet",
      "add": {
        "hint": "Partnerschaft zwischen Lieferant und Frachtführer hinzufügen",
        "label": "Partnerschaft hinzufügen",
        "title": "Partnerschaft zwischen Auftragnehmern hinzufügen"
      },
      "additionalDetails": "Zusätzliche Angaben",
      "adviceVerification": {
        "firstPartLabel": "E-Mail zur Verifizierung der Daten senden",
        "secondPartLabel": "vor jedem Lieferavis"
      },
      "allowsPeriodicTransport": "Periodische Lieferavise",
      "auxiliary": {
        "editionDisabled": "Sie können dieses nicht bearbeiten.",
        "title": "Das Unternehmen {contractorName} ist als Auftragnehmer für {principalCompanyName} tätig.",
        "usersEditionDisabled": "Sie können dessen Benutzer nicht bearbeiten."
      },
      "connectAll": {
        "CARRIER": "Frachtführer",
        "SUPPLIER": "Lieferanten",
        "SUPPLIER.CLIENT": "Kunden",
        "cofirmMsg": "Möchten Sie das Unternehmen {companyName} mit allen {contractorsType} im Bereich von {principalCompanyName} wirklich verknüpfen?",
        "label": "Alle Auftragnehmer verknüpfen",
        "success": "Partnerschaften erstellt"
      },
      "contractor": "Auftragnehmer",
      "contractorOfCompany": "vom Unternehmen {companyName}",
      "dateCreated": "Datum der Erstellung",
      "deleteMsg": "Möchten Sie diese Partnerschaft zwischen Auftragnehmern wirklich löschen?",
      "errors": {
        "additionalDetails": {
          "length": "Die maximale Zeichenanzahl beträgt 1000."
        },
        "adviceVerificationInterval": {
          "intervalFormatError": "Falsches Intervallformat – Geben Sie eine Zahl gefolgt von einer Zeiteinheit ein: s (Sekunden), m (Minuten), h (Stunden) oder d (Tage), z. B. 48h"
        },
        "contractorShortName": {
          "contractorShortNameTooLong": "Die Kurzbezeichnung ist zu lang."
        },
        "representativeEmail": {
          "email": "Bitte geben Sie eine gültige E-Mail Adresse ein."
        },
        "representativePhoneNumber": {
          "regex": "Die Telefonnummer in Polen muss neunstellig sein."
        },
        "transportLimit": {
          "invalidNumber": "Das Transportlimit muss eine natürliche Zahl sein."
        }
      },
      "logs": {
        "CONTRACTOR_GROUP_ADDED": "Auftragnehmergruppe hinzugefügt",
        "CONTRACTOR_GROUP_REMOVED": "Auftragnehmergruppe gelöscht",
        "DOCUMENT_DISABLED": "Dokument deaktiviert",
        "DOCUMENT_SAVED": "Dokument gespeichert",
        "DOCUMENT_UPDATED": "Dokument aktualisiert",
        "FILE_ADDED": "Datei hinzugefügt",
        "FILE_DELETED": "Datei gelöscht",
        "SECTION_CONFIRMED": "Abschnitt bestätigt",
        "SECTION_REJECTED": "Abschnitt abgelehnt",
        "SECTION_SAVED": "Abschnitt gespeichert",
        "SECTION_UPDATED": "Abschnitt aktualisiert",
        "STATUS_CHANGED": "Status geändert",
        "TYPE_EMAIL": "E-Mail-Benachrichtigung"
      },
      "representativeEmail": "Vertreter – E-Mail-Adresse",
      "representativeName": "Vertreter",
      "representativePhoneNumber": "Vertreter – Telefonnummer",
      "transportLimit": "Transportlimit",
      "transportLimitHint": "Lassen Sie das Feld leer, wenn es kein Transportlimit gibt.",
      "transportsCompletedCount": "Davon abgeschlossen",
      "transportsCreatedCount": "Transporte erstellt",
      "transportsInProgressCount": "Transporte in Bearbeitung"
    },
    "partnerships": {
      "label": "Partnerschaften"
    },
    "periodicTransport": {
      "cannotChangeCompany": "Das Unternehmen kann nicht geändert werden. Es muss mindestens ein Unternehmen mit aktiviertem periodischen Lieferavis im Auftrag enthalten sein.",
      "cannotDeleteStep": "Dieser Schritt kann nicht gelöscht werden. Es muss mindestens ein Unternehmen mit aktiviertem periodischen Lieferavis im Auftrag enthalten sein.",
      "deleteAllConfirmMsg": "Möchten Sie alle zukünftigen periodischen Lieferavise löschen? Mit \"Abbrechen\" wird nur dieser Auftrag gelöscht.",
      "errors": {
        "chooseAtLeastOneDay": "Wählen Sie mindestens einen Tag aus.",
        "chooseFirstDayOfPeriodicTransport": "Wählen Sie den Wochentag aus, an dem der erste periodische Transport stattfindet.",
        "endDateMustBeAfterStartDate": "Das Enddatum muss nach dem Startdatum liegen.",
        "incorrectRangeOption": "Falscher Bereich. Mögliche Werte sind \"Täglich\", \"Wöchentlich\" oder \"Monatlich\".",
        "windowMustBeInFirstDayOfPeriodicTransport": "Beim ersten Tag für das periodische Lieferavis muss es sich um das geplante Lieferavisdatum handeln.",
        "wrongDateRange": "Für eine ausgewählte Option \"{range}\" beträgt die maximale Zeitspanne für ein periodisches Lieferavis {interval}. Legen Sie eine kürzere Zeitspanne für ein periodisches Lieferavis fest."
      },
      "frequencies": {
        "DAILY": "Täglich",
        "MONTHLY": "Monatlich",
        "WEEKLY": "Wöchentlich"
      },
      "frequency": "Häufigkeit des Lieferavis",
      "label": "Periodisches Lieferavis",
      "updateAllConfirmMsg": "Möchten Sie alle zukünftigen periodischen Lieferavise aktualisieren? Mit \"Abbrechen\" werden die Änderungen nur für diesen Auftrag gespeichert."
    },
    "pricingPlans": {
      "BASIC": "BASIC",
      "CLIENT": "CLIENT",
      "OPERATOR": "OPERATOR",
      "PRO": "PRO"
    },
    "product": {
      "INDEX": {
        "name": "Produktbezeichnung",
        "name.autoCompletePlaceholder": "Beginnen Sie mit der Eingabe, um nach der Produktbezeichnung zu suchen."
      },
      "ORDER": {
        "name": "Auftragstyp",
        "name.autoCompletePlaceholder": "Beginnen Sie mit der Eingabe, um nach einem Auftragstyp zu suchen."
      },
      "add": "Produkt hinzufügen",
      "disableConfirm": "Möchten Sie dieses Produkt wirklich löschen?",
      "disabled": "Das Produkt {index} wurde entfernt.",
      "errors": {
        "index": {
          "tooLong": "Der eingegebene Text ist zu lang. Die maximale Länge beträgt 255 Zeichen."
        },
        "name": {
          "tooLong": "Der eingegebene Text ist zu lang. Die maximale Länge beträgt 255 Zeichen."
        },
        "shortName": {
          "tooLong": "Der eingegebene Text ist zu lang. Die maximale Länge beträgt 255 Zeichen."
        }
      },
      "index": "Index",
      "index.autoCompletePlaceholder": "Beginnen Sie mit der Eingabe, um ein Produkt nach dessen Index zu suchen.",
      "saved": "Das Produkt {index} wurde gespeichert.",
      "shortName": "Kurzbezeichnung",
      "shortName.autoCompletePlaceholder": "Beginnen Sie mit der Eingabe, um ein Produkt nach dessen Kurzbezeichnung zu suchen.",
      "unit": "Einheit",
      "updated": "Das Produkte {index} wurde aktualisiert."
    },
    "products": {
      "INDEX": {
        "label": "Produkte",
        "table": "Produkttabelle"
      },
      "ORDER": {
        "label": "Produkte",
        "table": "Produkttabelle"
      }
    },
    "queues": {
      "disableConfirm": "Möchten Sie diese Warteschlange wirklich deaktivieren?",
      "englishName": "Englische Bezeichnung",
      "idx": "Sequenz",
      "updateSuccessMessage": "Die Einstellungen für Warteschlange {queueName} wurden aktualisiert."
    },
    "queuesStatus": {
      "sendSmsModal": {
        "messagePlaceholder": "SMS-Text eingeben",
        "title": "SMS an Fahrer in der Warteschlange:"
      },
      "tableHeader": "Zusammenfassung des aktuellen Warteschlangenstatus"
    },
    "ramp": {
      "additionalSmsNotificationInfoEn": "Zusätzliche Informationen EN",
      "additionalSmsNotificationInfoPl": "Zusätzliche Informationen DE",
      "backToWarehouse": "Zurück zum Lager",
      "deleteDedicatedWindows": "Ungenutzte/genutzte fest zugewiesene Zeitfenster löschen",
      "disableConfirm": "Möchten Sie diese Rampe wirklich deaktivieren?",
      "disabledMessage": "Rampe \"{rampName}\" wurde gelöscht.",
      "enableConfirm": "Möchten Sie diese Rampe wirklich aktivieren?",
      "enabledMessage": "Rampe \"{rampName}\" wurde aktiviert.",
      "errors": {
        "cannotMoveWindowException": "Das Zeitfenster darf nicht an den angegebenen Termin verschoben werden.",
        "idx": {
          "idxTooBig": "Die Nummer der Rampe darf nicht größer sein als die Anzahl der Rampen in diesem Lager.",
          "nonPositiveInteger": "Die Nummer der Rampe muss eine positive Ganzzahl sein."
        },
        "shortName": {
          "sizeTooBig": "Die Kurzbezeichnung darf max. 10 Zeichen lang sein."
        },
        "wrongSwappedWindowSize": "Die getauschten Zeitfenster weisen eine unterschiedliche Dauer auf."
      },
      "idx": "Auftrag",
      "label": "Rampe",
      "maxPallets": "Max. Palettenanzahl",
      "roleGroupsLabel": "Benutzerrollen, für die die Rampe verfügbar ist",
      "updateSuccessMessage": "Die Einstellungen für die Rampe wurden aktualisiert.",
      "wantToSwapWindow": "Zeitfenster tauschen?"
    },
    "ramps": {
      "label": "Rampen"
    },
    "reports": {
      "contractors": {
        "header": "Bericht für Transporte erstellen, die zwischen den folgenden Zeitpunkten be- und entladen wurden",
        "label": "Bericht aus Zeitfenstern"
      },
      "label": "Berichte"
    },
    "resetPasswordWindow": {
      "enteredPasswordsIsNotIdentical": "Beide Passwörter müssen identisch sein.",
      "header": "Ändern Sie Ihr Systempasswort.",
      "info": "Setzen Sie das Passwort gemäß unserer Systemsicherheitsrichtlinie zurück.",
      "info2": "Das neue und das alte Passwort dürfen nicht übereinstimmen und müssen acht Zeichen umfassen (Ziffern, Klein- und Großbuchstaben)."
    },
    "roleGroup": {
      "label": "Rolle",
      "placeholder": "Rolle auswählen"
    },
    "route": {
      "addNewRate": "Neuen Tarif hinzufügen",
      "addNewRoute": "Neue Route hinzufügen",
      "address": {
        "city": "Stadt",
        "country": "Land",
        "load": "Beladen",
        "postal": "PLZ",
        "unload": "Entladen"
      },
      "addresses": "Adressen",
      "bestOffer": "Bestes Angebot",
      "createTransport": "Transport aus Route erstellen",
      "dataFromRoute": "Daten von der Route",
      "details": "Details zur Route",
      "editRoute": "Route bearbeiten",
      "edition": "Bearbeiten",
      "errors": {
        "description": {
          "length": "Der eingegebene Text ist zu lang. Die maximale Länge beträgt 255 Zeichen."
        },
        "name": {
          "length": "Der eingegebene Text ist zu lang. Die maximale Länge beträgt 255 Zeichen."
        }
      },
      "label": "Route",
      "name": "Name of route",
      "namePlaceholder": "Enter route name",
      "newRoute": "Neue Route",
      "noTransportTypeAvailable": "Sie verfügen derzeit nicht über Zugriff auf Transportarten mit Preisliste.",
      "offer": {
        "carrier": "Frachtführer",
        "confirmation": {
          "failure": "Leider konnte das Angebot nicht bestätigt werden. Wenden Sie sich an den Systemadministrator.",
          "message": "Möchten Sie dieses Angebot wirklich bestätigen?"
        },
        "confirmed": "Bestätigt",
        "date": "Datum des Tarifs",
        "deleteMessage": "Möchten Sie dieses Angebot wirklich entfernen?",
        "edition": "Bearbeiten",
        "number": "Angebotsnummer",
        "price": "Tarif",
        "showLess": "Weniger Angebote anzeigen ({n})",
        "showMore": "Mehr Angebote anzeigen ({n})",
        "toggleTable": "Angebotsliste wechseln",
        "validity": "Gültigkeitsdaten"
      },
      "rate": "Tarif",
      "rates": {
        "label": "Rates"
      },
      "termOfValidity": "Gültigkeitsdauer"
    },
    "secondary-section": {
      "bankAccountNumber": "Kontonummer",
      "bankFilePattern": {
        "label": "Formularvorlage"
      },
      "errors": {},
      "internalNotes": "Interne Anmerkungen",
      "label": "Zusätzliche Daten",
      "otherAttachments": "Sonstige Anhänge"
    },
    "section": {
      "accept": "Annehmen",
      "dateOfArchiving": "Datum der Archivierung",
      "dateOfDeletion": "Datum der Löschung",
      "dateOfExpiration": "Datum des Ablaufs",
      "deleted": "Gelöscht",
      "disable": "Deaktivieren",
      "disabled": "Deaktiviert",
      "expired": "Abgelaufen",
      "label": "Abschnitt",
      "reject": "Ablehnen",
      "requestVerification": "Überprüfung anfordern",
      "state": {
        "ACCEPTED": "Angenommen",
        "ARCHIVED": "Archiviert",
        "DELETED": "Gelöscht",
        "DOCUMENT_ADDED": "Aktualisiert",
        "EXPIRED": "Abgelaufen",
        "PENDING": "Ausstehend",
        "REJECTED": "Abgelehnt",
        "UPDATED": "Aktualisiert",
        "label": "Status des Abschnitts"
      },
      "successMessage": "Abschnitt erfolgreich gespeichert",
      "update": "Aktualisieren"
    },
    "section-action": {
      "noCommentError": "Geben Sie die Gründe für die Ablehnung an.",
      "rejectionComment": "Geben Sie den Grund für die Ablehnung des Abschnitts an."
    },
    "sectionType": {
      "ANTI_CORRUPTION_TYPE": "Antikorruptionserklärung",
      "BLOCKADES": "Aktive Blockaden",
      "GROUPS": "Gruppen",
      "HISTORY": "Verlauf",
      "INSURANCE": "Versicherung",
      "INSURANCE_DOMESTIC_OCP": "Frachtführerhaftpflichtversicherung im Inlandsverkehr",
      "INSURANCE_INTERNATIONAL_OCP": "Frachtführerhaftpflichtversicherung im internationalen Verkehr",
      "INSURANCE_OC": "Haftpflichtversicherung des Unternehmens",
      "INSURANCE_OCS": "Haftpflichtversicherung des Spediteurs",
      "LICENCE": "Lizenz",
      "LICENCE_DOMESTIC": "Inländische Lizenz",
      "LICENCE_FORWARDING": "Speditionslizenz",
      "LICENCE_INTERNATIONAL": "Internationale Lizenz",
      "MAIN_DATA_TYPE": "Primärdaten",
      "SECONDARY_DATA_TYPE": "Sekundärdaten",
      "SENSOR_CALIBRATION_CERTIFICATE": "Sensorkalibrierungszertifikat",
      "SUMMER_MAPPING": "Sommer-Mapping",
      "USERS": "Benutzer",
      "VEHICLE_MAIN_DATA_TYPE": "Verkehrsträgerdaten",
      "WINTER_MAPPING": "Winter-Mapping"
    },
    "service": {
      "arrival": "Ankunft",
      "company": "Unternehmen",
      "contractorCompanyPlaceholder": "Unternehmensname",
      "createTitle": "Dienstleistung planen",
      "departure": "Abfahrt",
      "description": "Beschreibung der Dienstleistung",
      "registrationNumber": "Registrierungsnummer",
      "selectCompany": "Unternehmen auswählen",
      "serviceman": "Name des Dienstleisters",
      "title": "Dienstleistung #%@1"
    },
    "serviceNotWorking": {
      "content": "die Plattform ist vorübergehend nicht verfügbar. Bitte versuchen Sie es später erneut. Wir entschuldigen uns für die entstandenen Unannehmlichkeiten.",
      "header": "Sehr geehrte/r Benutzer/in,",
      "signature": "Ihr Logintegra-Team"
    },
    "services": {
      "add": "Dienstleistung planen",
      "label": "Dienstleistungen"
    },
    "settings": {
      "backToSettings": "Zurück zu den Einstellungen",
      "confirmDelete": "Möchten Sie diese Einstellung wirklich löschen?",
      "create": "Einstellung hinzufügen",
      "desc": {
        "ADDITIONAL_ADD_FILE_BUTTON": "Zusätzliche Schaltfläche zum Hinzufügen von Dokumenten im Auftragsformular",
        "ADDITIONAL_CONFIRMATION_DURING_REMOVING_GRAY_WINDOW": "Zusätzliche Bestätigung beim Entfernen des ausgegrauten Blockadefensters",
        "ADDITIONAL_INFO_ON_PLAIN_TEMPLATE_VIEW": "Zusätzliche Informationen, die in der Druckansicht des Auftragsformulars angezeigt werden",
        "ADDITIONAL_INFO_ON_PLAIN_TEMPLATE_VIEW_EN": "Zusätzliche Informationen, die in der Druckansicht des Auftragsformulars angezeigt werden (Englisch)",
        "ADD_FILE_TO_TRANSPORT_AFTER_OFFER_ACCEPTATION": "Legt fest, ob die Datei dem Transport hinzugefügt werden soll, nachdem die Auktion gewonnen wurde oder nachdem der Auftrag an den Frachtführer übergeben wurde",
        "ADD_FILE_TO_TRANSPORT_AFTER_OFFER_ACCEPTATION_NAME": "Bezeichnung der Datei, die dem Transport hinzugefügt wird, nachdem die Auktion gewonnen wurde oder nachdem der Auftrag an den Frachtführer übergeben wurde",
        "ADVICE_CREATED_EMAIL_FILE_NAME": "Version der E-Mail zum Hinzufügen des Lieferavis",
        "ADVICE_CREATED_EMAIL_SUBJECT": "Betreff der E-Mail zum Hinzufügen des Lieferavis",
        "ADVICE_UPDATED_EMAIL_FILE_NAME": "Version der E-Mail zur Bearbeitung des Lieferavis",
        "ADVICE_VERIFICATION_EMAIL_ENABLED": "Legt fest, ob die Felder für den Versand einer E-Mail zur Überprüfung der Daten vor jedem Lieferavis in den Firmendetails angezeigt werden",
        "ALLOW_SAME_WAREHOUSE_IN_DIFFERENT_STEP": "Ermöglicht die Auswahl desselben Lagers in verschiedenen Schritten eines Transports",
        "ALT_IDENTIFIER_LABEL": "Beschriftung der Spalte für die Auftragsnummer",
        "ALT_IDENTIFIER_LABEL_EN": "Beschriftung der Spalte für die Auftragsnummer (Englisch)",
        "ALT_IDENTIFIER_RESET_INTERVAL": "Häufigkeit des Zurücksetzens der Folge der automatisch generierten \"altIdentifier\"-Nummer",
        "ANNOUNCEMENTS_COUNTER_POLL_INTERVAL": "Frequenz der Datenaktualisierung in Millisekunden – Ankündigungszähler",
        "API_MESSAGE_URL": "Adresse für den Versand von API-Nachrichten",
        "ARCHIVING_JOB_INTERVAL": "Wie lange nach der Bestätigung des endgültigen Status soll der Transport ins Archiv verschoben werden?",
        "ASSORTMENT_GROUP_LABEL": "Beschriftung des Formularfelds für die Sortimentsgruppe",
        "ASSORTMENT_GROUP_LABEL_EN": "Beschriftung des Formularfelds für die Sortimentsgruppe (Englisch)",
        "AUCTION_ENDED_WITHOUT_OFFERS_EMAIL_FILE_NAME": "Version einer E-Mail zum Ende einer Auktion ohne Gebote",
        "AUTOMATIC_DELETING_DEDICATED_WINDOWS_INTERVAL": "Wann sollen nicht verwendete fest zugewiesene Zeitfenster im Verhältnis zum Termin im Zeitplan gelöscht werden (wenn die Option \"Unbelegte/belegte Zeitfenster automatisch löschen\" ausgewählt ist). Wenn der Wert z. B. \"-2h\" ist, wird das nicht verwendete fest zugewiesene Zeitfenster von 16:00 (16:00 Uhr) um 14:00 (14:00 Uhr) entfernt.",
        "AUTOMATIC_DELETING_UNUSED_DEDICATED_WINDOWS_ENABLED": "Nicht verwendete Zeitfenster automatisch löschen",
        "AUTOMATIC_DELETING_USED_DEDICATED_WINDOWS_ENABLED": "Verwendete fest zugewiesene Zeitfenster automatisch löschen",
        "AUTOMATIC_GENERATE_DOCUMENT": "Legt fest, ob die Kennzeichnung shouldGenerateDocument angezeigt wird",
        "AUTOMATIC_TIME_WINDOW_ADVICE_EXCEL_EMAILS": "[Zeitfensterbericht] E-Mail-Adressen (durch Komma getrennt), an die wir automatische Zeitfenster-Benachrichtigungsberichte senden sollen",
        "AUTOMATIC_TIME_WINDOW_ADVICE_EXCEL_REF_USERNAME": "[Zeitfensterbericht] Die Benutzeranmeldung, die wir beim Erstellen von Berichten verwenden sollten",
        "AUTOMATIC_WEIGHING": "Aktiviert das automatische Wiegen",
        "AUTO_GENERATED_DELIVERY_NOTE_FIXED_TEXT": "Fester Text für automatisch generierte Lieferscheine",
        "AVAILABLE_LOGIN_PAGE_BACKGROUND_FILE_NAMES": "Dateinamen der verfügbaren Hintergründe für die Anmeldeseite",
        "CAN_ARRIVAL_DATE_BE_IN_THE_PAST": "Legt fest, ob das Ankunftsdatum in der Vergangenheit liegen darf",
        "CAN_EXPORT_INDEXES_TO_XLS": "Aktiviert die Funktion zum Exportieren von Indizes in eine Excel-Datei",
        "CAN_REFRESH_MANUALLY": "Möglichkeit zur manuellen Datenaktualisierung",
        "CHANGE_TRANSPORT_INTERVAL": "Wie lange vor dem voraussichtlichen Be-/Entladedatum der Transport durch den Lieferanten/Frachtführer geändert werden kann.",
        "CHECKPOINT_CONFIRMED_EMAIL_FILE_NAME": "Version der E-Mail zur Bestätigung eines Kontrollpunkts",
        "CHECKPOINT_REJECTED_EMAIL_FILE_NAME": "Version der E-Mail zur Ablehnung eines Kontrollpunkts",
        "CLASSIFICATIONS_TAB_POLL_INTERVAL": "Frequenz der Datenaktualisierung in Millisekunden – Registerkarte \"Klassifizierung\"",
        "CLASSIFICATION_RESOURCE_COMPLETED_DELAY": "Angabe der Zeit, nach der das Objekt aus der Warteschlange auf der Registerkarte \"Warteschlangen\" entfernt wird, wenn der Abfahrtsstatus noch nicht bestätigt wurde",
        "CLASSIFIED_RESOURCE_DISAPPEARANCE_DELAY": "Geben Sie die Verzögerung an, nach der klassifizierte Transporte von der Registerkarte \"Klassifizierung\" entfernt werden.",
        "COMPANY_FORM_SHOW_REPRESENTATIVE": "Felder in Bezug auf den Vertreter im Unternehmensformular anzeigen.",
        "CONTRACTOR_NUMBER_LABEL": "Bezeichnung des Felds zur Angabe der Auftragnehmernummer",
        "CONTRACTOR_NUMBER_LABEL_EN": "Bezeichnung des Felds zur Angabe der Auftragnehmernummer (Englisch)",
        "CUSTOM_FIELD_SPLIT_VALUE": "Anzahl der definierten benutzerdefinierten Felder, ab der diese Felder im Bestellformular in zwei Spalten aufgeteilt werden",
        "DAYS_TO_RESET_PASSWORD": "In welchem Intervall (in Tagen) der Benutzer aufgefordert werden soll, das Passwort zu ändern.",
        "DAYS_TO_SHOW_ADVICE_HISTORY": "Für welchen Zeitraum (in Tagen) sollen die Fahrerdaten in der Liste der Anmerkungen bei der Erstellung/Bearbeitung des Lieferavis angezeigt werden?",
        "DEFAULT_ADVICE_FUTURE_DAYS_LIMIT": "Standardzeitraum (Tage) für den Versand von Lieferavisen an neue Auftragnehmer.",
        "DEFAULT_EMAIL_DELAY_INTERVAL": "Standardverzögerung beim E-Mail-Versand",
        "DEFAULT_NUMBER_OF_DISPLAYED_OFFERS": "Standardanzahl der Angebote für die Route in der Preisliste",
        "DEFAULT_TAB_AFTER_LOGIN": "Standardregisterkarte, die nach der Anmeldung angezeigt wird",
        "DELAY_TIME_TO_MOVE_ORDER_TO_ARCHIVE_AFTER_TASK_TIMEOUT": "Verzögerungszeit, nach der der Auftrag automatisch in das Archiv verschoben wird (nur für angezeigte Aufgaben)",
        "DELAY_TIME_TO_MOVE_TIME_WINDOW_TO_NEXT_FREE_SPOT": "Länge der Verzögerungszeit, nach der die Plattform das Fenster auf das nächstmögliche Fenster verschiebt",
        "DISPLAY_INDEXES_IN_SCHEDULE": "Anzeige einer Indexliste in der Zeitplanansicht",
        "DISPLAY_TRAILER_REG_NUMBER_IN_ADVICE_COLUMN": "Anzeige einer Anhänger-Registrierungsnummer anstelle einer Registrierungsnummer in der Spalte \"Lieferavis\".",
        "DOCUMENTS_MONITORING_INTERVAL": "Die Dauer der Verzögerung beim Hinzufügen der Dokumente, nach der eine E-Mail an den Kunden gesendet wird.",
        "DOCUMENT_SECTION_VISIBLE_IN_TRANSPORT_FORM": "Anzeige des Dokumentenabschnitts im Transportformular",
        "DRIVER_PUNCTUALITY_INTERVAL": "Zeit relativ zum Beginn des Zeitfensters, das die Verzögerung der Schrittbestätigung festlegt",
        "EMAIL_NOTIFICATION_CONTAINS_PRODUCTS_SECTION": "Legt fest, ob der Abschnitt \"Produkte\" in den E-Mails angezeigt wird",
        "EMAIL_NOTIFICATION_CONTAINS_UNLOAD_SECTION": "Legt fest, ob der Abschnitt \"Entladen\" in den E-Mails angezeigt wird",
        "ENABLE_TRANSPORT_ROW_HIGHLIGHTING": "Legt fest, ob Transportzeilen nach dem Anklicken hervorgehoben werden",
        "ENABLE_TRANSPORT_TABLE_DAYS_LIMIT": "Aktiviert einen Filter in der Tabelle der aktiven Aufträge, der Aufträge mit Zeitfenstern nach einem bestimmten Datum ausblendet",
        "END_OF_DAY_IN_WAREHOUSE_INTERVAL": "Das Ende des Arbeitstags in einem Lager, das für die Zählung der Lieferavis-Zeitfenster verwendet wird. Achtung! Das Ende des Arbeitstags im Lager kann abweichen. Beispiel: Um das Ende des Tags auf 6:00 Uhr des Folgetags festzulegen, muss der Parameterwert 30h betragen [30.01. 00:00 Uhr + 30 h = 31.01. 06:00 Uhr]",
        "ERP_IDENTIFIER_LABEL": "Beschriftung der Spalte für die ERP-Auftragsnummer",
        "ERP_IDENTIFIER_LABEL_EN": "Beschriftung der Spalte für die ERP-Auftragsnummer (Englisch)",
        "FILE_TERMS_OF_CONDITIONS_ACCEPTED": "Link zu den Leistungsbedingungen.",
        "FOOTER": "Link zur Fußzeile, die in der Plattform angezeigt wird",
        "FOREIGN_DELIVERY_MULTIPLIER": "Der Wert, mit dem die Dauer des Entladens bei Auslandslieferungen multipliziert wird.",
        "HAS_ACCESS_TO_APPLIES": "Legt fest, ob eine Überprüfung der Dokumente des Frachtführers erfolgen soll",
        "HAS_ACCESS_TO_MAX_PALLET_PER_DAY": "Legt fest, ob das Palettenlimit für jeden Tag aktiviert werden soll",
        "HAS_ACCESS_TO_MAX_PALLET_PER_RAMP": "Legt fest, ob das Palettenlimit für jede Rampe aktiviert werden soll",
        "HAS_ACCESS_TO_NUMBER_OF_WINDOWS": "Legt fest, ob die Anzahl der Zeitfenster für einen bestimmten Tag in einem Lager angezeigt werden soll. Abhängig von Beginn und Ende des Arbeitstags im Lager.",
        "HD_MODE_NEXT_DAY_SHIFT_TIME": "Legt die Uhrzeit fest, zu der ein Zeitplan in der HD-Ansicht auf den nächsten Tag verschoben werden soll (Format HH:mm)",
        "HOW_MANY_DAYS_BEFORE_CERTIFICATE_EXPIRATION_SHOULD_MAIL_BE_SENT": "Wie viele Tage vor Ablauf der Gültigkeitsdauer des Sensorkalibrierungszertifikats eine E-Mail-Benachrichtigung mit einer Erinnerung gesendet werden soll",
        "HOW_MANY_DAYS_BEFORE_DOCUMENT_EXPIRATION_DATE_CARRIER_SHOULD_BE_BLOCKED": "Wie viele Tage vor dem Ablaufdatum des Dokuments soll der Frachtführer gesperrt werden?",
        "HOW_MANY_DAYS_BEFORE_INSURANCE_EXPIRATION_SHOULD_MAIL_BE_SENT": "Wie viele Tag vor Ablauf einer Versicherung eine E-Mail-Benachrichtigung mit einer Erinnerung an den Frachtführer gesendet werden soll",
        "HOW_MUCH_TIME_BEFORE_START_WINDOW_EDITING_ORDER_SENDS_SMS": "Wie lange vor Beginn des Zeitfensters die Bearbeitung von Lieferavisen den Versand von SMS auslösen.",
        "HYPERLINK_TO_LOGO_ON_NAVBAR": "Hyperlink zu dem Logo, das nach der Anmeldung in der Navigationsleiste angezeigt wird",
        "INVITATION_EXPIRATION_INTERVAL": "Die Gültigkeitsdauer der Einladung.",
        "LATE_ADVICE_EMAIL_FILE_NAME": "Version einer E-Mail zur Verzögerung",
        "LIMITED_INVITATION_MODE": "Benutzer können nur Benutzer für ihre Rolle und Auftragnehmer einladen. Nur Administratoren können alle Vorgänge vornehmen.",
        "LINK_TO_APPLIES_MANUAL": "Link zum Anwendungshandbuch",
        "LOADING_URL": "Plattform-URL",
        "LOCAL_PRINCIPAL_LANGUAGE": "Sprache des lokalen Unternehmens",
        "LOGO_ON_LOGIN_PAGE": "Link zu dem Logo, das auf der Anmeldeseite angezeigt wird.",
        "LOGO_ON_NAVBAR": "Link zu dem Logo, das nach der Anmeldung in der Navigationsleiste angezeigt wird",
        "MANAGE_TRANSPORT_INTERVAL": "Wie lange vor dem voraussichtlichen Be-/Entladedatum sollen Lieferavise erstellt bzw. geändert werden können.",
        "MAXIMUM_NUMBER_OF_ORDERS_TO_COPY": "Maximale Anzahl von Aufträgen, die mit der Funktion \"Ähnliche erstellen\" erstellt werden können",
        "MAXIMUM_PERMISSIBLE_VEHICLE_WEIGHT_SETTING": "Maximal zulässiges Fahrzeuggewicht [t]",
        "MAX_CHARACTERS_IN_INDEX_PRODUCTS_COLUMN": "Anzahl der maximal angezeigten Zeichen in der Produktspalte",
        "MAX_CHARACTERS_IN_ORDER_NUMBER_COLUMN": "Anzahl der angezeigten Zeichen in Spalten mit Auftragsnummern",
        "MAX_CHARACTERS_IN_TRANSPORT_RELATION_COLUMN": "Anzahl der maximal angezeigten Zeichen, die in der Beziehungsspalte erlaubt sind",
        "MAX_NUMBER_OF_TIME_WINDOWS_FOR_SUGGEST": "Maximale Anzahl vorgeschlagener Zeitfenster",
        "MAX_SIMULTANEOUSLY_ADDED_FILES_COUNT": "Max. zulässige Anzahl von Dateien, die dem Auftrag gleichzeitig hinzugefügt werden können",
        "MAX_WINDOW_LENGTH": "Max. zulässige Länge des Zeitfensters",
        "MIN_WEIGHT_TO_CREATE_TRANSPORT": "Mindestgewicht für die automatische Erstellung des Transports",
        "MSH_ADVICE_URL": "Url zum Senden der Beratungsdaten an MSH",
        "MSH_CHECKPOINTS_URL": "Url zum Senden von Kontrollpunktdaten an MSH",
        "MSH_COMPANIES_UPDATE_JOB_INTERVAL": "Jobintervall für das Herunterladen der Firmenliste von MSH",
        "MSH_COMPANIES_UPDATE_URL": "Url zum Herunterladen der Liste der Unternehmen von MSH",
        "MSH_GET_RESOURCES_URL": "Adresse einer externen Quelle zum Herunterladen von Ressourcendaten (Fahrer, Traktoren, Anhänger)",
        "MSH_TRANSACTION_CARRIER_URL": "Url zum Senden von Trägerdaten aus einer Transaktion an MSH",
        "MSH_TRANSPORTS_UPDATE_JOB_INTERVAL": "Jobintervall zum Abrufen der Liste der Transporte von MSH",
        "MSH_TRANSPORTS_UPDATE_URL": "Url zum Abrufen der Liste der Transporte aus MSH",
        "NAME_TERMS_OF_CONDITIONS_ACCEPTED": "Bezeichnung der Leistungsbedingungen.",
        "NAME_TERMS_OF_CONDITIONS_ACCEPTED_EN": "Bezeichnung der Nutzungsbedingungen (Englisch)",
        "NEW_ADVICE_EMAIL_RECIPIENTS": "Zusätzliche E-Mail-Adressen, an die Benachrichtigungen über neue Lieferavise gesendet werden (kommagetrennt, z. B. xyz@xyz.com,abc@abc.com)",
        "NEW_AUCTION_EMAIL_FILE_NAME": "Version einer E-Mail zu einer neuen Auktion",
        "NEW_TRANSPORT_EMAIL_FILE_NAME": "Version einer E-Mail zu einem neuen Auftrag",
        "ORDER_FORM_PRINTING_ENABLED": "Legt fest, ob eine druckbare Version im Auftragsformular verfügbar ist",
        "ORDER_WAITING_FOR_CONFIRMATION_EMAIL_FILE_NAME": "Version der E-Mail zur Übergabe des Auftrags an den Frachtführer",
        "OWN_ADDRESSES_ONLY": "Zugriff ausschließlich auf Adressen, die in einem bestimmten Auftraggeberbereich eingegeben wurden (gilt nicht für die Hauptadressen von Unternehmen)",
        "PACKAGE_TYPES_FEATURE_ENABLED": "Registerkarte für die Verwaltung der Paketeinstellungen anzeigen",
        "PATRON_IN_CONTRACTOR_DETAILS_ENABLED": "Legt fest, ob das Feld \"Kunde\" in den Firmendetails angezeigt wird",
        "POLL_INTERVAL": "Frequenz der Datenaktualisierung in Millisekunden – Tabelle und Zeitplan.",
        "QUEUES_TAB_POLL_INTERVAL": "Frequenz der Datenaktualisierung in Millisekunden – Registerkarten \"Warteschlangen\" und \"Warteschlangenstatus\"",
        "RESET_PASSWORD_IS_DISABLED": "Obligatorische Passwortrücksetzung deaktivieren.",
        "ROLES_WITH_ENABLED_USER_AND_GROUP_FILTER": "Liste der Rollen mit aktivierter zusätzlicher Filterung (meine Aufträge, Gruppenaufträge, alle Aufträge). Die Werte müssen nach dem Dezimalkomma angegeben werden, z. B. \"ADMIN, GUARD\".",
        "ROLE_ADD_CONTRACTOR_GROUP": "Die Möglichkeit, eine neue Gruppe im Gruppierungsabschnitt in den Auftragnehmerdetails hinzuzufügen",
        "ROLE_EDIT_CONTRACTOR_GROUP": "Möglichkeit, bestehende Gruppen von Auftragnehmern zu bearbeiten",
        "SCHEDULE_LEGEND_ENABLED": "Aktiviert die Zeitplanlegende",
        "SEND_API_MESSAGE_EVERY_TRANSPORT_UPDATE": "Aktiviert den Versand von API-Nachrichten nach Transportaktualisierungen",
        "SEND_CONTRACTOR_LATE_ADVICE_MAIL": "Gibt an, ob eine verzögerte E-Mail an externe Auftragnehmer gesendet werden soll, wenn der Auftragsersteller der Auftraggeber ist, oder an den erstellenden Auftragnehmer, wenn der Auftragsersteller der Auftragnehmer ist.",
        "SEND_FUTURE_PERIODIC_TRANSPORTS_EDIT_MAIL_INTERVAL_IN_DAYS": "Anzahl der Tage in der Zukunft, für die die E-Mail-Benachrichtigungen gesendet werden sollen, wenn periodische Lieferavise bearbeitet werden",
        "SEND_SMS_AFTER_CREATING_ORDER": "Nach Erstellen eines Lieferavis SMS an den Fahrer senden",
        "SEND_SMS_AFTER_EDITING_ORDER": "Nach Bearbeiten eines Lieferavis SMS an den Fahrer senden",
        "SET_SCHEDULE_LEGEND_TO_FIXED_POSITION": "Legt fest, ob die Legende im Zeitplan immer angezeigt wird",
        "SHOULD_RENDER_ALL_TRANSPORT_TYPES": "Standardmäßig werden alle Arten von Transporten in der Tabelle der aktiven Aufträge angezeigt.",
        "SHOW_COMPANY_VOIVODESHIP_FIELD": "Feld \"Woiwodschaft\" in den Unternehmensdaten anzeigen",
        "SHOW_PRINCIPAL_ADDRESS_IN_PRINT_VIEW": "Auftraggeberadresse in der Druckansicht anzeigen",
        "SHOW_QUANTITY_SUM_CHECK_MESSAGE": "Anzeige einer Meldung, wenn ein Auftrag mehr als X Paletten umfasst",
        "SHOW_TERMS_OF_CONDITIONS_ACCEPTED": "Das Feld, in dem die Leistungsbedingungen akzeptiert werden können, muss im Lieferavisformular angezeigt werden.",
        "SHOW_TRANSPORT_COMBINING_MODAL_ON_SINGLE_OPTION": "Zeigt ein Fenster zum Zusammenführen von Transporten an, auch mit nur einer möglichen Zusammenführungsoption",
        "SHOW_USERS_FOR_SUPPLIER": "Aktiviert eine Benutzerliste in den Lieferanten-/Empfängerdetails",
        "SORT_TABLE_BY_TRANSPORT_COMPLETED": "Legt fest, ob die Transporte in der Transporttabelle standardmäßig nach dem Status \"Abgeschlossen\" sortiert werden",
        "START_OF_DAY_IN_WAREHOUSE_INTERVAL": "Der Beginn des Arbeitstags in einem Lager, der für die Zählung der Lieferavis-Zeitfenster verwendet wird. Anmerkung! Der Beginn des Arbeitstags kann abweichen.",
        "SUPPLIER_FIELD_IN_COMPANY_DETAILS_TRANSLATION_KEY": "Übersetzungsschlüssel für das Feld \"Lieferant/Empfänger\". Mögliche Werte: 'CLIENT','COMPANY','COURIER'",
        "TERMS_OF_SERVICE_FILE_EXT": "Erweiterung der Datei mit den Leistungsbedingungen, z. B. DOCX, PDF",
        "TIME_TO_ACCEPT_AUTO_ROUTE_OFFER_PASS": "Zeit für die Auftragsannahme bei automatischer Übergabe von Aufträgen",
        "TIME_TO_ACCEPT_TRANSPORT_INTERVAL": "Zeit für die Auftragsannahme",
        "TIME_TO_RESTORE_ORDER_FROM_ARCHIVE": "Zeitspanne, innerhalb derer der Auftrag aus dem Archiv wiederhergestellt werden kann",
        "TIME_WINDOW_OFFSET_INTERVAL": "Anzahl der Minuten, um die Zeitfenster verschoben werden können. Mögliche Werte sind 15, 30 und 60.",
        "TRANSPORTS_LIST_LABEL": "Beschriftung der Transportliste",
        "TRANSPORTS_LIST_LABEL_EN": "Beschriftung der Transportliste (Englisch)",
        "TRANSPORT_DESCRIPTION_COLUMN_MAX_CHARS": "Maximale Anzahl von Zeichen, die in der Spalte \"Anmerkungen\" angezeigt werden",
        "TRANSPORT_FREE_SLOT_SEARCH_INTERVAL_IN_DAYS": "Zeitraum (in Tagen), in dem das Programm bei der Erstellung eines periodischen Lieferavis nach einem freien Zeitfenster suchen soll",
        "TRANSPORT_IDENTIFIER_TYPE": "Legt den Wert fest, bei dem der Benutzer mit Zugriff auf die REST-API Zugriff auf die Transporte hat. Die verfügbaren Optionen sind: ID, ERP_IDENTIFIER, ALT_IDENTIFIER.",
        "TRANSPORT_PAGINATION_DEFAULT_MAX_ROWS": "Legt die maximale Standardanzahl von Zeilen pro Seite für die Seitennummerierung der Transporttabelle fest",
        "TRANSPORT_TABLE_DAYS_LIMIT": "Zeitspanne (in Tagen) ab dem aktuellen Datum, ab der Aufträge in der Tabelle der aktiven Aufträge ausgeblendet werden",
        "USE_ADFS_AUTH_FOR_API_MESSAGE": "Aktiviert die Authentifizierung für den ADFS-Dienst (beim Senden von API-Nachrichten)",
        "USE_LEGACY_TRUCK_TYPES": "Fügt alte Lkw-Typen hinzu (für alle gleich)",
        "USE_WEBSOCKETS": "Aktiviert die Websocket-Unterstützung",
        "WAREHOUSE_LABEL": "Die Beschriftung des Formularfelds für die Angabe des Lagers",
        "WAREHOUSE_LABEL_EN": "Die Beschriftung des Formularfelds für die Angabe des Lagers (Englisch)",
        "WAREHOUSE_ZONE_LABEL": "Beschriftung des Formularfelds für die Lagerzone",
        "WAREHOUSE_ZONE_LABEL_EN": "Beschriftung des Formularfelds für die Lagerzone (Englisch)",
        "WEEK_SCHEDULE_START_DATE_SHIFT": "Anzahl der Tage, die in der Wochenansicht am Anfang eines Zeitplans hinzugefügt wurden",
        "WEIGHT_OF_PALLET": "Gibt das Gewicht einer Palette in Kilogramm an",
        "WEIGHT_SELECTOR": "Anzeige eines Elements mit einem Gewicht auf der Seite",
        "WEIGHT_URL": "Link zur Waagenanwendung",
        "WINDOW_CHANGE_MAIL_DELAY_INTERVAL": "Die Länge der Verzögerung, nach der eine E-Mail gesendet wird, wenn das Zeitfenster verschoben wird.",
        "WINDOW_CONSTANT_TIME": "Konstanter Wert in Minuten, der zur Dauer des Entladens in den Zeitfenstern addiert wird.",
        "XLS_ADDRESSES_ENABLED": "Die Spalte \"Adressen\" in den XLS-Bericht aufnehmen",
        "XLS_DESCRIPTION_ENABLED": "Spalte \"Anmerkungen\" in den XLS-Bericht aufnehmen",
        "XLS_EXTERNAL_ADDRESSES_DATA_ENABLED": "Spalte \"Externe Adressdaten\" in den XLS-Bericht aufnehmen",
        "XLS_TOP_ROW_ENABLED": "Kopfzeile für XLS-Berichte aktivieren."
      },
      "description": "Beschreibung",
      "edit": "Einstellung bearbeiten",
      "editable": "Bearbeitbar",
      "label": "Primäre Einstellungen",
      "name": "Bezeichnung",
      "other": "Sonstige Einstellungen",
      "restSettingLabel": "Sonstige Einstellungen",
      "roleGroups": {
        "actionIsPossible": "Aktion ist möglich",
        "actionType": "Berechtigung",
        "actionTypeDescription": {
          "ACCEPT_AND_REJECT_TRANSPORT": "Ermöglicht das Annehmen und Ablehnen der an den Auftragnehmer übergebenen Aufträge.",
          "ACCESS_CARRIER_COMPANY_FIELD": "Legt fest, ob das Feld \"Frachtführer\" im Transportformular angezeigt wird. Hierzu ist auch folgende Berechtigung erforderlich: \"Zugriff auf Transportart\"",
          "ACCESS_CUSTOM_FIELD": "Legt fest, ob ein benutzerdefiniertes Feld im Transportformular angezeigt wird",
          "ACCESS_DESCRIPTION_FIELD": "Legt fest, ob das Feld \"Anmerkung\" im Transportformular angezeigt wird. Hierzu ist auch folgende Berechtigung erforderlich: \"Zugriff auf Transportart\"",
          "ACCESS_INDEX": "Ein Benutzer mit dieser Rolle hat Zugriff auf den Indexabschnitt.",
          "ACCESS_PACKAGES": "Legt fest, ob der Abschnitts \"Pakete\" im Transportformular angezeigt wird. Hierzu ist auch folgende Berechtigung erforderlich: \"Zugriff auf Transportart\"",
          "ACCESS_SUPPLIER_COMPANY_FIELD": "Legt fest, ob das Feld \"Lieferant/Empfänger\" im Transportformular angezeigt wird. Hierzu ist auch folgende Berechtigung erforderlich: \"Zugriff auf Transportart\"",
          "ACCESS_TRANSPORT": "Aktiviert den Zugriff auf eine bestimmte Transportart (Anzeige der Transportart in der Auftragstabelle)",
          "ADD_ADVICE": "Ermöglicht das Hinzufügen von Lieferavisdaten.",
          "ADD_DOCUMENTS": "Ermöglicht das Anfügen von Dokumenten an den Auftrag. Der Zugriff auf die Spalte mit den Dokumenten in der Tabelle ist erforderlich.",
          "ADD_LOAD_STEP_FROM_LOAD": "Hinzufügen eines Beladeschritts nach einem Beladeschritt.  Außerdem muss die Einstellung für die Transportart aktiviert werden: \"Abschnitt 'Schritte' im Transportformular anzeigen\".",
          "ADD_LOAD_STEP_FROM_UNLOAD": "Hinzufügen eines Beladeschritts nach einem Entladeschritt. Außerdem muss die Einstellung für die Transportart aktiviert werden: \"Abschnitt 'Schritte' im Transportformular anzeigen\".",
          "ADD_NOTES": "Ermöglicht das Hinzufügen einer Anmerkung zu einem Transport in der Auftragstabelle. Der Zugriff auf die Spalte mit den Anmerkungen in der Tabelle ist erforderlich.",
          "ADD_PACKAGES": "Ermöglicht das Hinzufügen eines Pakets im Abschnitt \"Pakete\" im Transportformular. Hierzu ist auch folgende Berechtigung erforderlich: \"Abschnitt 'Pakete' anzeigen\"",
          "ADD_TIME_WINDOW": "Ermöglicht das Hinzufügen von Zeitfenstern.",
          "ADD_UNLOAD_STEP_FROM_LOAD": "Hinzufügen eines Entladeschritts nach einem Beladeschritt.  Außerdem muss die Einstellung für die Transportart aktiviert werden: \"Abschnitt 'Schritte' im Transportformular anzeigen\".",
          "ADD_UNLOAD_STEP_FROM_UNLOAD": "Hinzufügen eines Entladeschritts nach einem Entladeschritt. Außerdem muss die Einstellung für die Transportart aktiviert werden: \"Abschnitt 'Schritte' im Transportformular anzeigen\".",
          "ADVICE_ONLY_ON_DEDICATED_WINDOWS": "Hinzufügen von Zeitfenstern nur für fest zugewiesene Fenster",
          "BID_AUCTION": "Ermöglicht die Teilnahme an der Auktion und die Abgabe von Geboten.",
          "CREATE_AUCTION": "Benutzer mit dieser Berechtigung können eine Auktion erstellen.",
          "CREATE_TRANSPORT": "Ermöglicht das Erstellen eines neuen Transports. Hierzu ist auch folgende Berechtigung erforderlich: \"Zugriff auf Transportart\".",
          "DELETE_DOCUMENTS": "Ermöglicht dem Benutzer, an Transporte angefügte Dokumente zu löschen. Der Zugriff auf die Spalte mit den Dokumenten in der Tabelle ist erforderlich.",
          "DELETE_INDEX": "Ermöglicht das Löschen des hinzugefügten Index für den Transport.",
          "DELETE_LOAD_STEPS": "Ermöglicht das Löschen von Beladeetappen.  Außerdem muss die Einstellung für die Transportart aktiviert werden: \"Abschnitt 'Schritte' im Transportformular anzeigen\".",
          "DELETE_PACKAGES": "Ermöglicht das Löschen eines Pakets im Abschnitt \"Pakete\" des Transportformulars. Hierzu ist auch folgende Berechtigung erforderlich: \"Abschnitt 'Pakete' anzeigen\"",
          "DELETE_TRANSPORT": "Benutzer mit dieser Berechtigung können bereits erstellte Transporte löschen. Hierzu ist auch folgende Berechtigung erforderlich: \"Zugriff auf Transportart\".",
          "DELETE_UNLOAD_STEPS": "Ermöglicht das Löschen von Entladeetappen.  Außerdem muss die Einstellung für die Transportart aktiviert werden: \"Abschnitt 'Schritte' im Transportformular anzeigen\".",
          "DISABLE_DATA_FIELDS": "Legt fest, ob die Daten im Transportformular deaktiviert werden sollen",
          "EDIT_COURIER_INVOICE_SECTION": "Aktiviert die Option zum Bearbeiten des Abschnitts \"Warenrechnung\".",
          "EDIT_DESCRIPTION_FIELD": "Ermöglicht die Bearbeitung des Felds \"Anmerkungen\" in den Auftragsdetails. Hierzu ist auch folgende Berechtigung erforderlich: \"Zugriff auf Transportart\"",
          "EDIT_INDEX": "Ermöglicht das Bearbeiten von Indizes.",
          "EDIT_PACKAGES": "Ermöglicht die Bearbeitung des Pakets im Abschnitt \"Pakete\" des Transportformulars. Hierzu ist auch folgende Berechtigung erforderlich: \"Abschnitt 'Pakete' anzeigen\"",
          "EDIT_TRANSACTION_FREIGHT": "Aktiviert die Option zum Bearbeitung der Fracht im an den Frachtführer übergebenen Auftrag",
          "MOVE_ADVICE_WINDOW": "Ermöglicht das Verschieben von Lieferavis-Zeitfenstern.",
          "MOVE_LOAD_STEPS": "Aktiviert die Option zum Verschieben der Beladeetappen im Formular.  Außerdem muss die Einstellung für die Transportart aktiviert werden: \"Abschnitt 'Schritte' im Transportformular anzeigen\".",
          "MOVE_UNLOAD_STEPS": "Aktiviert die Option zum Verschieben der Entladeetappen im Formular. Außerdem muss die Einstellung für die Transportart aktiviert werden: \"Abschnitt 'Schritte' im Transportformular anzeigen\".",
          "PASS_COURIER": "Ermöglicht die Übergabe des Transports an den Kurier.",
          "PASS_TRANSPORT": "Ermöglicht die Übergabe des Transports an den Auftragnehmer.",
          "RESIZE_ADVICE_WINDOW": "Ermöglicht das Ändern der Länge von Lieferavis-Zeitfenstern. Hierzu ist auch folgende Berechtigung erforderlich: \"Lieferavis-Zeitfenster verschieben\".",
          "ROLE_ACCESS_AUCTION_DATA": "Ermöglicht die Sichtbarkeit der Auktionsdaten",
          "ROLE_ACCESS_COURIER_INVOICE_SECTION": "Legt fest, ob der Abschnitt \"Warenrechnung\" angezeigt wird",
          "ROLE_ACCESS_DELIVERY_DELIVERY_NUMBER": "Sichtbarkeit der Lieferungsnummer im Lieferungsformular.",
          "ROLE_ACCESS_DELIVERY_DESCRIPTION": "Sichtbarkeit der Lieferungsbeschreibung im Lieferungsformular.",
          "ROLE_ACCESS_DELIVERY_FREIGHT_COST": "Sichtbarkeit der Frachtkosten der Lieferung im Lieferformular.",
          "ROLE_ACCESS_DELIVERY_GROSS_WEIGHT": "Sichtbarkeit des Bruttogewichts der Lieferung im Lieferformular.",
          "ROLE_ACCESS_DELIVERY_INVOICE_NUMBER": "Sichtbarkeit der Rechnungsnummer im Lieferschein",
          "ROLE_ACCESS_DELIVERY_NET_WEIGHT": "Sichtbarkeit des Bruttogewichts der Lieferung im Lieferformular.",
          "ROLE_ACCESS_DELIVERY_PACKAGING_SECTION": "Zugang zum Verpackungsteil in Lieferungen",
          "ROLE_ACCESS_FREIGHT_DATA": "Ermöglicht die Sichtbarkeit der Frachtrate",
          "ROLE_ACCESS_TRANSPORT_ADDITIONAL_DESCRIPTION": "Sichtbarkeit des Feldes \"Zusätzliche Anmerkungen\"",
          "ROLE_ACCESS_WEIGHT_SECTION": "Legt fest, ob der Abschnitts \"Wiegen\" im Transportformular angezeigt wird. Hierzu ist auch folgende Berechtigung erforderlich: \"Zugriff auf Transportart\"",
          "ROLE_ADD_DELIVERY_PACKAGING": "Verpackung in Lieferung hinzufügen",
          "ROLE_AUTOMATIC_PASS_TRANSPORT_TO_ROUTE_OFFERS": "Ermöglicht die automatische Übergabe des Transports an nachfolgende Auftragnehmer.",
          "ROLE_CAN_ACCESS_ARCHIVED_TRUCK_DOCUMENTS": "Zugriff auf Archivierungsdokumente in Abschnitten zur Transportart",
          "ROLE_CAN_ACCESS_COURIERS_SECTION": "Legt den Zugriff auf den Abschnitt \"Kuriere\" im Transportformular fest",
          "ROLE_CAN_ACCESS_TASK": "Aktiviert die Anzeige von Aufgaben",
          "ROLE_CAN_ADD_HAS_TO_WAIT_FOR_RELATION": "Ermöglicht das Hinzufügen einer Verknüpfung zwischen Transporten",
          "ROLE_CAN_ADD_ROUTE": "Möglichkeit zum Hinzufügen einer neuen Route zur Preisliste",
          "ROLE_CAN_ADD_TRANSPORT_COMBINING_RELATION": "Ermöglicht die Verknüpfung einzelner Transporte. Kombinierte Transporte verfügen über eine gemeinsame Benachrichtigung, ein gemeinsames Fenster oder einen gemeinsamen Status.",
          "ROLE_CAN_AUTOMATICALLY_CONFIRM_PREVIOUS_CHECKPOINTS": "Durch die Bestätigung des angegebenen Status werden auch alle vorherigen unbestätigten Status automatisch bestätigt",
          "ROLE_CAN_BLOCK_TASK": "Aktiviert die Option, die Auftragsbestätigung zu sperren",
          "ROLE_CAN_CHOOSE_MORE_THAN_ONE_LOCATION": "Ermöglicht in den Auftragsdetails die Auswahl von mehr als einem Wert im Feld \"Standort\". Erfordert außerdem folgende Berechtigung: \"Zugriff auf Transportart\"",
          "ROLE_CAN_CONFIRM_TASK": "Aktiviert die Option zur Bestätigung von Aufgaben",
          "ROLE_CAN_CREATE_DELIVERY": "Ermöglicht die Erstellung von Versendungen in der Registerkarte \"Planung\"",
          "ROLE_CAN_CREATE_TRANSPORT_FROM_SCHEDULE": "Transport direkt aus dem Zeitfensterplan erstellen",
          "ROLE_CAN_DELETE_DELIVERY": "Ermöglicht die Löschung von Sendungen auf der Registerkarte \"Planung\"",
          "ROLE_CAN_DELETE_RELATION": "Ermöglicht das Aufheben der Verknüpfung von Transporten. Mit dieser Berechtigung lässt sich die Verknüpfung eines Transports aufheben.",
          "ROLE_CAN_DELETE_ROUTE": "Möglichkeit zum Löschen von Routen aus Preislisten",
          "ROLE_CAN_EDIT_COURIERS_SECTION": "Ermöglicht die Bearbeitung der Felder im Abschnitt \"Kuriere\" im Transportformular. Erfordert die Berechtigung \"Zugriff auf den Abschnitt 'Kuriere'\".",
          "ROLE_CAN_EDIT_DELIVERY": "Ermöglicht die Bearbeitung von Versendungen auf der Registerkarte \"Planung\"",
          "ROLE_CAN_EDIT_READY_TO_PLAN": "Ermöglicht das Bearbeiten des Kennzeichens \"Bereit zur Planung\" im Transportformular",
          "ROLE_CAN_EDIT_ROUTE": "Möglichkeit zum Bearbeiten von Routen in Preislisten",
          "ROLE_CAN_EDIT_TRANSPORT_PALLETS_WITHOUT_CHANGING_TIME_WINDOW_SIZE": "Möglichkeit, die Anzahl der transportierten Pakete trotz Platzmangels zu erhöhen, um das Zeitfenster entsprechend dem Algorithmus zu verlängern.",
          "ROLE_CAN_MULTI_ARCHIVE_TRANSPORTS": "Gruppenarchivierung von Transporten",
          "ROLE_CAN_SCAN_BARCODES": "Ermöglicht die Möglichkeit, Barcodes zu scannen, um Transporte zu durchsuchen",
          "ROLE_CAN_SEND_EMAIL_ADVICE": "Aktiviert die Option, eine Anfrage zur Bereitstellung von Lieferavisdaten per E-Mail-Lieferavis zu senden",
          "ROLE_CAN_SEND_EMAIL_ADVICE_CF": "Aktiviert die Option, eine Aufforderung zur Übermittlung von Auftragsdaten per E-Mail-Lieferavis zu senden",
          "ROLE_CAN_SKIP_CHECKPOINT_CONFIRMATION": "Ermöglicht das Überspringen einer Etappe ungeachtet der erforderlichen Reihenfolge der Bestätigung der einzelnen Etappen",
          "ROLE_CREATE_SIMILAR": "Ermöglicht das Erstellen eines ähnlichen Auftrags (Auftragskopie).",
          "ROLE_CREATE_SIMILAR_MULTIPLE": "Ähnlichen erstellen – Option zum Festlegen der Anzahl der Auftragskopien",
          "ROLE_CREATE_TRANSPORT_FROM_ROUTE": "Die Behörde ermöglicht es Ihnen, direkt von der Route zum Transportformular zu gelangen, wobei alle Daten der Route bereits ausgefüllt sind.",
          "ROLE_DELETE_DELIVERY_PACKAGING": "Verpackungen in der Lieferung löschen",
          "ROLE_DETACH_DELIVERY": "Ermöglicht das Abkoppeln von Lieferungen vom Transport",
          "ROLE_EDIT_DELIVERY_PACKAGING": "Verpackung in der Lieferung bearbeiten",
          "ROLE_EDIT_TRANSPORT_ADDITIONAL_DESCRIPTION": "Aktiviert die Möglichkeit, das Feld \"Zusätzliche Anmerkungen\" zu bearbeiten",
          "ROLE_EDIT_WAREHOUSE_LOCATION": "Ermöglicht das Bearbeiten des Felds \"Standort\" in den Auftragsdetails. Hierzu ist auch folgende Berechtigung erforderlich: \"Zugriff auf Transportart\"",
          "ROLE_EDIT_WEIGHT": "Gewicht im Abschnitt \"Wiegen\" bearbeiten",
          "SWAP_ADVICE_WINDOWS": "Ermöglicht das Austauschen von Lieferavis-Zeitfenstern.",
          "UPDATE_ADVICE": "Ermöglicht die Bearbeitung von Benachrichtigungsdaten.",
          "UPDATE_CARRIER_COMPANY_FIELD": "Ermöglicht das Bearbeiten des Felds \"Frachtführer\" in den Auftragsdetails. Hierzu ist auch folgende Berechtigung erforderlich: \"Zugriff auf Transportart\"",
          "UPDATE_CUSTOM_FIELD": "Ermöglicht die Bearbeitung eines benutzerdefinierten Felds im Transportformular",
          "UPDATE_MAIN_DATA_SECTION": "Ermöglicht das Bearbeiten von Transporten. Gilt für alle Felder mit Ausnahme der Lieferavisdaten, der benutzerdefinierten Felder und des Felds \"Anmerkungen\". Hierzu ist auch folgende Berechtigung erforderlich: \"Zugriff auf Transportart\".",
          "chooseActionType": "Berechtigungsstufe auswählen"
        },
        "addPermission": "Berechtigung hinzufügen",
        "authorityLabels": {
          "ACCEPT_AND_REJECT_TRANSPORT": "Übergebenen Transport annehmen/ablehnen",
          "ACCESS_CARRIER_COMPANY_FIELD": "Feld \"Frachtführer\" anzeigen",
          "ACCESS_CUSTOM_FIELD": "Benutzerdefiniertes Feld anzeigen",
          "ACCESS_DESCRIPTION_FIELD": "Feld \"Anmerkung\" anzeigen",
          "ACCESS_INDEX": "Zugriff auf den Abschnitt \"Indizes\"",
          "ACCESS_PACKAGES": "Abschnitt \"Paket\" anzeigen",
          "ACCESS_SUPPLIER_COMPANY_FIELD": "Feld \"Lieferant/Empfänger\" anzeigen",
          "ACCESS_TO_TRANSPORT_DETAILS": "Zugriff auf Transportdetails",
          "ACCESS_TRANSPORT": "Zugriff auf Transportart",
          "ADD_ADVICE": "Lieferavisdaten hinzufügen",
          "ADD_DOCUMENTS": "Dokumente hinzufügen",
          "ADD_LOAD_STEP_FROM_LOAD": "Hinzufügen eines Beladeschritts nach einem Beladeschritt.",
          "ADD_LOAD_STEP_FROM_UNLOAD": "Hinzufügen eines Beladeschritts nach einem Entladeschritt.",
          "ADD_NOTES": "Anmerkungen zur Tabelle hinzufügen",
          "ADD_PACKAGES": "Paket hinzufügen",
          "ADD_TIME_WINDOW": "Zeitfenster hinzufügen",
          "ADD_UNLOAD_STEP_FROM_LOAD": "Hinzufügen eines Entladeschritts nach einem Beladeschritt.",
          "ADD_UNLOAD_STEP_FROM_UNLOAD": "Hinzufügen eines Entladeschritts nach einem Entladeschritt.",
          "BID_AUCTION": "Bei einer Auktion bieten",
          "CAN_DELETE_APPLIES_FILES": "Dokumente aus Frachtführermeldung löschen",
          "CAN_DELETE_MOT_FILES": "Dokumente aus dem Verkehrsträger löschen",
          "CREATE_AUCTION": "Auktion erstellen",
          "CREATE_TRANSPORT": "Transport erstellen",
          "DELETE_DOCUMENTS": "Dokumente löschen",
          "DELETE_INDEX": "Index löschen",
          "DELETE_LOAD_STEPS": "Beladeschritt löschen",
          "DELETE_PACKAGES": "Paket entfernen",
          "DELETE_TRANSPORT": "Transport löschen",
          "DELETE_UNLOAD_STEPS": "Entladeschritt löschen",
          "DISABLE_DATA_FIELDS": "Daten im Transportformular ausblenden",
          "EDIT_COURIER_INVOICE_SECTION": "Aktiviert die Option zum Bearbeiten des Abschnitts \"Warenrechnung\".",
          "EDIT_DESCRIPTION_FIELD": "Ermöglicht die Bearbeitung des Felds \"Anmerkung\"",
          "EDIT_INDEX": "Index aktualisieren",
          "EDIT_PACKAGES": "Paket bearbeiten",
          "EDIT_TRANSACTION_FREIGHT": "Fracht bearbeiten",
          "MOVE_ADVICE_WINDOW": "Lieferavis-Zeitfenster verschieben",
          "MOVE_LOAD_STEPS": "Beladeschritte verschieben",
          "MOVE_UNLOAD_STEPS": "Entladeschritte verschieben",
          "PASS_COURIER": "Übergabe des Transports an den Kurier",
          "PASS_TRANSPORT": "Transport übergeben",
          "RESIZE_ADVICE_WINDOW": "Länge eines Lieferavis-Zeitfensters ändern",
          "ROLE_ACCESS_ALL_DEDICATED_WINDOWS": "Möglichkeit, ein Lieferavis in den Zeitfenstern eines beliebigen Unternehmens zu erstellen",
          "ROLE_ACCESS_ALL_QUEUES": "Zugriff auf alle Warteschlangen",
          "ROLE_ACCESS_ALL_RAMPS": "Zugriff auf alle Rampen/Zeitfenster im Zeitplan",
          "ROLE_ACCESS_ALL_USER_GROUP_DEDICATED_WINDOWS": "Möglichkeit, ein Lieferavis in den Zeitfenstern einer beliebigen Abteilung zu erstellen",
          "ROLE_ACCESS_API": "Zugriff auf die REST-API",
          "ROLE_ACCESS_AUCTION_DATA": "Sichtbarkeit von Auktionsdaten",
          "ROLE_ACCESS_COURIER_INVOICE_SECTION": "Legt fest, ob der Abschnitt \"Warenrechnung\" angezeigt wird",
          "ROLE_ACCESS_DELIVERY_DELIVERY_NUMBER": "Sichtbarkeit der Lieferungsnummer im Lieferungsformular.",
          "ROLE_ACCESS_DELIVERY_DESCRIPTION": "Sichtbarkeit der Lieferungsbeschreibung im Lieferungsformular.",
          "ROLE_ACCESS_DELIVERY_FREIGHT_COST": "Sichtbarkeit der Frachtkosten der Lieferung im Lieferformular.",
          "ROLE_ACCESS_DELIVERY_GROSS_WEIGHT": "Sichtbarkeit des Bruttogewichts der Lieferung im Lieferformular.",
          "ROLE_ACCESS_DELIVERY_INVOICE_NUMBER": "Sichtbarkeit der Rechnungsnummer im Lieferschein",
          "ROLE_ACCESS_DELIVERY_NET_WEIGHT": "Sichtbarkeit des Bruttogewichts der Lieferung im Lieferformular.",
          "ROLE_ACCESS_DELIVERY_PACKAGING_SECTION": "Zugang zum Verpackungsteil in Lieferungen",
          "ROLE_ACCESS_FREIGHT_DATA": "Sichtbarkeit der Frachtraten",
          "ROLE_ACCESS_PALLETS_SUMMARY": "Zugriff auf Palettenlimits",
          "ROLE_ACCESS_PRODUCT": "Zugriff auf Produktdetails",
          "ROLE_ACCESS_QUEUES_ACCESSIBILITY": "Zugriff auf Lagerflächen und Warteschlangen anzeigen",
          "ROLE_ACCESS_TASK_DATA": "Zugriff auf Aufgabendaten",
          "ROLE_ACCESS_TO_APPLY_HISTORY": "Zugriff auf den Meldungsverlauf des Frachtführers",
          "ROLE_ACCESS_TO_NUMBER_OF_WINDOWS": "Zeitfensterzähler in der Registerkarte \"Zeitplan\" anzeigen",
          "ROLE_ACCESS_TO_WAREHOUSE_TEMPLATES": "Zugriff auf Zeitplanvorlagen ",
          "ROLE_ACCESS_TRANSPORT_ADDITIONAL_DESCRIPTION": "Sichtbarkeit des Feldes \"Zusätzliche Anmerkungen\"",
          "ROLE_ACCESS_TRANSPORT_TYPE_DATA": "Zugriff auf Transportartdaten",
          "ROLE_ACCESS_TRUCKS": "Zugriff auf die Lkw-Liste",
          "ROLE_ACCESS_USERS_ACTIVITY": "Zugriff auf den Verlauf der Benutzeraktivität",
          "ROLE_ACCESS_WAREHOUSE_DATA": "Zugriff auf Lagerdaten",
          "ROLE_ACCESS_WEIGHT_SECTION": "Abschnitt \"Wiegen\" anzeigen",
          "ROLE_ACCESS_WORKFLOW_DATA": "Zugriff auf Workflow-Daten",
          "ROLE_ADD_CONTRACTOR_GROUP": "Gruppe für einen Auftragnehmer hinzufügen",
          "ROLE_ADD_DELIVERY_PACKAGING": "Verpackung in Lieferung hinzufügen",
          "ROLE_ADD_NEW_ADDRESS_IN_COMPANY_DETAILS": "Hinzufügen einer neuen Adresse im Abschnitt mit den Unternehmensdaten",
          "ROLE_ADD_WINDOW_NOTES": "Hinzufügen einer Anmerkung zu einem ausgegrauten (geblockten) Zeitfenster",
          "ROLE_ADVICE_EXCEL": "Aufträge als Excel-Datei herunterladen",
          "ROLE_ALLOW_CONCURRENT_SESSIONS": "Ermöglicht einem Benutzer parallele Sitzungen",
          "ROLE_ANNOUNCEMENTS_TAB": "Zugriff auf die Registerkarte \"Ankündigungen\"",
          "ROLE_API_MESSAGE_RESENDING": "API-Nachricht erneut senden",
          "ROLE_APPROVE_MOT": "Verkehrsträger genehmigen",
          "ROLE_ARCHIVE_TAB": "Zugriff auf die Registerkarte \"Archiv\"",
          "ROLE_AUTOMATIC_PASS_TRANSPORT_TO_ROUTE_OFFERS": "Automatische Übergabe von Transporten",
          "ROLE_BLOCK_RAMPS": "Blockierung in einem Zeitplan erstellen",
          "ROLE_CAN_ACCESS_COMPANY_TRANSPORT_LIMIT": "Felder mit dem Transportlimit für das Unternehmen anzeigen",
          "ROLE_CAN_ACCESS_COURIERS_SECTION": "Abschnitt \"Kuriere\" aufrufen",
          "ROLE_CAN_ACCESS_TASK": "Aufgabe anzeigen",
          "ROLE_CAN_ACCESS_TRANSPORT_SUPERVISOR": "Sichtbarkeit des Feldes \"Ansprechpartner\" im Transportformular",
          "ROLE_CAN_ADD_CONFIRMATION_DATE_TASK": "Aufgabendatum bestätigen",
          "ROLE_CAN_ADD_HAS_TO_WAIT_FOR_RELATION": "Verknüpfung zwischen Transporten hinzufügen",
          "ROLE_CAN_ADD_INVOICE_IN_TRANSACTION": "Rechnungsdaten hinzufügen",
          "ROLE_CAN_ADD_MOT": "Verkehrsträger hinzufügen",
          "ROLE_CAN_ADD_ROUTE": "Route zur Preisliste hinzufügen",
          "ROLE_CAN_ADD_TRANSPORT_COMBINING_RELATION": "Transporte verknüpfen",
          "ROLE_CAN_AUTOMATICALLY_CONFIRM_PREVIOUS_CHECKPOINTS": "Alle vorherigen Schritte automatisch bestätigen",
          "ROLE_CAN_BLOCK_TASK": "Aufgabenbestätigung blocken",
          "ROLE_CAN_CHOOSE_MORE_THAN_ONE_LOCATION": "Mehr als einen Wert im Feld \"Standort\" auswählen",
          "ROLE_CAN_CONFIRM_ROUTE_OFFER": "Angebot in der Preisliste genehmigen",
          "ROLE_CAN_CONFIRM_TASK": "Aufgabe bestätigen",
          "ROLE_CAN_CREATE_DELIVERY": "Erstellen von Lieferungen in der Registerkarte \"Planung\"",
          "ROLE_CAN_CREATE_MAX_PALLET": "Möglichkeit, ein Palettenlimit festzulegen",
          "ROLE_CAN_CREATE_ROUTE_OFFER": "Angebot in der Preisliste hinzufügen",
          "ROLE_CAN_CREATE_TRANSPORT_FROM_SCHEDULE": "Transport direkt aus dem Zeitfensterplan erstellen",
          "ROLE_CAN_DELETE_DELIVERY": "Lieferungen löschen",
          "ROLE_CAN_DELETE_RELATION": "Verknüpfung zwischen Transporten löschen",
          "ROLE_CAN_DELETE_ROUTE": "Routen aus Preislisten löschen",
          "ROLE_CAN_DELETE_ROUTE_OFFER": "Angebot aus der Preisliste entfernen",
          "ROLE_CAN_DOWNLOAD_ENTRY_PASS": "Abrufen einer Eintrittskarte",
          "ROLE_CAN_EDIT_ADDRESSES": "Adressen bearbeiten",
          "ROLE_CAN_EDIT_COMPANY_TRANSPORT_LIMIT": "Feld mit dem Transportlimit für das Unternehmen bearbeiten",
          "ROLE_CAN_EDIT_COURIERS_SECTION": "Felder im Abschnitt \"Kuriere\" bearbeiten",
          "ROLE_CAN_EDIT_DELIVERY": "Lieferungen bearbeiten",
          "ROLE_CAN_EDIT_INVOICE_IN_TRANSACTION": "Rechnungsdaten bearbeiten",
          "ROLE_CAN_EDIT_READY_TO_PLAN": "Kennzeichen \"Bereit zur Planung\" im Transportformular bearbeiten",
          "ROLE_CAN_EDIT_ROUTE": "Routen in Preislisten bearbeiten",
          "ROLE_CAN_EDIT_ROUTE_OFFER": "Angebot in der Preisliste bearbeiten",
          "ROLE_CAN_EDIT_TRANSPORT_PALLETS_WITHOUT_CHANGING_TIME_WINDOW_SIZE": "Dadurch können Sie die Anzahl der transportierten Pakete erhöhen, obwohl laut Algorithmus kein Platz für eine Verlängerung des Zeitfensters vorhanden ist.",
          "ROLE_CAN_EDIT_TRANSPORT_SUPERVISOR": "Möglichkeit zur Bearbeitung des Feldes \"Ansprechpartner\" im Transportformular",
          "ROLE_CAN_EXCEED_MAX_PALLET": "Lieferavise versenden, die über das Palettenlimit hinausgehen",
          "ROLE_CAN_EXCEED_MAX_PALLET_ON_DEDICATED_WINDOWS": "Lieferavis mit Überschreitung des Palettenlimits in fest zugewiesenen Fenstern",
          "ROLE_CAN_GENERATE_FULL_SETTLEMENT_REPORT": "Abrechnungsbericht als Excel-Datei herunterladen",
          "ROLE_CAN_GENERATE_LIST_OF_POINTS_REPORT": "Liste der Punkte als Excel-Datei herunterladen.",
          "ROLE_CAN_GENERATE_SHORT_SETTLEMENT_REPORT": "Lieferavisbericht generieren",
          "ROLE_CAN_MOVE_WINDOW_OUT_OF_ASSORTMENT_GROUP": "Ermöglicht das Verschieben eines Zeitfensters außerhalb einer Sortimentsgruppe",
          "ROLE_CAN_MULTI_ARCHIVE_TRANSPORTS": "Gruppenarchivierung von Transporten",
          "ROLE_CAN_PLAN_ADVICE_IN_THE_PAST": "Lieferavis in der Vergangenheit festlegen",
          "ROLE_CAN_REJECT_TASK": "Aufgabe ablehnen",
          "ROLE_CAN_REVOKE_TASK": "Aufgabe zurückziehen",
          "ROLE_CAN_SCAN_BARCODES": "Scannen von Barcodes",
          "ROLE_CAN_SEE_CURRENT_PLACE_IN_AUCTION": "Aktuellen Platz bei einer laufenden Auktion anzeigen",
          "ROLE_CAN_SEND_EMAIL_ADVICE": "E-Mail-Lieferavis – Anfrage bezüglich Lieferavisdaten senden",
          "ROLE_CAN_SEND_EMAIL_ADVICE_CF": "E-Mail-Lieferavis – Anfrage bezüglich Vervollständigung von Daten senden",
          "ROLE_CAN_SKIP_CHECKPOINT_CONFIRMATION": "Bestätigung der Etappe überspringen",
          "ROLE_CAN_SYNCHRONISE_CARRIERS_DATA": "Synchronisierung der Trägerdaten",
          "ROLE_CAN_UPDATE_VEHICLE_MAIN_DATA_SECTION": "Primärdaten des Transportmittels bearbeiten",
          "ROLE_CARRIERS_TAB": "Zugriff auf die Registerkarte \"Frachtführer\"",
          "ROLE_CHANGE_WAREHOUSE": "Lager in einem Zeitplan auswählen",
          "ROLE_CLASSIFICATION_TAB": "Zugriff auf die Registerkarte \"Klassifizierung\"",
          "ROLE_COMPANIES_TAB": "Zugriff auf die Registerkarte \"Unternehmen\"",
          "ROLE_CREATE_ASSORTMENT_GROUP": "Sortimentsgruppe erstellen",
          "ROLE_CREATE_BLOCKED_WINDOWS": "Geblockte Zeitfenster erstellen",
          "ROLE_CREATE_COMPANY": "Neues Unternehmen erstellen",
          "ROLE_CREATE_CUSTOM_FIELD_DEFINITIONS": "Zugriff auf die Einstellungen für benutzerdefinierte Felder",
          "ROLE_CREATE_DISCREPANCY_PROTOCOL": "Abweichungsbericht generieren",
          "ROLE_CREATE_DRIVER": "Neuen Fahrer erstellen",
          "ROLE_CREATE_GUARD_ROLE_GROUP": "Erstellen einer Guardian-Rolle",
          "ROLE_CREATE_PLACE": "Orte erstellen",
          "ROLE_CREATE_PRODUCT": "Produkt erstellen",
          "ROLE_CREATE_QUEUE": "Warteschlangen erstellen",
          "ROLE_CREATE_RAMP": "Neue Rampe erstellen",
          "ROLE_CREATE_ROLE_GROUP": "Möglichkeit, eine neue Rollengruppe zu erstellen",
          "ROLE_CREATE_SIMILAR": "Ähnlichen Transport erstellen",
          "ROLE_CREATE_SIMILAR_MULTIPLE": "Ähnlichen erstellen – Option zum Festlegen der Anzahl der Auftragskopien",
          "ROLE_CREATE_SQUARE": "Lagerflächen erstellen",
          "ROLE_CREATE_TRANSPORT_FROM_ROUTE": "Transport aus der Route erstellen",
          "ROLE_CREATE_TRUCK": "Neuen Lkw erstellen",
          "ROLE_CREATE_USED_FOR_DELIVERIRES_ROLE_GROUP": "Erstellung einer Versandplanungsrolle",
          "ROLE_CREATE_USER": "Neuen Benutzer erstellen",
          "ROLE_CREATE_WAREHOUSE": "Neues Lager erstellen",
          "ROLE_CREATE_WAREHOUSE_ZONE": "Lagerzone erstellen",
          "ROLE_DELETE_COMPANY": "Unternehmen deaktivieren",
          "ROLE_DELETE_DELIVERY_PACKAGING": "Verpackungen in der Lieferung löschen",
          "ROLE_DELETE_DRIVER": "Fahrer löschen",
          "ROLE_DELETE_INVITATION": "Aktive Einladung löschen",
          "ROLE_DELETE_MULTIPLE_PERIODIC_TRANSPORTS": "Mehrere periodische Lieferavise entfernen",
          "ROLE_DELETE_PLACE": "Ort entfernen",
          "ROLE_DELETE_PRODUCT": "Produkt löschen",
          "ROLE_DELETE_QUEUE": "Warteschlange entfernen",
          "ROLE_DELETE_RAMP": "Rampe löschen",
          "ROLE_DELETE_SQUARE": "Lagerfläche entfernen",
          "ROLE_DELETE_TRUCK": "Fahrzeug löschen",
          "ROLE_DELETE_USER": "Benutzer deaktivieren",
          "ROLE_DELETE_WAREHOUSE": "Lager löschen",
          "ROLE_DETACH_DELIVERY": "Lieferung vom Transport abkoppeln",
          "ROLE_DEV_TOOLS": "Zusätzliche Entwicklertools",
          "ROLE_EDIT_ALL_USERS": "Alle Benutzer bearbeiten",
          "ROLE_EDIT_ASSORTMENT_GROUP": "Sortimentsgruppe bearbeiten",
          "ROLE_EDIT_COMPANY": "Ausgewählte Felder eines Unternehmens bearbeiten",
          "ROLE_EDIT_CONTRACTOR_GROUP": "Kontrahentengruppe bearbeiten",
          "ROLE_EDIT_DELIVERY_PACKAGING": "Verpackung in der Lieferung bearbeiten",
          "ROLE_EDIT_GUS_DATA": "Vom Statistischen Hauptamt (GUS) heruntergeladene Daten bearbeiten",
          "ROLE_EDIT_INCOMPLETE_LOADING": "Kontrollkästchen \"Beladen unvollständig\" verwalten.",
          "ROLE_EDIT_MULTIPLE_PERIODIC_TRANSPORTS": "Mehrere periodische Lieferavise bearbeiten",
          "ROLE_EDIT_MY_NOTIFICATION": "Einstellungen für eigene Benachrichtigungen bearbeiten",
          "ROLE_EDIT_MY_OWN_COMPANY": "Möglichkeit zur Bearbeitung eigener Unternehmensdaten",
          "ROLE_EDIT_MY_QUEUES": "Zugriff auf eigene Warteschlangen und Lagerflächen bearbeiten",
          "ROLE_EDIT_MY_RAMP": "Zugriff auf eigene Rampen bearbeiten",
          "ROLE_EDIT_PLACE": "Orte bearbeiten",
          "ROLE_EDIT_PRODUCT": "Produkt aktualisieren",
          "ROLE_EDIT_QUEUE": "Warteschlangen bearbeiten",
          "ROLE_EDIT_RAMP": "Rampe bearbeiten",
          "ROLE_EDIT_ROLE_GROUP": "Benutzerrolle ändern",
          "ROLE_EDIT_SQUARE": "Lagerflächen bearbeiten",
          "ROLE_EDIT_TRANSPORT_ADDITIONAL_DESCRIPTION": "Aktiviert die Möglichkeit, das Feld \"Zusätzliche Anmerkungen\" zu bearbeiten",
          "ROLE_EDIT_USERS_MAIN_DATA": "Benutzerstammdaten bearbeiten",
          "ROLE_EDIT_USERS_NOTIFICATION": "Benachrichtigungen bearbeiten",
          "ROLE_EDIT_USERS_RAMP": "Benutzerzugriff auf Rampen bearbeiten",
          "ROLE_EDIT_WAREHOUSE": "Lager bearbeiten",
          "ROLE_EDIT_WAREHOUSE_LOCATION": "Feld \"Lagerstandort\" bearbeiten",
          "ROLE_EDIT_WAREHOUSE_ZONE": "Lagerzone bearbeiten",
          "ROLE_EDIT_WEIGHT": "Gewicht im Abschnitt \"Wiegen\" bearbeiten",
          "ROLE_EDIT_WORKFLOW": "Statuseinstellungen bearbeiten",
          "ROLE_FILTER_ACTIVE_TRANSPORTS_TABLE": "Spaltenfilter in der Tabelle der aktiven Aufträge anzeigen",
          "ROLE_FULL_EDIT_COMPANY": "Alle Felder eines Unternehmens bearbeiten",
          "ROLE_INVITATIONS_TAB": "Zugriff auf die Registerkarte \"Einladungen\"",
          "ROLE_INVITE_AS_TEMPORARY_USER_WHEN_ACCOUNT_LIMIT_EXCEEDED": "Temporäre Benutzer einladen, durch die das Unternehmenslimit überschritten wird",
          "ROLE_INVOICE_COLUMN": "Rechnungsspalte anzeigen",
          "ROLE_MANAGE_ADMIN_ANNOUNCEMENTS": "Verwaltungsmeldungen verwalten",
          "ROLE_MANAGE_ADVICE_VERIFICATION_MAIL": "E-Mail-Benachrichtigung bezüglich der Datenverifizierung verwalten",
          "ROLE_MANAGE_ALLOWED_DOMAINS": "Zugriff auf die Verwaltung der zulässigen E-Mail-Domains für das Unternehmen",
          "ROLE_MANAGE_ANNOUNCEMENTS": "Möglichkeit, Ankündigungen zu verwalten",
          "ROLE_MANAGE_APPLIES_SETTINGS": "Zugriff auf die Verwaltung von Benachrichtigungseinstellungen",
          "ROLE_MANAGE_APPLY_SECTIONS": "Frachtführermeldungen verwalten",
          "ROLE_MANAGE_AUCTION_SETTINGS": "Zugriff auf die Verwaltung von Auktionseinstellungen",
          "ROLE_MANAGE_AUTHORITIES": "Möglichkeit, Berechtigungen für eine Rolle zu verwalten",
          "ROLE_MANAGE_CMR_SETTINGS": "Einstellungen für CMR-Dateien verwalten",
          "ROLE_MANAGE_COLUMN_VISIBILITY": "Anzeige von Spalten festlegen",
          "ROLE_MANAGE_COMPANY_PRICING_PLAN": "Abonnementoption für das Unternehmen bearbeiten",
          "ROLE_MANAGE_COMPANY_RAMP": "Zugriff auf die Änderung von Rampen für das Unternehmen",
          "ROLE_MANAGE_DEDICATED_WINDOWS": "Fest zugewiesene Zeitfenster verwalten",
          "ROLE_MANAGE_DELIVERY_SETTINGS": "Verwaltung der Lieferbedingungen",
          "ROLE_MANAGE_LOCAL_ADMINS": "Lokale Administratoren von Auftragnehmerunternehmen bearbeiten",
          "ROLE_MANAGE_MAX_COMPANY_ACCOUNT_NUMBER": "Maximale Anzahl von Konten für das Unternehmen bearbeiten",
          "ROLE_MANAGE_MOT_SECTIONS": "Verkehrsträger verwalten",
          "ROLE_MANAGE_NOTIFICATIONS": "Benachrichtigungen verwalten",
          "ROLE_MANAGE_PACKAGE_TYPE_TRANSPORT_TYPE": "Paketeinstellungen verwalten",
          "ROLE_MANAGE_PARTNERSHIP": "Partnerschaftsverbindungen verwalten",
          "ROLE_MANAGE_PRINCIPAL_DEDICATED_WINDOWS": "Dem Kundenunternehmen fest zugewiesene Fenster erstellen",
          "ROLE_MANAGE_QUEUES_ACCESSIBILITY": "Zugriff auf Lagerflächen und Warteschlangen verwalten",
          "ROLE_MANAGE_SETTINGS": "Einstellungen verwalten",
          "ROLE_MANAGE_TRANSPORT_TYPE": "Einstellungen für die Transportart verwalten",
          "ROLE_MANAGE_TRUCK_TYPES": "Verwaltung von Lkw-Typen",
          "ROLE_MANAGE_USER_GROUPS": "Gruppen innerhalb des Auftraggeberunternehmens verwalten",
          "ROLE_MANAGE_USER_ROLE_INVITATIONS": "Einladungen für Benutzerrollen bearbeiten",
          "ROLE_MODE_OF_TRANSPORTATION_TAB": "Zugriff auf die Registerkarte \"Transportart\"",
          "ROLE_MOVE_BLOCKED_WINDOWS": "Geblockte Zeitfenster verschieben",
          "ROLE_MOVE_DEDICATED_WINDOWS": "Fest zugewiesene Zeitfenster verschieben",
          "ROLE_MOVE_WINDOWS_BETWEEN_WAREHOUSE_ZONES": "Zeitfenster zwischen Lagerzonen verschieben",
          "ROLE_OWN_COLUMNS_EXCEL": "Bericht anhand von Tabellenspalten erstellen",
          "ROLE_OWN_COMPANY_TAB": "Zugriff auf die Registerkarte \"Unternehmen\" im Konto des Auftragnehmers",
          "ROLE_PERFORM_ACTION_IN_WAREHOUSE": "Aktionen in einem Lager durchführen",
          "ROLE_PLANNING_TAB": "Zugang zur Registerkarte Planung",
          "ROLE_PRICE_LIST_TAB": "Zugriff auf die Registerkarte \"Preislisten\"",
          "ROLE_PRODUCTS_TAB": "Zugriff auf Produktliste",
          "ROLE_QUEUES_TAB": "Zugriff auf die Registerkarte \"Warteschlangen\"",
          "ROLE_QUEUE_STATUS_TAB": "Zugriff auf die Registerkarte \"Warteschlangenstatus\"",
          "ROLE_READ_WINDOW_NOTES": "Anmerkungen zu ausgegrauten (geblockten) Zeitfenstern anzeigen",
          "ROLE_REJECT_CHECKPOINT": "Kontrollpunkt ablehnen",
          "ROLE_RESIZE_BLOCKED_WINDOWS": "Größe geblockter Zeitfenster ändern",
          "ROLE_RESIZE_DEDICATED_WINDOWS": "Größe fest zugewiesener Zeitfenster ändern",
          "ROLE_RESTORE_ORDER_FROM_ARCHIVE": "Möglichkeit, Aufträge aus dem Archiv wiederherzustellen",
          "ROLE_REVOKE_CHECKPOINT": "Kontrollpunkt entfernen",
          "ROLE_SEND_SMS_TO_ALL_DRIVERS_IN_QUEUE": "Möglichkeit, SMS an alle Fahrer in einer bestimmten Warteschlange zu senden",
          "ROLE_SETTINGS_TAB": "Zugriff auf die Registerkarte \"Einstellungen\"",
          "ROLE_SHOW_API_INTEGRATION_LOGS": "Zugriff auf den Abschnitt \"Integration\"",
          "ROLE_SHOW_AWAITING_INVITATIONS_TABLE": "Liste der zu erwartenden Einladungen anzeigen",
          "ROLE_SHOW_CUSTOM_FIELD_DEFINITIONS": "Benutzerdefinierte Felder verwalten",
          "ROLE_SHOW_PARTNERSHIP_TABLE": "Liste der Partnerschaften anzeigen",
          "ROLE_SHOW_TRANSPORT_LOGS": "Zugriff auf den Auftragsverlauf",
          "ROLE_SORT_TRANSPORT_TABLE": "Transporttabelle sortieren",
          "ROLE_STATISTICS_REPORT_TAB": "Zugriff auf die Registerkarte \"Statistik\".",
          "ROLE_SUPER_USER": "Besondere Benutzerberechtigungen",
          "ROLE_TRANSPORT_TABLE": "Zugriff auf die Transporttabelle",
          "ROLE_USERS_TAB": "Zugriff auf die Registerkarte \"Benutzer\"",
          "ROLE_WAREHOUSE_TAB": "Zugriff auf die Registerkarte \"Zeitplan\"",
          "SWAP_ADVICE_WINDOWS": "Lieferavis-Zeitfenster tauschen",
          "UNLIMITED_ACCESS_TO_ARCHIVED_TRANSPORTS": "Uneingeschränkter Zugriff auf archivierte Aufträge",
          "UPDATE_ADVICE": "Lieferavis aktualisieren",
          "UPDATE_CARRIER_COMPANY_FIELD": "Feld \"Frachtführer\" aktualisieren",
          "UPDATE_CUSTOM_FIELD": "Benutzerdefiniertes Feld aktualisieren",
          "UPDATE_MAIN_DATA_SECTION": "Haupttransportfelder aktualisieren"
        },
        "authorityRangeLabel": "Zuständigkeitsbereich",
        "authorityRangeLabels": {
          "ACTIVE": "Wenn der Auftrag noch nicht archiviert wurde.",
          "ACTIVE_AUCTION_BASED": "Abhängig von der zum Auftrag gehörigen aktiven Auktion.",
          "ADVICE_RESTRICTION_BASED": "Während eines bestimmten Zeitraums für einen Lieferavis.",
          "AFTER_CHECKPOINT_BASED": "Nachdem der Kontrollpunkt bestätigt wurde.",
          "BEFORE_CHECKPOINT_BASED": "Bis der Kontrollpunkt bestätigt wurde.",
          "BLOCKED": "Aktion geblockt.",
          "NO_ROUTE_OFFER": "Abhängig von der Annahme des Transports durch den Frachtführer zum Tarif in der Preisliste.",
          "OWN_COMPANY_BASED": "Abhängig vom Unternehmen des Auftragserstellers.",
          "TIME_BASED": "Zeitabhängig",
          "UNLIMITED": "Ohne zusätzliche Bedingungen"
        },
        "authorityRangePhrases": {
          "ACTIVE": "bis der Auftrag archiviert wurde.",
          "ACTIVE_AUCTION_BASED": "wenn der Transport keine aktive Auktion aufweist.",
          "ADVICE_RESTRICTION_BASED": "während eines bestimmten Zeitraums für ein Lieferavis.",
          "AFTER_CHECKPOINT_BASED": "nachdem der Kontrollpunkt {checkpointName} bestätigt wurde.",
          "BEFORE_CHECKPOINT_BASED": "bis der Kontrollpunkt {checkpointName} bestätigt wurde.",
          "BLOCKED": "Aktion geblockt.",
          "CHECKPOINT_BASED": "basierend auf dem Kontrollpunkt {checkpointName}.",
          "NO_ROUTE_OFFER": "wenn keine bevorzugten Angebote vorliegen oder wenn Frachtführer mit gültigen Angeboten in der Preisliste den Transport nicht zur Durchführung angenommen haben.",
          "OWN_COMPANY_BASED": "innerhalb des Erstellerunternehmens.",
          "TIME_BASED": "nicht später als {timeInterval} {afterOrBefore} {timeReference}.",
          "TIME_BASED_CURRENT_DATE_AFTER": "{timeInterval} nach dem aktuellen Datum.",
          "TIME_BASED_CURRENT_DATE_BEFORE": "bis zu {timeInterval} ab dem aktuellen Datum.",
          "UNLIMITED": "ohne zusätzliche Bedingungen."
        },
        "checkpoint": "Status",
        "conditions": "Bedingungen",
        "create": {
          "copyFrom": "Berechtigungen aus einer anderen Rolle kopieren:",
          "info": "Berechtigungen können nach dem Speichern der Rolle geändert werden",
          "isGuard": "Die Rolle des Schutzes",
          "isGuardInfo": "Rolle mit vereinfachter Statusbestätigung",
          "label": "Rolle hinzufügen",
          "nameEn": "Bezeichnung (EN)",
          "namePl": "Bezeichnung",
          "noPartnershipInfo": "Ohne Partnerschaft wird eine Auftraggeberrolle erstellt.",
          "partnershipType": "Art der Partnerschaft:",
          "usedForDeliveries": "Die Rolle der Versandplanung",
          "usedForDeliveriesInfo": "Eine Rolle zur Planung von Sendungen auf der Registerkarte Planung",
          "usedForMarcos": "Rolle bei der Integration von Marcos",
          "usedForMarcosInfo": "Rolle bei der Integration von Marcos"
        },
        "customFieldDefinition": "Benutzerdefiniertes Feld",
        "dateAfterPhrase": "nach dem Datum mit der Angabe",
        "dateBeforePhrase": "vor dem Datum mit der Angabe",
        "deleteAllAuthoritiesTitle": "Schaltfläche zum Entfernen aller Berechtigungen von {title}",
        "generalAuthorityDescriptions": {
          "ACCESS_TO_TRANSPORT_DETAILS": "Ermöglicht den Zugriff auf die Auftragsdetails",
          "ADVICE_ONLY_ON_DEDICATED_WINDOWS": "Die Berechtigung verhindert, dass Benutzer verfügbare fest zugewiesene Zeitfenster durch einfache Zeitfenster ersetzen.",
          "CAN_DELETE_APPLIES_FILES": "Möglichkeit, der Bewerbung des Frachtführers beigefügte Dokumente zu löschen.",
          "CAN_DELETE_MOT_FILES": "Ermöglicht das Löschen von Dokumenten, die einem Verkehrsträger beigefügt sind.",
          "ROLE_ACCESS_ALL_DEDICATED_WINDOWS": "Ermöglicht das Erstellen eines Lieferavis in fest zugewiesenen Zeitfenstern eines Unternehmens",
          "ROLE_ACCESS_ALL_QUEUES": "Zugriff auf alle Warteschlangen.",
          "ROLE_ACCESS_ALL_RAMPS": "Zugriff auf alle Rampen/Zeitfenster im Zeitplan.",
          "ROLE_ACCESS_ALL_USER_GROUP_DEDICATED_WINDOWS": "Ermöglicht das Erstellen eines Lieferavis in fest zugewiesenen Zeitfenstern einer Abteilung",
          "ROLE_ACCESS_API": "Ermöglicht den Zugriff auf REST-API-Methoden für Benutzer",
          "ROLE_ACCESS_PALLETS_SUMMARY": "Zugriff auf Palettenlimits",
          "ROLE_ACCESS_PRODUCT": "Ermöglicht den Zugriff auf die Produktdetails.",
          "ROLE_ACCESS_QUEUES_ACCESSIBILITY": "Die Berechtigung ermöglicht das Festlegen der Anzeige des Zugriffs auf Lagerflächen und Warteschlangen. Wenn diese Berechtigung aktiviert ist, wird das Element in den Angaben zum Benutzer angezeigt.",
          "ROLE_ACCESS_TASK_DATA": "Zugriff auf Aufgabendaten",
          "ROLE_ACCESS_TO_APPLY_HISTORY": "Zugriff auf den Bewerbungsverlauf des Frachtführers.",
          "ROLE_ACCESS_TO_NUMBER_OF_WINDOWS": "Ermöglicht die Anzeige eines Zeitfensterzählers im Zeitplan",
          "ROLE_ACCESS_TO_WAREHOUSE_TEMPLATES": "Ermöglicht das Verwalten von Zeitfenstervorlagen im Zeitplan.",
          "ROLE_ACCESS_TRANSPORT_TYPE_DATA": "Zugriff auf Transportartdaten",
          "ROLE_ACCESS_TRUCKS": "Zugriff auf die Lkw-Liste",
          "ROLE_ACCESS_USERS_ACTIVITY": "Ermöglicht den Zugriff auf den Verlauf der Benutzeraktivitäten",
          "ROLE_ACCESS_WAREHOUSE_DATA": "Zugriff auf Lagerdaten",
          "ROLE_ACCESS_WORKFLOW_DATA": "Zugriff auf Workflow-Daten",
          "ROLE_ADD_CONTRACTOR_GROUP": "Möglichkeit, eine neue Gruppe für einen Auftragnehmer hinzuzufügen",
          "ROLE_ADD_NEW_ADDRESS_IN_COMPANY_DETAILS": "Ermöglicht dem Benutzer das Hinzufügen einer neuen Adresse im Abschnitt mit den Unternehmensdaten",
          "ROLE_ADD_WINDOW_NOTES": "Ermöglicht das Hinzufügen von Anmerkungen zu ausgegrauten Zeitfenstern.",
          "ROLE_ADVICE_EXCEL": "Ermöglicht das Exportieren von Auftragsdaten in eine Excel-Datei.",
          "ROLE_ALLOW_CONCURRENT_SESSIONS": "Ermöglicht parallele Sitzungen eines Benutzers über mehrere Browser",
          "ROLE_ANNOUNCEMENTS_TAB": "Ermöglicht den Zugriff auf die Registerkarte \"Ankündigungen\"",
          "ROLE_API_MESSAGE_RESENDING": "Möglichkeit, eine API-Nachricht erneut zu senden",
          "ROLE_APPROVE_MOT": "Ermöglicht das Genehmigen eines Verkehrsträgers.",
          "ROLE_ARCHIVE_TAB": "Ermöglicht den Zugriff auf die Registerkarte \"Archiv\".",
          "ROLE_BLOCK_RAMPS": "Möglichkeit, ein Zeitfenster im Zeitplan zu blocken und nach dem Klicken auf die Kopfzeile für die Rampe die gesamte Rampe zu blocken.",
          "ROLE_CAN_ACCESS_ARCHIVED_TRUCK_DOCUMENTS": "Ermöglicht den Zugriff auf archivierte Dokumente in den Angaben zum Verkehrsträger auf der Registerkarte \"Verkehrsträger\".",
          "ROLE_CAN_ACCESS_COMPANY_TRANSPORT_LIMIT": "Ermöglicht das Anzeigen von Feldern in Zusammenhang mit dem Transportlimit für das Unternehmen",
          "ROLE_CAN_ACCESS_COURIERS_SECTION": "Legt den Zugriff auf den Abschnitt \"Kuriere\" des Transportformulars fest",
          "ROLE_CAN_ACCESS_TRANSPORT_SUPERVISOR": "Sichtbarkeit des Feldes \"Ansprechpartner\"",
          "ROLE_CAN_ADD_HAS_TO_WAIT_FOR_RELATION": "Ermöglicht das Hinzufügen einer Verknüpfung zwischen Transporten",
          "ROLE_CAN_ADD_INVOICE_IN_TRANSACTION": "Ermöglicht das Hinzufügen von Rechnungsdaten zu einer Transaktion aus der Transporttabelle",
          "ROLE_CAN_ADD_MOT": "Ermöglicht das Hinzufügen eines Verkehrsträgers.",
          "ROLE_CAN_ADD_ROUTE": "Möglichkeit zum Hinzufügen einer neuen Route zur Preisliste",
          "ROLE_CAN_ADD_TRANSPORT_COMBINING_RELATION": "Ermöglicht die Verknüpfung einzelner Transporte. Kombinierte Transporte verfügen über eine gemeinsame Benachrichtigung, ein gemeinsames Fenster oder einen gemeinsamen Status.",
          "ROLE_CAN_AUTOMATICALLY_CONFIRM_PREVIOUS_CHECKPOINTS": "Durch die Bestätigung des angegebenen Status werden auch alle vorherigen unbestätigten Status automatisch bestätigt",
          "ROLE_CAN_CHOOSE_MORE_THAN_ONE_LOCATION": "Ermöglicht in den Auftragsdetails die Auswahl von mehr als einem Wert im Feld \"Standort\". Erfordert außerdem folgende Berechtigung: \"Zugriff auf Transportart\"",
          "ROLE_CAN_CREATE_DELIVERY": "Ermöglicht die Erstellung von Lieferungen auf der Registerkarte \"Planung\"",
          "ROLE_CAN_CREATE_MAX_PALLET": "Möglichkeit, das Palettenlimit festzulegen.",
          "ROLE_CAN_CREATE_ROUTE_OFFER": "Möglichkeit, ein neues Angebot zur Preisliste hinzuzufügen",
          "ROLE_CAN_DELETE_DELIVERY": "Ermöglicht die Löschung von Lieferungen",
          "ROLE_CAN_DELETE_RELATION": "Ermöglicht das Aufheben der Verknüpfung von Transporten. Mit dieser Berechtigung lässt sich die Verknüpfung eines Transports aufheben.",
          "ROLE_CAN_DELETE_ROUTE": "Möglichkeit zum Löschen von Routen aus Preislisten",
          "ROLE_CAN_DELETE_ROUTE_OFFER": "Möglichkeit, ein Angebot aus der Preisliste zu entfernen",
          "ROLE_CAN_DOWNLOAD_ENTRY_PASS": "Möglichkeit eine Eintrittskarte herunterzuladen",
          "ROLE_CAN_EDIT_ADDRESSES": "Möglichkeit, Adressen auf der Registerkarte \"Unternehmen\" zu bearbeiten und zu löschen.",
          "ROLE_CAN_EDIT_COMPANY_TRANSPORT_LIMIT": "Ermöglicht das Bearbeiten des Felds mit dem Transportlimit für das Unternehmen",
          "ROLE_CAN_EDIT_COURIERS_SECTION": "Ermöglicht die Bearbeitung der Felder im Abschnitt \"Kuriere\" im Transportformular. Erfordert die Berechtigung \"Abschnitt 'Kuriere' aufrufen\".",
          "ROLE_CAN_EDIT_DELIVERY": "Ermöglicht die Bearbeitung von Lieferungen",
          "ROLE_CAN_EDIT_INVOICE_IN_TRANSACTION": "Ermöglicht die Bearbeitung von Rechnungsdaten in einer Transporttransaktion",
          "ROLE_CAN_EDIT_READY_TO_PLAN": "Ermöglicht das Bearbeiten des Kennzeichens \"Bereit zur Planung\" im Transportformular",
          "ROLE_CAN_EDIT_ROUTE": "Möglichkeit zum Bearbeiten von Routen in Preislisten",
          "ROLE_CAN_EDIT_ROUTE_OFFER": "Möglichkeit, ein vorhandenes Angebot in der Preisliste zu bearbeiten",
          "ROLE_CAN_EDIT_TRANSPORT_SUPERVISOR": "Ermöglicht die Bearbeitung des Feldes \"Ansprechpartner\"",
          "ROLE_CAN_EXCEED_MAX_PALLET": "Möglichkeit, ein Lieferavis zu erstellen, das über das Palettenlimit hinausgeht.",
          "ROLE_CAN_EXCEED_MAX_PALLET_ON_DEDICATED_WINDOWS": "Möglichkeit bei Zeitfenstern, die vollständig in fest zugewiesenen Zeitfenstern liegen, ein Lieferavis zu erstellen, das über das Palettenlimit hinausgeht",
          "ROLE_CAN_GENERATE_FULL_SETTLEMENT_REPORT": "Ermöglicht das Exportieren von Abrechnungsberichtsdaten in eine Excel-Datei.",
          "ROLE_CAN_GENERATE_LIST_OF_POINTS_REPORT": "Die Berechtigung ermöglicht das Exportieren einer Liste mit Punkten in eine Excel-Datei.",
          "ROLE_CAN_GENERATE_SHORT_SETTLEMENT_REPORT": "Ermöglicht das Erstellen eines Lieferavis-Berichts in Form einer Excel-Datei",
          "ROLE_CAN_MOVE_WINDOW_OUT_OF_ASSORTMENT_GROUP": "Ermöglicht das Verschieben des Zeitfensters außerhalb einer Sortimentsgruppe",
          "ROLE_CAN_PLAN_ADVICE_IN_THE_PAST": "Ermöglicht das Festlegen eines Lieferavis in der Vergangenheit",
          "ROLE_CAN_SEE_CURRENT_PLACE_IN_AUCTION": "Aktuellen Platz bei einer laufenden Auktion anzeigen",
          "ROLE_CAN_SKIP_CHECKPOINT_CONFIRMATION": "Ermöglicht das Überspringen einer Etappe ungeachtet der erforderlichen Reihenfolge der Bestätigung der einzelnen Etappen",
          "ROLE_CAN_SYNCHRONISE_CARRIERS_DATA": "Die Autorisierung ermöglicht die Synchronisierung von Carrier-Daten mit einem externen Dienst",
          "ROLE_CAN_UPDATE_VEHICLE_MAIN_DATA_SECTION": "Primärdaten des Transportmittels bearbeiten",
          "ROLE_CARRIERS_TAB": "Ermöglicht den Zugriff auf die Registerkarte \"Frachtführer\".",
          "ROLE_CHANGE_WAREHOUSE": "Möglichkeit, das Lager im Zeitplan zu ändern.",
          "ROLE_CLASSIFICATION_TAB": "Ermöglicht den Zugriff auf die Registerkarte \"Klassifizierung\".",
          "ROLE_COMPANIES_TAB": "Ermöglicht den Zugriff auf die Registerkarte \"Unternehmen\".",
          "ROLE_CREATE_ASSORTMENT_GROUP": "Möglichkeit, eine neue Sortimentsgruppe zu erstellen.",
          "ROLE_CREATE_BLOCKED_WINDOWS": "Ermöglicht das Erstellen ausgegrauter Zeitfenster in der Zeitplanansicht.",
          "ROLE_CREATE_COMPANY": "Diese Berechtigung ermöglicht das Erstellen eines neuen Unternehmens, ohne eine Einladung zu versenden.",
          "ROLE_CREATE_CUSTOM_FIELD_DEFINITIONS": "Die Berechtigung ermöglicht den Zugriff auf die Einstellungen für benutzerdefinierte Felder.",
          "ROLE_CREATE_DISCREPANCY_PROTOCOL": "Ermöglicht das Generieren eines Abweichungsberichts",
          "ROLE_CREATE_DRIVER": "Möglichkeit, einen neuen Fahrer hinzuzufügen.",
          "ROLE_CREATE_GUARD_ROLE_GROUP": "Möglichkeit der Erstellung einer Security Rolle",
          "ROLE_CREATE_PLACE": "Die Rolle ermöglicht das Erstellen eines neuen Orts",
          "ROLE_CREATE_PRODUCT": "Ermöglicht das Hinzufügen eines neuen Produkts. Erfordert außerdem folgende Berechtigungsstufe: \"Zugriff auf Produkte\".",
          "ROLE_CREATE_QUEUE": "Die Rolle ermöglicht das Erstellen einer neuen Warteschlange",
          "ROLE_CREATE_RAMP": "Möglichkeit zum Hinzufügen einer neuen Rampe zu einem vorhanden Lager. Erforderliche Rolle: \"Lager bearbeiten\".",
          "ROLE_CREATE_ROLE_GROUP": "Ermöglicht das Erstellen einer neuen Rollengruppe",
          "ROLE_CREATE_SIMILAR": "Möglichkeit, einen ähnlichen Auftrag zu erstellen (Auftragskopie).",
          "ROLE_CREATE_SIMILAR_MULTIPLE": "Möglichkeit, die Anzahl der zu erstellenden Aufträge festzulegen",
          "ROLE_CREATE_SQUARE": "Die Rolle ermöglicht das Anlegen einer neuen Lagerfläche",
          "ROLE_CREATE_TRUCK": "Möglichkeit, ein neues Fahrzeug hinzuzufügen.",
          "ROLE_CREATE_USED_FOR_DELIVERIRES_ROLE_GROUP": "Möglichkeit, die Rolle der Erstellung von Versendungen zu schaffen",
          "ROLE_CREATE_USER": "Diese Berechtigung ermöglicht das Erstellen eines neuen Benutzers, ohne eine Einladung zu versenden.",
          "ROLE_CREATE_WAREHOUSE": "Möglichkeit, ein neues Lager hinzuzufügen. Erforderliche Rolle: \"Lager bearbeiten\". ",
          "ROLE_CREATE_WAREHOUSE_ZONE": "Möglichkeit, eine neue Lagerzone zu erstellen.",
          "ROLE_DELETE_COMPANY": "Möglichkeit, ein Unternehmen zu deaktivieren.",
          "ROLE_DELETE_DRIVER": "Möglichkeit, einen Fahrer zu entfernen. ",
          "ROLE_DELETE_INVITATION": "Ermöglicht das Löschen einer aktiven Einladung",
          "ROLE_DELETE_MULTIPLE_PERIODIC_TRANSPORTS": "Möglichkeit, mehrere periodische Lieferavise zu entfernen",
          "ROLE_DELETE_PLACE": "Ermöglicht das Entfernen des Orts",
          "ROLE_DELETE_PRODUCT": "Ein Benutzer mit dieser Berechtigungsstufe kann vorhandene Produkte löschen. Erfordert außerdem folgende Berechtigungsstufe: \"Zugriff auf Produkte\".",
          "ROLE_DELETE_QUEUE": "Ermöglicht das Entfernen der Warteschlange",
          "ROLE_DELETE_RAMP": "Möglichkeit, eine Rampe zu löschen. Erforderliche Rolle: \"Lager bearbeiten\".",
          "ROLE_DELETE_SQUARE": "Ermöglicht das Entfernen der Lagerfläche",
          "ROLE_DELETE_TRUCK": "Möglichkeit, ein Fahrzeug zu löschen.",
          "ROLE_DELETE_USER": "Möglichkeit, den Benutzer zu deaktivieren. Erfordert die Berechtigung \"Benutzerstammdaten bearbeiten\". Bei Drittanbieterbenutzern ist zusätzlich die Berechtigung \"Alle Benutzer bearbeiten\" erforderlich.",
          "ROLE_DELETE_WAREHOUSE": "Möglichkeit, ein Lager zu löschen. Erforderliche Rolle: \"Lager bearbeiten\".",
          "ROLE_DEV_TOOLS": "Zusätzliche Entwicklertools",
          "ROLE_EDIT_ALL_USERS": "Drittanbieterbenutzer können bearbeitet werden. Hierzu ist eine der folgenden Berechtigungen erforderlich: \"Benutzerstammdaten bearbeiten\", \"Benutzerzugriff auf Rampen bearbeiten\", \"Benutzerbenachrichtigungen bearbeiten\".",
          "ROLE_EDIT_ASSORTMENT_GROUP": "Möglichkeit, eine Sortimentsgruppe zu bearbeiten.",
          "ROLE_EDIT_COMPANY": "Möglichkeit, die Kurzbezeichnung des Auftragnehmerunternehmens, die Auftragnehmernummer und weitere Angaben zu bearbeiten.",
          "ROLE_EDIT_CONTRACTOR_GROUP": "Möglichkeit, bestehende Gruppen von Auftragnehmern zu bearbeiten",
          "ROLE_EDIT_GUS_DATA": "Möglichkeit zur Bearbeitung von Unternehmensdaten, die vom Statistischen Hauptamt (GUS) überprüft wurden",
          "ROLE_EDIT_INCOMPLETE_LOADING": "Möglichkeit, das Kontrollkästchen \"Beladen unvollständig\" zu aktivieren/deaktivieren.",
          "ROLE_EDIT_MULTIPLE_PERIODIC_TRANSPORTS": "Möglichkeit, mehrere periodischer Lieferavise zu bearbeiten",
          "ROLE_EDIT_MY_NOTIFICATION": "Einstellungen für eigene Benachrichtigungen bearbeiten",
          "ROLE_EDIT_MY_OWN_COMPANY": "Möglichkeit zur Bearbeitung eigener Unternehmensdaten",
          "ROLE_EDIT_MY_QUEUES": "Zugriff auf eigene Warteschlangen und Lagerflächen bearbeiten",
          "ROLE_EDIT_MY_RAMP": "Zugriff auf eigene Rampen bearbeiten",
          "ROLE_EDIT_PLACE": "Die Rolle ermöglicht das Bearbeiten eines Orts",
          "ROLE_EDIT_PRODUCT": "Ermöglicht das Bearbeiten vorhandener Produkt. Erfordert außerdem folgende Berechtigungsstufe: \"Zugriff auf Produkte\".",
          "ROLE_EDIT_QUEUE": "Die Rolle ermöglicht das Bearbeiten einer Warteschlange",
          "ROLE_EDIT_RAMP": "Die Rampe kann bearbeitet werden.",
          "ROLE_EDIT_ROLE_GROUP": "Möglichkeit, die Rolle in den Benutzerdetails zu ändern.",
          "ROLE_EDIT_SQUARE": "Die Rolle ermöglicht das Bearbeiten einer Lagerfläche",
          "ROLE_EDIT_USERS_MAIN_DATA": "Möglichkeit zur Bearbeitung von Name, E-Mail-Adresse, Telefonnummer und Passwort",
          "ROLE_EDIT_USERS_NOTIFICATION": "Ermöglicht dem Benutzer, die erhaltenen Benachrichtigungen zu bearbeiten",
          "ROLE_EDIT_USERS_RAMP": "Möglichkeit zur Bearbeitung des Benutzerzugriffs auf Rampen und Lager",
          "ROLE_EDIT_WAREHOUSE": "Möglichkeit, Lagerdaten zu bearbeiten",
          "ROLE_EDIT_WAREHOUSE_ZONE": "Möglichkeit, eine Lagerzone zu bearbeiten.",
          "ROLE_EDIT_WORKFLOW": "Möglichkeit, detaillierte Statuseinstellungen für jede Transportart zu bearbeiten.",
          "ROLE_FILTER_ACTIVE_TRANSPORTS_TABLE": "Zeigt Filter oberhalb der Spalten in der Tabelle aktiver Transporte an",
          "ROLE_FULL_EDIT_COMPANY": "Möglichkeit, die Angaben zum Auftragnehmerunternehmen zu bearbeiten.",
          "ROLE_INVITATIONS_TAB": "Ermöglicht den Zugriff auf die Registerkarte \"Einladungen\".",
          "ROLE_INVITE_AS_TEMPORARY_USER_WHEN_ACCOUNT_LIMIT_EXCEEDED": "Temporäre Benutzer einladen, durch die das Unternehmenslimit überschritten wird",
          "ROLE_INVOICE_COLUMN": "Rechnungsspalte anzeigen",
          "ROLE_MANAGE_ADMIN_ANNOUNCEMENTS": "Möglichkeit zur Verwaltung von Administratormeldungen. Erforderliche Berechtigungen: \"Zugriff auf die Registerkarte 'Ankündigungen'\" und \"Möglichkeit, Ankündigungen zu verwalten\"",
          "ROLE_MANAGE_ADVICE_VERIFICATION_MAIL": "Mit dieser Berechtigung können Sie die E-Mail-Benachrichtigung mit der Aufforderung zur Überprüfung der Lieferavisdaten aktivieren/deaktivieren und den Zeitpunkt für den Versand der E-Mail ändern. Nachdem die Berechtigung aktiviert wurde, wird das Element in den Angaben zum Auftragnehmer angezeigt.",
          "ROLE_MANAGE_ALLOWED_DOMAINS": "Zugriff und Verwaltung zulässiger E-Mail-Domains im Abschnitt \"Zulässige Domains\" in den Angaben zum Auftragnehmer.",
          "ROLE_MANAGE_ANNOUNCEMENTS": "Ermöglicht den Zugriff auf die Verwaltung von Ankündigungen. Erforderliche Berechtigungsstufe: \"Zugriff auf die Registerkarte 'Ankündigungen'\".",
          "ROLE_MANAGE_APPLIES_SETTINGS": "Zugriff auf die Verwaltung von Benachrichtigungseinstellungen",
          "ROLE_MANAGE_APPLY_SECTIONS": "Frachtführerbewerbungen verwalten. Möglichkeit, einzelne Abschnitte der Bewerbung des Frachtführers anzunehmen oder abzulehnen.",
          "ROLE_MANAGE_AUCTION_SETTINGS": "Zugriff auf die Verwaltung von Auktionseinstellungen",
          "ROLE_MANAGE_AUTHORITIES": "Ermöglicht das Erstellen, Bearbeiten und Löschen von Berechtigungen für Rollen (allgemeine Berechtigungen und Berechtigungen für die Transportarten)",
          "ROLE_MANAGE_CMR_SETTINGS": "Einstellungen für CMR-Dateien verwalten",
          "ROLE_MANAGE_COLUMN_VISIBILITY": "Ermöglicht das Festlegen der Anzeige der Spalten in der Berechtigungsansicht in Abhängigkeit von der Transportart",
          "ROLE_MANAGE_COMPANY_PRICING_PLAN": "Die Berechtigung ermöglicht es, die Abonnementoption für das Unternehmen zu ändern",
          "ROLE_MANAGE_COMPANY_RAMP": "Ermöglicht den Zugriff auf den Abschnitt \"Rampen bearbeiten\" in den Auftragnehmerdetails",
          "ROLE_MANAGE_DEDICATED_WINDOWS": "Erteilt die Berechtigung zum Erstellen und Löschen fest zugewiesener Zeitfenster.",
          "ROLE_MANAGE_DELIVERY_SETTINGS": "Verwaltung der Lieferbedingungen",
          "ROLE_MANAGE_LOCAL_ADMINS": "Ermöglicht die Verwaltung lokaler Administratoren in Auftragnehmerunternehmen",
          "ROLE_MANAGE_MAX_COMPANY_ACCOUNT_NUMBER": "Die Berechtigung ermöglicht es, die maximale Anzahl der Konten für Unternehmen zu ändern",
          "ROLE_MANAGE_MOT_SECTIONS": "Verkehrsträger verwalten. Möglichkeit, einzelne Abschnitte des Verkehrsträgers zu anzunehmen oder abzulehnen.",
          "ROLE_MANAGE_NOTIFICATIONS": "Ermöglicht die Verwaltung von Benachrichtigungen",
          "ROLE_MANAGE_PACKAGE_TYPE_TRANSPORT_TYPE": "Paketeinstellungen verwalten",
          "ROLE_MANAGE_PARTNERSHIP": "Ermöglicht das Hinzufügen und Löschen von Verknüpfungen zwischen Vertragsnehmern",
          "ROLE_MANAGE_PRINCIPAL_DEDICATED_WINDOWS": "Ermöglicht das Erstellen fest zugewiesener Fenster für das Kundenunternehmen. Der Benutzer des Kundenunternehmens kann diesen Fenstern beliebige Lieferavise hinzufügen.",
          "ROLE_MANAGE_QUEUES_ACCESSIBILITY": "Die Berechtigung ermöglicht das Aktivieren/Deaktivieren von Lagerflächen und Warteschlangen.",
          "ROLE_MANAGE_SETTINGS": "Ermöglicht die Verwaltung von Einstellungen",
          "ROLE_MANAGE_TRANSPORT_TYPE": "Einstellungen für die Transportart verwalten.",
          "ROLE_MANAGE_TRUCK_TYPES": "Verwaltung von Lkw-Typen",
          "ROLE_MANAGE_USER_GROUPS": "Gruppen innerhalb des Auftraggeberunternehmens verwalten",
          "ROLE_MANAGE_USER_ROLE_INVITATIONS": "Ermöglicht die Zuweisung einer Liste von Rollen, die für eine bestimmte Benutzerrolle eingeladen werden sollen",
          "ROLE_MODE_OF_TRANSPORTATION_TAB": "Ermöglicht den Zugriff auf die Registerkarte \"Verkehrsträger\".",
          "ROLE_MOVE_BLOCKED_WINDOWS": "Ermöglicht das Verschieben ausgegrauter Zeitfenster.",
          "ROLE_MOVE_DEDICATED_WINDOWS": "Ermöglicht das Verschieben fest zugewiesener Zeitfenster.",
          "ROLE_MOVE_WINDOWS_BETWEEN_WAREHOUSE_ZONES": "Ermöglicht das Verschieben von Zeitfenstern zwischen Lagerzonen",
          "ROLE_OWN_COLUMNS_EXCEL": "Die Berechtigung ermöglicht das Erstellen eines Berichts zur Anzeige der Spalten der Transporttabelle.",
          "ROLE_OWN_COMPANY_TAB": "Ermöglicht den Zugriff auf die Registerkarte \"Unternehmen\" im Konto des Auftragnehmers.",
          "ROLE_PERFORM_ACTION_IN_WAREHOUSE": "Die Rolle ermöglicht das Durchführen von Aktionen im Lager",
          "ROLE_PLANNING_TAB": "Ermöglicht den Zugriff auf die Registerkarte Planung",
          "ROLE_PRICE_LIST_TAB": "Ermöglicht die Genehmigung eines zur Preisliste hinzugefügten Angebots",
          "ROLE_PRODUCTS_TAB": "Ermöglicht den Zugriff auf die Registerkarte mit den Produkten.",
          "ROLE_QUEUES_TAB": "Ermöglicht den Zugriff auf die Registerkarte \"Warteschlangen\".",
          "ROLE_QUEUE_STATUS_TAB": "Ermöglicht den Zugriff auf die Registerkarte \"Warteschlangenstatus\".",
          "ROLE_READ_WINDOW_NOTES": "Ermöglicht das Anzeigen von Anmerkungen in ausgegrauten Zeitfenstern für die Benutzer mit dieser Berechtigungsstufe.",
          "ROLE_REJECT_CHECKPOINT": "Ermöglicht das Ablehnen eines Kontrollpunkts.",
          "ROLE_RESIZE_BLOCKED_WINDOWS": "Ermöglicht das Ändern der Größe ausgegrauter (geblockter) Zeitfenster. Hierzu ist auch folgende Berechtigung erforderlich: \"Geblockte Zeitfenster verschieben\".",
          "ROLE_RESIZE_DEDICATED_WINDOWS": "Ermöglicht das Ändern der Größe fest zugewiesener Zeitfenster. Hierzu ist auch folgende Berechtigung erforderlich: \"Fest zugewiesene Zeitfenster verschieben\".",
          "ROLE_RESTORE_ORDER_FROM_ARCHIVE": "Möglichkeit zur Wiederherstellung von Aufträgen aus dem Archiv nach automatischer Archivierung aufgrund von Verzögerungen",
          "ROLE_REVOKE_CHECKPOINT": "Ermöglicht das Entfernen eines Kontrollpunkts.",
          "ROLE_SEND_SMS_TO_ALL_DRIVERS_IN_QUEUE": "Ermöglicht den Versand von SMS an alle Fahrer in einer bestimmten Warteschlange (Registerkarte \"Warteschlangenstatus\")",
          "ROLE_SETTINGS_TAB": "Ermöglicht den Zugriff auf die Registerkarte \"Einstellungen\".",
          "ROLE_SHOW_API_INTEGRATION_LOGS": "Abschnitt \"Integration\" im Transportformular anzeigen.",
          "ROLE_SHOW_AWAITING_INVITATIONS_TABLE": "Mit dieser Berechtigung kann in den Angaben zu einem Unternehmen die Liste der ausstehenden Einladungen angezeigt werden.",
          "ROLE_SHOW_CUSTOM_FIELD_DEFINITIONS": "Mit dieser Berechtigung können benutzerdefinierte Felder hinzugefügt, bearbeitet und gelöscht werden. Zusätzlich ist die Berechtigung \"Zugriff auf die Einstellungen für benutzerdefinierte Felder\" erforderlich.",
          "ROLE_SHOW_PARTNERSHIP_TABLE": "Mit dieser Berechtigung kann in den Angaben zu einem Unternehmen die Liste der zugehörigen Auftragnehmer angezeigt werden. Die Partnerschaften werden auf Einladung erstellt.",
          "ROLE_SHOW_TRANSPORT_LOGS": "Abschnitt \"Verlauf\" im Transportformular anzeigen.",
          "ROLE_SORT_TRANSPORT_TABLE": "Die Rolle ermöglicht das Filtern der Transporttabelle nach einer ausgewählten Spalte",
          "ROLE_STATISTICS_REPORT_TAB": "Zugriff auf die Registerkarte \"Statistik\".",
          "ROLE_SUPER_USER": "Besondere Benutzerberechtigungen.",
          "ROLE_TRANSPORT_TABLE": "Ein Benutzer mit dieser Rolle hat Zugriff auf die Transporttabelle.",
          "ROLE_USERS_TAB": "Ermöglicht den Zugriff auf die Registerkarte \"Benutzer\". ",
          "ROLE_WAREHOUSE_TAB": "Ermöglicht den Zugriff auf die Registerkarte \"Zeitplan\".",
          "UNLIMITED_ACCESS_TO_ARCHIVED_TRANSPORTS": "Legt den Zugriff auf Aufträge auf unbestimmte Zeit fest"
        },
        "generalPermissions": "Allgemeine Berechtigungen",
        "header": "Berechtigungen für eine Rolle ",
        "invitationSection": "Benutzer kann Rollen einladen",
        "invitationSettings": "Einstellungen für Einladungen",
        "label": "Einstellungen für Rollengruppen",
        "noAuthorities": "Keine Berechtigung.",
        "notificationSection": "Einstellungen für Benachrichtigungen",
        "notificationSettings": "Einstellungen für Benachrichtigungen",
        "permissions": "Berechtigungen",
        "pricingPlans": "für Unternehmen mit Abonnement {pricingPlan}",
        "successCreate": "Rolle erstellt",
        "successDelete": "Berechtigung wurde deaktiviert",
        "successUpdate": "Änderungen gespeichert",
        "sureToDelete": "Möchten Sie diese Berechtigung wirklich deaktivieren?",
        "sureToDeleteAll": "Möchten Sie wirklich alle Berechtigungen für diese Gruppe deaktivieren?",
        "timeIntervalLabel": "Intervall",
        "timeReferenceLabel": "Zeitreferenz",
        "timeReferences": {
          "EARLIEST_TIME_WINDOW_CURRENT_DATE": "Beginn des ersten Zeitfensters relativ zum aktuellen Datum",
          "EARLIEST_TIME_WINDOW_START_AFTER_CHANGE": "Beginn des ersten Zeitfensters (nach dem Verschieben)",
          "EARLIEST_TIME_WINDOW_START_BEFORE_CHANGE": "Beginn des ersten Zeitfensters (vor dem Verschieben)",
          "LATEST_TIME_WINDOW_STOP": "Ende des letzten Zeitfensters",
          "TRANSPORT_DATE_CREATED": "Datum, an dem der Transport erstellt wurde"
        },
        "usersList": "Liste der Benutzer"
      },
      "transportTypes": {
        "addAuctionSetting": "Auktionseinstellungen hinzufügen",
        "addTransactionSetting": "Transaktionseinstellungen hinzufügen",
        "auctionSetting": "Auktionseinstellungen",
        "create": "Transportart erstellen",
        "englishName": "Englische Bezeichnung",
        "group": "Gruppe",
        "invalidCustomFieldId": "Das benutzerdefinierte Feld mit der angegebenen ID ist nicht vorhanden.",
        "label": "Transportarteinstellungen",
        "name": "Bezeichnung",
        "noAuctionSetting": "Es wurden noch keine Auktionseinstellungen hinzugefügt.",
        "noTransactionSetting": "Es wurde noch keine Einstellungen für die Übergabe eines Auftrags an den Frachtführer hinzugefügt.",
        "packageTypeSettings": "Paketarteinstellungen",
        "permissions": "Berechtigungen",
        "permissionsSaveSuccess": "Die Berechtigungseinstellungen wurden gespeichert.",
        "selectRoleGroups": "Wählen Sie die Rollen aus, die Transportarten erstellen können.",
        "settings": "Transportarteinstellungen",
        "settingsDescription": {
          "adviceRequiresPhoneNumber": "Legt fest, ob das Feld \"Mobilfunknummer\" beim Erstellen eines Lieferavis erforderlich ist",
          "adviceRestrictionInEveryStep": "Legt fest, ob das Feld \"Zeitraum für Lieferavis\" in allen Schritten angezeigt wird",
          "adviceSavedWithTransport": "Die Lieferavisdaten werden mit dem Transport gespeichert. Die Eingabe der Lieferavisdaten erfolgt im Transportformular.",
          "adviceSeparatedFromWindow": "Legt fest, ob das Hinzufügen eines Fensters ein vom Hinzufügen der Lieferavisdaten getrennter Schritt ist",
          "allowLoadStepTypeChange": "Legt fest, ob die Art des Beladeschritts im Transportformular zwischen Lager und Auftragnehmer geändert werden kann",
          "allowUnloadStepTypeChange": "Legt fest, ob die Art des Entladeschritts im Transportformular zwischen Lager und Auftragnehmer geändert werden kann",
          "allowsAltIdentifier": "Legt fest, ob eine zusätzliche Auftragskennung (altIdentifier) angezeigt wird. Dieses Feld wird im Transportformular unter der in der Einstellung \"Beschriftung der Spalte für die Auftragsnummer\" festgelegten Bezeichnung angezeigt.",
          "allowsCarRegistrationNumber": "Legt fest, ob das Feld \"Fahrzeugkennzeichen\" angezeigt wird",
          "allowsCarrier": "Legt fest, ob das Unternehmen \"Frachtführer\" am Transport beteiligt ist; legt fest, ob das Feld \"Frachtführer\" im Transportformular angezeigt wird",
          "allowsContractor": "Legt fest, ob das Unternehmen \"Lieferant\" am Transport beteiligt ist; legt fest, ob das Feld \"Lieferant\" im Transportformular angezeigt wird",
          "allowsDriverDocumentNumber": "Legt fest, ob das Feld \"Dokumentennummer des Fahrers\" angezeigt wird",
          "allowsDriverName": "Legt fest, ob das Feld \"Vor- und Nachname des Fahrers\" beim Erstellen eines Lieferavis angezeigt wird",
          "allowsErpIdentifier": "Legt fest, ob eine Auftragskennung (erpIdentifier) angezeigt wird. Dieses Feld wird im Transportformular unter der in der Einstellung \"ERP_IDENTIFIER_LABEL\" festgelegten Bezeichnung angezeigt.",
          "allowsPeriodicTransport": "Legt fest, ob die Option für periodische Lieferavise verfügbar ist. Die Option ist nur für ausgewählte Lieferanten/Empfänger verfügbar.",
          "allowsPhoneNumber": "Legt fest, ob das Feld \"Mobilfunknummer\" beim Erstellen eines Lieferavis angezeigt wird",
          "allowsPriceList": "Legt fest, ob bei der Transportart Zugriff auf \"Preisliste\" besteht",
          "allowsRodoCheckbox": "Legt fest, ob die DSGVO-Bedingung im Lieferavisformular angezeigt wird und erforderlich ist",
          "allowsTrailer": "Legt fest, ob das Feld \"Kennzeichen des Anhängers/Sattelaufliegers\" beim Erstellen eines Lieferavis angezeigt wird",
          "altIdentifierFormat": "Legt das Format der automatisch generierten \"altIdentifier\"-Nummer fest. Variablen: $-Sequenz – automatisch generierte Sequenznummer, DD/MM/JJJJ – Option zur Eingabe des Datums. Beispiel: \"IN/$sequence/MM/JJ\".",
          "altIdentifierIsCreatedAutomatically": "Legt fest, ob das Feld für die Transportkennung (altIdentifier) automatisch erstellt werden soll. Wenn diese Option aktiviert ist, kann die Kennung im Transportformular nicht bearbeitet werden.",
          "altIdentifierRegex": "Legt den regulären Ausdruck fest, mit dem geprüft wird, ob der Wert im Feld \"altIdentifier\" gültig ist.",
          "altIdentifierSequenceName": "Legt eine Sequenz fest, die auf eine fortlaufende Nummer für das automatisch generierte Feld \"altIdentifier\" verweist",
          "alternativeStepsLayout": "Schritt jeweils in einer separaten Zeile anzeigen",
          "calculatingTimeWindowSizeFromAlgorithm": "Legt fest, ob der im Lager angegebene Algorithmus zur Berechnung der Größe des Zeitfensters verwendet werden muss",
          "calculatingTimeWindowSizeFromDelivery": "Gibt an, ob Lieferdaten zur Berechnung der Größe des Zeitfensters verwendet werden sollen",
          "carRegistrationNumberTranslationKey": "Übersetzungsschlüssel für das Feld \"Fahrzeugzulassungsnummer\"",
          "carrierMustBeSelectedBeforeAdvice": "Ein Lieferavis kann erst zum Transport hinzugefügt werden, wenn dem Transport der Frachtführer zugewiesen wurde.",
          "checkAmountOfCover": "Validieren Sie die Deckungssumme bei der Erstellung von Auktionen oder bei der Übergabe an den Spediteur",
          "dateFilterProperty": "Legt das Feld fest, nach dem die Aufträge im Archiv standardmäßig gefiltert werden.",
          "defaultAmountOfCover": "Standardwert des Feldes: \"Anzahl der Deckung\"",
          "defaultLoadCompanyId": "Standardunternehmen im Beladeschritt",
          "defaultLoadStepEndHour": "Standardmäßiger Beginn des Zeitraums bei Etappen mit Partnern",
          "defaultLoadStepStartHour": "Standardmäßiger Beginn des Zeitraums bei Etappen mit Partnern",
          "defaultServicemanCompany": "Das gewählte Dienstleistungsunternehmen wird beim Anlegen eines neuen Transports automatisch eingerichtet",
          "defaultTextForTransportAdditionalDescription": "Standardtext im Feld \"Zusätzliche Hinweise\"",
          "defaultUnloadCompanyId": "Standardunternehmen im Entladeschritt",
          "dimensionsTranslationKey": "Übersetzungsschlüssel für das Feld \"Abmessungen\" (DIMENSIONS)",
          "dimensionsVisibleInContractorStep": "Legt fest, ob das Feld \"Auftragsnummer\" im Transportformular angezeigt wird (Schritt ohne Lager)",
          "dimensionsVisibleInWarehouseStep": "Legt fest, ob das Feld \"Auftragsnummer\" im Transportformular angezeigt wird (Schritt mit Lager)",
          "documentIdentifierTranslationKey": "Übersetzungsschlüssel für das Feld \"Lieferscheinnummer\" (DELIVERY_NOTE_NUMBER, DELIVERY_NOTE_NUMBER_SHORT, CUSTOMERS_ORDER_NUMBER, ISSUE_ORDER_NUMBER, REFERENCE_NUMBER)",
          "generateTransportOrder": "Legt fest, ob der zugeordnete Fahrauftrag beim Anlegen des Transports erzeugt wird",
          "generatingWeightReceiptEnabled": "Aktiviert die Optionen zum Herunterladen eines Wiegebelegs für den Transport. Der Beleg kann im Transportformular im Abschnitt \"Wiegen\" heruntergeladen werden. Zusätzlich kann in Konten mit der Rolle GUARD der Beleg direkt aus der Transporttabelle heruntergeladen werden.",
          "hasCmrDocumentEnabled": "Legt fest, ob für die Transportart das Dokument \"CMR\" vorhanden ist, das im Abschnitt \"Dokumente\" und in der Transporttabelle in der Spalte \"Dokument\" heruntergeladen werden kann",
          "hasDiscrepancyReportEnabled": "Legt fest, ob der Abschnitt \"Abweichungsbericht\" im Transportformular angezeigt wird",
          "hasEntrancePassDocumentEnabled": "Gibt an, ob nach dem Hinzufügen eines Avis eine Einreisekarte in der Transportart erstellt wird, die im Abschnitt \"Dokument\" und in der Transporttabelle in der Spalte Dokumente zum Download zur Verfügung steht",
          "hasProductSpecificationDocumentEnabled": "Legt fest, ob für die Transportart das Dokument \"Produktspezifikation\" vorhanden ist, das im Abschnitt \"Dokumente\" und in der Transporttabelle in der Spalte \"Dokument\" heruntergeladen werden kann",
          "hasVisibleSteps": "Legt fest, ob Felder in Zusammenhang mit Transportschritten im Formular angezeigt werden",
          "idx": "Legt die Reihenfolge fest, in der die Transporte in der Liste angezeigt werden",
          "isAmountOfCoverVisible": "Sichtbarkeit des Feldes: \"Anzahl der Deckung\"",
          "isInternalReleaseNumberVisibleInContractorStep": "Legt fest, ob das Feld \"Lieferscheinnummer\" im Transportformular angezeigt wird (Schritt ohne Lager)",
          "isInternalReleaseNumberVisibleInWarehouseStep": "Legt fest, ob das Feld \"Lieferscheinnummer\" im Transportformular angezeigt wird (Schritt mit Lager)",
          "isLoadStepInWarehouseByDefault": "Legt fest, ob beim Erstellen eines Auftrags der Beladeschritt standardmäßig im Lager erfolgt (kann bei \"allowLoadStepTypeChange == true\" geändert werden)",
          "isReadyToPlanObligatory": "Erforderlichkeit des Felds: \"Bereit zur Planung\" im Transportformular",
          "isReadyToPlanVisible": "Anzeige des Felds: \"Bereit zur Planung\" im Transportformular",
          "isTruckTypeVisible": "Pflichtfeld \"Transportart\"",
          "isUnloadStepInWarehouseByDefault": "Legt fest, ob beim Erstellen eines Auftrags der Entladeschritt standardmäßig im Lager erfolgt (kann bei \"allowUnloadStepTypeChange == true\" geändert werden)",
          "isVolumeVisibleInContractorStep": "Legt fest, ob das Feld \"Volumen\" im Transportformular angezeigt wird (Schritt ohne Lager)",
          "isVolumeVisibleInWarehouseStep": "Legt fest, ob das Feld \"Volumen\" im Transportformular angezeigt wird (Schritt mit Lager)",
          "isWarehouseLocationObligatory": "Erforderlichkeit des Felds: \"Standort\" im Transportformular",
          "isWarehouseLocationVisible": "Anzeige des Felds: \"Standort\" im Transportformular",
          "isWarehouseZoneVisible": "Legt fest, ob das Feld \"Lagerzone/Sortiment\" im Transportformular angezeigt wird",
          "isWeightVisibleInContractorStep": "Legt fest, ob das Feld \"Gewicht\" im Transportformular angezeigt wird (Schritt ohne Lager)",
          "isWeightVisibleInWarehouseStep": "Legt fest, ob das Feld \"Gewicht\" im Transportformular angezeigt wird (Schritt mit Lager)",
          "maximumPermissableVehicleWeight": "Legt fest, ob beim Wiegen eine Meldung zur Gewichtsüberschreitung angezeigt wird",
          "newAddressAsDefaultInLoadStep": "Nach der Unternehmensauswahl im Beladeschritt wird die Ansicht \"Neue Adresse hinzufügen\" angezeigt",
          "newAddressAsDefaultInUnloadStep": "Nach der Unternehmensauswahl im Entladeschritt wird die Ansicht \"Neue Adresse hinzufügen\" angezeigt",
          "newAddressCityAutocompleteEnabled": "Autovervollständigung des Felds \"Stadt\" beim Hinzufügen einer neuen Adresse im Auftragsformular",
          "numberOfDaysAddedToFirstStep": "Legt die Anzahl der Tage fest, die im Transportformular zum geplanten Termin bzw. der Zeitspanne für den ersten Schritt hinzugefügt werden:",
          "numberOfDaysAddedToFirstStepEnd": "Legt die Anzahl der Tage fest, die im Transportformular zum Ende des geplanten Termins bzw. der Zeitspanne für den ersten Schritt hinzugefügt werden:",
          "numberOfDaysAddedToNextSteps": "Legt die Anzahl der Tage fest, die im Transportformular zum geplanten Termin bzw. der Zeitspanne für den zweiten und alle weiteren Schritte hinzugefügt werden:",
          "numberOfDaysAddedToNextStepsEnd": "Legt die Anzahl der Tage fest, die im Transportformular zum Ende des geplanten Termins bzw. der Zeitspanne für den zweiten und alle weiteren Schritte hinzugefügt werden:",
          "pastingFromExcelEnabled": "Option zum Einfügen von Lieferavisdaten aus Excel",
          "purchaseOrderNumberTranslationKey": "Übersetzungsschlüssel für das Feld \"Auftragsnummer\" (SD_NUMBER)",
          "purchaseOrderNumberVisibleInContractorStep": "Legt fest, ob das Feld \"Auftragsnummer\" im Transportformular angezeigt wird (Schritt ohne Lager)",
          "purchaseOrderNumberVisibleInWarehouseStep": "Legt fest, ob das Feld \"Auftragsnummer\" im Transportformular angezeigt wird (Schritt mit Lager)",
          "quantitySumCheckValue": "Legt die die Anzahl der Paletten fest, nach denen eine Bestätigungsmeldung im Transportformular angezeigt wird",
          "requiresAddress": "Legt fest, ob bei einem Transportschritt ohne Lager eine Adresse hinzugefügt werden muss",
          "requiresAdvice": "Lieferavisdaten erforderlich – Bei Deaktivierung dieser Option bleiben das Lieferavisformular und die Lieferavisdetails leer.",
          "requiresAdviceDateRestrictions": "Legt fest, ob das Feld \"Zeitraum für Lieferavis\" beim Erstellen eines Transports angezeigt wird und erforderlich ist",
          "requiresAltIdentifier": "Legt fest, ob das Feld \"altIdentifier\" beim Erstellen eines Transports angezeigt wird und erforderlich ist. Dieses Feld wird im Transportformular unter der in der Einstellung \"Beschriftung der Spalte für die Auftragsnummer\" festgelegten Bezeichnung angezeigt.",
          "requiresArrivalAndDepartureDate": "Legt fest, ob das Feld \"Abholdatum\"/\"Lieferdatum\" angezeigt wird(Stufe mit Auftragnehmer)",
          "requiresArrivalAndDepartureDateInWarehouseStep": "Legt fest, ob das Feld \"Abholdatum\"/\"Lieferdatum\" angezeigt wird(Lagerstufe)",
          "requiresCarRegistrationNumber": "Legt fest, ob das Feld \"Fahrzeugkennzeichen\" beim Erstellen eines Lieferavis erforderlich ist",
          "requiresCarrier": "Legt fest, ob das Feld \"Frachtführer\" beim Erstellen eines Transports erforderlich ist",
          "requiresContractor": "Es müssen Angaben zum \"Lieferanten/Empfänger\" vorgenommen werden, um den Transport zu speichern. Wenn nicht in Zusammenhang mit dem Lager stehende Schritte im Transport angezeigt werden, kann der Auftragnehmer in diesem Abschnitt ausgewählt werden. Andernfalls kann der Auftragnehmer im Hauptteil des Transportformulars ausgewählt werden.",
          "requiresContractorStepCompanyGroup": "Legt fest, ob das Feld \"Auftragnehmergruppe\" beim Erstellen eines Transports erforderlich ist",
          "requiresDriverDocumentNumber": "Legt fest, ob das Feld \"Dokumentennummer des Fahrers\" beim Erstellen eines Lieferavis erforderlich ist",
          "requiresDriverLanguage": "Legt fest, ob das Feld \"Sprache des Fahrers\" angezeigt wird und erforderlich ist",
          "requiresDriverName": "Legt fest, ob das Feld \"Vor- und Nachname des Fahrers\" beim Erstellen eines Lieferavis erforderlich ist",
          "requiresErpIdentifier": "Legt fest, ob das Feld \"erpIdentifier\" beim Erstellen eines Transports angezeigt wird und erforderlich ist. Dieses Feld wird im Transportformular unter der in der Einstellung \"ERP_IDENTIFIER_LABEL\" festgelegten Bezeichnung angezeigt.",
          "requiresModeOfTransportationVerification": "Legt fest, ob alle Fahrzeuge des Frachtführers oder nur die verifizierten Fahrzeuge für ein Lieferavis angezeigt werden",
          "requiresStepsWeight": "Legt fest, ob das Feld \"Gewicht\" beim Erstellen eines Transports erforderlich ist",
          "requiresTimeWindow": "Es muss ein Zeitfenster ausgewählt werden, um den Transport zu speichern. Das bedeutet, dass der Benutzer nach dem Speichern des Transportformulars direkt zum Zeitplan weitergeleitet wird, um ein freies Zeitfenster auszuwählen.",
          "requiresTrailer": "Legt fest, ob das Feld \"Kennzeichen des Anhängers/Sattelaufliegers\" beim Erstellen eines Lieferavis erforderlich ist",
          "requiresTransportSupervisor": "Unverbindlichkeit des Feldes \"Kontaktperson",
          "requiresTruckType": "Unverbindlichkeit des Feldes \"Transportart\"",
          "requiresUniqueErpIdentifier": "Legt fest, ob das Feld \"erpIdentifier\" eindeutige Werte enthalten muss. Gelöschte Aufträge werden bei der Eindeutigkeitsprüfung nicht berücksichtigt.",
          "requiresWarehouse": "Es muss ein Lager ausgewählt werden, um den Transport zu speichern. Bei Auswahl der Option \"Auswahl eines Zeitfensters beim Anlegen eines Transports erforderlich\" ist zudem die Angabe eines Lagers erforderlich.",
          "selectionOfWindowBeforeAdvice": "Legt fest, ob die Fensterauswahl vor dem Hinzufügen der Lieferavisdaten erfolgen soll",
          "shouldCopyAdviceOnTransportCopy": "Gibt an, ob die Avisdaten mit der Option \"Ähnliches anlegen\" kopiert werden sollen",
          "showAddressFieldsInContractorSteps": "Legt fest, ob die Adressfelder in den Auftragnehmerschritten angezeigt werden",
          "transportTypeName": "Legt die Bezeichnung der Transportart fest",
          "transportTypeNameEn": "Legt die englische Bezeichnung der Transportart fest",
          "useResourcesFromExternalSource": "Von einer externen Quelle heruntergeladene Ressourcendaten (Fahrer, Traktoren, Anhänger)",
          "usedForDeliveries": "Fähigkeit, Lieferungen zu erstellen",
          "usedForMarcos": "Für diecos-Integration verwendete Transportart",
          "windowColorDependsOnWarehouse": "Legt fest, ob die Farbe des Zeitfensters nur von den Status abhängt, die dem Zeitfenster zugewiesen sind",
          "workerFieldTranslationKey": "Legt den angezeigten Namen des Feldes \"Mitarbeiter\" im Formular für die Transporterstellung fest (WORKERS, GUESTS, SERVICEMEN)"
        },
        "settingsGroup": {
          "advice": "Einstellungen für ein Lieferavis",
          "adviceForm": "Einstellungen für ein Lieferavisformular",
          "auctionMails": "Einstellungen für E-Mail-Benachrichtigungen",
          "auctionOffers": "Einstellungen für Angebotspreise",
          "auctionPass": "Einstellungen für die Auftragsübergabe an den Frachtführer",
          "general": "Allgemeine Einstellungen",
          "identifier": "Einstellungen für eine Transportkennung",
          "index": "Einstellungen für Indizes",
          "offerForm": "Einstellungen für das Angebotsformular",
          "others": "Sonstiges",
          "step": "Einstellungen für Transportschritte",
          "transaction": "Einstellungen für die Übergabe eines Transports an einen Frachtführer",
          "transport": "Transporteinstellungen",
          "transportForm": "Einstellungen für ein Transportformular",
          "transportType": "Einstellungen für die gesamte Transportart"
        },
        "settingsLabel": {
          "adviceRequiresPhoneNumber": "Erforderlichkeit des Felds: Telefonnummer des Fahrers",
          "adviceRestrictionInEveryStep": "Anzeige des Felds \"Zeitraum für Lieferavis\" in allen Schritten",
          "adviceSavedWithTransport": "Lieferavisdaten werden mit dem Transport gespeichert",
          "adviceSeparatedFromWindow": "Legt fest, ob das Hinzufügen eines Fensters ein vom Hinzufügen der Lieferavisdaten getrennter Schritt ist",
          "allowLoadStepTypeChange": "Anzeige des Kontrollkästchens \"Lager\" im Beladeschritt",
          "allowUnloadStepTypeChange": "Anzeige des Kontrollkästchens \"Lager\" im Entladeschritt",
          "allowsAltIdentifier": "Anzeige der Reihenfolge der zusätzlichen ID im Formular - \"altIdentifier\".",
          "allowsCarRegNumberDuringTransportCreation": "Anzeige des Felds: \"Fahrzeugkennzeichen\" im Feld für die Auftragserstellung",
          "allowsCarRegistrationNumber": "Legt fest, ob das Feld \"Fahrzeugkennzeichen\" angezeigt wird",
          "allowsCarrier": "Frachtführerunternehmen in den Transport aufnehmen",
          "allowsContractor": "Lieferantenunternehmen in den Transport aufnehmen",
          "allowsDriverDocumentNumber": "Anzeige des Felds \"Dokumentennummer des Fahrers\".",
          "allowsDriverName": "Erforderlichkeit des Felds: Vor- und Nachname des Fahrers",
          "allowsErpIdentifier": "Anzeige der Reihenfolge der ID im Formular - \"erpIdentifier\".",
          "allowsPeriodicTransport": "Legt fest, ob periodische Lieferavise hinzugefügt werden können",
          "allowsPhoneNumber": "Anzeige der \"Telefonnummer des Fahrers\" in einem Lieferavisformular",
          "allowsPriceList": "Legt fest, ob bei der Transportart Zugriff auf \"Preisliste\" besteht",
          "allowsRodoCheckbox": "Aktivierung der DSGVO-Bedingung",
          "allowsTrailer": "Anzeige des Felds: \"Kennzeichen des Anhängers/Sattelaufliegers\"",
          "altIdentifierFormat": "Format der automatisch generierten \"altIdentifier\"-Nummer",
          "altIdentifierIsCreatedAutomatically": "Automatische Generierung einer zusätzlichen Auftragsnummer - \"altIdentifier\".",
          "altIdentifierRegex": "Regulärer Ausdruck für die Validierung des Felds \"altIdentifier\"",
          "altIdentifierSequenceName": "Laufende Bezeichnung für das Feld \"altIdentifier\"",
          "alternativeStepsLayout": "Alternative Schrittansicht im Auftragsformular",
          "auctionSetting": {
            "addFileToTransportAfterOfferAcceptation": "Automatisch eine Datei an den Auftrag anfügen, nachdem der Auftrag vom Frachtführer angenommen wurde",
            "addFileToTransportAfterOfferAcceptationName": "Bezeichnung der Datei, die an den Auftrag angefügt wird, nachdem der Frachtführer den Auftrag angenommen hat",
            "auctionEmailSubjectEn": "Betreff der E-Mail-Benachrichtigung über die Auktion (Englisch)",
            "auctionEmailSubjectPl": "Betreff der E-Mail-Benachrichtigung über die Auktion",
            "auctionEndedWithoutOffersEmailFileName": "Version der E-Mail über das Ende der Auktion ohne Gebote",
            "auctionProlongationOffsetInterval": "Zeitraum, um die die Auktion bei einem Wechsel des Leiters verlängert werden soll",
            "canAcceptWithMinPrice": "Gewinn einer Auktion durch den Frachtführer nach Erreichen des Mindestpreises",
            "defaultAuctionFinishDelayInterval": "Standarddauer der Auktion",
            "defaultCurrency": "Standardwährung der Auktion",
            "exportAuctionInfoToExcel": "Auktionsdaten in eine Excel-Datei mit Aufträgen exportieren",
            "minOfferReductionSize": "Mindestwert, um den das Gebot reduziert werden soll",
            "newAuctionEmailFileName": "Version der E-Mail über die neue Auktion",
            "reminderTimeAboutAuction": "Zeitangabe für den Versand einer Erinnerung an eine laufende Auktion",
            "requireMaxPrice": "Maximalgebot beim Erstellen einer Auktion erforderlich",
            "requireMinOfferReductionSizeValidation": "Validierung des Mindestwerts für die Preisreduzierung",
            "requireMinPrice": "Mindestpreis beim Erstellen einer Auktion erforderlich",
            "requireReasonIfNotBestOfferIsChosen": "Angabe von Gründen, falls nicht das beste Angebot ausgewählt wurde",
            "selectAllCarriersByDefault": "Standardmäßig werden alle Frachtführer für die Auktion ausgewählt",
            "shouldPassToCarrierAfterAuction": "Automatische Übergabe des Auftrags an den Gewinner der Auktion",
            "showBlockedCompaniesSection": "Anzeige des Abschnitts mit den gesperrten Unternehmen beim Erstellen einer Auktion",
            "showCompanyGroupsSection": "Anzeige des Abschnitts mit der Gruppierung der Frachtführer beim Erstellen einer Auktion",
            "showDescriptionSection": "Anzeige des Felds für Anmerkungen im Formular zur Angebotsabgabe",
            "showFinishAuctionButton": "Anzeige der Schaltfläche \"Auktion beenden\" bei einer laufenden Auktion",
            "showPassToCarrierButton": "Anzeige der Schaltfläche \"Nicht bieten\"",
            "showPriceRangeFields": "Anzeige der Preisspanne beim Erstellen einer Auktion",
            "showQuantityFieldInOfferForm": "Anzeige der Anzahl der Paletten im Formular zur Angebotsabgabe",
            "showRevokeOfferButton": "Anzeige der Schaltfläche \"Angebot zurückziehen\"",
            "showStepDataInOfferForm": "Anzeige der Be- und Entladeplätze im Formular zur Angebotsabgabe",
            "translationKey": "Übersetzungsschlüssel"
          },
          "calculatingTimeWindowSizeFromAlgorithm": "Zeitfensterlänge auf Grundlage eines Algorithmus berechnen",
          "calculatingTimeWindowSizeFromDelivery": "Berechnung der Fenstergröße anhand der Lieferdaten",
          "carRegistrationNumberTranslationKey": "Übersetzungsschlüssel für das Feld \"Fahrzeugzulassungsnummer\" (TRUCK, VEHICLE)",
          "carrierMustBeSelectedBeforeAdvice": "Legt fest, ob ein Lieferavis hinzugefügt werden kann, ohne einen Frachtführer auszuwählen",
          "checkAmountOfCover": "Validieren Sie die Deckungssumme bei der Erstellung von Auktionen oder bei der Übergabe an den Spediteur",
          "dateFilterProperty": " Der Standardfilter im Archiv: ",
          "defaultAmountOfCover": "Standardwert des Feldes: \"Anzahl der Deckung\"",
          "defaultLoadCompanyId": "Standardunternehmen im Beladeschritt",
          "defaultLoadStepEndHour": "Standardmäßiger Beginn des Zeitraums bei Etappen mit Partnern",
          "defaultLoadStepStartHour": "Standardmäßiger Beginn des Zeitraums bei Etappen mit Partnern",
          "defaultServicemanCompany": "Standarddienstart Unternehmen",
          "defaultTextForTransportAdditionalDescription": "Standardtext im Feld \"Zusätzliche Hinweise\"",
          "defaultUnloadCompanyId": "Standardunternehmen im Entladeschritt",
          "dimensionsTranslationKey": "Übersetzungsschlüssel für das Feld \"Abmessungen\".",
          "dimensionsVisibleInContractorStep": "Anzeige des Felds \"Abmessungen\" im Auftragsformular (Schritt ohne Lager)",
          "dimensionsVisibleInWarehouseStep": "Anzeige des Felds \"Abmessungen\" im Auftragsformular (Schritt mit Lager)",
          "documentIdentifierTranslationKey": "Übersetzungsschlüssel für das Feld \"Lieferscheinnummer\".",
          "generateTransportOrder": "Legt fest, ob der zugeordnete Fahrauftrag beim Anlegen des Transports erzeugt wird",
          "generatingTransportWeightReceiptEnabled": "Legt fest, ob eine Gewichtsquittung in der Art erzeugt werden soll",
          "generatingWeightReceiptEnabled": "Möglichkeit, einen Wiegebeleg zu erstellen",
          "hasCmrDocumentEnabled": "Legt fest, ob für die Transportart ein CMR-Dokument vorhanden ist",
          "hasDiscrepancyReportEnabled": "Anzeige der Abschnitte: \"Abweichungsbericht\" im Transportformular",
          "hasEntrancePassDocumentEnabled": "Gibt an, ob die Eingabekarte nach dem Hinzufügen eines Vorschlags erstellt wird.",
          "hasProductSpecificationDocumentEnabled": "Legt fest, ob für die Transportart das Dokument \"Produktspezifikation\" vorhanden ist",
          "hasVisibleSteps": "Anzeige des Abschnitts \"Transportschritte\" in einem Formular",
          "idx": "Idx",
          "isAmountOfCoverVisible": "Sichtbarkeit des Feldes: \"Anzahl der Deckung\"",
          "isContactDetailsFieldsRequired": "Erforderlichkeit der Felder \"Ansprechpartner\", \"E-Mail-Adresse\" und \"Telefonnummer\" des Ansprechpartners",
          "isContactDetailsFieldsVisible": "Anzeige der Felder \"Ansprechpartner\", \"E-Mail-Adresse\" und \"Telefonnummer\" des Ansprechpartners im Auftragsformular",
          "isInternalReleaseNumberVisibleInContractorStep": "Anzeige des Felds \"Lieferscheinnummer\" im Auftragsformular (Schritt ohne Lager)",
          "isInternalReleaseNumberVisibleInWarehouseStep": "Anzeige des Felds \"Lieferscheinnummer\" im Auftragsformular (Schritt mit Lager)",
          "isLoadStepInWarehouseByDefault": "Beladeschritt standardmäßig für Lager",
          "isReadyToPlanObligatory": "Erforderlichkeit des Felds: \"Bereit zur Planung\" im Transportformular",
          "isReadyToPlanVisible": "Anzeige des Felds: \"Bereit zur Planung\" im Transportformular",
          "isTruckTypeVisible": "Pflichtfeld \"Transportart\"",
          "isUnloadStepInWarehouseByDefault": "Entladeschritt standardmäßig für Lager",
          "isVolumeVisibleInContractorStep": "Anzeige des Felds \"Volumen\" im Auftragsformular (Schritt ohne Lager)",
          "isVolumeVisibleInWarehouseStep": "Anzeige des Felds \"Volumen\" im Auftragsformular (Schritt mit Lager)",
          "isWarehouseLocationObligatory": "Erforderlichkeit des Felds: \"Standort\" im Transportformular",
          "isWarehouseLocationVisible": "Anzeige des Felds: \"Standort\" im Transportformular",
          "isWarehouseZoneVisible": "Anzeige des Felds \"Lagerzone/Sortiment\" im Transportformular",
          "isWeightVisibleInContractorStep": "Anzeige des Felds \"Gewicht\" im Auftragsformular (Schritt ohne Lager)",
          "isWeightVisibleInWarehouseStep": "Anzeige des Felds \"Gewicht\" im Auftragsformular (Schritt mit Lager)",
          "maximumPermissableVehicleWeight": "Meldung über die Überschreitung des zulässigen Fahrzeuggewichts anzeigen",
          "newAddressAsDefaultInLoadStep": "Nach der Unternehmensauswahl im Beladeschritt wird die Ansicht \"Neue Adresse hinzufügen\" angezeigt",
          "newAddressAsDefaultInUnloadStep": "Nach der Unternehmensauswahl im Entladeschritt wird die Ansicht \"Neue Adresse hinzufügen\" angezeigt",
          "newAddressCityAutocompleteEnabled": "Autovervollständigung des Felds \"Stadt\" beim Hinzufügen einer neuen Adresse im Auftragsformular",
          "numberOfDaysAddedToFirstStep": "Die Anzahl der Tage, die im Transportformular zum geplanten Termin bzw. zur Zeitspanne für den ersten Schritt hinzugefügt werden:",
          "numberOfDaysAddedToFirstStepEnd": "Die Anzahl der Tage, die im Transportformular zum Ende des geplanten Termins bzw. der Zeitspanne für den ersten Schritt hinzugefügt werden:",
          "numberOfDaysAddedToNextSteps": "Die Anzahl der Tage, die im Transportformular zum geplanten Termin bzw. zur Zeitspanne für den zweiten Schritt und alle weiteren Schritte hinzugefügt werden:",
          "numberOfDaysAddedToNextStepsEnd": "Die Anzahl der Tage, die im Transportformular zum Ende des geplanten Termins bzw. der Zeitspanne für den zweiten Schritt und alle weiteren Schritte hinzugefügt werden:",
          "pastingFromExcelEnabled": "Möglichkeit, Lieferavisdaten aus Excel einzufügen",
          "purchaseOrderNumberTranslationKey": "Übersetzungsschlüssel für das Feld \"Auftragsnummer\".",
          "purchaseOrderNumberVisibleInContractorStep": "Anzeige des Felds \"Auftragsnummer\" im Auftragsformular (Schritt ohne Lager)",
          "purchaseOrderNumberVisibleInWarehouseStep": "Anzeige des Felds \"Auftragsnummer\" im Auftragsformular (Schritt mit Lager)",
          "quantitySumCheckValue": "Anzahl der Tage, nach denen eine Warnung angezeigt wird",
          "requiresAddress": "Adressdaten bei Schritten ohne Lager erforderlich",
          "requiresAdvice": "Lieferavisdaten erforderlich",
          "requiresAdviceDateRestrictions": "Anzeige und Erforderlichkeit des Felds \"Zeitraum für Lieferavis\"",
          "requiresAltIdentifier": "Erforderlichkeit des Felds: \"altIdentifier\"",
          "requiresArrivalAndDepartureDate": "Anzeige und Erforderlichkeit der Felder \"Abholtermin\"/\"Liefertermin\" (Stufe mit Auftragnehmer)",
          "requiresArrivalAndDepartureDateInWarehouseStep": "Anzeige und Erforderlichkeit der Felder \"Abholtermin\"/\"Liefertermin\" (Lagerstufe)",
          "requiresCarRegistrationNumber": "Erforderlichkeit des Felds: \"Fahrzeugkennzeichen\"",
          "requiresCarrier": "Erforderlichkeit des Felds: \"Frachtführer\"",
          "requiresContractor": "Erforderlichkeit der Daten: Lieferant/Empfänger",
          "requiresContractorStepCompanyGroup": "Erforderlichkeit des Felds: \"Auftragnehmergruppe\"",
          "requiresDriverDocumentNumber": "Erforderlichkeit des Felds: \"Dokumentennummer des Fahrers\"",
          "requiresDriverLanguage": "Anzeige und Erforderlichkeit des Felds \"Sprache des Fahrers\".",
          "requiresDriverName": "Erforderlichkeit des Felds: \"Vor- und Nachname des Fahrers\"",
          "requiresErpIdentifier": "Erforderlichkeit des Felds: \"erpIdentifier\"",
          "requiresModeOfTransportationVerification": "Überprüfung der Transportart (nur verwendbar, wenn die Funktion \"Übergabe an den Frachtführer\" aktiviert ist)",
          "requiresStepsWeight": "Erforderlichkeit des Felds: \"Gewicht\"",
          "requiresTimeWindow": "Auswahl eines Zeitfensters beim Anlegen eines Transports erforderlich",
          "requiresTrailer": "Erforderlichkeit des Felds: \"Kennzeichen des Anhängers/Sattelaufliegers\"",
          "requiresTransportSupervisor": "Unverbindlichkeit des Feldes \"Kontaktperson",
          "requiresTruckType": "Unverbindlichkeit des Feldes \"Transportart\"",
          "requiresUniqueErpIdentifier": "Eindeutigkeit des Felds \"erpIdentifier\"",
          "requiresWarehouse": "Auswahl eines Lagers beim Anlegen eines Transports erforderlich",
          "selectionOfWindowBeforeAdvice": "Legt fest, ob die Fensterauswahl vor dem Hinzufügen der Lieferavisdaten erfolgen soll",
          "shouldCopyAdviceOnTransportCopy": "Gibt an, ob die Avisdaten mit der Option \"Ähnliches anlegen\" kopiert werden sollen",
          "shouldShowArrivalDatesInScheduleTooltip": "Gibt an, ob beim Hinzufügen eines Zeitfensters die Abladezeiten angezeigt werden sollen",
          "showAddressFieldsInContractorSteps": "Anzeige der Adressfelder in den Schritten mit Auftragnehmern",
          "supplierFieldTranslationKey": "Umrechnungsschlüssel für das Feld \"Lieferant/Empfänger\" (CLIENT , COMPANY, COURIER)",
          "transportTypeName": "Bezeichnung der Transportart",
          "transportTypeNameEn": "Englische Bezeichnung der Transportart",
          "useResourcesFromExternalSource": "Von einer externen Quelle heruntergeladene Ressourcendaten (Fahrer, Traktoren, Anhänger)",
          "usedForDeliveries": "Fähigkeit, Lieferungen zu erstellen",
          "usedForMarcos": "Für diecos-Integration verwendete Transportart",
          "windowColorDependsOnWarehouse": "Legt fest, ob die Farbe des Zeitfensters nur von den Status abhängt, die dem Zeitfenster zugewiesen sind",
          "workerFieldTranslationKey": "Umrechnungsschlüssel für das Feld \"Arbeitnehmer\" (ARBEITNEHMER, GÄSTE, DIENSTLEISTEN)\""
        },
        "settingsSaveSuccess": "Die Transportarteinstellungen wurden gespeichert.",
        "transactionSetting": "Transaktionseinstellungen",
        "workflow": "Schritte und Status (Workflow)"
      },
      "updateSuccessMessage": "Die Einstellungen wurden aktualisiert.",
      "value": "Wert"
    },
    "square": {
      "SQUARE": {
        "addQueue": "Warteschlange hinzufügen",
        "buffer": "Puffer",
        "carsInFrontOfSmelter": "Fahrzeuge vor der Qualitätskontrolle",
        "classificationTab": "Klassifizierung",
        "create": "Neuen Rohstofflagerfläche erstellen",
        "createPlace": "Platz hinzufügen",
        "label": "Rohstofflagerflächen",
        "placeLabel": "Platz",
        "queueLabel": "Warteschlange",
        "queueStatusTab": "Warteschlangenstatus",
        "queuesStatus": "Status der Warteschlange (nach der Qualitätskontrolle)",
        "queuesStatusTableHeader": "Zusammenfassung des aktuellen Warteschlangenstatus",
        "queuesTab": "Warteschlangen",
        "square": "Lagerfläche",
        "unload": "Entladen"
      },
      "createQueueAfterSave": "Warteschlangen können nach dem Speichern einer Lagerfläche konfiguriert werden.",
      "disableConfirm": "Möchten Sie diese Lagerfläche wirklich deaktivieren?",
      "updateSuccessMessage": "Die Einstellungen für die Lagerfläche {squareName} wurden aktualisiert."
    },
    "squareSetting": {
      "bufferSecondTableColumnVisibility": "Anzeige der Spalte (Tabelle 2) \"Puffer\"",
      "carRegistrationFirstTableColumnVisibility": "Anzeige der Spalte (Tabelle 1) \"Fahrzeugkennzeichen\"",
      "hdsFirstTableColumnVisibility": "Anzeige der Spalte (Tabelle 1) \"HDS\"",
      "label": "Einstellungen für Lagerflächen und Warteschlangen",
      "orderNumberFirstColumnVisibility": "Anzeige der Spalte (Tabelle 1) \"Auftragsnummer\"",
      "queueFirstTableColumnVisibility": "Anzeige der Spalte (Tabelle 1) \"Warteschlange\"",
      "queueSecondTableColumnVisibility": "Anzeige der Spalte (Tabelle 2) \"Warteschlange\"",
      "squareFirstTableColumnVisibility": "Anzeige der Spalte (Tabelle 1) \"Lagerfläche\"",
      "squareSecondTableColumnVisibility": "Anzeige der Spalte (Tabelle 2) \"Lagerfläche\"",
      "translationKey": "Übersetzungsschlüssel",
      "unloadSecondTableColumnVisibility": "Anzeige der Spalte (Tabelle 2) \"Entladen\""
    },
    "statuses": {
      "deleted": "Gelöscht",
      "finished": "Abgeschlossen",
      "initial": "Lieferavis gesendet",
      "movedToArchiveDueDelay": "Verzögert",
      "waitingForConfirmation": "Warten auf Bestätigung von"
    },
    "statute": {
      "accept": {
        "disabled.title": "Machen Sie sich mit der Erklärung zur Annahme der Nutzungsbedingungen vertraut.",
        "header": "Sehr geehrte/r Benutzer/in,",
        "inTouch": "Ich erkläre mich einverstanden. Wir bleiben in Kontakt.",
        "informed": "Ich habe mich mit dem Vorstehenden vertraut gemacht und habe ausreichend Informationen erhalten.",
        "label": "Ich habe mich mit dem Vorstehenden vertraut gemacht und erkläre mich einverstanden.",
        "manual": "Ich habe mich mit dem Sicherheitshandbuch vertraut gemacht und erkläre mich einverstanden.",
        "paragraph_1": "1. LOGINTEGRA Sp. z o. o. mit Sitz in Gdańsk, Lęborska 3b, KRS 0000426238, NIP 584-272-80-64, REGON 221707056 ist der gemäß DSGVO für die Verarbeitung Ihrer im System gespeicherten personenbezogenen Daten Verantwortliche.  Kontaktdaten des Verantwortlichen: Tel.: +48 58 535 78 06, E-Mail: info@logintegra.com. Ihre Daten können anderen Systembenutzern und weiteren unten erläuterten Stellen verfügbar gemacht werden.",
        "paragraph_10": "10. Wenden Sie sich bei Fragen, Anmerkungen oder Wünschen in Bezug auf Ihre Daten über die folgende E-Mail-Adresse an uns: info@logintegra.com",
        "paragraph_2": "2. Die personenbezogenen Daten des Benutzers werden nur zum Zweck der Erfüllung der zwischen dem Verantwortlichen und anderen Benutzern geschlossenen Verträge, zur Erfüllung der mit den geschlossenen Verträgen verbundenen Pflichten und Rechten sowie zur Erfüllung der sich aus gesetzlichen Bestimmungen ergebenden Pflichten sowie in dem Umfang verarbeitet, in dem der Benutzer der Verarbeitung der Daten zugestimmt hat. ",
        "paragraph_3": "3. Das System dient zur Zusammenarbeit zwischen Unternehmen und zur Abwicklung der Logistikprozesse von Unternehmen der Benutzer. Der Betrieb erfordert, dass personenbezogene Daten des Benutzers anderen Benutzern des Systems zugänglich gemacht, an diese gesendet oder auf andere Weise übermittelt werden. Personenbezogene Daten des Benutzers können auch in anderen Fällen übermittelt werden, wenn dies gesetzlich vorgesehenen ist. Insbesondere: ",
        "paragraph_3_1": "an Steuerbehörden – soweit die Übermittlung personenbezogener Daten aufgrund steuerrechtlicher Bestimmungen verpflichtend ist;",
        "paragraph_3_2": "an andere staatliche Stellen wie Strafverfolgungsbehörden, Gerichte, Staatsanwaltschaften, Sozialversicherungsträger – soweit die Übermittlung personenbezogener Daten aufgrund gesetzlicher Bestimmungen verpflichtend ist;",
        "paragraph_3_3": "an zuverlässige Unterauftragnehmer, die in unserem Auftrag den technischen Support oder das Hosting übernehmen;",
        "paragraph_3_4": "an Erbringer von Buchhaltungs-, Steuer- und Rechtsdienstleistungen.",
        "paragraph_4": "4. Die personenbezogenen Daten des Benutzers werden nur während des Zeitraums verarbeitet, der für die Erfüllung der in Absatz 2 genannten Zwecke erforderlich ist, oder bis die Einwilligung widerrufen wird. Daten bestimmter Kategorien können länger gespeichert werden, wenn dies gesetzlich vorgeschrieben ist, z. B. werden Rechnungsdaten vom Verantwortlichen so lange gespeichert, bis die gesetzliche Frist für die Aufbewahrung von Rechnungen für steuerliche Zwecke abgelaufen ist.",
        "paragraph_5": "5. Der Benutzer stellt personenbezogene Daten freiwillig zur Verfügung. Die Angabe bestimmter Daten ist hierbei jedoch für den Betrieb des Systems erforderlich. Das System kann nur verwendet werden, wenn der Benutzer alle erforderlichen personenbezogenen Daten im Registrierungsformular angibt oder seine Zustimmung zur Verarbeitung dieser Daten widerruft.",
        "paragraph_6": "6. Jeder Benutzer (natürliche Person) hat folgende Rechte:",
        "paragraph_6_1": "Recht auf Zugriff auf seine Daten",
        "paragraph_6_2": "Recht auf Korrektur seiner Daten",
        "paragraph_6_3": "Recht auf Löschung oder Einschränkung der Verarbeitung seiner Daten",
        "paragraph_6_4": "Recht auf Einspruch gegen die Verarbeitung seiner Daten",
        "paragraph_6_5": "Recht auf Übermittlung seiner Daten",
        "paragraph_6_6": "Recht auf jederzeitigen Widerruf der Einwilligung zur Datenverarbeitung, ohne dass hierdurch die Rechtmäßigkeit der Verarbeitung berührt wird, die vor dem Widerruf der Einwilligung erfolgt ist",
        "paragraph_6_bottom": "Darüber hinaus hat der Benutzer das Recht auf Beschwerde bei einer Aufsichtsbehörde, wenn er der Meinung ist, dass die Verarbeitung seiner personenbezogenen Daten gegen die Vorschriften zum Schutz personenbezogener Daten verstößt.",
        "paragraph_7": "7. Ausführliche Informationen zum Datenschutz finden Sie in folgenden Dokumenten:",
        "paragraph_7_1": "Datenschutzrichtlinie",
        "paragraph_7_2": "Sicherheitshandbuch",
        "paragraph_7_3": "Nutzungsbedingungen für das System",
        "paragraph_7_bottom": "Klicken Sie auf den jeweiligen Namen, um diese Dokumente aufzurufen.",
        "paragraph_8": "8. Unser System verwendet Cookies.",
        "paragraph_9": "9. Beachten Sie, dass das System personenbezogene Daten verarbeitet. Wenn Sie personenbezogene Daten Dritter, z. B. von Fahrern, in das System eingeben, müssen Sie sicherstellen, dass die Verpflichtungen des Verantwortlichen gemäß DSGVO eingehalten werden. Zu diesen Verpflichtungen gehört unter anderem, dass die betroffene Person über Folgendes informiert wird:",
        "paragraph_9_1": "die Identität des Verantwortlichen und dessen Kontaktdaten;",
        "paragraph_9_2": "die Zwecke der Verarbeitung der personenbezogenen Daten sowie die Rechtsgrundlage für die Verarbeitung;",
        "paragraph_9_3": "die Empfänger oder Kategorien von Empfängern der personenbezogenen Daten, falls vorhanden;",
        "paragraph_9_4": "den Zeitraum, für den die personenbezogenen Daten gespeichert werden;",
        "paragraph_9_5": "das Recht, vom Verantwortlichen Auskunft über die personenbezogenen Daten der betroffene Person, die Berichtigung oder Löschung dieser Daten bzw. die Einschränkung der Verarbeitung zu fordern oder der Verarbeitung zu widersprechen, sowie das Recht auf Datenübertragbarkeit;",
        "paragraph_9_6": "wenn die Verarbeitung aufgrund der Einwilligung einer bestimmten Person erfolgt – das Recht, die Einwilligung jederzeit zu widerrufen, ohne dass hiervon die Rechtmäßigkeit der auf der Einwilligung beruhenden Verarbeitung vor deren Widerruf berührt wird;",
        "paragraph_9_7": "das Recht, Beschwerde bei einer Aufsichtsbehörde einzureichen;",
        "paragraph_9_8": "die Angabe, ob die Bereitstellung personenbezogener Daten gesetzlich oder vertraglich vorgeschrieben oder für den Abschluss eines Vertrags erforderlich ist, sowie die Angabe, ob die betroffene Person verpflichtet ist, die personenbezogenen Daten bereitzustellen, und welche Folgen die Nichtbereitstellung dieser Daten haben kann.",
        "paragraph_9_bottom": "Stellen Sie sicher, dass die Verpflichtungen des Verantwortlichen erfüllt werden. Benachrichtigen Sie uns unverzüglich, wenn Sie von einem möglichen Verstoß gegen die Vorschriften zum Schutz personenbezogener Daten Kenntnis erlangen.",
        "policy": "Ich habe mich mit der Datenschutzrichtlinie vertraut gemacht und erkläre mich einverstanden.",
        "statute": "Ich habe mich mit dem Nutzungsbedingungen des Systems vertraut gemacht und erkläre mich einverstanden.",
        "subHeader": "am 25. Mai 2018 sind die Bestimmungen der VERORDNUNG (EU) 2016/679 DES EUROPÄISCHEN PARLAMENTS UND DES RATES zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten, zum freien Datenverkehr und zur Aufhebung der Richtlinie 95/46/EG (Datenschutz-Grundverordnung), veröffentlicht im Amtsblatt der Europäischen Union L 119 von 2016, S. 1, in Kraft getreten. Der Einfachheit halber wird diese Verordnung im Folgenden als DSGVO bezeichnet. Gemäß der Verpflichtungen, die die DSGVO den Verantwortlichen und den Auftragsverarbeitern personenbezogener Daten auferlegt, informieren wir Sie hiermit über einige wichtige Aspekte in diesem Zusammenhang. Machen Sie sich sorgfältig mit den nachstehenden Inhalten vertraut:"
      },
      "appliesManualLabel": "Anwendungshandbuch öffnen",
      "label": "Leistungsbedingungen",
      "label.ablative": "Leistungsbedingungen",
      "links": {
        "manual": "https://pl.logintegra.com/static/Manual.pdf",
        "policy": "https://pl.logintegra.com/static/Policy.pdf",
        "statute": "https://pl.logintegra.com/static/Statute-2018-05-24.pdf"
      },
      "openKnowledgeBase": "Knowledge Base öffnen",
      "policyLabel": "Datenschutzrichtlinie",
      "reject": {
        "button": "Abbrechen (dieses Konto wird gesperrt)",
        "buttonForInvitations": "Ablehnen",
        "confirmation": "Sind Sie sicher? Ihr Konto wird gesperrt."
      },
      "safetyManualLabel": "Sicherheitshandbuch"
    },
    "step": {
      "CUSTOMERS_ORDER_NUMBER.label": "Auftragsnummer des Kunden",
      "add": {
        "title": "Weiteren Schritt hinzufügen"
      },
      "address": "Adresse",
      "canMoveLoadStep": "Mit Ihrer Berechtigungsstufe können nur Beladeschritte verschoben werden.",
      "canMoveUnloadStep": "Mit Ihrer Berechtigungsstufe können nur Entladeschritte verschoben werden.",
      "companyGroup": "Auftragnehmergruppe",
      "contactPerson": {
        "email": "E-Mail-Adresse",
        "label": "Ansprechpartner",
        "phoneNumber": "Telefonnummer"
      },
      "delete": {
        "confirmMessage": "Möchten Sie diesen Schritt aus diesem Transport wirklich löschen?",
        "confirmMessageWithIndexes": "Möchten Sie diesen Schritt aus diesem Transport wirklich löschen? Alle zugehörigen Indizes werden zusammen mit diesem Schritt gelöscht.",
        "title": "Schritt aus diesem Transport entfernen"
      },
      "dimensions": {
        "DEFAULT.label": "Abmessungen",
        "DIMENSIONS.label": "Abmessungen"
      },
      "documentIdentifier": {
        "CUSTOMERS_ORDER_NUMBER.label": "Auftragsnummer des Kunden",
        "DEFAULT.label": "Lieferscheinnummer",
        "DELIVERY_NOTE_NUMBER.label": "Lieferscheinnummer",
        "DELIVERY_NOTE_NUMBER_SHORT.label": "Liefsch",
        "REFERENCE_NUMBER.label": "Referenznummer"
      },
      "errors": {
        "arrivalDate": {
          "isInPast": "Das Lieferdatum darf nicht in der Vergangenheit liegen."
        },
        "company": {
          "isRequired": "Für diese Transportart muss ein Auftragnehmer angegeben werden. Legen Sie eine Partnerschaft an, wenn keine Unternehmen angezeigt werden."
        },
        "companyGroup": {
          "blank": "Sie müssen mindestens eine Auftragnehmergruppe auswählen.",
          "wrongValue": "Die ausgewählte Auftragnehmergruppe gehört nicht zu den Gruppen des ausgewählten Lieferantenunternehmens."
        },
        "contactPersonEmail": {
          "invalidEmailAddress": "Bitte geben Sie eine gültige E-Mail Adresse ein."
        },
        "dimensions": {
          "length": "Der eingegebene Text ist zu lang. Die maximale Länge beträgt 255 Zeichen."
        },
        "maxAdviceDate": {
          "beforeWindowStop": "Der angegebene Bereich kann nicht gespeichert werden, da ein zuvor hinzugefügtes Lieferavis-Zeitfenster außerhalb des Bereichs liegt. Verschieben Sie zunächst das Zeitfenster.",
          "earlierThanMinAdviceDate": "Die späteste mögliche Zeit für ein Lieferavis darf nicht vor der frühesten möglichen Zeit für ein Lieferavis liegen.",
          "inThePast": "Das Ende der Zeitspanne für ein Lieferavis muss in der Zukunft liegen, damit das Lieferavis erstellt werden kann.",
          "timeHasPassed": "Leider ist die zulässige Zeitspanne für das Lieferavis abgelaufen. Bitte wenden Sie sich an den Administrator."
        },
        "minAdviceDate": {
          "afterWindowStart": "Der angegebene Bereich kann nicht gespeichert werden, da ein zuvor hinzugefügtes Lieferavis-Zeitfenster außerhalb des Bereichs liegt. Verschieben Sie zunächst das Zeitfenster."
        },
        "minQuantityValue": "Die minimal zulässige Palettenanzahl ist {minQuantityValue}",
        "outsideOfInterval": "Das zulässige Intervall beträgt {windowInterval} Minuten, da dies das Zeitintervall für das Verschieben von Zeitfenstern im ausgewählten Lager ist.",
        "outsideWarehouseWorkHours": "Das ausgewählte Datum liegt außerhalb der Arbeitszeiten des Lagers: {workStart}-{workEnd}.",
        "purchaseOrderNumber": {
          "length": "Der eingegebene Text ist zu lang. Die maximale Länge beträgt 255 Zeichen."
        },
        "warehouse": {
          "sameWarehousesInTransfer": "Das Erstellen eines lagerinternen Transports ist nicht möglich."
        }
      },
      "label": "Schritt",
      "load": {
        "place": "Beladeort",
        "term": "Abholdatum"
      },
      "maxAdviceDate": "Späteste mögliche Zeit für ein Lieferavis",
      "minAdviceDate": "Früheste mögliche Zeit für ein Lieferavis",
      "orderInTransport": "Reihenfolge bei einem Auftrag",
      "purchaseOrderNumber": {
        "DEFAULT.label": "SD-Nummer",
        "SD_NUMBER.label": "SD-Nummer"
      },
      "quantityLoadAndUnloadSumCheckMessage": "Möchten Sie wirklich ein Lieferavis zum Beladen/Entladen von mehr als einem Trailer erstellen?",
      "quantityLoadSum": "Anzahl der Paletten bei der Beladung",
      "quantityLoadSumCheckMessage": "Möchten Sie wirklich ein Lieferavis zum Beladen von mehr als einem Trailer erstellen?",
      "quantityUnloadSum": "Anzahl der Paletten bei der Entladung",
      "quantityUnloadSumCheckMessage": "Möchten Sie wirklich ein Lieferavis zum Entladen von mehr als einem Trailer erstellen?",
      "segmentLdm": "Lademeter",
      "segmentPayload": "Nutzlast",
      "timeRangeForAdvice": "Zeitspanne für ein Lieferavis",
      "timeRangeForAdviceDoesNotApply": "Nur zur Information – gilt nicht für Sie",
      "timeRangeForLoad": "Zeitspanne für das Beladen",
      "timeRangeForUnload": "Zeitspanne für das Entladen",
      "timeWindow": "Zeitfenster",
      "unload": {
        "place": "Entladeort",
        "term": "Lieferdatum"
      },
      "unloadStepArrivalDates": "Datum und Uhrzeit der Entladung",
      "weightLoadSum": "Gesamtgewicht der Beladeschritte",
      "weightUnloadSum": "Gesamtgewicht der Entladeschritte"
    },
    "table": {
      "loadMore": "Weitere Daten laden",
      "loading": "Transporte werden geladen",
      "numberOfTransports": "{n} Transporte",
      "scanBarcode": "Scannen von Barcodes",
      "scanBarcodeError": "Beim Scannen des Barcodes ist ein Fehler aufgetreten. Überprüfen Sie das Scangerät, und versuchen Sie es erneut, oder wenden Sie sich an Ihren Administrator.",
      "transports": {
        "scrollToType": "Scrollen Sie zum Abschnitt \"Transporte\" dieses Typs.",
        "selectType": "Typ auswählen",
        "showAll": "Alle Transporte anzeigen",
        "showAllActive": "Alle aktiven Aufträge anzeigen",
        "showLimited": "Limitierte Aufträge anzeigen",
        "showMine": "Nur meine Transporte anzeigen",
        "showWithinGroup": "Transporte in meiner Gruppe anzeigen"
      }
    },
    "tabs": {
      "announcements": "Ankündigungen",
      "archive": "Archiv",
      "carriers": "Frachtführer",
      "companies": "Unternehmen",
      "invitations": "Einladungen",
      "modeOfTransportation": "Verkehrsträger",
      "planning": "Planung",
      "priceLists": "Preislisten",
      "report": "Statistiken",
      "schedule": "Zeitplan",
      "settings": "Einstellungen",
      "superAdmin": "ADMINISTRATOR",
      "transports": "Transporte",
      "users": "Benutzer"
    },
    "task": {
      "alias": "Schrittbezeichnung",
      "altCheckpointColor": "Alternative Statusfarbe",
      "altCheckpointName": "Alternative Bezeichnung für Schritt DE",
      "altCheckpointNameEn": "Alternative Bezeichnung für Schritt EN",
      "associations": {
        "CONTRACTOR": "Auftragnehmer",
        "WAREHOUSE": "Lager",
        "WAREHOUSE_AND_CONTRACTOR": "Lager und Auftragnehmer",
        "label": "Aufgabe zugewiesen zu"
      },
      "beforeConfirmation": {
        "willChangeColorTo": "und die entsprechende Tabellenzeile und das Zeitfenster werden angezeigt wie folgt:",
        "willChangeStateTo": "Bis zur Bestätigung dieses Schritts erhält der Transport folgenden Status "
      },
      "blockade": {
        "customFieldsRestriction": "Der Status kann erst bestätigt werden, nachdem folgende Felder im Transportformular ausgefüllt und gespeichert wurden:",
        "documentRestriction": "Der Status kann erst bestätigt werden, nachdem die Transportdokumente hinzugefügt wurden."
      },
      "canBeBlocked": "Status kann gesperrt werden (der Status kann erst nach dem Entsperren geändert werden), erfordert die Berechtigung ROLE_CAN_BLOCK_TASK",
      "canBeConfirmAfterAddingDocumentsForTransport": "Kann bestätigt werden, nachdem die Transportdokumente hinzugefügt wurden",
      "canBeConfirmedByGuard": "Kann von Wachpersonal bestätigt werden",
      "canBeRejected": "Der Status kann abgelehnt werden.",
      "canBeRevoked": "Der Status kann widerrufen werden.",
      "canConfirmPreviousConfirm": "Möchten Sie die Abfahrt für diesen Auftrag wirklich bestätigen? Die Entladestatus wurden nicht bestätigt.",
      "checkpointName": "Bezeichnung für Schritt DE",
      "checkpointNameEn": "Bezeichnung für Schritt EN",
      "checkpointSortId": "Reihenfolge der Etappe bei der Sortierung",
      "checkpointSortIdSettingLabel": "Etappenreihenfolge beim Sortieren der Status in der Transporttabelle",
      "color": "STATUS-Farbe",
      "confirmCheckpointAfterCarrierSelect": "Automatische Bestätigung nach Zuweisen eines Frachtführers",
      "confirmCheckpointAfterCourierDelivered": "Automatische Bestätigung nach Bestätigung des Status \"Zugestellt\" durch den Kurier",
      "confirmCheckpointAfterCourierPickup": "Automatische Bestätigung nach Bestätigung des Status \"Abgeholt\" durch den Kurier",
      "customModalComponentName": "Komponentenname, der als Pop-up-Modal verwendet wird, wenn für die Aufgabe die Einstellung openCustomModal aktiviert ist (siehe oben)",
      "defaultStagePrefix": "Bestätigt",
      "disableConfirm": "Möchten Sie diesen Schritt wirklich deaktivieren?",
      "editLabel": "Schritt bearbeiten: {id} - {transportType}",
      "errors": {},
      "expectedDateMode": {
        "reference": "nicht später als"
      },
      "expectedDateReference": "Referenz",
      "group": {
        "0": "Vorbereitung",
        "1": "Be- und Entladungen",
        "2": "Ende"
      },
      "label": "Schritt",
      "markIfWasDelayed": {
        "disabled": {
          "label": "Verspätung kennzeichnen",
          "title": "Klicken Sie auf das Symbol, wenn bei verspäteter Bestätigung dieses Schritts ein roter Rahmen um das Zeitfenster angezeigt werden soll."
        },
        "enabled": {
          "label": "Verspätung kennzeichnen",
          "title": "Wenn ein Schritt zu spät bestätigt wird, wird das Zeitfenster mit einem roten Rahmen gekennzeichnet. Klicken Sie auf das Symbol, um die Einstellungen zu ändern."
        }
      },
      "moveTransportToArchiveIfDelayed": "Auftrag im Fall einer Verzögerung automatisch archivieren",
      "newSubTasksCheckpoints": "Neue Checkpoints werden generiert",
      "openCustomModal": "Um diese Aufgabe zu bestätigen, müssen Sie das Pop-up-Modal bestätigen",
      "recreateMasterTaskSubTasks": "Erstellen Sie nach der Bestätigung alle Unteraufgaben der Hauptaufgabe dieser Aufgabe neu (Hauptaufgabe :: {n}).",
      "reference": {
        "after": {
          "earliestStepStart": "Beginn der ersten Be-/Entladung",
          "latestStepStop": "Ende der ersten Be-/Entladung",
          "minAdviceDate": "\"Ab\"-Datum in einem Datumsbereich für den Versand eines Lieferavis",
          "stepStart": "Beginn",
          "stepStop": "Ende",
          "transportCreated": "Transport erstellen"
        },
        "before": {
          "earliestStepStart": "Beginn der ersten Be-/Entladung",
          "latestStepStop": "Ende der ersten Be-/Entladung",
          "minAdviceDate": "\"Ab\"-Datum in einem Datumsbereich für den Versand eines Lieferavis",
          "stepStart": "Beginn",
          "stepStop": "Ende",
          "transportCreated": "Transport erstellen"
        }
      },
      "repeatSendApiMessage": "Benachrichtigung erneut senden, wenn ein Fehler auftritt",
      "requiresWarehouseLocation": "Lagerort zur Bestätigung des Status erforderlich",
      "saveSubTasks": "Alle Unteraufgaben speichern",
      "sendApiMessage": "Versand von API-Nachrichten",
      "sendDocumentDelayedEmail": "E-Mail-Benachrichtigung über die Verzögerung beim Hinzufügen von Dokumenten senden",
      "settingsLabel": "Einstellungen für den Schritt",
      "shouldBeApprovedWithAdvice": "Status nach Hinzufügen eines Lieferavis automatisch bestätigen",
      "shouldBeApprovedWithCFTypeEmailAdvice": "Status nach Hinzufügen von Transportdaten aus einem E-Mail-Lieferavis mit CF_DATA automatisch bestätigen.",
      "shouldBeApprovedWithTimeWindow": "Status nach Hinzufügen eines Zeitfensters automatisch bestätigen.",
      "shouldBeConfirmedBy": "müssen bestätigt werden durch",
      "shouldBeConfirmedByRelatedCheckpoint": "[DELIVERY] Gibt den Prüfpunkt an, der genehmigt wird, wenn der entsprechende Prüfpunkt mit dem Flag `shouldConfirmRelatedCheckpint` genehmigt wird.",
      "shouldBeSavedAsApproved": "Status beim Erstellen eines Auftrags automatisch bestätigen",
      "shouldConfirmRelatedCheckpoint": "[DELIVERY] Gibt einen Prüfpunkt an, der, wenn er genehmigt wird, auch den entsprechenden Prüfpunkt mit dem Flag `shouldBeConfirmedByRelatedCheckpoint` genehmigt",
      "shouldFindFreeSpotAfterDelay": "Zeitfenster bei Verzögerung verschieben",
      "shouldGenerateDocument": "Lieferschein automatisch erstellen",
      "shouldSendLpToMSHMessages": "Er definiert den Prüfpunkt, nach dem wir eine Nachricht an den MSH-Microservice senden",
      "shouldSendLpToSapMessages": "[DELIVERY] Er definiert den Prüfpunkt, nach dem wir eine Nachricht an den MSP-Microservice senden",
      "showPunctualityAlertAfterConfirmation": "Informationen über die Pünktlichkeit der Statusbestätigung anzeigen",
      "sign": "Vorher/Nachher",
      "stageName": "Bezeichnung des Status vor Bestätigung des Schritts DE",
      "stageNameEn": "Bezeichnung des Status vor Bestätigung des Schritts EN",
      "stepType": {
        "firstLoad": "erste Beladung",
        "firstUnload": "erste Entladung",
        "lastLoad": "letzte Beladung",
        "lastUnload": "letzte Entladung",
        "load": "Beladen",
        "placeholder": "Aktionstyp",
        "unload": "Entladen"
      },
      "taskIsNotSavedHint": "Diese Aufgabe wurde noch nicht gespeichert. Klicken Sie auf \"Speichern\", um die hinzugefügte Aufgabe zu bearbeiten.",
      "timeWindowCssClassName": "Zusätzliche css-Klasse für Zeitfenster",
      "tracked": {
        "disabled": {
          "label": "",
          "title": "Die voraussichtliche Frist für die Schrittbestätigung wird nur zur Veranschaulichung angezeigt. Wenn ein Schritt nicht rechtzeitig bestätigt wird, ändert sich die Farbe der Zeile oder des Zeitfensters nicht und es wird keine Benachrichtigung über die Verzögerung versendet. Klicken Sie auf das Symbol, um die Einstellungen zu ändern."
        },
        "enabled": {
          "label": "Überwachung der Pünktlichkeit",
          "title": "Wenn ein Schritt nicht rechtzeitig bestätigt wird, ändert sich der Status des Transports zu \"Verspätet\". Die Farbe der Zeile in der Tabelle und des Zeitfensters ändert sich zu Rot und es werden E-Mails mit einer Benachrichtigung über die Verspätung versendet. Klicken Sie auf das Symbol, um die Einstellungen zu ändern."
        }
      },
      "unit": "Einheit",
      "userHasToAddCheckpointConfirmationDate": "Statusbestätigungsdatum ist erforderlich",
      "visibleInDeliveryDocument": "Auf dem Lieferschein angeben",
      "weighingIncluded": "Wiegeschritt"
    },
    "tasks": {
      "empty": "Hier keine Aufgaben vorhanden."
    },
    "temporaryAccountAlert": {
      "daysLeft": "Verbleibende Tage bis zur Sperrung: {days}.",
      "limitReached": "Ihr Unternehmen hat das im Abonnement enthalte Benutzerlimit erreicht, daher ist dieses Konto nur vorübergehend verfügbar.",
      "pricingPlans": "Preisliste.",
      "title": "Benutzerlimit erreicht",
      "upgrade": "Wenn Sie die Anzahl der Benutzer erhöhen möchten, müssen Sie ein anderes Abonnement erwerben. Ausführliche Informationen finden Sie in unserer"
    },
    "terms-of-service": {
      "errors": {
        "unchecked": "Sie müssen den LEISTUNGSBEDINGUNGEN zustimmen, bevor Sie einen Verifizierungsantrag stellen können."
      },
      "label": {
        "acquiant": "Ich habe Folgendes zur Kenntnis genommen: ",
        "confirmed": "wurde am {confirmationDate} angenommen von {user}",
        "documents": "Leistungsbedingungen:"
      },
      "name": "LEISTUNGSBEDINGUNGEN",
      "shortName": "LB"
    },
    "time-window": {
      "details": {
        "carRegistrationNumber": "Lkw",
        "deliveryIncoterms": "Lieferbedingungen/Incoterms",
        "deliveryNumbers": "Liefernummern",
        "deliveryUnloadAddress": "Kundenname",
        "deliveryUnloadDate": "Lieferdatum",
        "erpIdentifier": "Auftragsnr.",
        "trailerRegistrationNumber": "Anhänger/Sattelauflieger"
      },
      "errors": {
        "start": {
          "isInPast": "Transporte dürfen nicht in der Vergangenheit liegen.",
          "outsideOfAdviceRange": "Dieses Datum kann nicht ausgewählt werden, da das Zeitfenster außerhalb der Zeitspanne für das Lieferavis liegt."
        },
        "stop": {
          "earlierThanStart": "Das Ende des Zeitfensters muss nach seinem Beginn liegen.",
          "outsideOfAdviceRange": "Dieses Datum kann nicht ausgewählt werden, da das Zeitfenster außerhalb der Zeitspanne für das Lieferavis liegt."
        }
      },
      "start": "Zeitangabe für Zeitfenster"
    },
    "timeWindow": {
      "cancelCreation": "Abbrechen (Position eines Zeitfensters anpassen)",
      "errors": {
        "start": {
          "notConsistentWithWarehouseOffset": "Der Beginn des Zeitfensters passt nicht zu den Lagereinstellungen. Optionen für den Beginn des Zeitfensters für die ausgewählte Stunde: {possibleWindowStarts}",
          "outsideWarehouseWorkHours": "Das ausgewählte Datum liegt außerhalb der Arbeitszeiten des Lagers: {workStart}-{workEnd}.",
          "startsEarlierThenPreviousWindowStops": "Der Beginn des Zeitfensters darf nicht vor dem Ende des vorherigen Zeitfensters im Transport liegen."
        },
        "stop": {
          "notConsistentWithWarehouseOffset": "Das Ende des Zeitfensters passt nicht zu den Lagereinstellungen. Optionen für das Ende des Zeitfensters für die ausgewählte Stunde: {possibleWindowStops}",
          "outsideWarehouseWorkHours": "Das ausgewählte Datum liegt außerhalb der Arbeitszeiten des Lagers: {workStart}-{workEnd}."
        },
        "time": {
          "outsideOfAdviceRange": "Dieses Zeitfenster kann hier nicht hinzugefügt werden, da es außerhalb der Zeitspanne für das Lieferavis liegen würde: {minAdviceDate} - {maxAdviceDate}."
        },
        "warehouseHasNoSpaceThisDay": "Am <date> sind keine Zeitfenster verfügbar. Bitte wählen Sie ein anderes Datum aus.",
        "wrongWarehouse": "Zeitfenster können nur für Lager festgelegt werden, die dem Transport zugewiesen sind."
      },
      "ramp": "Rampe",
      "start": "Beginn des Zeitfensters",
      "stop": "Ende des Zeitfensters"
    },
    "transaction": {
      "accept": "Annehmen",
      "autoPass": "Autom. überg.",
      "autoPassConfirmation": "Möchten Sie die automatische Übergabe von Transporten an nachfolgende Frachtführer wirklich aktivieren",
      "autoRouteOfferPassError": "Fehler bei der automatischen Auftragsübergabe",
      "carrier": "Frachtführer",
      "carrierMustAcceptTransport": "Übergabe erfordert Annahme durch den Frachtführer",
      "carrierMustAcceptTransportHint": {
        "checked": "Nach Übergabe des Auftrags kann der ausgewählte Frachtführer den Auftrag annehmen oder ablehnen. Der Frachtführer muss den Auftrag bestätigen, um diesen zu erhalten.",
        "unchecked": "Der ausgewählte Frachtführer erhält den Auftrag unmittelbar."
      },
      "chooseCarrier": "Frachtführer auswählen",
      "chooseCourier": "Kurier auswählen",
      "courier": "Kurier",
      "currency": "Währung",
      "dateCreated": "Erstellt am",
      "errors": {
        "expectedConfirmationDate": {
          "isInPast": "Das eingegebene Datum darf nicht in der Vergangenheit liegen."
        },
        "invoicePrice": {
          "wrongNumberFormat": "Der Preis muss eine Zahl sein."
        }
      },
      "expectedConfirmationDate": "Frist für die Bestätigung",
      "invoiceCurrency": "Rechnungswährung",
      "invoiceNumber": "Rechnungsnummer",
      "invoicePrice": "Rechnungspreis",
      "isRejected": "Abgelehnt",
      "label": "Transaktion",
      "notYetImplemented": "Diese Funktion wurde noch nicht implementiert.",
      "offer": "Angebot",
      "pass": "Übergeben",
      "price": "Preis",
      "rate": "Tarif",
      "reject": "Ablehnen",
      "rejectedByAllCarriers": "Kein Spediteur hat die Bestellung angenommen",
      "rejectedByCarrier": "Abgelehnt",
      "transport": "Transport",
      "updateDisabledInfo": {
        "route": "Der Tarif gilt für die Route {routeId} und kann daher nicht geändert werden."
      },
      "waitingForAcceptanceInfo": "Annahme ausstehend",
      "withInvoice": "mit Rechnung",
      "withoutInvoice": "ohne Rechnung"
    },
    "transactionSetting": {
      "description": {
        "canChooseCarrierFromTransportTable": "Legt fest, ob die Schaltfläche \"Übergeben\" in der Transporttabelle in der Transportzeile verfügbar ist (bei entsprechend festgelegten AuthorityRestriction-Optionen).",
        "carrierAutoAcceptsTransport": "Gibt an, ob ein Auftrag automatisch einem Beförderer zugewiesen werden soll, wenn ein übermittelter Auftrag nach Ablauf der Bestätigungsfrist nicht bestätigt oder abgelehnt wird.",
        "defaultCurrency": "Legt die auf dem Transportformular angegebene Standardwährung fest.",
        "defaultInvoiceCurrency": "Legt die auf dem Rechnungsformular angegebene Standardwährung fest.",
        "priceLabel": "Legt die Standardbeschriftung für den Preis fest.",
        "requireCarrierConfirmation": "Legt fest, ob eine Bestätigung erforderlich ist, wenn der Auftrag an den Frachtführer übergeben wird. Wenn die Kennzeichnung \"Übergabe erfordert Annahme durch den Frachtführer\" auf TRUE festgelegt ist, wird dadurch der Standardwert des Felds \"Übergabe erfordert Annahme durch den Frachtführer\" festgelegt.",
        "requiresReasonField": "Feld \"Grund\" erforderlich.",
        "showAndRequireReasonFieldIfPriceIsZero": "Sichtbarkeit und Verpflichtung des Feldes „Grund“, wenn im Feld „Fracht“ der Wert 0 eingetragen ist",
        "showCarrierConfirmationCheckbox": "Legt fest, ob das Kontrollkästchen im Überweisungsformular angezeigt werden soll: Feld \"Übergabe erfordert Annahme durch den Frachtführer\"",
        "showExpectedConfirmationDate": "Legt fest, ob das Kontrollkästchen im Überweisungsformular angezeigt werden soll: Feld \"Termin na potwierdzenie\"",
        "showPriceField": "Legt fest, ob das Kontrollkästchen im Überweisungsformular angezeigt werden soll: Feld \"Preis\"",
        "showPriceFieldForDeliveries": "Sichtbarkeit des Feldes „Fracht“ für jede Lieferung",
        "showReasonField": "Feld \"Grund\" anzeigen."
      },
      "label": {
        "canChooseCarrierFromTransportTable": "Schaltfläche \"Übergeben\" in der Transporttabelle",
        "carrierAutoAcceptsTransport": "Automatische Trägerzuweisung bei fehlender Bestätigung/Ablehnung",
        "defaultCurrency": "Standardwährung für Transaktionen",
        "defaultInvoiceCurrency": "Standardwährung für Rechnungen",
        "priceLabel": "Standardbeschriftung für Preis",
        "requireCarrierConfirmation": "Transaktionsbestätigung durch Frachtführer erforderlich",
        "requiresReasonField": "Feld \"Grund\" erforderlich.",
        "showAndRequireReasonFieldIfPriceIsZero": "Sichtbarkeit und Verpflichtung des Feldes „Grund“, wenn im Feld „Fracht“ der Wert 0 eingetragen ist",
        "showCarrierConfirmationCheckbox": "Feld \"Übergabe erfordert Annahme durch den Frachtführer\" anzeigen",
        "showExpectedConfirmationDate": "Feld \"Termin na potwierdzenie\" anzeigen",
        "showPriceField": "Feld \"Preis\" anzeigen",
        "showPriceFieldForDeliveries": "Sichtbarkeit des Feldes „Fracht“ für jede Lieferung",
        "showReasonField": "Feld \"Grund\" anzeigen."
      },
      "priceLabel": {
        "FREIGHT": "Fracht",
        "FREIGHT_FOR_CARRIER": "Fracht für den Spediteur",
        "PRICE": "Preis",
        "RATE": "Rate"
      },
      "saveSuccess": "Transaktionseinstellungen gespeichert"
    },
    "transport": {
      "CARTONS": "Kartons",
      "CHEP_PALLETS": "CHEP-Paletten",
      "DISPOSABLE_PALLETS": "Einwegpaletten",
      "Delivery": "Lieferung",
      "EURO_PALLETS": "EURO-Paletten",
      "LOADING_PLACES": "Beladeplätze",
      "PALLETS": "Paletten",
      "PALLET_PLACES": "Palettenplätze",
      "THE_NUMBER_OF_ITEMS": "Anzahl der Artikel",
      "accountExpired": "Konto aufgrund mangelnder Aktivität gesperrt",
      "actionType": "Aktionstyp",
      "addNewCompany": {
        "assignmentCompany": "Möchten Sie dieses Unternehmen als Transportunternehmer beauftragen?",
        "cooperatingCompanies": "Sie arbeiten bereits mit einem Unternehmen diesem Namen zusammen.",
        "emailInfo": "Eine Einladung zur Zusammenarbeit wird an die angegebene E-Mail-Adresse gesendet.",
        "emailRequired": "Es muss eine E-Mail-Adresse angegeben werden, damit eine Einladung zur Zusammenarbeit gesendet werden kann.",
        "existingCompany": "In der Datenbank ist bereits ein Unternehmen mit der ausgewählten Steuerzahleridentifikationsnummer vorhanden.",
        "existingCompanyAndPartnership": "Das Unternehmen mit der Steuerzahleridentifikationsnummer {taxIdNumber} ist bereits im System vorhanden und gehört zu anderen Mitarbeitenden.",
        "label": "Fügen Sie ein neues Unternehmen hinzu, das noch nicht im System vorhanden ist.",
        "sendInvitation": "Einladung zur Zusammenarbeit senden",
        "wrongEmail": "Die eingegebene E-Mail-Adresse ist nicht korrekt."
      },
      "additionalDescription": "Zusätzliche Anmerkungen",
      "advice": "Lieferavis",
      "altIdentifier": "Alternative Auftragsnummer",
      "amountOfCover": "Anzahl der Deckung",
      "archived": "Archiviert",
      "arrivalDate": "Abholdatum",
      "assetId": "Asset-ID",
      "authorityRange": "Zuständigkeitsbereich",
      "biggerVehicleRequired": "Erforderliche Verwendung eines größeren Fahrzeugs - ",
      "canNotEditAfterProcessStarts": "Nur die Daten eines von Ihnen erstellten Lieferavis können bearbeitet werden (nur vor der Ankunft eines Fahrers).",
      "canNotEditBecauseOfOtherContractors": "Die Daten eines Lieferavis von mehreren Auftragnehmern können nicht bearbeitet werden.",
      "carrier": {
        "label": "Frachtführer"
      },
      "carrierCompany": "Frachtführerunternehmen",
      "carrierCompanyChooser": "Person, die den Frachtführer ausgewählt hat",
      "carrierCompanySelectedBy": "Frachtführer ausgewählt von {companyName}",
      "combinedWeight": "Gesamtgewicht der Transporte: ",
      "company": "Lieferant",
      "company.autoCompletePlaceholder": "Beginnen Sie mit der Eingabe, um nach einem Unternehmen zu suchen.",
      "companyNotQualified": "Ihr Unternehmen erfüllt nicht die Bedingungen",
      "completed": "Abgeschlossen",
      "courier": {
        "dateInPast": "Das angegebene Abholdatum liegt in der Vergangenheit. Aktualisieren Sie das Abholdatum in den Auftragsdetails, um die Sendung an den Kurier weiterzuleiten.",
        "dhlMissingDocument": "Das ausgewählte Land/die ausgewählten Länder liegt/liegen außerhalb der EU und erfordert/erfordern PLT. Geben Sie im Abschnitt \"Warenrechnung\" des Transports das Rechnungsdatum sowie die Rechnungsnummer ein und fügen Sie die Rechnung im PDF-Format bei, um den Transport an DHL Express zu übergeben.",
        "dhlMissingExportDeclaration": "Das ausgewählte Land/die ausgewählten Länder liegen außerhalb der EU. Geben Sie im Abschnitt \"Warenrechnung\" des Transports das Rechnungsdatum und die Rechnungsnummer ein, um den Transport an DHL Express zu übergeben.",
        "label": "Kurier",
        "loadCity.label": "Beladeort:",
        "missingContactDetails": "Der Transport kann dem Kurier nicht übergeben werden, wenn Name, Telefonnummer oder E-Mail-Adresse der Kontaktperson in einem der Transportschritte fehlt.",
        "pickUpDate.label": "Abholdatum",
        "pickUpHour.label": "Abholuhrzeit",
        "service.label": "Dienstleistung",
        "unloadCity.label": "Entladeort:"
      },
      "courierInvoiceSection": {
        "button": "Rechnung hinzufügen",
        "date": "Rechnungsdatum",
        "file": "Angefügte Rechnung",
        "label": "Warenrechnung",
        "number": "Rechnungsnummer"
      },
      "courierSection": {
        "deliveryDate": "Lieferdatum",
        "goodsValue": "Warenwert",
        "goodsValueCurrency": "Währung",
        "incoterms": "Incoterms",
        "label": "Kuriere",
        "orderBy": "Auftrag von",
        "serviceType": "Dienstleistungstyp",
        "totalPrice": "Gesamtpreis"
      },
      "createSimilarMultiple": "Ähnliche Transporte erstellen",
      "createSimilarMultipleInProgress": "Kopie eines Transports in Bearbeitung. Das Fenster wird geschlossen, wenn alle Transporte erstellt werden.",
      "createSimilarMultiplePlaceholder": "Anzahl der Kopien",
      "createSimilarMultipleValue": "Anzahl der zu erstellenden Transporte",
      "createTimeWindow": "Zeitfenster erstellen",
      "createTitle": "Liefertermin planen",
      "createdAsCopy": "als Kopie des Auftrags",
      "createdBy": "Erstellt von",
      "createdFromRoute": "auf der Grundlage der Route",
      "createdMessage": "Erstellt am {date} von {person} ({company}).",
      "creator": "Ersteller",
      "dateCreated": "Datum der Erstellung",
      "dateOfAdvice": "Datum des Lieferavis",
      "dateOfArchiving": "Datum der Archivierung",
      "dateOfDeletion": "Datum der Löschung",
      "delete": "Transport löschen",
      "deleteTransport": "Möchten Sie diesen Transport wirklich löschen?",
      "departureDate": "Angefordertes Lieferdatum",
      "description": "Anmerkungen",
      "details": "Details zur Lieferung",
      "documentType": "Dokumenttyp",
      "documents": {
        "dragAndDrop": "Dokumente ziehen und ablegen oder anklicken",
        "empty": "Zu dieser Lieferung wurden noch keine Dokumente hinzugefügt.",
        "header": "Dokumente für Lieferung {identifier}",
        "label": "Dokumente"
      },
      "earliestTimeWindowStart": "Beginn des frühesten Zeitfensters",
      "edit": "Transport bearbeiten",
      "email": "E-Mail-Adresse",
      "erpIdentifier": "Auftragsnummer",
      "erpIdentifier.placeholder": "z. B. 110011",
      "errors": {
        "altIdentifier": {
          "regex": "Der angegebene Wert darf nicht mit dem regulären Ausdruck aus den Einstellungen übereinstimmen."
        },
        "carrierCompany": {
          "info": "Bei dieser Transportart muss das Frachtführerunternehmen angegeben werden. Legen Sie eine Partnerschaft an, wenn keine Unternehmen angezeigt werden."
        },
        "contractors": {
          "notUnique": "Geben Sie eine eindeutige Auftragnehmerliste an."
        },
        "dateOfAdvice": {
          "isInPast": "Es können keine Lieferungen geplant werden, die in der Vergangenheit liegen."
        },
        "erpIdentifier": {
          "minLength": "Eine ERP-Kennung muss aus mindestens 3 Zeichen bestehen."
        },
        "firstContractor": {
          "info": "Für diese Transportart muss das Unternehmen des Auftragnehmers angegeben werden. Legen Sie eine Partnerschaft an, wenn keine Unternehmen angezeigt werden."
        }
      },
      "firstSavedStart": "Erstes aufgezeichnetes Datum des Beginns des Zeitfensters",
      "forAcceptOrReject": " für die Annahme/Ablehnung in Auftrag gegebener Sendungen.",
      "forAuction": " für die Abgabe von Geboten in Auktionen.",
      "forProcess": " zum Hinzufügen einer Anmerkung in diesem Prozess.",
      "generateLOPReport": "Bericht mit der Liste der Punkte herunterladen",
      "getCourierQuotes": "Kurierangebote einholen",
      "goodsValue": "Warenwert",
      "goodsValueCurrency": "Währung",
      "group": "Gruppe der Lieferung",
      "history": "Auftragsverlauf",
      "incompleteLoading": "Beladen unvollständig",
      "incoterms": "Incoterms",
      "indexes": {
        "overwritePreviousIndexes": "Vorherige Indizes überschreiben",
        "pasteFromExcel": "Aus Excel einfügen"
      },
      "indicesTransportNumbers": {
        "label": "Auftragstyp"
      },
      "initialStart": "Erstes Startdatum des Zeitfensters",
      "isCombinedTransport": "Gemeinsamer Transport mit: ",
      "isRequiredFor": "Ist erforderlich für: ",
      "isWaitingFor": "Warten auf: ",
      "label": "Transport",
      "lastLoginDate": "Anmeldung beim System",
      "latestTimeWindowStop": "Ende des spätesten Zeitfensters",
      "load": "Beladen",
      "logs": {
        "apiIntegration": "Integration",
        "carrierCompany": "Frachtführerunternehmen",
        "dateOfArchiving": "Der Transport wurde archiviert.",
        "dateOfDeletion": "Der Transport wurde entfernt.",
        "initialStartInfo": "Das Zeitfenster wurde wie folgt zugewiesen: {initialDate} bis {startDate}",
        "label": "Änderungsverlauf",
        "name": "Datei",
        "ramp": "Rampe",
        "start": "Beginn des Zeitfensters",
        "stop": "Ende des Zeitfensters"
      },
      "maxSegmentLdm": "Maximale Anzahl von Lastmessern:",
      "maxSegmentWeight": "Maximale Nutzlast:",
      "mobilePhoneNumber": "Telefonnummer",
      "mobilePhonePrefix": "Telefonnummer – Vorwahl",
      "multiArchive": {
        "alert": "Sie haben keine Berechtigungen zum Archivieren von Transporten mit den folgenden Nummern: ",
        "confirm": "Sind Sie sicher, dass Sie folgende Transporte archivieren möchten: ",
        "error": "Unerwarteter Fehler beim Versuch, Transporte in großen Mengen zu archivieren. ",
        "label": "Archivierung von Gruppen"
      },
      "needTwoVehicles": "Es ist notwendig, die Bestellung auf zwei Fahrzeuge aufzuteilen",
      "noFilesForOldBrowsers": "In dieser Browseransicht können keine Dateien hinzugefügt werden. Fügen Sie auf Ebene der Tabelle eine Datei hinzu.",
      "optimalVehicleSelected": "Es wurde das optimale Fahrzeug ausgewählt",
      "option": "Ausgewählte Option",
      "otherRequirements": "Sonstige Anforderungen",
      "packs": {
        "boxes": "Kisten",
        "label": "Paket",
        "pallets": "Paletten",
        "pallets.abbr": "P."
      },
      "periodicTransport": "Periodisches Lieferavis",
      "periodicTransportInternalIndex": "Nummer des periodischen Lieferavis",
      "periodicTransportMessage": "Auftrag mit Nr. des periodischen Lieferavis {periodicTransportInternalIndex} verknüpft.",
      "print": "Ansicht drucken",
      "quantities": {
        "CARTONS": "Anzahl der Kisten",
        "CHEP_PALLETS": "Anzahl der CHEP-Paletten",
        "CUBIC_METERS": "Anzahl der Kubikmeter",
        "DISPOSABLE_PALLETS": "Anzahl der Einwegpaletten",
        "EURO_PALLETS": "Anzahl der EURO-Paletten",
        "LOADING_PLACES": "Anzahl der Beladeplätze",
        "PALLETS": "Anzahl der Paletten",
        "PALLETS_WITH_RETURNED_GOODS": "Anzahl der Paletten mit Retourware",
        "PALLET_PLACES": "Anzahl der Palettenplätze",
        "RETURNABLE_PALLETS": "Anzahl leerer Mehrwegpaletten",
        "SPATIAL_METERS": "Anzahl der Raummeter",
        "THE_NUMBER_OF_ITEMS": "Anzahl der Artikel",
        "shortName": {
          "PALLETS_WITH_RETURNED_GOODS": "Gibt zurück",
          "RETURNABLE_PALLETS": "Mehrwegpaletten"
        }
      },
      "quantity": "Anzahl der Paletten",
      "quantity.placeholder": "z. B. 33",
      "readyToPlan": "Bereit zur Planung",
      "recipient": "Empfänger",
      "relations": {
        "bind": "Verknüpfen",
        "combineClearingAlert": "Sind Sie sicher, dass Sie ausgewählte Transporte kombinieren möchten? Durch die Rückmeldung werden die Zeitfenster und Avisdaten für die ausgewählten Transporte gelöscht.",
        "combining": "\"Kombinierte Transporte\" (TRANSPORT_COMBINING)",
        "defaultLabel": "Sie können ausgewählte Transporte mit folgenden Verknüpfungsarten kombinieren: ",
        "deletedSuccessfully": "Transportverknüpfungen erfolgreich entfernt",
        "hasToWaitFor": "\"Transport wartet auf\" (HAS_TO_WAIT_FOR)",
        "mainTransport": "Haupttransport: ",
        "maxWeight": "Das maximale Gewicht ist: ",
        "noTypeForSelection": "Leider sind bei den ausgewählten Transporten keine Verknüpfungen möglich.",
        "relatedTransports": "Zugehörige Transporte: ",
        "removeRelation": "Verknüpfung löschen",
        "removingMaster": "Wenn Sie Verknüpfungen auf der Ebene des Haupttransports löschen, werden auch alle Verknüpfungen zugehöriger Transporte entfernt.",
        "title": "Transportverknüpfungen",
        "übergewichtig": "Transporte können wegen Überschreitung des Höchstgewichts nicht kombiniert werden"
      },
      "restoredSuccessfully": "Auftrag erfolgreich wiederhergestellt",
      "roleGroup": "Rolle des Benutzers",
      "routeOffer": "Routenangebot",
      "scheduledDateOfAdvice": "Geplantes Lieferavisdatum",
      "selectCountry": "Land",
      "selectSupplier": "Lieferant \\\\ Empfänger auswählen",
      "selectTransportType": "Liefertyp auswählen",
      "selectUnloadStartDate": "Min. Entladedatum",
      "selectUnloadStartDateTitle": "Wählen Sie das minimale Entladedatum aus.",
      "selectUnloadStopDate": "Max. Entladedatum",
      "selectUnloadStopDateTitle": "Wählen Sie das maximale Entladedatum aus.",
      "selectWarehouse": "Lager auswählen",
      "selectedCombinedTransportsWindows": "Den Transporten zugeordnete Fenster ",
      "selectedWindows": "Die dem Transport zugewiesenen Zeitfenster {transportIdentifier}",
      "smallerVehiclePossible": "Mögliche Verwendung eines kleineren Fahrzeugs - ",
      "stepType": "Schritttyp",
      "sureToSaveMessage": "Möchten Sie die Änderungen wirklich speichern? Einige der Felder wurden von einem anderen Benutzer geändert.",
      "task": "Aufgabe",
      "title": "Transport {ident}",
      "tooManyCopies": "Sie können max. {maxValue} Kopien von Transporten gleichzeitig erstellen.",
      "transportSupervisor": {
        "autoCompletePlaceholder": "Beginnen Sie mit der Eingabe, um nach einem Ansprechpartner zu suchen.",
        "label": "Ansprechpartner"
      },
      "transportType": "Transportart",
      "truck": "Verkehrsträger",
      "truckType": "Transportart",
      "type": {
        "ALL": "Alle",
        "COLLECTION": "Abholung",
        "DELIVERY": "Lieferung",
        "SERVICE": "Kurier, Dienstleistung usw.",
        "SIMPLIFIED_DELIVERY": "Vereinfachte Lieferung",
        "TRANSFER_BETWEEN_WAREHOUSES": "Transfer zwischen Lagern",
        "TRANSPORT": "Transport"
      },
      "typeOfService": "Dienstleistung des Kuriers",
      "unload": "Entladen",
      "value": "Wert",
      "volume": "Volumen",
      "warehouse": "Lager",
      "wasMovedAutomatically": "Zeitfenster automatisch verschoben",
      "weight": "Gewicht",
      "weight.placeholder": "z. B. 23",
      "weightSection": {
        "downloadWeightReceipt": "Beleg über das Wiegen herunterladen",
        "entryWeight": "Wiegen 1",
        "errors": {
          "cannotGetWeight": "Das Gewicht kann leider nicht heruntergeladen werden. Bitte versuchen Sie es später erneut. Wenden Sie sich an den Systemadministrator, wenn das Problem weiterhin auftritt.",
          "trySaveCheckpointWithoutWeight": "An diesem Kontrollpunkt muss gewogen werden. Das Gewicht kann leider nicht heruntergeladen werden. Möchten Sie den Kontrollpunkt mit einem Gewicht von Gewicht 0 bestätigen?"
        },
        "exitWeight": "Wiegen 2",
        "label": "Gewicht",
        "netWeight": "Nettogewicht",
        "notSynchronized": "Gewicht nicht mit Server synchronisiert",
        "overweightMessage": "Das beladene Fahrzeug überschreitet das Höchstgewicht. Dieses Fahrzeug darf nicht beladen werden.",
        "overweightNote": "Das Fahrzeug überschreitet die festgelegte Gewichtsgrenze.",
        "unit": "kg",
        "weighAgain": "Erneut wiegen",
        "weight": "Gewicht"
      },
      "wzDocument": "Lieferscheinnummer",
      "wzNumber": "Lieferscheinnummer"
    },
    "transport-type": {
      "errors": {
        "defaultLoadStepEndHour": {
          "regex": "Beim angegebenen Wert muss es sich um eine Zeitangabe im Format HH:SS handeln."
        },
        "defaultLoadStepStartHour": {
          "regex": "Beim angegebenen Wert muss es sich um eine Zeitangabe im Format HH:SS handeln."
        }
      }
    },
    "transportPanel": {
      "ADVICE": "Lieferavis",
      "DISCREPANCY_REPORT": "Abweichungsbericht",
      "DOCUMENTS": "Dokumente",
      "LOGS": "Änderungsverlauf",
      "WEIGHT": "Gewicht",
      "selectPlaceholder": "Panel auswählen"
    },
    "transportSupervisorTranslationKeys": {
      "CONTACT_PERSON": "Ansprechpartner",
      "HOST_PERSON": "Empfänger",
      "INVITEE": "Einladend",
      "ORDERER": "Besteller",
      "PERSON_TO_NOTIFY": "Zu benachrichtigende Person"
    },
    "transportsToExcel": {
      "XLSGenerateMessage": "Der Auftragsbericht wird generiert und an die E-Mail-Adresse der Person gesendet, die den Bericht erstellt hat.",
      "activeArchiveType": "Laufende/archivierte Aufträge",
      "advancedExportType": "Erweitert",
      "advancedExportTypeDescription": "Die erweiterte Version besteht aus zwei Blättern. Das erste Blatt enthält dieselben Daten wie in der einfachen Version, mit Ausnahme der Informationen zum Transportstatus. Das zweite Blatt enthält alle Informationen in Zusammenhang mit Statusbestätigungen.",
      "adviceReportLabel": "Diese Version enthält einen Lieferavisbericht. Der Bericht ist für die folgenden Typen verfügbar: ",
      "adviceReportLabelEmpty": "Keine Transportart verfügbar",
      "adviceReportType": "Lieferavisbericht",
      "alsoExportActiveOrders": "Auch laufende Aufträge exportieren",
      "basicExportType": "Einfach",
      "basicExportTypeDescription": "Die einfache Version enthält alle Daten auf einem Blatt.",
      "billingExportType": "Abrechungsbericht",
      "billingExportTypeDescription": "Diese Version enthält Abrechnungsdaten.",
      "errors": {
        "XLSGenerateEmailErrorMessage": "Der Auftragsbericht wird an die E-Mail-Adresse der Person gesendet, die den Bericht erstellt. Bitte geben Sie die E-Mail-Adresse auf der Registerkarte \"Mein Konto\" ein.",
        "XLSGenerateErrorMessage": "Fehler bei der Excel-Berichterstellung. Versuchen Sie es in einigen Minuten erneut oder wenden Sie sich an info@logintegra.com."
      },
      "export": "Exportieren",
      "exportType": "Dateiversion",
      "noTypeChosen": "Wählen Sie mindestens eine Transportart aus.",
      "ownColumnExportType": "Tabellenspalten",
      "ownColumnExportTypeDescription": "Die Version mit Tabellenspalten enthält nur die in der Transporttabelle angezeigten Informationen.",
      "title": "Excel-Dateiparameter",
      "transportTypes": "Auftragsarten"
    },
    "truck": {
      "addTruck": "Lkw hinzufügen",
      "description": "Beschreibung",
      "disableConfirm": "Möchten Sie diesen Lkw wirklich entfernen?",
      "errors": {
        "registrationNumber": {
          "regex": "Das Kennzeichen muss zwischen 4 und 50 Zeichen lang sein und darf nur Buchstaben, Zahlen und die Zeichen . , ; / \\\\ & _ enthalten."
        },
        "trailer": {
          "regex": "Das Kennzeichen muss zwischen 4 und 50 Zeichen lang sein und darf nur Buchstaben, Zahlen und die Zeichen . , ; / \\\\ & _ enthalten."
        }
      },
      "hds": "HDS",
      "label": "Lkw",
      "lastUpdated": "Zuletzt aktualisiert",
      "registrationNumber": "Registrierungsnummer",
      "timeWindow": "Zeitfenster",
      "trailer": "Anhänger/Sattelauflieger",
      "truckType": "Typ"
    },
    "truckType": {
      "errors": {
        "truckTypeName": {
          "length": "Wprowadzony tekst jest zbyt długi. Dopuszczalna długość to 255 znaków."
        },
        "truckTypeNameEn": {
          "length": "Wprowadzony tekst jest zbyt długi. Dopuszczalna długość to 255 znaków."
        }
      },
      "form": {
        "height": "Innere Höhe [m]",
        "idx": "Idx",
        "length": "Innere Länge [m]",
        "maxLoad": "Maximale Tragfähigkeit [kg]",
        "name": "Lkw-Typ",
        "nameEn": "Lkw-Typ - EN",
        "title": {
          "add": "Neuen Lkw-Typ hinzufügen",
          "edit": "Lkw-Typ bearbeiten"
        },
        "transportTypes": "Transportarten, für die der LKW-Typ verfügbar sein wird",
        "width": "Innere Breite [m]"
      },
      "settingButtonLabel": "Lkw-Typen",
      "table": {
        "height": "Innere Höhe [m]",
        "length": "Innere Länge [m]",
        "maxLoad": "Maximale Tragfähigkeit [kg]",
        "name": "Lkw-Typ",
        "width": "Innere Breite [m]"
      }
    },
    "trucks": {
      "label": "Lkw"
    },
    "unit": {
      "KG": "kg",
      "LITERS": "Liter",
      "PIECES": "Stück",
      "label": "Einheit",
      "placeholder": "Einheit auswählen"
    },
    "user": {
      "accountCreatedBy": "Konto erstellt von",
      "accountUpdated": "Die Änderungen an den Kontoeinstellungen wurden gespeichert.",
      "activeNotifications": "Aktive Benachrichtigungen",
      "availableQueues": "Verfügbare Warteschlangen",
      "availableRamps": "Verfügbare Rampen",
      "availableWarehouses": "Verfügbare Lager",
      "contractorGroupName": "Gruppe",
      "createUser": "Benutzer erstellen",
      "deleteConfirm": "Möchten Sie diesen Benutzer wirklich löschen?",
      "deletedMessage": "Gelöscht am {date} von {person} vom Unternehmen {company}.",
      "deletedUser": "Der Benutzer wurde entfernt.",
      "disableConfirm": "Möchten Sie diesen Benutzer wirklich deaktivieren?",
      "disabledUser": "Der Benutzer wurde deaktiviert.",
      "editUser": "Benutzer bearbeiten",
      "email": "E-Mail-Adresse",
      "groupName": "Abteilung",
      "hasRoleAccessAllRamp": "Administratoren verfügen über die Berechtigung \"Zugriff auf alle Rampen/Zeitfenster im Zeitplan\". Der Benutzer hat Zugriff auf alle Rampen, unabhängig von der Auswahl der in dieser Ansicht verfügbaren Rampen.",
      "invitedBy": "Eingeladen: {date} von {person}.",
      "lastLoginDate": "Datum der letzten Anmeldung",
      "ldapUser": "Benutzer für LDAP-Integration",
      "limitedDataHint": "Die Anzeige und Bearbeitung bestimmter Details des Benutzers {user} ist eingeschränkt. Änderungen sind nur durch Administratoren des Unternehmens {company} möglich.",
      "list": "Liste der Benutzer",
      "localAdmin": "Lokaler Administrator",
      "mobilePhoneNumber": "Telefonnummer",
      "myAccount": "Mein Konto",
      "name": "Vor- und Nachname",
      "noActiveNotifications": "Keine aktiven Benachrichtigungen",
      "noAvailableRamps": "Keine verfügbaren Rampen",
      "registrationDate": "Datum der Registrierung",
      "roleDescription": "Beschreibung der Rolle",
      "selectRole": "Rolle auswählen",
      "status": {
        "ACTIVE": "Aktiv",
        "DELETED": "Gelöscht",
        "EXPIRED": "Inaktiv",
        "TEMPORARY": "Temporär",
        "label": "Benutzerstatus"
      },
      "temporaryAccountDaysLeft": "Tage bis zur Sperrung",
      "userGroup": {
        "allTransports": "Alle Transporte",
        "myGroupsTransports": "Transporte meiner Gruppe",
        "myTransports": "Meine Transporte"
      },
      "username": "Benutzername"
    },
    "userGroups": {
      "canSeeOtherGroupsTransports": "Zugriff auf Transporte anderer Gruppen",
      "identifier": "ID",
      "label": "Benutzergruppen",
      "name": "Bezeichnung"
    },
    "users": {
      "activity": "Aktivität",
      "data": "Benutzerdaten",
      "label": "Benutzer",
      "notifications": "Benachrichtigungen",
      "queues": "Zugriff auf Warteschlangen",
      "ramps": "Zugriff auf Rampen/Zeitfenster"
    },
    "warehouse": {
      "addRamp": "Rampe hinzufügen",
      "adviceHasBeenSavedInfo": "Lieferavis wurde für folgendes Uhrzeit gespeichert: ",
      "autoCompletePlaceholder": "Beginnen Sie mit der Eingabe, um nach dem Lager, der Straße usw. zu suchen.",
      "backToDeliveries": "Tabelle der Lieferungen",
      "configureAlgorithmAfterSave": "Der Algorithmus zur Berechnung der Länge von Zeitfenstern kann nach dem Speichern des Lagers festgelegt werden.",
      "configureAssortmentGroupsAfterSave": "Die Sortimentsgruppen können nach dem Speichern des Lagers festgelegt werden.",
      "confirmDeleteDedicateWindow": "Möchten Sie das fest zugewiesene Zeitfenster für das Unternehmen {company} wirklich entfernen?",
      "create": "Lager erstellen",
      "createRampsAfterSave": "Die Lagerbereiche, -orte und -rampen können nach dem Speichern des Lagers konfiguriert werden.",
      "createTransportInfo": "Wählen Sie einen freien Termin im Zeitplan aus, um eine Transportart zu erstellen: {transportTypeName}.",
      "disableConfirm": "Möchten Sie dieses Lager wirklich deaktivieren?",
      "disabledMessage": "Das Lager \"{warehouseName}\" wurde gelöscht.",
      "enableConfirm": "Möchten Sie dieses Lager wirklich aktivieren?",
      "errors": {
        "idx": {
          "invalidIdxNumber": "Die ID-Nummer des Lagers muss im folgenden Bereich liegen: 0 bis {numberOfWarehouses}"
        },
        "snapToClosestWindowInterval": {
          "info": "Geben Sie den maximalen Abstand zwischen den Rändern für die automatische Zuordnung von Zeitfenstern ein. Mögliche Werte sind 0, 15, 30 und 60."
        },
        "timeWindowOffsetInterval": {
          "info": "Geben Sie die Anzahl der Minuten ein, um die die Zeitfenster verschoben werden können."
        },
        "windowConstantTime": {
          "info": "Geben Sie einen konstanten Wert in Minuten ein, der zur Dauer der Entladung in den Zeitfenstern addiert wird."
        },
        "workEndHour": {
          "endBeforeStart": "Die Schließzeit des Lagers muss nach der Öffnungszeit liegen.",
          "invalidNumber": "Wählen Sie eine Zahl zwischen 1 und 24 aus."
        },
        "workStartHour": {
          "invalidNumber": "Wählen Sie eine Zahl zwischen 0 und 23 aus."
        }
      },
      "hdOff": "HD deaktivieren",
      "hint": "Klicken Sie auf eine leere Zelle in der Tabelle, um den Zeitpunkt der Lieferung auszuwählen.",
      "idx": "Die ID-Nummer des Lagers.",
      "indexesDetailsTitle": "Klicken, um Indizes anzuzeigen",
      "integrationCode": "Lagercode für die MSP-Integration",
      "label": "Lager",
      "numberOfRamps": "Anzahl der Rampen",
      "reserveFreePlace": "Reservieren Sie einen freien Termin im Zeitplan für {initialStart}",
      "selectCompany": "Erstellen Sie nach dem Klicken auf eine leere Zelle ein spezifisches Zeitfenster für das Unternehmen.",
      "selectCountry": "Land auswählen",
      "selectDepartment": "oder wählen Sie die Abteilung aus",
      "selectNewPlaceForWindow": "Wählen Sie einen neuen Ort für das ausgewählte Zeitfenster aus.",
      "selectWindowInfo": "Wählen Sie einen freien Termin im Zeitplan aus und fügen Sie ein Lieferavis zu Transport {identifier} hinzu.",
      "selectWindowWarning": "Ein Lieveravis kann spätestens zum {date} geplant werden.",
      "selectedWindowsCombinedTransports": "Wählen Sie einen freien Platz im Fahrplan, um einen Hinweis für kombinierte Transporte hinzuzufügen",
      "shouldFindFreeSpotAfterDelay": "Übertragung des Zeitfensters im Falle einer Verspätung auf der angegebenen Etappe",
      "slider": {
        "title": "Größe der Zeilen im Zeitplan ändern"
      },
      "snapToClosestWindowInterval": "Maximaler Abstand zwischen den Kanten für das automatische Zuordnen des Zeitfensters. Nur ein Zeitfenster wird zugeordnet -> das als neues Zeitfenster platzierte.",
      "sumOfPallets": "Summe der Paletten",
      "templates": {
        "copyFrom": "Vorlagen aus Tag kopieren",
        "datesWithErrorMessage": "Anmerkung: Aufgrund der Zeitumstellung konnten einige Zeitfenster für diese Tage nicht gespeichert werden: {windows}.",
        "header": "Vorlagen von Lagerzeitfenstern",
        "label": "Vorlagen",
        "saveFor": "Vorlage speichern für",
        "savedMessage": "Die Vorlage wurde gespeichert."
      },
      "timeWindowDefaultLengthInMinutes": "Die Standarddauer der Zeitfenster in Minuten",
      "timeWindowLengthLongerThanWarehouseWorkingHours": "Das Zeitfenster kann länger sein als die Arbeitszeiten des Lagers",
      "timeWindowMustOccupyEntireRamp": "Das Zeitfenster muss an dem gewählten Tag die gesamte Rampe belegen.",
      "timeWindowMustOccupyEntireRampDisclaimer": "Das Zeitfenster in diesem Lager belegt die gesamte Rampe.",
      "timeWindowOffsetInterval": "Anzahl der Minuten, um die die Zeitfenster verschoben werden können",
      "timeWindowSummaryInfo": "Wählen Sie zum Hinzufügen einen Termin im Zeitplan aus.",
      "timeWindowSummaryInfoSubtitle": "Für den Transport müssen {timeWindowsNumber} Zeitfenster hinzugefügt werden.",
      "transportTypes": "Transportarten, für die ein Lager verfügbar ist",
      "updateSuccessMessage": "Die Einstellungen für das Lager {warehouseName} wurden aktualisiert.",
      "warehouseLocationAdd": "Lagerstandort hinzufügen",
      "warehouseLocationLabel": "Lagerstandort",
      "warehouseLocationLabelPlural": "Lagerstandorte",
      "warehouseLocationNewLocation": "Neuer Lagerstandort",
      "warehouseLocationNextLocation": "Umzug an einen anderen Lagerstandort?",
      "weighingIncluded": "Das Wiegen erfolgt in diesem Lager.",
      "windowConstantTime": "Konstanter Wert in Minuten, der zur Dauer der Entladung in den Zeitfenstern addiert wird.",
      "windowIsLoad": "L",
      "windowIsUnload": "U",
      "windowNoteLabel": "Inhalt der Anmerkung",
      "windowNoteRemoveWarning": "Möchten Sie das Zeitfenster mit folgender Anmerkung löschen: {note}?",
      "windowNoteTitle": "Anmerkung eingeben",
      "windowRemoveWarning": "Möchten Sie das Sperrzeitfenster wirklich entfernen?",
      "workEndHour": "Schließzeit",
      "workStartHour": "Öffnungszeit",
      "workTime": "Arbeitszeiten des Lagers",
      "youAreEditingThisWindow": "Sie bearbeiten dieses Zeitfenster.",
      "youAreInWarehouse": "Sie befinden sich in einem Lager.",
      "youWereMovedToWarehouse": "Sie wurden zu einem Lager beordert."
    },
    "warehouse-zone": {
      "errors": {}
    },
    "warehouseZone": {
      "addZone": "Lagerzone hinzufügen",
      "algorithmSettings": "Algorithmus zur Berechnung eines Zeitfensters",
      "deliveryDefaultZone": "[DELIVERY] Standardzone, zu der Transportmittel geleitet werden, die keinem Muster entsprechen",
      "deliveryModeOfTransport": "[DELIVERY] Transportmittel, die in diese durch ein Semikolon getrennte Lagerzone geleitet werden, z.B.: Plandeka Wysoka;Plandeka standard;Plandeka standard EXW",
      "disableConfirm": "Möchten Sie diese Zone wirklich deaktivieren?",
      "englishName": "Englische Zonenbezeichnung",
      "errors": {},
      "hint": "{warehouseZoneLabel}: kann geändert werden, indem Sie das Zeitfenster im Zeitplan verschieben.",
      "idx": "Die Reihenfolge der Zone",
      "label": "Lagerzone",
      "noTransportTypesAssigned": "Keine zugeordneten Lagerzonen. In den Lagerzonendetails können der Lagerzone Transportarten zugeordnet werden.",
      "noTransportTypesSelected": "Sind Sie sicher, dass der Lagerzone keine Transportart zugewiesen werden soll? Für diese Zone können keine Lieferavise hinzugefügt werden.",
      "selectWarehouse": "{warehouseZoneLabel}: Wählen Sie zunächst Folgendes aus: {warehouseLabel}",
      "settings": "Zoneneinstellungen",
      "transportTypes": "Transportarten, für die die Lagerzone verfügbar ist",
      "updateSuccessMessage": "Die Einstellungen der Zone wurden geändert."
    },
    "warehouses": {
      "label": "Lager"
    },
    "weightLog": {
      "ENTRY_WEIGHT": "Wiegen bei Einfahrt",
      "ENTRY_WEIGHT_AGAIN": "Wiegen bei Einfahrt (nachwiegen)",
      "EXIT_WEIGHT": "Wiegen bei Ausfahrt",
      "EXIT_WEIGHT_AGAIN": "Wiegen bei Ausfahrt (nachwiegen)"
    },
    "worker": {
      "GUESTS": "Gäste",
      "SERVICEMEN": "Servicemitarbeiter",
      "WORKERS": "Mitarbeiter",
      "addGuest": "Neuen Gast hinzufügen",
      "addServiceman": "Neuen Servicemitarbeiter hinzufügen",
      "addWorker": "Neuen Arbeiter hinzufügen",
      "autoCompletePlaceholder": "Schreiben Sie, um einen Mitarbeiter zu finden",
      "chooseWorkers": "Mitarbeiter auswählen",
      "deleteWorkerMsg": "Sind Sie sicher, dass Sie diesen Arbeiter löschen wollen?",
      "editGuest": "Mitarbeiter bearbeiten",
      "editServiceman": "Servicemitarbeiter bearbeiten",
      "editWorker": "Mitarbeiter bearbeiten",
      "errors": {
        "phoneNumber": {
          "regex": "Die Nummer muss 9 bis 12 Stellen aufweisen."
        }
      },
      "name": "Vor- und Nachname",
      "phoneNumber": {
        "GUESTS": "Telefonnummer des Gastes",
        "SERVICEMEN": "Telefonnummer des Servicetechnikers",
        "WORKERS": "Telefonnummer des Mitarbeiters"
      },
      "trained": "trainiert",
      "workersList": "WorkersList"
    },
    "workflows": {
      "addSubTask": "Hinzufügen einer Unteraufgabe",
      "addTask": "Aufgabe hinzufügen",
      "finalStageNamesLabel": "Nach Bestätigung aller Schritte wird der Status geändert zu:",
      "label": "Workflow (Schritt- und Statuseinstellungen)",
      "noTasks": "Diesem Teil des Prozesses wurde noch keine Aufgabe zugewiesen.",
      "saveSuccessMessage": "Die Einstellungen für Schritt und Status (Workflow) wurden gespeichert.",
      "updateDisclaimer": "Anmerkung: Die Änderungen bezüglich der Zeit gelten nur für NEUE Transporte."
    },
    "wrongUrl": "Kein Zugriff oder angeforderte Seite nicht vorhanden"
  };
  _exports.default = _default;
});