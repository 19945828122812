define("apollo/pods/components/transport/delivery-section/delivery-additional-data-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    authorityCheckerService: Ember.inject.service('authority.authority-checker-service'),
    transportTypeId: Ember.computed('delivery.transportType.id', function () {
      return this.get('delivery.transportType.id');
    }),
    showPackagingsSections: Ember.computed('transportTypeId', function () {
      return this.authorityCheckerService.findPositiveAuthorityRestrictionsByActionType('ROLE_ACCESS_DELIVERY_PACKAGING_SECTION', this.get('transportTypeId'));
    }),
    autoExpanded: Ember.computed('', function () {
      return this.get('showPackagingsSections') && this.get('delivery.packaging.length') > 0;
    }),
    currencies: Ember.computed('', function () {
      return this.get('store').peekAll('currency');
    }),
    delivery: Ember.computed('model.delivery', function () {
      return this.get('model.delivery');
    }),
    packaging: Ember.computed('delivery.packaging.[]', function () {
      return this.get('delivery.packaging');
    }),
    sortedCustomFields: Ember.computed('delivery.deliveryCustomFieldValues.{[],defnition.idx}', function () {
      return this.get('delivery.deliveryCustomFieldValues').sortBy('definition.idx');
    })
  });

  _exports.default = _default;
});