define("apollo/translations/it", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "CARRIER": {
      "label": "Vettore",
      "panel": {
        "label": "Scheda vettore"
      }
    },
    "CLIENT": {
      "panel": {
        "label": "Scheda cliente"
      }
    },
    "COMPANY": {
      "panel": {
        "label": "Scheda azienda"
      }
    },
    "OPERATOR": {
      "label": "Operatore",
      "panel": {
        "label": "Scheda operatore"
      }
    },
    "SERVICE": {
      "panel": {
        "label": "Scheda servizio"
      }
    },
    "SERVICEMAN": {
      "panel": {
        "label": "Scheda tecnico dell’assistenza"
      }
    },
    "SUPPLIER": {
      "label": "Fornitore",
      "panel": {
        "label": "Scheda fornitore"
      }
    },
    "Tipo-gruppo-appaltatore": {
      "ADR": "Divisione basata sul trasporto di merci e carichi pericolosi",
      "AVAILABLE_VEHICLES": "Divisione basata sui veicoli disponibili",
      "PRIVATE": "Gruppi privati, non visibili a un appaltatore",
      "SUPPORTED_REGIONS": "Divisione basata sulle regioni supportate",
      "SUPPORTED_VOIVODESHIPS": "Divisione basata sul voivodato supportato",
      "TERRITORIAL_DIVISION": "Divisione territoriale",
      "TYPES_OF_BUSINESS": "Divisione basata su un tipo di attività aziendale",
      "TYPES_OF_TRANSPORT": "Divisione basata sui tipi di trasporto supportati",
      "errors": {
        "canSelectOnlyOneContractorsGroup": "Selezionare solo un gruppo",
        "requiresContractorsGroup": "Selezionare almeno un gruppo dalla sezione “{groupType}”"
      }
    },
    "Valore-campo-personalizzato": {
      "autoCompletePlaceholder": "Per cercare un’opzione inizia a digitare.",
      "errors": {
        "maxValue": {
          "value": "Il valore massimo è {minValue}"
        },
        "minValue": {
          "value": "Il valore minimo è {minValue}"
        },
        "value": {
          "notProperValueType": "Tipo di valore errato, è necessario un numero"
        }
      }
    },
    "Zona-magazzino": {
      "errors": {}
    },
    "address": {
      "addNewAddress": "Aggiungi un nuovo indirizzo",
      "addressUnloadingTime": {
        "daysToUnload": "Giorni scarico",
        "friday": "Venerdì",
        "label": "Orario scarico",
        "monday": "Lunedì",
        "saturday": "Sabato",
        "sunday": "Domenica",
        "thursday": "Giovedì",
        "tuesday": "Martedì",
        "wednesday": "Mercoledì"
      },
      "autoCompletePlaceholder": "Inizia a digitare per cercare una città, via, ecc.",
      "chooseCompanyFirst": "Seleziona prima un’azienda",
      "city": "Città",
      "country": "Paese",
      "deleteAddress": "Eliminare questo indirizzo?",
      "errors": {},
      "label": "Indirizzo",
      "newAddress": "Nuovo indirizzo",
      "postal": "Codice postale",
      "province": "Provincia",
      "recipient": "Destinatario",
      "removeAddress": "Rimuovi un indirizzo",
      "street": "Via",
      "voivodeship": "Voivodato"
    },
    "administration": {
      "newPrincipal": {
        "aliasHint": "L’alias deve essere una parola (preferibilmente il nome'del cliente), scritta in minuscolo (ad es. logintegra)",
        "baseData": "Dati azienda base",
        "companyAddress": "Sede principale",
        "create": "Crea nuovo principale",
        "creation": "Creazione nuova azienda principale",
        "successMsg": "La nuova azienda principale è stata salvata correttamente. Puoi accedere al tuo account: {username}",
        "userData": "Dati utente (SUPER_ADMIN)"
      }
    },
    "advice": {
      "add": "Aggiungi un avviso",
      "carRegistrationNumber.placeholder": "Ad es. GDA12345",
      "carRegistrationNumberLabel": {
        "TRUCK": "Targa camion",
        "VEHICLE": "Targa veicolo"
      },
      "carRegistrationNumberSimple": "Numero di targa",
      "creator": "Il creatore di un avviso",
      "dateCreated": "Creato alle",
      "documentNumber": "Autista'- numero documento",
      "documentNumber.placeholder": "ad es. 78646003",
      "documentType": {
        "ID_CARD": "N. documento d’identità",
        "PASSPORT": "Passaporto"
      },
      "driverId": "Autista' - numero documento identità",
      "driverLanguage": "Autista'- lingua",
      "driverMobilePhoneNumber": "Numero di cellulare",
      "driverMobilePhonePrefix": "Autista' - prefisso numero di cellulare",
      "driverName": "Nome e cognome autista",
      "driverName.placeholder": "Ad es. Jan Kowalski",
      "errors": {
        "carRegistrationNumber": {
          "regex": "Il numero di targa deve essere composto da 4 a 50 caratteri e può contenere solo lettere, numeri e caratteri. , ; / \\\\ & _"
        },
        "driverMobilePhoneNumber": {
          "regex": "Il numero di telefono in Italia deve contenere dieci cifre"
        },
        "rodoAccepted": {
          "notSelected": "I dati devono essere trattati legalmente"
        },
        "saveAsResource": {
          "info": "Per salvare l’autista nell’elenco delle risorse, è necessario compilare i campi obbligatori nella sezione avviso."
        },
        "termsOfConditionsAccepted": {
          "notSelected": "Per creare un avviso devi accettare i termini e le condizioni"
        },
        "trailerRegistrationNumber": {
          "regex": "Il numero di targa di un rimorchio/semirimorchio deve essere composto da 4 a 50 caratteri e può contenere solo lettere, numeri e caratteri. , ; / \\\\ & _"
        }
      },
      "label": "Avviso",
      "pasteFromExcel": "Incolla dati avviso",
      "rodo": "I dati inseriti nel modulo possono essere legalmente trattati da Logintegra sp. z o.o.",
      "saveAsResource": "Salvare i dati dell’autista e del veicolo nell’elenco delle risorse",
      "termsOfConditionsAccepted": "Accetto ",
      "trailerRegistrationNumber": "Targa rimorchio/semirimorchio",
      "trailerRegistrationNumber.placeholder": "Ad es. GDA6789",
      "transport": "Trasporto"
    },
    "afterRegistration": {
      "addWarehouseButton": "Aggiungi un magazzino",
      "info": "Apri il tuo magazzino e invita i tuoi colleghi a pianificare trasporti e avvisi.",
      "invitationButton": "Elenco inviti",
      "label": "Benvenuto!",
      "title": "Questa è la scelta giusta per poter dire addio alle code!"
    },
    "algorithmSettings": {
      "deleteSetting": "Eliminare questa impostazione?",
      "deletionSuccessMessage": "Impostazioni algoritmo per {n} pallet eliminate correttamente",
      "label": "Impostazioni dell’algoritmo per il calcolo della dimensione dello slot temporale",
      "loadTimeInMinutes": "Tempo di carico in minuti",
      "numberOfPallets": "Numero di pallets (limite superiore)",
      "saveSuccessMessage": "Impostazioni algoritmo salvate correttamente."
    },
    "announcement": {
      "errors": {
        "companies": {
          "chooseAtLeastOneCompany": "Scegli almeno un’azienda"
        },
        "roleGroups": {
          "chooseAtLeastOneRoleGroup": "Scegli almeno un gruppo ruolo"
        }
      }
    },
    "announcements": {
      "add": "Aggiungi un annuncio",
      "announcementInEnglish": "Annuncio in inglese",
      "companies": "Le aziende che visualizzeranno l’annuncio:",
      "content": "Contenuto messaggio",
      "contentEn": "Contenuto messaggio (EN)",
      "dateCreated": "Data di creazione",
      "dateFrom": "Data scadenza da",
      "dateTo": "Data scadenza a",
      "delete": "Eliminare questo annuncio?",
      "edit": "Modifica un annuncio",
      "expirationDates": "Date di scadenza",
      "label": "Comunicazioni",
      "lastEditor": "Ultima modifica eseguita da",
      "lastUpdated": "Ultima modifica",
      "manage": "Gestisci annunci",
      "noActiveAnnouncements": "In questo momento non ci sono annunci attivi.",
      "noAnnouncements": "Nessun annuncio",
      "range": "Scelta degli utenti",
      "ranges": {
        "COMPANY": "In base a' azienda utente",
        "ROLE_GROUP": "In base a' ruoli utente"
      },
      "roleGroups": "Gli utenti con ruoli appropriati che visualizzeranno l’annuncio:",
      "subject": "Oggetto messaggio",
      "subjectEn": "Oggetto messaggio (EN)",
      "type": "Annuncio amministrativo"
    },
    "apiIntegrationLogs": {
      "event": {
        "CHECKPOINT_CONFIRMED": "Checkpoint confermato",
        "TRANSPORT_CREATED": "Creazione trasporto mediante API pubblica",
        "TRANSPORT_UPDATED": "Aggiornamento trasporto mediante API pubblica",
        "TRANSPORT_UPDATED_MANUAL": "Aggiorna trasporto"
      }
    },
    "appliesSettings": {
      "add": "Aggiungi impostazioni richieste",
      "description": {
        "createAntiCorruptionSection": "Aggiungi sezione anti-corruzione a nuove richieste",
        "showPatternToDownload": "Mostra modulo per download",
        "showStatementToDownload": "Mostra dichiarazioni principali firmate per download"
      },
      "label": "Impostazioni richieste",
      "noSettings": "Nessuna impostazione richieste disponibile"
    },
    "artrSettings": {
      "add": "Aggiungi impostazioni ANPR",
      "fields": {
        "entryGateCheckpointAlias": "Alias dell’attività ingresso (barriera)",
        "entryWhiteListCheckpointAlias": "Alias dell’attività che deve inviare il numero di targa alla whitelist - ingresso",
        "exitGateCheckpointAlias": "Alias dell’attività partenza (barriera)",
        "exitWhiteListCheckpointAlias": "Alias dell’attività che deve inviare il numero di targa alla whitelist - partenza",
        "expirationTimeInHours": "Orario scadenza whitelist (ore)"
      },
      "label": "Impostazioni ANPR",
      "noSettings": "Nessuna impostazione ANPR disponibile"
    },
    "assortmentGroup": {
      "add": "Aggiungi gruppo assortimento",
      "adviceFromTime": "Avviso da",
      "adviceToTime": "Avviso fino a",
      "confirmDeletion": "Eliminare il gruppo assortimento?",
      "confirmEdition": "Nota. Gli orari per l’avviso dei gruppi di assortimento sono stati modificati. Continuare?",
      "errors": {
        "adviceFromTime": {
          "invalidAssortmentGroupAdviceFromTime": "L’avviso deve rientrare nell’orario di lavoro del magazzino."
        },
        "adviceToTime": {
          "invalidAssortmentGroupAdviceToTime": "L’avviso deve rientrare nell’orario di lavoro del magazzino."
        },
        "nameEn": {
          "minLength": "La voce deve contenere almeno 3 caratteri"
        },
        "namePl": {
          "minLength": "La voce deve contenere almeno 3 caratteri"
        }
      },
      "label": "Gruppo assortimento",
      "listLabel": "Gruppi assortimento",
      "transportTypes": "Tipi di trasporto per i quali il gruppi assortimento deve essere disponibile"
    },
    "auction": {
      "AUCTION": {
        "actualFinishTime": "Orario effettivo chiusura asta",
        "buttonLabel": "+ Asta",
        "creator": "Asta'- creatore",
        "currency": "Valuta asta",
        "dateCreated": "Asta creata alle",
        "endedWithoutOffers": "L’asta è terminata senza offerte",
        "finish": "Termina asta",
        "inProgress": "L’asta termina alle",
        "initialFinishTime": "Orario iniziale chiusura asta",
        "label": "Asta",
        "noPreviousAuctions": "Nessuna asta precedente",
        "participants": "Aziende che partecipano all’asta",
        "plannedFinishTime": "Data di fine dell’asta",
        "previousAuctions": "Aste precedenti",
        "reject": {
          "alert": "Rifiutare questa asta?",
          "fail": {
            "CannotRejectOffersExist": "Non è possibile rifiutare questa asta, la tua azienda ha già inviato un’offerta."
          },
          "label": "Non sono interessato",
          "success": "Asta rifiutata correttamente"
        },
        "save": "Salva asta.",
        "savingInProgress": "Salvataggio asta in corso.",
        "selectParticipants": "Seleziona partecipanti all’asta"
      },
      "INQUIRY": {
        "actualFinishTime": "Orario di fine effettivo",
        "buttonLabel": "Richiesta",
        "creator": "Creatore richiesta",
        "currency": "Valuta",
        "dateCreated": "Creato alle",
        "endedWithoutOffers": "La richiesta è terminata senza offerte",
        "finish": "Termina richiesta",
        "inProgress": "Data di scadenza della richiesta",
        "initialFinishTime": "Orario di fine iniziale",
        "label": "Richiesta",
        "noPreviousAuctions": "Nessuna richiesta precedente",
        "participants": "Aziende che partecipano alla richiesta",
        "plannedFinishTime": "Data di fine della richiesta",
        "previousAuctions": "Richiesta precedenti",
        "reject": {
          "alert": "Rifiutare questa richiesta?",
          "fail": {
            "CannotRejectOffersExist": "Non è possibile rifiutare questa richiesta, la tua azienda ha già inviato un’offerta."
          },
          "label": "Non sono interessato",
          "success": "Richiesta rifiutata correttamente"
        },
        "save": "Crea una richiesta",
        "savingInProgress": "Salvataggio richiesta in corso",
        "selectParticipants": "Seleziona partecipanti alla richiesta"
      },
      "VALUATION": {
        "actualFinishTime": "Orario di chiusura finale per la presentazione di un’offerta",
        "buttonLabel": "+ Valutazione",
        "creator": "Valutazione'- creatore",
        "currency": "Valuta",
        "dateCreated": "Creato alle",
        "endedWithoutOffers": "La valutazione è terminata senza offerte",
        "finish": "Termina valutazione",
        "inProgress": "La valutazione termina alle",
        "initialFinishTime": "Orario di chiusura iniziale per la presentazione di un’offerta",
        "label": "Valutazione",
        "noPreviousAuctions": "Nessuna valutazione precedente",
        "participants": "Aziende partecipanti alla presentazione offerta",
        "plannedFinishTime": "Data di fine presentazione offerta",
        "previousAuctions": "Valutazioni precedenti",
        "reject": {
          "alert": "Rifiutare questa valutazione?",
          "fail": {
            "CannotRejectOffersExist": "Non è possibile rifiutare questa valutazione, la tua azienda ha già inviato un’offerta."
          },
          "label": "Non sono interessato",
          "success": "Valutazione rifiutata correttamente"
        },
        "save": "Salva valutazione",
        "savingInProgress": "Salvataggio valutazione in corso",
        "selectParticipants": "Seleziona partecipanti alla procedura di presentazione offerta"
      },
      "bid": "Offerta",
      "blockedCarriers": "Aziende vettore bloccate",
      "buttonLabel": "+ Asta",
      "buyNow": "Accetta",
      "buyNowConfirmation": "Accettare l’ordine {transportIdentifier} alla tariffa {currencySymbol} {minPrice}?",
      "buyNowHeader": "Accetta immediatamente l’offerta e ricevi l’ordine per la tariffa minima",
      "buyNowLabel": "Offerta minima",
      "create": "Crea asta",
      "createTitle": "Creazione asta per trasporto {transportIdentifier}",
      "currentOffer": "Offerta attuale",
      "details": "Cronologia",
      "errors": {
        "companies": {
          "empty": "Scegliere almeno un’azienda"
        },
        "maxPrice": {
          "invalidNumber": "Il prezzo massimo deve essere un numero maggiore di zero.",
          "maxPriceLowerThanMinPrice": "Il prezzo massimo non può essere minore del prezzo minimo."
        },
        "minPrice": {
          "invalidNumber": "Il prezzo minimo deve essere un numero maggiore o uguale a zero."
        },
        "plannedFinishTime": {
          "isInPast": "La data di fine deve essere nel futuro."
        }
      },
      "finishTimeChanged": "Orario finale modificato da {date}.",
      "label": "Asta",
      "lastUpdated": "Ultimo aggiornamento",
      "maxPrice": "Prezzo massimo",
      "minPrice": "Prezzo minimo",
      "noOffers": "Nessuna offerta valida",
      "plannedFinishTimeAbbr": "piano.",
      "priceRange": "Intervallo prezzi",
      "selectGroup": "Seleziona il gruppo ",
      "transport": "Trasporto"
    },
    "auth": {
      "accountActivated": "L’account è stato attivato",
      "accountExpired": "Il tuo account può essere stato sospeso a causa dell’eccessiva inattività. Il link di attivazione dell’account è stato inviato all’indirizzo e-mail correlato all’account. In caso di problemi contattaci all’indirizzo {supportEmail} oppure al numero: {supportPhone}.",
      "accountLocked": "Il tuo account è stato bloccato per {arg} minuti a causa di tentativi di accesso ripetuti. Riprova tra {arg} minuti oppure ripristina la password.",
      "accountLockedByAdmin": "Il tuo account è stato bloccato dall’amministratore di sistema",
      "activateAccount": "Attiva account",
      "alreadyRegistered": "Hai già un account?",
      "changePassword": {
        "false": "Modifica password",
        "true": "Non modificare la password"
      },
      "confirmPassword": "Conferma password",
      "description": "Piattaforma logistica",
      "email": "Indirizzo e-mail",
      "emailPlaceholder": "Inserisci indirizzo e-mail",
      "fail": "Errore di accesso",
      "footer": "Preparato da",
      "impersonate": "Accedi all’account utente",
      "invalidLoginOrPassword": "Nome utente e password non validi.",
      "login": "Accesso",
      "loginPlaceholder": "Inserisci e-mail",
      "loginWithActiveDirectory": "Accedi con Active Directory",
      "logout": "Disconnetti",
      "logoutByConcurrentSession": "Sei stato disconnesso perché stai utilizzando questo account su un altro dispositivo.",
      "logoutByService": "Sei stato disconnesso a causa di lavori di manutenzione o perché non hai utilizzato il sistema per molto tempo.",
      "newPassword": "Nuova password",
      "noLoginAndPassword": "Inserire nome utente e password.",
      "notRegistered": "Non hai ancora un account?",
      "oldPassword": "Password precedente",
      "or": "oppure",
      "password": "Password",
      "passwordChanged": "La password per l’utente {username} è stata modificata",
      "passwordPlaceholder": "Inserisci password",
      "passwordRemindedMessage": "Se l’indirizzo e-mail {email} esiste, sarà inviata un’e-mail.",
      "remind": "Ricorda",
      "remindEmail": "Digita l’indirizzo e-mail collegato al tuo account al quale invieremo un link per ripristinare la password:",
      "remindFail": "Operazione non riuscita",
      "remindHeader": "Ricorda password",
      "remindLink": "Password dimenticata?",
      "remindingInProgress": "E-mail inviata",
      "repeatNewPassword": "Ripeti nuova password",
      "repeatPassword": "Ripeti password",
      "serverError": "Il server non risponde. Riprova tra qualche minuto oppure contattaci all’indirizzo info@logintegra.com.",
      "signin": "Registrazione",
      "username": "Nome utente"
    },
    "authorityGroup": {
      "ADVICE": "Avviso",
      "ANNOUNCEMENT": "Comunicazioni",
      "API": "API",
      "APPLIES": "Richieste vettori",
      "ASSORTMENT_GROUP": "Gruppi assortimento",
      "AUCTION": "Trasferimento ordine, aste",
      "COMPANY": "Aziende",
      "COURIER": "Corrieri",
      "CUSTOM_FIELD": "Campi personalizzati",
      "DELIVERY": "Pianificazione consegne",
      "INDEX": "Indici",
      "MAIN_TRANSPORT_DATA": "Il campo principale del modulo ordine",
      "MODE_OF_TRANSPORTATION": "Verifica modalità di trasporto",
      "NOTES_AND_DOCUMENTS": "Note, documenti",
      "OTHERS": "Altri",
      "PACKAGE": "Confezioni",
      "PRICE_LIST": "Listini prezzi",
      "PRODUCT": "Prodotti",
      "QUEUE": "Linee",
      "SETTINGS": "Impostazioni",
      "TASKS": "Checkpoint",
      "TIME_WINDOW": "Slot temporali",
      "TRANSPORT": "Trasporto, ordine",
      "USER": "Utenti",
      "WAREHOUSE": "Magazzini",
      "WEIGHT": "Pesatura"
    },
    "blockades": {
      "invalidSection": "Sezione rifiutata o non valida",
      "noActiveBlockades": "Nessun blocco attivo"
    },
    "checkpoint": {
      "actualExecutionDate": "Data conferma reale",
      "actualExecutionDateLabel": "Indica la data reale in cui lo stato è stato confermato ",
      "blockLabel": "Blocca",
      "blockText": "La possibilità di bloccare questo stato è stata bloccata da {name}",
      "blockTextShort": "Bloccato da {name}",
      "confirmator": "Conferma",
      "errors": {
        "blockadeReason": {
          "length": "Il numero massimo di caratteri è 255"
        },
        "executionDate": {
          "isInPast": "La data indicata deve essere nel passato e deve essere successiva alla data di conferma dello stato precedente."
        }
      },
      "executionDate": "Data conferma stato",
      "reject": "Rifiuta",
      "rejectConfirm": "Rifiutare questo stato? Se confermi il rifiuto, l’autista sarà rimandato indietro e le notifiche e-mail saranno inviate al cliente e al vettore.",
      "rejectionDate": "Ultima data rifiuto: ",
      "unblockLabel": "Sblocca"
    },
    "checkpoints": {
      "confirm": "Conferma",
      "confirmAfterTime": "Stato confermato con ritardo",
      "confirmBeforeTime": "Stato confermato prima della scadenza",
      "confirmStatus": "Conferma stato",
      "confirmedMessage": "conf. il {date}",
      "confirmedMessageBy": "da {person}",
      "empty": "Nessuno stato",
      "plan": "piano.",
      "processFinished": "Processo terminato!",
      "revokeConfirm": "Revocare la conferma di questo stato?",
      "revokeManyConfirm": "Nota: poiché non è l’ultimo stato confermato, saranno revocate anche le conferme di tutti gli ultimi stati. Procedere?"
    },
    "classifications": {
      "successMessage": "Il veicolo {registrationNumber} è stato assegnato alla linea: {squareName} - {queueName}"
    },
    "cmrSettings": {
      "add": "Aggiungi impostazioni CMR",
      "fields": {
        "appendedDocsSpecificationCustomField": "Aggiungi valore campo personalizzato nel campo n. 5 (Documenti allegati)",
        "appendedDocsSpecificationNumber": "Aggiungi numero nel campo n. 5 (Documenti allegati)",
        "appendedDocsText": "Testo costante nel campo n. 5 (Documenti allegati)",
        "featuresAndNumbersCustomField": "Aggiungi valore campo personalizzato nel campo n. 6 (Contrassegni e numeri)",
        "featuresAndNumbersText": "Testo costante nel campo n. 6 (Contrassegni e numeri)",
        "packageTypeText": "Testo costante nel campo n. 8 (Metodo di confezionamento)",
        "quantityCustomField": "Aggiungi valore campo personalizzato nel campo n. 7 (Numero confezioni)",
        "quantityText": "Testo costante nel campo n. 7 (Numero confezioni)",
        "senderInstructionCustomField": "Visualizza il valore campo personalizzato nel campo n. 13 (Istruzioni mittente)",
        "senderInstructionText": "Testo costante nel campo n. 13 (Istruzioni'mittente)",
        "showPrincipalName": "Visualizza nel campo numero 1 (Mittente) il nome del Responsabile",
        "showWarehouseName": "Visualizza nel campo numero 1 (Mittente) il nome del magazzino",
        "weightGrossCustomField": "Aggiungi valore campo personalizzato nel campo n. 11 (Peso lordo in kg)"
      },
      "label": "Impostazioni CMR",
      "noSettings": "Nessuna impostazione CMR disponibile",
      "senderInstructionTextHint": "Questo campo può contenere massimo 8 linee. Importante: non impostare linee troppo lunghe, poiché'non saranno visibili sul CMR stampato!"
    },
    "columns": {
      "ADVICE": "Avviso",
      "ALT_IDENTIFIER": "Identificatore alternativo",
      "ARRIVAL_DATE": "Consegna",
      "CAR": "Veicolo",
      "CARRIER": "Vettore",
      "CONTRACTOR": "Appaltatore",
      "CONTRACTOR_FULL_NAME": "Appaltatore - nome completo",
      "CONTRACTOR_SHORT_NAME": "Appaltatore - nome abbreviato",
      "CREATION_DATE": "Data creazione",
      "CUSTOM_FIELDS": "Campi personalizzati",
      "DELIVERY_INCOTERMS": "Incoterms",
      "DELIVERY_NETTO_WEIGHT": "Peso netto [kg]",
      "DOCUMENTS": "Documenti",
      "DOCUMENT_IDENTIFIER": "ID documento",
      "DRIVER": "Autista",
      "DRIVER_IDENTIFIER": "Autista'- ID",
      "DRIVER_PHONE": "Telefono",
      "EMAIL_ADVICE": "Avviso e-mail",
      "EMAIL_ADVICE_EMAIL_ADDRESS": "Indirizzo e-mail",
      "IDENTIFIER": "ID",
      "INDEX_DOCUMENT_NUMBER": "Numero ordine",
      "INDEX_PRODUCTS": "Prodotti",
      "INDEX_TRANSPORT_NUMBER": "N. trasporto",
      "INSPECTION": "Ispezione",
      "INVOICE": "Fattura",
      "LOAD_CITY": "Città",
      "LOAD_COUNTRY": "Paese",
      "LOAD_PACKAGES_QUANTITY": "Carico confezioni",
      "LOAD_PLACE": "Posizione di carico",
      "LOAD_QUANTITY": "N. di pallet caricati",
      "LOAD_TIME_WINDOW": "Carica",
      "LOAD_WAREHOUSE": "Magazzino",
      "LOCATION": "Sede",
      "MANUAL_NOTIFICATION": "Notifiche",
      "NOTES": "Note",
      "PERIODIC_TRANSPORT_INTERNAL_INDEX": "N. avviso periodico",
      "PRICE": "Prezzo",
      "QUANTITY": "Numero di pallet",
      "RAMP": "N. rampa",
      "REQUIRES_INSPECTION": "Ispezione necessaria",
      "SERVICE_COMPANY_SHORT_NAME": "Azienda di servizi",
      "STATUS": "Stato",
      "STORAGE_ZONE": "Digita",
      "SUM_LOAD_QUANTITY": "Totale pallet caricati",
      "SUM_UNLOAD_QUANTITY": "Totale pallet scaricati",
      "TOTAL_WEIGHT": "Peso [kg]",
      "TRAILER": "Rimorchio/Semirimorchio",
      "TRANSPORT_DESCRIPTION": "Note",
      "TRANSPORT_RELATION": "Numero relazione",
      "TRANSPORT_SUPERVISOR": "Persona di contatto",
      "UNLOAD_CITY": "Città",
      "UNLOAD_COUNTRY": "Paese",
      "UNLOAD_PACKAGES_QUANTITY": "Scarico confezioni",
      "UNLOAD_PLACE": "Posizione di consegna",
      "UNLOAD_QUANTITY": "N. di pallet scaricati",
      "UNLOAD_TIME_WINDOW": "Scarica",
      "UNLOAD_WAREHOUSE": "Magazzino",
      "WORKER": "Lavoratori",
      "disableCustomFieldInfo": "L’attivazione/disattivazione di questo articolo attiverà/disattiverà tutti i campi Campo personalizzato visibili nella tabella",
      "label": "Visibilità colonne"
    },
    "common": {
      "accept": "Accetta",
      "accepted": "Accettato",
      "active": "Attiva",
      "add": "Aggiungi",
      "addedBy": "aggiunto da",
      "addingBlockedWindows": "Aggiunta finestre di pausa in corso",
      "address": "Indirizzo",
      "allRightsReserved": "Tutti i diritti riservati",
      "and": "e",
      "andBetween": "e",
      "appVersion": "Crea",
      "back": "Indietro",
      "browseAndUploadDocuments": "Naviga e carica documenti",
      "by": "da",
      "byYou": "da te",
      "cancel": "Annulla",
      "cancelProcess": "Annulla processo",
      "changeOfField": "Modifica del campo",
      "check": "verifica",
      "choosingReason": "Motivo",
      "clear": "Cancella",
      "clearFilters": "Cancella filtri",
      "clearTextArea": "Cancella contenuti di questa casella di testo",
      "client": "Cliente",
      "close": "Chiudi",
      "company": "Azienda",
      "confirmMessage": "Continuare?",
      "createSimilar": "Crea simile",
      "currency": "Valuta",
      "dateCreated": "Data creazione",
      "day": "Giorno",
      "days2": "2 giorni",
      "days4": "4 giorni",
      "daysOfWeek": {
        "1": "Lun",
        "2": "Mar",
        "3": "Mer",
        "4": "Gio",
        "5": "Ven",
        "6": "Sab",
        "7": "Dom",
        "label": "per giorni"
      },
      "dedicatedWindow": "Slot dedicato",
      "defaultError": "Operation failed. Please try again or contact us at info@logintegra.com.",
      "delete": "Elimina",
      "deleteTitle": "Elimina questo trasporto (il trasporto eliminato sarà visualizzato nell’archivio).",
      "deletingProgress": "eliminazione in corso",
      "desc": "Descrizione",
      "description": "Descrizione",
      "details": "dettagli",
      "disable": "Disattiva",
      "disableXLS": "Per esportare il file in XLS, specificare l’intervallo di date o filtrare i risultati della ricerca.",
      "download": "Scarica {name}",
      "downloadFile": "Scarica file",
      "downloadManualHeader": "Scarica manuale",
      "driverMobilePhoneNumber.placeholder": "ad es. 111222333",
      "driverMobilePhonePrefix.placeholder": "ad es. 48",
      "edit": "Modifica",
      "enable": "Attiva",
      "english": {
        "abbr": "ita.",
        "label": "italiano"
      },
      "error": "Error",
      "errorCode": "Error code",
      "errorList": "Error list",
      "export": "Esporta",
      "exportAsXLS": "Esporta come XLS.",
      "finishedWindow": "Processo terminato",
      "forDateRange": {
        "M": "mese",
        "X": "intervallo personalizzato",
        "Y": "anno"
      },
      "formShouldNotBeEmpty": "La data modulo non deve essere vuota!",
      "from": "da",
      "fromCompany": "da azienda",
      "further": "Successivo",
      "grayWindow": "Slot bloccato",
      "grossWeightKg": "Peso lordo [kg]",
      "hint": "Consiglio:",
      "hour": "Ora",
      "id": "ID",
      "idx": "Indice",
      "instructionalVideo": "Video informativo",
      "interval": {
        "M": "{count} {count, plural, one {month} other {months}}",
        "d": "{count} {count, plural, one {day} other {days}}"
      },
      "language": {
        "ENG": "ENG",
        "PL": "IT"
      },
      "lastUpdated": "Data ultima modifica",
      "lateWindow": "Ultimo slot",
      "legend": "Legenda",
      "loadingData": "Caricamento dati in corso...",
      "max": "Max",
      "maxDate": "Data massima",
      "message": "Messaggio",
      "min": "Min",
      "minDate": "Data minima",
      "more": "Altro",
      "name": "Nome",
      "nameEn": "Nome inglese",
      "namePl": "Nome polacco",
      "netWeightKg": "Peso netto[kg]",
      "newMessagesTitle": "Hai un nuovo messaggio.",
      "next": "Successivo",
      "no": "No",
      "noAdviceAddedYet": "Nessun consiglio ancora aggiunto",
      "noAuthority": "Non hai l’autorizzazione ad eseguire questa azione",
      "noData": "Nessun dato",
      "noResults": "La ricerca non ha prodotto risultati",
      "notSpecified": "Non specificato",
      "ok": "Ok",
      "oldBrowser": "Stai utilizzando un browser datato. La nostra app funziona meglio con un browser moderno come Google Chrome, Firefox, Opera, Vivaldi, Safari, o IE Edge.",
      "onDay": "il",
      "operationInProgress": "Operazione in corso...",
      "options": "Opzioni",
      "or": "o",
      "order": "Ordine",
      "orderNumber": "N. O.",
      "other": "Altri",
      "otherRequirements": "Altri requisiti",
      "otherRequirementsVisibleToCarrier": "Altri requisiti (il campo è visibile al trasportatore)",
      "outOf": "su",
      "perPage": "per pagina",
      "phone": "Telefono",
      "plan": "Pianifica",
      "plannedDate": "Data pianificata",
      "pleaseWait": "Attendere...",
      "previous": "Precedente",
      "recipient": "Destinatario",
      "refresh": "Aggiorna",
      "register": "Iscriviti",
      "registryOfPersons": "Registro delle persone",
      "reject": "Rifiuta",
      "rejected": "Rifiutato",
      "rememberFilters": "Ricorda filtri",
      "resend": "Invia di nuovo",
      "reset": "Ripristina",
      "restore": "Ripristina",
      "role": "Ruolo",
      "save": "Salva",
      "saveAndClose": "Salva e chiudi",
      "saveSuccessAdditionalMessage": "Aggiorna la pagina per visualizzare le modifiche",
      "saveSuccessMessage": "Modifiche salvate correttamente",
      "saved": "Salvato",
      "saving": "Salvataggio in corso",
      "scrollToClosestTransport": "Scorri al trasporto con le date più vicine",
      "search": "Cerca",
      "selectDate": "Seleziona data",
      "selectDeselectAll": "Seleziona/Deseleziona tutto",
      "selectValue": "Select value",
      "send": "Invia",
      "settings": "Impostazioni",
      "shortName": "Nome abbreviato",
      "shortNameEn": "Nome inglese abbreviato",
      "shortNamePl": "Nome polacco abbreviato",
      "show": "Mostra",
      "sure": "Procedere?",
      "timeSign": {
        "dopo": "dopo",
        "prima": "prima"
      },
      "timeUnit": {
        "d": "giorni",
        "h": "ore",
        "m": "minuti",
        "s": "secondi"
      },
      "to": "a",
      "today": "Oggi",
      "transportCombiningIndicator": "Ha la stessa relazione tipo trasporto",
      "transportDeletionDisabled": "Impossibile eliminare questo trasporto. Contattare l’amministratore.",
      "transportEditionDisabled": "Non hai l’autorizzazione a modificare questo trasporto.",
      "type": "Digita",
      "update": "Aggiorna",
      "uploadFile": "Carica file",
      "value": "Valore",
      "waiting": "In attesa",
      "week": "Settimane",
      "weightAndSave": "Pesa e salva",
      "windowMovedAutomatically": "A causa del ritardo, la finestra temporale è stata automaticamente spostata",
      "yes": "Sì"
    },
    "company": {
      "address": "Indirizzo",
      "addresses": "Indirizzi",
      "alias": "Alias",
      "allowedDomains": {
        "emailNotMatch": "Puoi invitare in questa azienda solo le persone con e-mail in determinati domini: {allowedDomains}",
        "header": "Domini consentiti per azienda {companyName}",
        "info": "Solo le persone con uno dei domini consentiti presenti nell’elenco seguente possono essere invitati nell’azienda",
        "label": "Domini consentiti",
        "list": "Elenco di domini consentiti:",
        "successDeleteMsg": "Il dominio è stato eliminato correttamente",
        "successSaveMsg": "I domini sono stati salvati correttamente"
      },
      "chooseCompanyTypeHint": "Inserire il tipo di azienda",
      "chooseCountryHint": "Scegli prima il paese!",
      "contractorNumber": "Numero appaltatore",
      "country": "Paese",
      "createCompany": "Crea azienda",
      "deleteConfirm": "Eliminare la partnership tra l’azienda {companyName} e l’azienda {principalName}? Dopo questa azione l’azienda {companyName} non avrà più accesso alla piattaforma {principalName}.",
      "deletedMessage": "“{companyName}” è stata eliminata correttamente.",
      "descriptionOfTheFleet": "Descrizione della flotta",
      "editCompany": "Modifica azienda",
      "employees": "Dipendenti",
      "errors": {},
      "existingCompanyName": "Azienda già esistente nella piattaforma. Impossibile editare il suo nominativo.",
      "gusHasProblem": "Nessuna connessione con il database dell’Ufficio centrale di statistica. L’azienda sarà verificata in base ai dati ricevuti dall’Ufficio centrale di statistica quando la connessione sarà ripristinata",
      "krs": "KRS/CEiDG",
      "label": "Azienda",
      "legalStatus": {
        "businessActivity": "Attività aziendale",
        "label": "Stato legale",
        "limitedCompany": "Azienda"
      },
      "limitedDataHint": "La visualizzazione e la modifica di alcuni dettagli dell’azienda {company} sono limitate, la gestione è possibile solo da parte degli Amministratori dell’azienda {company}.",
      "list": "Elenco aziende",
      "maxNumberOfAccounts": "Numero massimo di account",
      "maxNumberOfAccountsHint": "Dopo aver raggiunto il numero massimo di account non sarà possibile invitare nuovi utenti in questa azienda. Se il numero massimo di account non è impostato, non c’è alcun limite al numero di account.",
      "name": "Nome",
      "noEmployees": "Questa azienda non ha dipendenti",
      "noPartnershipWarning": "La tua azienda non ha creato nessuna partnership. Ciò può causare problemi con la scelta dell’appaltatore.",
      "partnershipStatus": {
        "ACCEPTED": "Accettato",
        "AWAITING_VERIFICATION": "In attesa di verifica",
        "BLOCKED": "Bloccato",
        "NO_INVITATION": "Nessun invito",
        "REGISTRATION_IS_IN_PROGRESS": "Registrazione in corso",
        "REJECTED": "Rifiutato",
        "UPDATED": "Aggiornato",
        "WAITING": "In attesa",
        "label": "Stato"
      },
      "partnershipTable": {
        "awaitingLabel": "Elenco inviti in attesa",
        "companyName": "Nome azienda",
        "label": "Elenco aziende associate"
      },
      "patron": "Supervisore",
      "patronWillChange": "Il supervisore sarà modificato negli ordini {transportIds}. Continuare? Selezionando “Annulla”, le modifiche non'saranno salvate",
      "possessedCertificates": "Certificati posseduti",
      "pricingPlan": "Opzione iscrizione azienda",
      "rampEdition": {
        "header": "Modifica rampa per utenti dell’azienda {companyName}",
        "label": "Modifica rampa",
        "updateForAllUsers": "Aggiorna rampe per tutti gli utenti di questa azienda"
      },
      "registrationDate": "Data di registrazione",
      "regon": "REGON",
      "saveSuccessMessage": "Azienda salvata correttamente.",
      "sections": "Sezioni",
      "selectCountry": "Seleziona paese",
      "selectType": "Seleziona tipo",
      "selectVoivodeship": "Seleziona voivodato",
      "shortName": "Nome abbreviato",
      "taxIdNumber": "Codice di identificazione fiscale (CIF)",
      "type": {
        "CARRIER": "Vettore",
        "CLIENT": "Cliente",
        "OPERATOR": "Operatore",
        "SERVICE": "Servizio",
        "SERVICEMAN": "Tecnico dell’assistenza",
        "SUPPLIER": "Fornitore//Destinatario",
        "SUPPLIER.CLIENT": "Cliente",
        "SUPPLIER.COLLECTION": "Destinatario",
        "SUPPLIER.COMPANY": "Azienda",
        "SUPPLIER.COURIER": "Corriere",
        "SUPPLIER.DELIVERY": "Fornitore",
        "label": "Digita",
        "producer": "Produttore"
      },
      "typeNipHint": "Digita prima il CIF!",
      "update": "Aggiorna",
      "verifiedByGus": "L’azienda è stata verificata correttamente dall’Ufficio centrale di statistica. Impossibile editare il suo nominativo."
    },
    "companyDisabledIcon": {
      "bothInsurancesMissing": "Bloccato. Nessuna assicurazione OCP e OCS attiva",
      "insuranceAmountTooLow": "Bloccato. Valore copertura "
    },
    "companyRamp": {
      "notExists": "L’azienda {companyName} non ha ancora un accesso alla rampa assegnato predefinito",
      "updateForAllUsersConfirmMsg": "Sovrascrivere l’accesso alle rampe per tutti gli utenti di questa azienda?",
      "usersWithIndividualRampAccess": "Elenco di utenti con assegnazione accesso rampa differente"
    },
    "courier": {
      "COURIER_PACKAGE": {
        "add": "Aggiungi",
        "description": "Contenuti",
        "disableConfirm": "Eliminare questo articolo?",
        "edit": "Modifica",
        "height": "Altezza [cm]",
        "internalId": "ID",
        "length": "Lunghezza [cm]",
        "numberOfPackagesTotal": "Importo totale:",
        "packageIdPlaceholder": "ID",
        "packageType": "Digita",
        "packageTypePlaceholder": "Digita",
        "packageValue": "Valore delle merci (per collo)",
        "packageValueLine": "Valore delle merci (per linea)",
        "packageWeightTotal": "Peso totale:",
        "pallet": "PALLET",
        "parcel": "CONFEZIONE",
        "quantity": "Quantità",
        "sectionLabel": "Confezioni",
        "sum": "Somma:",
        "weight": "Peso (per collo) [kg]",
        "width": "Larghezza [cm]"
      },
      "documents": {
        "connote": "Nota consegna",
        "label": "Etichetta",
        "manifest": "Manifesto di carico"
      },
      "priceDetails": {
        "Vat": "IVA",
        "basePrice": "Prezzo base",
        "basePriceVat": "Prezzo base IVA inclusa",
        "dhlPremium": "12:00 Express",
        "emergencySituation": "Situazione di emergenza",
        "finalPriceComponents": "Componenti prezzo finale",
        "fuelCharge": "Supplemento carburante",
        "grossPrice": "Prezzo lordo",
        "grossServicePrice": "Prezzo servizio lordo",
        "netPriceComponents": "Componenti prezzo netto",
        "netWorth": "Valore netto",
        "peakPrice": "Supplemento stagione di punta",
        "priceMultiplePackaging": "Supplemento multi-confezione",
        "servicePrice": "Prezzo servizio"
      },
      "validation.float.range": "Il valore deve essere un numero compreso tra 0,001 e 999999999999",
      "validation.integer.range": "Il valore deve essere un numero compreso tra 1 e 9999999",
      "validation.minimumOnePackage": "È necessario aggiungere almeno una confezione valida",
      "validation.range": "Il valore deve essere un numero compreso tra 1 e 9999999"
    },
    "courierSettings": {
      "add": "Aggiungi impostazioni corrieri",
      "description": {
        "isGetCourierQuotesFieldVisibility": "Visibilità campo “Richiedi offerte corriere”",
        "shouldGetCourierQuotesByDefault": " Richiedi offerte corriere come impostazione predefinita",
        "transportType": "Tipo di trasporto"
      },
      "label": "Impostazioni corrieri",
      "noSettings": "Nessuna impostazione corrieri disponibile"
    },
    "dateFilterProperty": {
      "DATE_CREATED": "Data di creazione dell’ordine",
      "DEPARTURE_DATE": "Data di consegna selezionata (nessuno slot temporale per questa fase)",
      "LOAD_DATE": "Data di carico",
      "TIME_RANGE_FOR_ADVICE_START": "Inizio dell’intervallo di tempo (nessuno slot temporale per questa fase)",
      "TIME_RANGE_FOR_ADVICE_STOP": "Fine dell’intervallo di tempo (nessuno slot temporale per questa fase)",
      "TIME_WINDOW_DATES": "Inizio o fine di uno slot temporale",
      "TIME_WINDOW_START": "Inizio di uno slot temporale",
      "TIME_WINDOW_STOP": "Fine di uno slot temporale",
      "UNLOAD_DATE": "Data di scarico",
      "hint": "Se il tipo di trasporto consente la creazione di un ordine senza uno slot temporale, allora l’ordine non sarà incluso nei filtri relativi alle proprietà dello slot temporale (ad es. inizio di uno slot temporale)."
    },
    "definizione-campo-personalizzato": {
      "addNewField": "Aggiungi un nuovo campo",
      "amountOfDelayedTimeForTaskToBeRequired": "La quantità del ritardo di tempo attività per il campo richiesto",
      "autocompleteEnabled": "Completamente automatico abilitato",
      "cancel": "Annullare gli aggiornamenti?",
      "create": "Crea una definizione campo modulo",
      "defaultOption": {
        "hint": "L’opzione fornita sarà il valore selezionato predefinito",
        "label": "Nome dell’opzione predefinita",
        "placeholder": "Seleziona una delle variabili disponibili",
        "title": "Opzione predefinita"
      },
      "delete": "Eliminare questo campo?",
      "deleteSuccessMessage": "Impostazioni campo eliminate correttamente.",
      "deliveryAlias": "Alias per integrazione MSP",
      "englishName": "Nome inglese",
      "errors": {
        "maxValue": {
          "dotSeparator": "Il separatore decimale deve essere un punto “.”",
          "maxLowerThenMin": "Il valore massimo non può essere minore del valore minimo"
        },
        "minValue": {
          "dotSeparator": "Il separatore decimale deve essere un punto “.”",
          "minHigherThanMax": "Il valore minimo non può essere maggiore del valore massimo"
        },
        "numberDecimalFormat": {
          "regex": "Il formato numero può contenere solo i seguenti caratteri: # , . 0 i _"
        },
        "obligatory": {
          "invalid": "Se si seleziona il campo “Obbligatorio”, selezionare almeno una delle seguenti opzioni: “Obbligatorietà di un campo durante e dopo la conferma del checkpoint”, “Visibile durante la creazione di un trasporto”"
        },
        "options": {
          "empty": "Fornire almeno una delle seguenti opzioni"
        },
        "textAreaRowsNumber": {
          "textAreaSize": "Il numero di righe deve essere maggiore di 0"
        }
      },
      "filterInArchive": "Applica filtri all’archivio",
      "filterLabel": "Filtri personalizzati:",
      "idx": "Idx",
      "includedInPriceList": "Incluso nel listino prezzi",
      "makeFieldDependentOn": {
        "definitionPlaceholder": "Scegli il nome di un campo dipendente",
        "dependsOnTaskInfo": "Il campo selezionato ha un checkpoint assegnato",
        "fieldCompleted": "Campo completato",
        "hint": {
          "obligatory": "Se la condizione è soddisfatta, il campo sarà visibile e obbligatorio",
          "voluntary": "Se la condizione è soddisfatta, il campo sarà visibile e volontario"
        },
        "title": "Rende la visibilità di un campo dipendente da un valore di un altro campo"
      },
      "maxValue": "Valore massimo consentito:",
      "minValue": "Valore minimo consentito:",
      "name": "Nome",
      "noTaskSelected": "Indipendentemente da un’attività",
      "numberDecimalFormat": {
        "comma": "Separatore raggruppamento",
        "dot": "Separatore decimale o separatore decimale monetario",
        "hash": "Numero, zero non mostrato",
        "label": "Formato numero",
        "meaning": "Significato",
        "minus": "Segno meno",
        "symbol": "Simbolo",
        "zero": "Numero"
      },
      "obligatory": "Obbligatorio",
      "order": "Ordine visualizzazione (idx)",
      "requiredInDelayedTransport": "Richiesto in caso di ritardo trasporto",
      "requiredTaskInDelayedTransport": "Campo richiesto durante la conferma dell’attività nel caso in cui il trasporto sia ritardato",
      "saveSuccessMessage": "Impostazioni campo salvate correttamente.",
      "saveSuccessMessageForTimeWindow": "Impostazioni campi salvate correttamente. Le modifiche saranno visibili tra qualche minuto.",
      "settingsFields": "Impostazioni campo",
      "side": "Il lato in cui il campo sarà visibile se il numero di campi in un modulo supera i {number} campi personalizzato",
      "sideLeft": "Sinistro",
      "sideRight": "Destro",
      "sideShort": "Lato",
      "task": "Un campo obbligatorio durante e dopo la conferma di un’attività",
      "taskHint": "Per impostare l’obbligatorietà del campo: Fare clic sulla croce “Indipendentemente da un’attività” accanto all’attività selezionata in precedenza",
      "textAreaRowsNumber": "Numero di righe per il campo",
      "type": {
        "date": "Data",
        "datetime": "Data e ora",
        "number": "Numero",
        "select": "Seleziona",
        "text": "Testo",
        "text_area": "Area di testo"
      },
      "typeField": "Tipo di campo",
      "visibilityDependsOnDefinition": "Nome del campo dipendente",
      "visibilityDependsOnOption": "Valore di un campo dipendente",
      "visibleDuringTransportCreation": "Visibile durante la creazione di un trasporto",
      "visibleInAdviceSection": "Visibile in un modulo avviso",
      "visibleInDeliveryDocument": "Visibile nella nota di consegna",
      "visibleInDiscrepancyReportSection": "Visibile nella sezione “Record delle divergenze”",
      "visibleInTable": "Visibile in una tabella",
      "visibleInTransportWeightReceipt": "Visibile in una ricevuta peso trasporto",
      "visibleInWeightSection": "Visibile nella sezione “Pesatura”",
      "visibleOnTimeWindow": "Visibile in uno slot temporale"
    },
    "delivery": {
      "additionalData": "Dati aggiuntivi",
      "contactPersonDetails": "Persona di contatto",
      "create": "Crea nuova consegna",
      "delete": "Eliminare la consegna?",
      "deliveryNumber": "Numero consegna",
      "description": "Descrizione",
      "edit": "Modifica consegna",
      "freightCost": "Costi di trasporto",
      "freightCostCurrency": "Valuta",
      "incoterms": "Incoterms",
      "label": "Consegna {id}",
      "modeOfTransport": "Modalità di trasporto",
      "placeholder": {
        "city": "Città",
        "postal": "12-345"
      },
      "planningTabTitle": "Pianificazione consegne",
      "step": {
        "country": "Paese",
        "load": "Carica",
        "loadArrivalDate": "Data arrivo carico",
        "loadRealDate": "Data arrivo carico reale",
        "maxAdviceDate": "Intervallo temporale a",
        "minAdviceDate": "Intervallo temporale da",
        "postalAndCity": "CAP e città",
        "recipient": "Destinatario",
        "street": "Via",
        "unload": "Scarica",
        "unloadArrivalDate": "Data arrivo scarico",
        "unloadRealDate": "Data arrivo scarico reale"
      },
      "toPlan": "Consegne non programmate"
    },
    "deliveryPackaging": {
      "description": "Contenuti",
      "height": "Altezza [cm]",
      "ldmLabel": "Metri di carico (ldm)",
      "length": "Lunghezza [cm]",
      "modalTitle": "Confezioni",
      "packageType": "Digita",
      "quantity": "Quantità",
      "sectionTitle": "Confezioni",
      "stackingFactor": "Impilabilità",
      "weight": "Peso (per collo) [kg]",
      "width": "Larghezza [cm]"
    },
    "dev": {
      "confirm": "Conferma",
      "performSaveAt": "Salva a"
    },
    "discrepancyReport": {
      "generate": "Genera un record di divergenze",
      "label": "Registrazione delle divergenze",
      "noAdviceInfo": "Dopo aver aggiunto l’avviso, sarà possibile generare il record delle divergenze",
      "noDiscrepanciesFound": "Nessuna divergenza rilevata",
      "noDiscrepanciesInfo": "Dopo aver rilevato le discrepanze, sarà possibile generare il record delle divergenze"
    },
    "document": {
      "CERTIFICATE": {
        "expirationDate": "Data scadenza certificato a",
        "number": "Numero certificato"
      },
      "INSURANCE": {
        "create": "Aggiungi assicurazione",
        "expirationDate": "Data scadenza assicurazione a",
        "expirationDateFrom": "Data scadenza assicurazione da",
        "new": "Nuovo {typeLabel}",
        "number": "Numero assicurazione",
        "save": "Salva assicurazione",
        "savingInProgress": "Salvataggio assicurazione in corso",
        "showArchived": "Visualizza assicurazioni archiviate"
      },
      "LICENCE": {
        "create": "Aggiungi licenza",
        "expirationDate": "Data scadenza",
        "new": "Nuovo {typeLabel}",
        "number": "Numero licenza",
        "save": "Salva licenza",
        "savingInProgress": "Salvataggio licenza in corso",
        "showArchived": "Visualizza licenze archiviate"
      },
      "MAPPING": {
        "expirationDate": "Data scadenza certificato a",
        "expirationDateFrom": "Data scadenza certificato da",
        "number": "Numero mappatura"
      },
      "amount": "Copertura assicurativa",
      "currency": "Valuta",
      "disableConfirm": "Assegnare questo documento all’archivio? I documenti archiviati non vengono considerati nella verifica della validità.",
      "documentType": "Ambito",
      "documentType.placeholder": "Seleziona ambito",
      "errors": {
        "expirationDate": {
          "isInPast": "La data di scadenza non può essere nel passato."
        },
        "expirationDateFrom": {
          "afterExpirationDate": "La data di scadenza “da” deve essere precedente alla data “a”."
        }
      },
      "expirationShiftInfo": "Il documento è considerato non valido {days} giorni prima della data di scadenza dell’assicurazione, ad. es. {date}",
      "expired": "Questo documento è scaduto!",
      "files": "Aggiungi campi",
      "maxCountExceeded": "È possibile aggiungere massimo {maxCount} file in una richiesta",
      "noFilesInfo": "Nessun file aggiunto a questo documento",
      "noFilesWarning": "Aggiungere un documento scannerizzato!",
      "scopeOfInsurance": "Copertura territoriale"
    },
    "documentType": {
      "CERTIFICATE": {
        "add": "Aggiungi certificato di calibrazione",
        "expirationDate": "Data scadenza a",
        "expirationDateFrom": "Data scadenza da",
        "expirationDateLabel": "Validità della calibrazione",
        "name": "Certificato calibrazione sensore",
        "number": "Numero certificato",
        "showArchived": "Visualizza certificati di calibrazione archiviati"
      },
      "SUMMER_MAPPING": {
        "add": "Aggiungi mappatura estiva",
        "expirationDate": "Data scadenza a",
        "expirationDateFrom": "Ultima data mappatura",
        "name": "Mappatura estiva",
        "showArchived": "Visualizza mappature estive archiviate"
      },
      "WINTER_MAPPING": {
        "add": "Aggiungi mappatura invernale",
        "expirationDate": "Data scadenza a",
        "expirationDateFrom": "Ultima data mappatura",
        "name": "Mappatura invernale",
        "showArchived": "Visualizza mappature invernali archiviate"
      },
      "isRequiredForModeOfTransportationInfo": "Documenti mancanti:"
    },
    "driver": {
      "addDriver": "Aggiungi un autista",
      "disableConfirm": "Rimuovere questo autista?",
      "documentNumber": "Numero documento",
      "documentType": {
        "ID_CARD": "Documento d’identità nazionale",
        "PASSPORT": "Passaporto",
        "label": "Tipo documento"
      },
      "errors": {
        "phoneNumber": {
          "regex": "Il numero deve contenere da 9 a 12 cifre"
        }
      },
      "label": "Autisti",
      "lastUpdated": "Ultimo aggiornamento",
      "name": "Nome e cognome",
      "nationality": "Nazionalità",
      "phoneNumber": "Numero di telefono",
      "timeWindow": "Slot temporale"
    },
    "emailAdvice": {
      "adviceDetails": "Dettagli avviso",
      "adviceDetailsCf": "Completa dati",
      "alreadySent": "La richiesta avviso e-mail è già stata inviata",
      "alreadySentCf": "Richiesta completamento dati già inviata",
      "confirm": "Inviare nuovamente la richiesta dati? Il link precedente sarà disattivato.",
      "logLabel": {
        "ADVICE_DATA": "Richiesta dettagli avviso",
        "CF_DATA": "Completa richiesta dati"
      },
      "noRole": "Non disponi'delle autorizzazioni sufficienti per questa azione",
      "title": "Invia richiesta dati avviso via e-mail",
      "titleCf": "Invia richiesta completamento dati via e-mail"
    },
    "errors": {
      "blank": "Inserire un valore",
      "blankCheckbox": "Il campo deve essere selezionato",
      "blankSelect": "Inserire un valore",
      "emptyFile": "Il file caricato è vuoto, selezionare un altro file",
      "fileAlreadyExists": "Si è verificato un errore durante il caricamento del file. Il file con nome assegnato esiste già.",
      "fileTooLarge": "Si è verificato un errore durante il caricamento del file. Il file è troppo grande!",
      "fileUploadError": "Si è verificato un errore durante il caricamento del file",
      "filterRequestTimeout": "Si è verificato un errore durante la ricerca. Restringere l’intervallo delle date di ricerca e riprovare.",
      "invalidDatesSequence": "Data 'Da' deve essere prima della Data 'A'",
      "invalidEmail": "Inserire un indirizzo e-mail valido",
      "invalidQuantity": "Il numero di pallet deve essere un numero intero positivo",
      "noNegativeNumber": "Questa voce può non essere un numero negativo",
      "number": "Questa voce può contenere solo numeri",
      "positiveInteger": "Questa voce deve essere un numero intero positivo",
      "positiveNumber": "Questa voce deve essere un numero positivo",
      "tooLong": "Questa voce deve avere al massimo %@ caratteri",
      "tooShort": "Questa voce deve avere almeno %@ caratteri"
    },
    "file": {
      "confirmDeletion": "Eliminare questo file?"
    },
    "finestra-temporale": {
      "details": {
        "carRegistrationNumber": "Camion",
        "erpIdentifier": "N. ordine",
        "trailerRegistrationNumber": "Rimorchio/Semirimorchio"
      },
      "errors": {
        "start": {
          "isInPast": "Impossibile programmare un trasporto nel passato.",
          "outsideOfAdviceRange": "Impossibile selezionare questa data perché sarebbe al di fuori dell’intervallo di tempo dell’avviso."
        },
        "stop": {
          "earlierThanStart": "La fine dello slot temporale deve essere successiva al suo inizio.",
          "outsideOfAdviceRange": "Impossibile selezionare questa data perché sarebbe al di fuori dell’intervallo di tempo dell’avviso."
        }
      },
      "start": "Slot temporale"
    },
    "formChanges": {
      "dirtyValueChanged": "Attenzione! Hai modificato il valore in questo campo in \"{yourValue}\", ma un altro utente ha salvato le modifiche in questo campo con il valore “{newValue}”.",
      "newValue": "Attenzione! Un altro utente ha impostato il valore “{value}” in questo campo.  ",
      "valueChanged": "Attenzione! Un altro utente ha modificato il valore in questo campo da “{oldValue}” a “{newValue}”.",
      "valueRemoved": "Attenzione! Un altro utente ha rimosso il valore “{value}” in questo campo."
    },
    "freeSubsequentSpots": {
      "infoAboutCurrentSpot": "Rampa selezionata: <b>{currentRamp}</b>, orario: <b>{currentStart}</b>",
      "infoAboutWindowSize": "Il tuo slot temporale non è adatto alla posizione selezionato, orario necessario per scarico: <b>{windowSizeInMinutes}</b> minuti",
      "showSearchSpotsButton": "Mostra posizioni disponibili nel programma",
      "suggestionsHeader": "Il primo orario di avviso possibile è:"
    },
    "gates": {
      "artrIdentifier": "Identificatore ANPR",
      "create": "Crea gate",
      "gateTypes": {
        "ENTRY_GATE": "Gate ingresso",
        "EXIT_GATE": "Gate uscita",
        "UNKNOWN": "Sconosciuto",
        "label": "Gate's type"
      },
      "label": "Gate"
    },
    "groupTypeSettings": {
      "description": {
        "canSelectOnlyOneContractorsGroup": "Consente la selezione di un solo gruppo in un determinato tipo",
        "partnershipTypesWithAccess": "Specifica per quale tipo di partnership il tipo di gruppo deve essere visibile",
        "requiresContractorsGroup": "Determina se è richiesta la selezione di almeno un gruppo nel tipo di gruppo",
        "visible": "Specifica se il tipo di gruppo deve essere visibile nelle notifiche"
      },
      "label": "Impostazioni tipo gruppo",
      "labels": {
        "canSelectOnlyOneContractorsGroup": "È obbligatorio selezionare solo un gruppo",
        "partnershipTypesWithAccess": "Per quale tipo di partnership il tipo di gruppo deve essere visibile",
        "requiresContractorsGroup": "È obbligatorio selezionare almeno un gruppo",
        "visible": "Visibilità tipo gruppo in invio"
      }
    },
    "gruppo-appaltatori": {
      "addGroup": "Aggiungi gruppo",
      "description": {
        "details": "Dettagli",
        "ifYouDoNotHaveDocument": "Se il documento richiesto è mancante, disattivare il gruppo selezionato.",
        "label": "La selezione di un gruppo richiede l’aggiunta di documenti di licenza o assicurazione appropriati",
        "notApplicable": "Non applicabile",
        "optionalDocuments": "e uno di questi documenti:"
      },
      "errors": {
        "obligatoryDocuments": "Per appartenere al gruppo {groupName}, la tua azienda deve avere i seguenti documenti: {documents}.",
        "optionalDocuments": "Per appartenere al gruppo {groupName}, la tua azienda deve avere almeno uno dei seguenti documenti: {documents}."
      },
      "label": "Raggruppamento",
      "name": "Nome",
      "nameEn": "Nome in inglese",
      "selectADRInfo": "Dopo aver selezionato questa opzione, l’azienda farà parte del gruppo di Vettori che sono in grado di evadere gli ordini di trasporto di merci pericolose. Verifica che la tua azienda abbia l’autorizzazione a trasportare materiali pericolosi.",
      "shortName": "Nome abbreviato",
      "shortNameEn": "Nome abbreviato in inglese",
      "unSelectADRInfo": "Dopo aver deselezionato questa opzione, l’azienda sarà rimossa del gruppo di Vettori che sono in grado di evadere gli ordini di trasporto di merci pericolose. Abbandonare il gruppo ADR?"
    },
    "help": {
      "changePrincipalSpaceInfo": "Nota: la pagina in cui ti trovi sarà aggiornata; prima di completare questa azione salvare le modifiche.",
      "contactUs": "Helpdesk Logintegra ",
      "contactWithWarehouse": "Contatta il magazzino",
      "label": "Guida",
      "newMessagesTitle": "Hai %@1 nuovi messaggi!",
      "selectRamp": "Seleziona gruppo carico.",
      "statute": "Regole"
    },
    "impostazioni-cmr": {
      "errors": {
        "senderInstructionText": {
          "maxLineCountIs8": "La quantità massima di linee in questo campo è 8"
        }
      }
    },
    "index": {
      "INDEX": {
        "add": "Aggiungi un indice",
        "disableConfirm": "Eliminare questo indice?",
        "enabled": "Indice abilitato",
        "globalNumber": "Numero indice",
        "globalNumber.abbr": "N. indice",
        "label": "Indice",
        "localNumber": "Numero locale",
        "localNumber.abbr": "N. locale",
        "name": "Nome prodotto",
        "name.abbr": "Nome",
        "notes": "Note indice",
        "product": "Prodotto",
        "recipient": "Destinatario",
        "sectionLabel": "Indici",
        "step": "Assegnazione di un indice a una fase di trasporto",
        "version": "Numero versione"
      },
      "PALLETS": {
        "add": "Aggiungi pallet",
        "disableConfirm": "Eliminare questo articolo?",
        "enabled": "Articolo abilitato",
        "globalNumber": "Numero pallet",
        "globalNumber.abbr": "N. pallet",
        "label": "Pallet",
        "localNumber": "Numero locale",
        "localNumber.abbr": "N. locale",
        "name": "Nome prodotto",
        "name.abbr": "Nome",
        "notes": "Informazioni aggiuntive",
        "product": "Prodotto",
        "sectionLabel": "Pallet",
        "shortName": "Nome abbreviato",
        "step": "Associazione di pallet a una fase"
      },
      "company": "Appaltatore",
      "companyRecipient": "Cliente'- destinatario",
      "dateCreated": "Data di creazione",
      "documentNumber": "Numero ordine",
      "documentNumber.abbr": "Numero ordine",
      "exportDisabledHint": "L’esportazione sarà possibile dopo il salvataggio delle modifiche",
      "selectCompany": "Seleziona un cliente",
      "selectCompanyHint": "Per prima cosa seleziona il cliente nel modulo ordine",
      "selectRecipient": "Seleziona un destinatario",
      "transportNumber": "Tipo di ordine",
      "transportNumber.abbr": "Tipo di ordine"
    },
    "indice-quantità": {
      "errors": {
        "quantity": {
          "conditionallyObligatory": "Inserire uno di questi valori: {names}.",
          "invalidNumber": "Inserire il numero positivo corretto.",
          "obligatory": "Inserire la quantità."
        }
      },
      "incompleteLoadingRulesNotMet": "È possibile inserire questo valore solo per carichi non completi."
    },
    "inspectionSection": {
      "fillInspection": "Compila",
      "label": "Ispezione",
      "noInspection": "Ispezione trasporto non eseguita"
    },
    "invitation": {
      "acceptedByAnotherPerson": "L’invito è già stato accettato da un’altra persona della tua azienda.",
      "acceptedSummary": "Accettato",
      "alreadyAccepted": "Questo invito è già stato accettato.",
      "alreadyHandled": "Questo invito è stato gestito",
      "companyRegistration": "Registrazione azienda",
      "createdBy": "{personName} ti ha invitato nel sistema",
      "creator": "Creatore",
      "email": "E-mail",
      "errors": {},
      "expirationDate": "Data scadenza",
      "for": {
        "CARRIER": "Vettore",
        "CLIENT": "Cliente",
        "JOIN_COMPANY": "Alla tua azienda",
        "OPERATOR": "Operatore",
        "SERVICEMAN": "Tecnico dell’assistenza",
        "SUPPLIER": "Fornitore//Destinatario",
        "SUPPLIER.CLIENT": "Cliente"
      },
      "hasExpired": "Scaduto",
      "instruction": {
        "label": "Ho letto",
        "linkName": "TERMINI DI SERVIZIO GENERALI"
      },
      "invalidTaxIdNumber": {
        "info": "I dettagli azienda non corrispondono all’invito Contattare Logintegra."
      },
      "invite": "Invita",
      "inviteToCompany": "Invito per azienda",
      "label": "Un invito",
      "notFound": "L’invito non esiste o è scaduto.",
      "ofCompany": "dell’azienda {companyName}",
      "registeredAccount": "Account registrato",
      "registrationComplete": "Grazie. Il tuo account è stato registrato. Accedere usando le credenziali {username}.",
      "registrationCompleteLdap": "Thank you. Your account has been registered. You can log in using the login {username} and the password that is used to log in to your company's internal systems (Active Directory).",
      "rejectedSummary": "Rifiutato",
      "sendTo": "Invia un invito",
      "sent": "Invito inviato a {email}.",
      "taxIdNumberExist": {
        "info": "Il Codice di identificazione fiscale (CIF) è già presente nel database. Contattare Logintegra."
      },
      "toCompanyWithRole": "per avviare una cooperazione con l’azienda {companyName} come {roleName}",
      "toJoinExistingCompany": "per azienda {companyName}",
      "toPartnership": "{personName} ti ha invitato ad avviare l’azienda {companyName} come {roleName}.",
      "type": {
        "CARRIER": "Collabora come vettore",
        "CLIENT": "Collabora come cliente",
        "JOIN_COMPANY": "Collabora come azienda",
        "SERVICEMAN": "Collabora come tecnico dell’assistenza",
        "SUPPLIER": "Collabora come Fornitore//Destinatario",
        "SUPPLIER.CLIENT": "Collabora come cliente"
      },
      "userRegistration": "Registrazione utente"
    },
    "invitations": {
      "cannotInviteAnyRoleGroup": "Non hai l’autorizzazione per invitare un qualsiasi utente",
      "emptyReceived": "Non hai ancora ricevuto alcun invito.",
      "emptySent": "Non hai inviato alcun invito.",
      "label": "Inviti",
      "noRoleGroupsFound": "Non hai l’autorizzazione per invitare alcun ruolo con il tipo di partnership selezionato",
      "received": "Ricevuto",
      "sent": "Inviato",
      "types": {
        "ACCEPTED": "Accettato",
        "EXPIRED": "Scaduto",
        "REJECTED": "Rifiutato",
        "WAITING": "In attesa"
      }
    },
    "log": {
      "actor": "Autore della modifica",
      "change": "Modifica",
      "dateCreated": "Data",
      "fieldChange": "Modifica del campo",
      "integration": {
        "resend": "Invia nuovamente",
        "resendFailed": "Errore nuovo invio messaggio",
        "resendSuccess": "Il nuovo invio del messaggio è stato pianificato correttamente",
        "sureToResend": "Inviare nuovamente messaggio API?"
      },
      "method": "Metodo",
      "object": "Oggetto",
      "partnership": "N.{id} partnership",
      "request": "Richiesta",
      "requestType": "Tipo richiesta",
      "response": "Risposta",
      "transport": "Trasporto",
      "url": "Indirizzo URL"
    },
    "logintegraEmail": "info@logintegra.com",
    "logintegraPhone": "0048 585 357 806",
    "logs": {
      "DELETE": {
        "ActionAuthority": "Autorizzazione ruolo utente dipendente dal tipo di trasporto eliminata",
        "ActiveAuctionRestriction": "Limitazione eliminata",
        "ActiveRestriction": "Limitazione eliminata",
        "AdviceDatesRestriction": "Limitazione eliminata",
        "AfterCheckpointBasedRestriction": "Limitazione eliminata",
        "AuthorityRestriction": "Autorizzazione ruolo utente dipendente da oggetto eliminata",
        "BeforeCheckpointBasedRestriction": "Limitazione eliminata",
        "BlockedRestriction": "Limitazione bloccata eliminata",
        "CompanyRamp": "Eliminazione assegnazione rampa per un’azienda",
        "CustomFieldValue": "Campo personalizzato aggiornato",
        "Delivery": "Consegna eliminata: ",
        "NoRouteOfferRestriction": "Limitazione eliminata",
        "OwnCompanyRestriction": "Limitazione eliminata",
        "PersonNotification": "Persona notifica rimossa",
        "PersonRamp": "Eliminazione assegnazione rampa per un utente",
        "RoleGroupAuthority": "Autorizzazione ruolo utente eliminata",
        "Step": "Fase eliminata",
        "TimeBasedRestriction": "Limitazione eliminata",
        "TransportTransportRelation": "Relazione trasporto eliminata",
        "UnlimitedRestriction": "Limitazione eliminata"
      },
      "INSERT": {
        "ARTR": "Integrazione sistema ANPR",
        "ActionAuthority": "Autorizzazione ruolo utente dipendente dal tipo di trasporto aggiunta",
        "ActiveAuctionRestriction": "Limitazione aggiunta",
        "ActiveRestriction": "Limitazione aggiunta",
        "Advice": "Avviso creato",
        "AdviceDatesRestriction": "Limitazione aggiunta",
        "AfterCheckpointBasedRestriction": "Limitazione aggiunta",
        "Auction": "Asta creata",
        "AuthorityRestriction": "Autorizzazione ruolo utente dipendente da oggetto aggiunta",
        "BeforeCheckpointBasedRestriction": "Limitazione aggiunta",
        "BlockedRestriction": "Limitazione bloccata aggiunta",
        "CompanyRamp": "Riassegnazione rampe azienda",
        "CustomFieldValue": "Campo personalizzato completato",
        "File": "File aggiunto",
        "Index": "Indice aggiunto",
        "NoRouteOfferRestriction": "Limitazione aggiunta",
        "Note": "Nota creata",
        "Offer": "Offerta aggiunta",
        "OwnCompanyRestriction": "Limitazione aggiunta",
        "Person": "Account creato",
        "PersonNotification": "Persona notifica aggiunta",
        "PersonRamp": "Riassegnazione rampe utente",
        "RoleGroupAuthority": "Autorizzazione ruolo utente aggiunta",
        "Step": "Fase aggiunta",
        "TimeBasedRestriction": "Limitazione aggiunta",
        "Transaction": "Ordine trasferito",
        "Transport": "Trasporto creato",
        "TransportTransportRelation": "Relazione trasporto aggiunta",
        "UnlimitedRestriction": "Limitazione aggiunta"
      },
      "TYPE_EMAIL": "Invio notifica e-mail",
      "TYPE_SMS": "Invio notifica SMS",
      "UPDATE": {
        "ActionAuthority": "Autorizzazione ruolo utente dipendente dal tipo di trasporto modificata",
        "Advice": "Avviso aggiornato",
        "Auction": "Asta aggiornata",
        "AuthorityRestriction": "Autorizzazione ruolo utente dipendente da oggetto modificata",
        "Checkpoint": "Stato aggiornato",
        "CompanyRamp": "Riassegnazione rampe azienda",
        "CustomFieldValue": "Campo personalizzato aggiornato",
        "File": "File aggiornato",
        "Index": "Indice aggiornato",
        "IndexQuantity": "Quantità indice aggiornata",
        "Note": "Nota aggiornata",
        "Offer": "Offerta aggiornata",
        "Person": "Accesso",
        "PersonRamp": "Riassegnazione rampe utente",
        "Step": "Fase aggiornata",
        "StepPackageType": "Confezioni",
        "TimeWindow": "Finestra temporale aggiornata",
        "Transaction": "Transazione aggiornata",
        "Transport": "Trasporto aggiornato"
      },
      "WeightLog": "Lettura peso"
    },
    "modeOfTransportation": {
      "approve": "Approva la modalità di trasporto",
      "brand": "Marca",
      "create": "Aggiungi una modalità di trasporto",
      "disabledReason": {
        "blockedSections": "Sezioni bloccate: {blockedSections}",
        "blockingDocument": "Impossibile selezionare questa modalità di trasporto e inviare un avviso su di esso a causa della data di validità del documento {blockingDocument} ({minValidityDate}). La validità scade prima del termine del trasporto ({maxTransportDate}).",
        "label": "Stato modalità di trasporto: {status}",
        "rejectedSections": "Sezioni rifiutate: {rejectedSections}"
      },
      "documents": {
        "disabled": "Impossibile aggiungere documenti a un veicolo disabilitato"
      },
      "edit": {
        "disable": "Disabilitare questa modalità di trasporto?"
      },
      "label": "{count, plural, one {Mode of transportation} other {Modes of transportation}}",
      "serialNumberOfRecorder": "Numero di serie di un registratore",
      "showEnabled": {
        "false": "Mostra solo modalità di trasporto abilitate",
        "true": "Mostra anche modalità di trasporto disabilitate"
      },
      "status": {
        "ACCEPTED": "Accettato",
        "AWAITING_VERIFICATION": "Non accettato",
        "BLOCKED": "Bloccato",
        "DISABLED": "Disattivato",
        "REJECTED": "Rifiutato",
        "UPDATED": "Aggiornato"
      },
      "subcontractor": "Subappaltatore",
      "verificationInfo": "La modalità di trasporto è stata approvata il {date} dall’utente {user}"
    },
    "noAccessibleStatisticsReports": "Non hai acceso ad alcun tipo di report",
    "noAccessibleTransportTypes": "Non hai acceso ad alcun tipo di trasporto",
    "note": {
      "autoGenerated": "Generato automaticamente",
      "creator": "Creatore",
      "dateCreated": "Creato alle",
      "label": "Nota",
      "note": "Nota",
      "transport": "Trasporto"
    },
    "notification": {
      "ADVICE_CONFIRMATION": {
        "desc": "La notifica inviata al creatore dopo che un avviso è stato salvato.",
        "label": "Conferma della creazione di un avviso: {transportTypeName}",
        "labelShort": "Conferma della creazione di un avviso"
      },
      "ADVICE_CREATED": {
        "desc": "Una notifica inviata al dopo che un avviso è stato salvato.",
        "label": "Verifica di un avviso: {transportTypeName}",
        "labelShort": "Creazione di un avviso"
      },
      "ADVICE_CREATED_SMS": {
        "desc": "Notifica SMS inviata all’autista dopo la creazione di un avviso",
        "label": "Notifica SMS inviata all’autista dopo la creazione di un avviso",
        "labelShort": "SMS inviato all’autista dopo la creazione di un avviso"
      },
      "ADVICE_UPDATED": {
        "desc": "Una notifica inviata alle parti interessate (ad es. se il creatore di un avviso è un fornitore//destinatario, sarà inviata un’e-mail a un esperto di logistica e ai vettori) dopo la modifica di un avviso.",
        "label": "Verifica di un avviso: {transportTypeName}",
        "labelShort": "Modifica di un avviso"
      },
      "ADVICE_UPDATED_SMS": {
        "desc": "Notifica SMS inviata all’autista dopo la modifica di un avviso",
        "label": "Notifica SMS inviata all’autista dopo la modifica di un avviso",
        "labelShort": "SMS inviato all’autista dopo la modifica di un avviso"
      },
      "ADVICE_VERIFICATION": {
        "desc": "Notifica relativa alla verifica di un avviso",
        "label": "Notifica relativa alla verifica di un avviso",
        "labelShort": "Verifica di un avviso"
      },
      "AUCTION_ENDED_WITHOUT_OFFERS": {
        "desc": "Notifica inviata quando un’asta termina senza alcuna offerta; è inviata agli utenti che possono presentare un’offerta per un’asta.",
        "label": "Questa asta non contiene alcuna offerta: {transportTypeName}",
        "labelShort": "Nessuna offerta nell’asta"
      },
      "AUCTION_LOST": {
        "desc": "Notifica inviata dopo la mancata aggiudicazione di un’asta.",
        "label": "L’asta relativa al trasporto {transportTypeName} è stata persa",
        "labelShort": "Asta persa"
      },
      "AUCTION_REMINDER": {
        "Shortabel": "Promemoria termine asta",
        "desc": "Notifica inviata come promemoria in prossimità del termine dell’asta.",
        "label": "Promemoria relativo al termine dell’asta per il trasporto {transportTypeName}"
      },
      "AUCTION_WON": {
        "desc": "Notifica inviata dopo l’aggiudicazione di un’asta.",
        "label": "L’asta relativa al trasporto {transportTypeName} è stata vinta",
        "labelShort": "Asta vinta"
      },
      "CARRIER_CHOSEN": {
        "desc": "Notifica inviata in caso di selezione o modifica di un vettore",
        "label": "Vettore selezionato per il trasporto {transportTypeName}",
        "labelShort": "Vettore selezionato"
      },
      "CHECKPOINT_CONFIRMED_BY_SMS": {
        "desc": "Notifica SMS inviata all’autista con data di conferma programmata per un determinato stato.",
        "label": "Notifica SMS inviata all’autista per richiesta conferma stato",
        "labelShort": "Notifica SMS inviata all’autista per richiesta conferma stato"
      },
      "CHECKPOINT_CONFIRMED_SMS": {
        "desc": "Notifica SMS inviata all’autista dopo la conferma del checkpoint",
        "label": "Notifica SMS inviata all’autista dopo la conferma del checkpoint",
        "labelShort": "SMS dopo conferma del checkpoint"
      },
      "CHECKPOINT_CONFIRMED_SMS_ASSOCIATED_WITH_USER": {
        "desc": "Notifica SMS inviata all’utente dopo la conferma del checkpoint",
        "label": "Notifica SMS inviata all’utente dopo la conferma del checkpoint",
        "labelShort": "Notifica SMS inviata all’utente dopo la conferma del checkpoint"
      },
      "CONFIRMED_CHECKPOINT": {
        "desc": "Notifica inviata quando lo stato {checkpointName} è confermato.",
        "label": "La conferma stato {checkpointName} per il tipo di trasporto {transportTypeName} ",
        "labelShort": "Conferma stato"
      },
      "DISCREPANCY_PROTOCOL_CREATED": {
        "desc": "Notifica inviata dopo la creazione del record delle divergenze per gli utenti che hanno accesso all’ordine per il quale il record è stato aggiunto.",
        "label": "Creazione record divergenze per {transportTypeName}",
        "labelShort": "Creazione record divergenze"
      },
      "INVITATION": {
        "desc": "Invito alla creazione di una partnership con",
        "label": "Invito alla creazione di una partnership con",
        "labelShort": "Invito a una partnership"
      },
      "LATE_ADVICE": {
        "desc": "Notifica inviata in caso di ritardo'nell’arrivo dell’autista.",
        "label": "Ritardo avviso: {transportTypeName}",
        "labelShort": "Ritardo avviso"
      },
      "LATE_DOCUMENT_EMAIL": {
        "desc": "Notifica inviata se il fornitore non aggiunge documenti di spedizione in modo puntuale.",
        "label": "Ritardo nell’aggiunta di documenti: {transportTypeName}, {checkpointName}",
        "labelShort": "Ritardo nell’aggiunta di documenti"
      },
      "MODE_OF_TRANSPORTATION_UPDATED": {
        "desc": "Notifica inviata dopo l’aggiornamento di una modalità di trasporto",
        "label": "Aggiornamento modalità di trasporto",
        "labelShort": "Aggiornamento modalità di trasporto"
      },
      "NEW_ADDED_OFFER": {
        "desc": "Notifica inviata quando il vettore aggiunge una nuova offerta.",
        "label": "Aggiunta di una nuova offerta: {transportTypeName}",
        "labelShort": "Aggiunta di una nuova offerta"
      },
      "NEW_AUCTION": {
        "desc": "Notifica inviata agli utenti che possono presentare un’offerta per un’asta e agli utenti delle aziende selezionate durante la creazione dell’asta.",
        "label": "Nuova asta per il trasporto {transportTypeName}",
        "labelShort": "Nuova asta"
      },
      "NEW_MODE_OF_TRANSPORTATION": {
        "desc": "Notifica inviata dopo l’aggiunta di una nuova modalità di trasporto",
        "label": "Nuova modalità di trasporto",
        "labelShort": "Nuova modalità di trasporto"
      },
      "NEW_OFFER": {
        "desc": "La notifica inviata agli utenti delle aziende selezionate durante la creazione della richiesta.",
        "label": "Nuova richiesta di trasporto: {transportTypeName}",
        "labelShort": "Nuova richiesta di trasporto"
      },
      "NEW_USER_IN_COMPANY": {
        "desc": "Notifica inviata quando un nuovo utente viene aggiunto all’azienda",
        "label": "Notifica inviata quando un nuovo utente viene aggiunto all’azienda",
        "labelShort": "Nuovo utente nell’azienda"
      },
      "OFFER_HAS_BEEN_OUTBID": {
        "desc": "Notifica inviata quando c’è una modifica nella prima posizione di un’asta.",
        "label": "L’offerta è stata superata: {transportTypeName}",
        "labelShort": "L’offerta è stata superata"
      },
      "OFFER_REJECTED": {
        "desc": "Una notifica inviata agli utenti delle aziende selezionate durante la fase di richiesta nel caso in cui l’offerta non sia stata selezionata",
        "label": "Nella richiesta per il trasporto {transportTypeName} non è stata selezionata alcuna offerta",
        "labelShort": "Nella richiesta non è stata selezionata alcuna offerta"
      },
      "ORDER_PASSED": {
        "desc": "Notifica inviata agli utenti dall’azienda del vettore'quando un ordine viene trasferito al vettore",
        "label": "Ordine trasferito: {transportTypeName}",
        "labelShort": "Ordine trasferito"
      },
      "ORDER_WAITING_FOR_CONFIRMATION": {
        "desc": "Notifica inviata agli utenti dall’azienda del vettore'quando un ordine viene trasferito al vettore ed è in attesa della'conferma del vettore",
        "label": "L’ordine è in attesa della conferma: {transportTypeName}",
        "labelShort": "L’ordine è in attesa della conferma"
      },
      "REJECTED_CHECKPOINT": {
        "desc": "Notifica inviata quando lo stato {checkpointName} è rifiutato.",
        "label": "Il rifiuto dello stato {checkpointName} per il tipo di trasporto {transportTypeName}",
        "labelShort": "Rifiuto stato"
      },
      "TIME_WINDOW_ADDED": {
        "desc": "Notifica invita dopo l’aggiunta di una nuova finestra temporale all’ordine",
        "label": "Finestra temporale creata",
        "labelShort": "Finestra creata"
      },
      "TIME_WINDOW_EDITED": {
        "desc": "Notifica inviata quando l’utente ha modificato la finestra temporale",
        "label": "Notifica inviata quando l’utente ha modificato la finestra temporale",
        "labelShort": "Finestra temporale modificata"
      },
      "TRANSPORT_CREATED": {
        "desc": "La notifica inviata agli appaltatori dopo che un trasporto è stato salvato.",
        "label": "Creazione di un trasporto {transportTypeName}",
        "labelShort": "Creazione di un trasporto"
      },
      "TRANSPORT_DELETED_EMAIL": {
        "desc": "Notifica inviata quando il trasporto viene eliminato",
        "label": "Notifica inviata quando il trasporto viene eliminato",
        "labelShort": "Trasporto eliminato"
      },
      "TRANSPORT_DELETED_SMS": {
        "desc": "La notifica SMS dopo il trasporto è stata eliminata (avvisi e-mail)",
        "label": "La notifica SMS dopo il trasporto è stata eliminata (avvisi e-mail)",
        "labelShort": "L’SMS dopo il trasporto è stato eliminato (avvisi e-mail)"
      },
      "roleGroups": {
        "desc": "Gli utenti con un determinato ruolo potranno ricevere una determinata notifica e abilitare/disabilitare le notifiche nelle impostazioni del proprio account.",
        "label": "Ruoli utente",
        "labelShort": "Ruoli utente"
      }
    },
    "notificationLog": {
      "TYPE_EMAIL": "È stata inviata un’e-mail",
      "TYPE_SMS": "È stato inviato un messaggio di testo"
    },
    "notificationToast": {
      "deliveryTransport": {
        "ADD_DELIVERY_TO_TRANSPORT": {
          "error": "Incompatible loading cities in deliveries!"
        },
        "CREATE": {
          "error": "Error during planning of transport: ",
          "loading": "In progress of planning transport: ",
          "success": "Successfully planned transport: "
        }
      },
      "titles": {
        "error": "Errore",
        "loading": "Carico",
        "success": "Riuscito"
      },
      "transportCombining": {
        "error": "Errore durante l’unione dei trasporti",
        "loading": "Combinazione trasporto in corso",
        "success": "Trasporti uniti"
      }
    },
    "notifications": {
      "add": "Aggiungi notifica",
      "appendTransportFiles": "Invia file allegati",
      "availableNotifications": "Notifiche disponibili",
      "edit": "Modifica",
      "label": "Notifiche",
      "legendAdviceUpdated": "Variabili utilizzate per messaggi di notifica AVVISO AGGIORNATO",
      "legendCommon": "Variabili generali",
      "legendDescriptions": "Il contenuto dell’SMS può contenere variabili. È sufficiente inserire il nome della variabile delimitato da “%” nel corpo dell’SMS. Esempio di utilizzo di una variabile: %NUM_TARG_RIMORCHIO%",
      "mailNotification": "Notifiche e-mail",
      "manualNotification": "Notifica manuale",
      "messageEn": "Contenuto in inglese",
      "messageInputType": {
        "ADDRESS_CITY": "Città scarico",
        "CAR_REG_NUM": "Targa del veicolo",
        "COMPANY_SHORT_NAME": "Nome azienda abbreviato",
        "GATE": "Gate's name in location",
        "LOCATION": "Sede magazzino",
        "NEW_ADVICE_DATE": "Nuova data avviso",
        "OLD_ADVICE_DATE": "Data avviso precedente",
        "OLD_REGISTRATION_NUMBER": "Targa precedente del veicolo",
        "PLACE_NAME": "Queue's place name",
        "QUEUE_NAME": "Nome linea",
        "RAMP_MESSAGE_EN": "Informazioni aggiuntive dalla rampa assegnata al trasporto EN",
        "RAMP_MESSAGE_PL": "Informazioni aggiuntive dalla rampa assegnata al trasporto IT",
        "RAMP_NUMBER": "Numero rampa",
        "SQUARE_NAME": "Nome stallo",
        "TIME_WINDOW_DATES": "Date finestra temporale",
        "TRAILER_REG_NUM": "Targa rimorchio",
        "TRANSPORT_ID": "Numero di identificazione trasporto"
      },
      "messagePl": "Contenuto in italiano",
      "new": "Nuova notifica",
      "noNotificationsForRole": "Nessuna notifica disponibile per il ruolo selezionato.",
      "selectRole": "Selezionare prima un ruolo.",
      "sureToDelete": "Eliminare questa notifica?",
      "task": "Attività",
      "transportType": "Tipo di trasporto"
    },
    "offer": {
      "auction": "Asta",
      "company": "Azienda",
      "creator": "Utente",
      "dateCreated": "Data di creazione",
      "deleted": "Eliminato",
      "description": "Commenti sull’offerta",
      "edit": "Modifica",
      "enabled": "Attivato",
      "errors": {
        "auction": {
          "hasEnded": "Spiacenti'l’asta relativa a questo ordine è già stata completata. Impossibile salvare l’offerta."
        },
        "price": {
          "higherThanMaximum": "Il prezzo inserito è maggiore del prezzo massimo relativo a questo ordine impostato dal Responsabile.",
          "lowerThanMinimum": "Il prezzo inserito è inferiore al prezzo minimo relativo a questo ordine impostato dal Principale.",
          "nonPositiveNumber": "L’offerta proposta deve essere un numero maggiore di zero.",
          "reductionNotMet": "Spiacenti, ma come richiesto da {principalCompanyName}, l’importo minimo con cui diminuire l’offerta precedente è {currencySymbol} {minReductionSize}."
        }
      },
      "hasWon": "Offerta vincente",
      "label": "Offerta",
      "place": "posizione",
      "price": "La tua offerta",
      "revoke": {
        "alert": "Revocare l’offerta attuale per questo ordine?",
        "fail": {
          "CannotRevokeOffer": "Impossibile revocare questa offerta."
        },
        "label": "Revoca l’offerta attuale",
        "success": "Offerta revocata correttamente"
      },
      "save": "Salva offerta",
      "savingInProgress": "Salvataggio offerta in corso",
      "valid": "Valido"
    },
    "opzione-campo-personalizzato": {
      "add": "Aggiungi un opzione",
      "dependenciesHint": "Selezionare campo personalizzato e opzioni, dai quali dipenderà la chiave selezionata",
      "editDependencies": "Modifica dipendenze",
      "errors": {},
      "optionKey": "Valore - chiave",
      "relatedDefinition": "Campo personalizzato",
      "relatedOptions": "Opzioni correlate",
      "remove": "Rimuovi un’opzione"
    },
    "packageTypes": {
      "CARTONS": {
        "label": "Cartoni",
        "lowerCase": "cartoni",
        "singular": "cartone"
      },
      "CHEP_PALLETS": {
        "label": "Pallet CHEP",
        "lowerCase": "Pallet CHEP",
        "singular": "Pallet CHEP"
      },
      "CUBIC_METERS": {
        "label": "Metri cubi",
        "lowerCase": "metri cubi",
        "singular": "Metro cubo"
      },
      "DISPOSABLE_PALLETS": {
        "label": "Pallet monouso",
        "lowerCase": "pallet monouso",
        "singular": "Pallet monouso"
      },
      "EURO_PALLETS": {
        "label": "Pallet EURO",
        "lowerCase": "Pallet EURO",
        "singular": "Pallet EURO"
      },
      "LOADING_PLACES": {
        "label": "Posizioni di carico",
        "lowerCase": "posizioni di carico",
        "singular": "Posizione di carico"
      },
      "PALLETS": {
        "label": "Pallet",
        "lowerCase": "pallet",
        "singular": "pallet"
      },
      "PALLETS_WITH_RETURNED_GOODS": {
        "label": "Pallet con merce resa",
        "lowerCase": "pallet con merce resa",
        "singular": "Pallet con merce resa"
      },
      "PALLET_PLACES": {
        "label": "Posizioni pallet",
        "lowerCase": "posizione pallet",
        "singular": "Posizione pallet"
      },
      "RETURNABLE_PALLETS": {
        "label": "Pallet a rendere",
        "lowerCase": "pallet a rendere",
        "singular": "Pallet a rendere"
      },
      "SPATIAL_METERS": {
        "label": "Metri spaziali",
        "lowerCase": "metri spaziali",
        "singular": "Metro spaziali"
      },
      "THE_NUMBER_OF_ITEMS": {
        "label": "Numero di articoli",
        "lowerCase": "numero di articoli",
        "singular": "Un pezzo"
      },
      "noRelationsWithPackageTypes": "Nessuna relazione tra tipo di trasporto e tipo di confezione",
      "referenceQuantity": "Quantità di {referencePackage}: {referenceQuantity}",
      "transportTypes": {
        "confirmDelete": "Eliminare il collegamento tra tipo di trasporto e tipo di confezione?",
        "defaultValue": "Valore predefinito",
        "idx": "Ordine di visualizzazione",
        "minValue": "Valore minimo",
        "multiplier": "{package} per {referencePackage}",
        "packageType": "Tipo di confezione",
        "referencePackage": "Attenzione! Stai per modificare le impostazioni relative al tipo di confezione di riferimento!",
        "referencePackageChkbox": "Riferimento confezione",
        "required": "Richiesto",
        "transportType": "Tipo di trasporto",
        "usedForMaxPalletLimitChkbox": "Includi per conteggio limite pallet massimo",
        "visibleInContractorStep": "Visibile nella fase appaltatore",
        "visibleInLoadPackageQuantityInTransportTable": "Quantità carico visibile nella tabella trasporto",
        "visibleInUnloadPackageQuantityInTransportTable": "Quantità scarico visibile nella tabella trasporto",
        "visibleInWarehouseStep": "Visibile nella fase magazzino"
      }
    },
    "page": {
      "pageTitle": "Piattaforma logistica"
    },
    "paidFeaturesInfo": {
      "authorities": {
        "UNLIMITED_ACCESS_TO_ARCHIVED_TRANSPORTS": "Il piano prezzi attuale della tua azienda consente di accedere agli ordini degli ultimi <b>3 mesi</b>."
      },
      "label": "Incoraggiamo a consultare i piani prezzi dell’azienda disponibile"
    },
    "pallet-max": {
      "errors": {},
      "label": "Limite pallet",
      "title": "Limite pallet giornaliero"
    },
    "partnership": {
      "ANTI_CORRUPTION_TYPE": "Dichiarazione anti-corruzione",
      "CONTRACTOR_GROUP_ADDED": "Gruppo aggiunto",
      "CONTRACTOR_GROUP_REMOVED": "Gruppo rimosso",
      "GROUPS": "Gruppi",
      "INSURANCE_DOMESTIC_OCP": "Assicurazione di responsabilità come vettore per trasporti nazionali",
      "INSURANCE_INTERNATIONAL_OCP": "Assicurazione di responsabilità come vettore per trasporti internazionali",
      "INSURANCE_OC": "Assicurazione di terze parti",
      "INSURANCE_OCS": "Assicurazione di responsabilità'dello spedizioniere",
      "LICENCE_DOMESTIC": "Licenza nazionale",
      "LICENCE_FORWARDING": "Licenza di spedizione merci",
      "LICENCE_INTERNATIONAL": "Licenza internazionale",
      "MAIN_DATA_TYPE": "Dati principali",
      "SECONDATY_DATA_TYPE": "Dati secondari",
      "STATUS_CHANGED": "Modifica dello stato",
      "TYPE_EMAIL": "E-mail inviata",
      "add": {
        "hint": "Aggiungi partnership tra fornitore e vettore",
        "label": "Aggiungi partnership",
        "title": "Aggiungi partnership tra appaltatori"
      },
      "additionalDetails": "Dettagli aggiuntivi",
      "adviceVerification": {
        "firstPartLabel": "Invia un’e-mail per verificare i dati",
        "secondPartLabel": "prima di ogni avviso"
      },
      "allowsPeriodicTransport": "Avvisi periodici",
      "auxiliary": {
        "editionDisabled": "Non modificabile.",
        "title": "L’azienda {contractorName} opera come appaltatore dell’azienda {principalCompanyName}.",
        "usersEditionDisabled": "Non puoi modificare i suoi utenti."
      },
      "connectAll": {
        "CARRIER": "vettori",
        "SUPPLIER": "fornitori",
        "SUPPLIER.CLIENT": "clienti",
        "cofirmMsg": "Collegare l’azienda {companyName} con tutti i {contractorsType} nello spazio {principalCompanyName}?",
        "label": "Collega tutti gli appaltatori",
        "success": "La partnership è stata creata"
      },
      "contractor": "Appaltatore",
      "contractorOfCompany": "dell’azienda {companyName}",
      "dateCreated": "Data di creazione",
      "deleteMsg": "Eliminare questa partnership tra appaltatori?",
      "errors": {
        "additionalDetails": {
          "length": "Il numero massimo di caratteri è 1.000"
        },
        "adviceVerificationInterval": {
          "intervalFormatError": "Formato intervallo errato - inserire un numero seguito da un’unità di tempo - s (secondi), m (minuti), h (ore) o d (giorni), ad es. 48h"
        },
        "contractorShortName": {
          "contractorShortNameTooLong": "Il nome abbreviato è troppo lungo!"
        },
        "representativeEmail": {
          "email": "Inserire un indirizzo e-mail valido"
        },
        "representativePhoneNumber": {
          "regex": "Il numero di telefono in Polonia deve contenere nove cifre"
        },
        "transportLimit": {
          "invalidNumber": "Il limite di trasporto deve essere un numero naturale."
        }
      },
      "logs": {
        "CONTRACTOR_GROUP_ADDED": "Gruppo appaltatore aggiunto",
        "CONTRACTOR_GROUP_REMOVED": "Gruppo appaltatore eliminato",
        "DOCUMENT_DISABLED": "Il documento è stato disattivato",
        "DOCUMENT_SAVED": "Il documento è stato salvato",
        "DOCUMENT_UPDATED": "Il documento è stato aggiornato",
        "FILE_ADDED": "File aggiunto",
        "FILE_DELETED": "File eliminato",
        "SECTION_CONFIRMED": "La sezione è stata confermata",
        "SECTION_REJECTED": "La sezione è stata rifiutata",
        "SECTION_SAVED": "La sezione è stata salvata",
        "SECTION_UPDATED": "La sezione è stata aggiornata",
        "STATUS_CHANGED": "Stato modificato",
        "TYPE_EMAIL": "Notifiche e-mail"
      },
      "representativeEmail": "Rappresentante - e-mail",
      "representativeName": "Rappresentante",
      "representativePhoneNumber": "Rappresentante - numero di telefono",
      "transportLimit": "Limiti di trasporto",
      "transportLimitHint": "Se non c’è alcun limite, lasciare il campo vuoto",
      "transportsCompletedCount": "dei quali completati",
      "transportsCreatedCount": "Trasporti creati",
      "transportsInProgressCount": "Trasporti in corso"
    },
    "partnerships": {
      "label": "Partnership"
    },
    "periodicTransport": {
      "cannotChangeCompany": "Non è possibile modificare questa azienda - è necessario includere nell’ordine almeno un’azienda con un’opzione di avviso periodico abilitata",
      "cannotDeleteStep": "Non è possibile eliminare questa fase - è necessario includere nell’ordine almeno un’azienda con un’opzione di avviso periodico abilitata",
      "deleteAllConfirmMsg": "Eliminare tutti gli avvisi periodici futuri? “Annulla” eliminerà solo questo ordine",
      "errors": {
        "chooseAtLeastOneDay": "Scegli almeno un giorno",
        "chooseFirstDayOfPeriodicTransport": "Scegli un giorno della settimana in cui sarà effettuato il primo trasporto periodico",
        "endDateMustBeAfterStartDate": "La data di fine deve essere successiva alla data di inizio",
        "incorrectRangeOption": "Intervallo non corretto - i valori possibili sono “Giornaliero”, “Settimanale” o “Mensile”",
        "windowMustBeInFirstDayOfPeriodicTransport": "Il primo giorno dell’avviso periodico deve corrispondere alla data di avviso programmata",
        "wrongDateRange": "Per un’opzione selezionata “{range}” l’intervallo di date massimo per un avviso periodico è {interval}. Imposta un intervallo di date più corto per un avviso periodico"
      },
      "frequencies": {
        "DAILY": "Giornaliero",
        "MONTHLY": "Mensile",
        "WEEKLY": "Settimanale"
      },
      "frequency": "Frequenza avviso",
      "label": "Avviso periodico",
      "updateAllConfirmMsg": "Aggiornare tutti gli avvisi periodici futuri? “Annulla” salverà le modifiche solo per questo ordine"
    },
    "pricingPlans": {
      "BASIC": "BASE",
      "CLIENT": "CLIENTE",
      "OPERATOR": "OPERATORE",
      "PRO": "PRO"
    },
    "product": {
      "INDEX": {
        "name": "Nome prodotto",
        "name.autoCompletePlaceholder": "Per cercare un prodotto in base al nome iniziare a digitare"
      },
      "ORDER": {
        "name": "Tipo di ordine",
        "name.autoCompletePlaceholder": "Per cercare in base al tipo di ordine iniziare a digitare"
      },
      "add": "Aggiungi prodotto",
      "disableConfirm": "Eliminare questo prodotto?",
      "disabled": "Prodotto {index} rimosso correttamente.",
      "errors": {
        "index": {
          "tooLong": "Il testo inserito è troppo lungo. La lunghezza massima è 255 caratteri."
        },
        "name": {
          "tooLong": "Il testo inserito è troppo lungo. La lunghezza massima è 255 caratteri."
        },
        "shortName": {
          "tooLong": "Il testo inserito è troppo lungo. La lunghezza massima è 255 caratteri."
        }
      },
      "index": "Indice",
      "index.autoCompletePlaceholder": "Per cercare un prodotto in base al suo indice iniziare a digitare",
      "saved": "Prodotto {index} salvato correttamente.",
      "shortName": "Nome abbreviato",
      "shortName.autoCompletePlaceholder": "Per cercare un prodotto in base al suo nome abbreviato iniziare a digitare",
      "unit": "Unità",
      "updated": "Prodotto {index} aggiornato correttamente."
    },
    "products": {
      "INDEX": {
        "label": "Prodotti",
        "table": "Tabella prodotto"
      },
      "ORDER": {
        "label": "Prodotti",
        "table": "Tabella prodotto"
      }
    },
    "queues": {
      "disableConfirm": "Disabilitare questa linea?",
      "englishName": "Nome inglese",
      "idx": "Sequenza",
      "updateSuccessMessage": "Le impostazioni linea {squareName} sono state aggiornate correttamente."
    },
    "queuesStatus": {
      "sendSmsModal": {
        "messagePlaceholder": "Inserisci il messaggio di testo",
        "title": "Messaggio di testo agli autisti dalla linea:"
      },
      "tableHeader": "Riepilogo stato linea attuale"
    },
    "ramp": {
      "additionalSmsNotificationInfoEn": "Additional information EN",
      "additionalSmsNotificationInfoPl": "Additional information PL",
      "backToWarehouse": "Torna a magazzino",
      "deleteDedicatedWindows": "Elimina slot temporali dedicati inutilizzati/utilizzati",
      "disableConfirm": "Disabilitare questa rampa?",
      "disabledMessage": "La rampa “{rampName}” è stata eliminata correttamente.",
      "enableConfirm": "Abilitare questa rampa?",
      "enabledMessage": "Il nome “{rampName}” è stato abilitato correttamente.",
      "errors": {
        "cannotMoveWindowException": "Puoi non spostare lo slot nella posizione indicata.",
        "idx": {
          "idxTooBig": "L’ordine della rampa non deve essere maggiore del numero di rampe in questo magazzino.",
          "nonPositiveInteger": "L’ordine della rampa deve essere un numero intero positivo."
        },
        "shortName": {
          "sizeTooBig": "La dimensione massima del nome abbreviato è 10 caratteri."
        },
        "wrongSwappedWindowSize": "Gli slot temporali scambiati hanno durate temporali differenti"
      },
      "idx": "Ordine",
      "label": "Rampa",
      "maxPallets": "Limite di pallet",
      "roleGroupsLabel": "Ruoli utenti per i quali la rampa sarà disponibile",
      "updateSuccessMessage": "Rampa'Le impostazioni della rampa sono state aggiornate.",
      "wantToSwapWindow": "Scambiare gli slot temporali?"
    },
    "ramps": {
      "label": "Rampe"
    },
    "relationsWizard": {
      "description": "Dopo l’approvazione, gli articoli selezionati saranno collegati insieme e rappresenteranno un veicolo. Gli articoli deselezionati dopo l’approvazione non saranno nel gruppo dei trasporti collegati.",
      "errors": {
        "atLeastOneOfAKind": "You must select at least one transport each from the list of existing links and the list of transports without links."
      },
      "label": "Trasporti combinati",
      "table": {
        "button": {
          "cancel": "Return",
          "createRelations": "Create relations"
        },
        "column": {
          "address": "Indirizzo",
          "relationIdentifier": "Identificatore relazione",
          "weight": "Peso [kg]",
          "wz": "Identificatore Erp"
        },
        "header": {
          "existingRelations": "Relazioni attuali",
          "withoutRelations": "Articoli da correlare"
        }
      }
    },
    "reports": {
      "contractors": {
        "header": "Genera un report per trasporti caricati/scaricati tra",
        "label": "Report da slot temporali"
      },
      "label": "Report"
    },
    "resetPasswordWindow": {
      "enteredPasswordsIsNotIdentical": "Entrambe le password devono essere identiche.",
      "header": "Modifica password di sistema",
      "info": "Ripristina la password in conformità alla nostra politica di sicurezza dei sistemi",
      "info2": "La nuova password deve essere diversa dalla precedente e deve contenere 8 caratteri (numeri, lettere minuscole e maiuscole)."
    },
    "roleGroup": {
      "label": "Ruolo",
      "placeholder": "Seleziona un ruolo"
    },
    "route": {
      "addNewRate": "Aggiungi nuova tariffa",
      "addNewRoute": "Aggiungi nuovo itinerario",
      "address": {
        "city": "Città",
        "country": "Paese",
        "load": "Carico",
        "postal": "Codice postale",
        "unload": "Scarico"
      },
      "addresses": "Indirizzi",
      "bestOffer": "Offerta migliore",
      "details": "Dettagli itinerario",
      "edition": "Modifica",
      "errors": {
        "description": {
          "length": "Il testo inserito è troppo lungo. La lunghezza massima è 255 caratteri."
        }
      },
      "label": "Itinerario",
      "newRoute": "Nuovo itinerario",
      "noTransportTypeAvailable": "Al momento non hai accesso ad alcun tipo di trasporto con listino prezzi",
      "offer": {
        "carrier": "Vettore",
        "confirmation": {
          "failure": "Spiacenti, impossibile confermare l’offerta. Contatta il tuo amministratore di sistema.",
          "message": "Confermare questa offerta?"
        },
        "confirmed": "Confermato",
        "date": "Data tariffa",
        "deleteMessage": "Rimuovere questa offerta?",
        "edition": "Modifica",
        "number": "Numero offerta",
        "price": "Tariffa",
        "showLess": "Mostra meno offerte ({n})",
        "showMore": "Mostra più offerte ({n})",
        "toggleTable": "Attiva/disattiva offerte' elenco",
        "validity": "Date validità"
      },
      "rate": "Tariffa",
      "rates": {
        "label": "Rates"
      },
      "termOfValidity": "Termini di validità"
    },
    "scheduleActionSelectModal": {
      "addWindow": "Aggiungi finestra",
      "blockedWindow": "Blocca finestra",
      "createTransport": "Crea un trasporto",
      "dedicatedWindow": "Finestra dedicata",
      "title": "Seleziona un’azione"
    },
    "section": {
      "accept": "Accetta",
      "dateOfArchiving": "Data di archiviazione",
      "dateOfDeletion": "Data di eliminazione",
      "dateOfExpiration": "Data di scadenza",
      "deleted": "Eliminato",
      "disable": "Disattiva",
      "disabled": "Disattivato",
      "expired": "Scaduto",
      "label": "sezione",
      "reject": "Rifiuta",
      "requestVerification": "Richiedi verifica",
      "state": {
        "ACCEPTED": "Accettato",
        "ARCHIVED": "Archiviato",
        "DELETED": "Eliminato",
        "DOCUMENT_ADDED": "Aggiornato",
        "EXPIRED": "Scaduto",
        "PENDING": "In attesa",
        "REJECTED": "Rifiutato",
        "UPDATED": "Aggiornato",
        "label": "Stato sezione"
      },
      "successMessage": "Sezione salvata correttamente",
      "update": "Aggiorna"
    },
    "sectionType": {
      "ANTI_CORRUPTION_TYPE": "Dichiarazione anti-corruzione",
      "BLOCKADES": "Blocchi attivi",
      "GROUPS": "Gruppi",
      "HISTORY": "Cronologia",
      "INSURANCE": "Assicurazione",
      "INSURANCE_DOMESTIC_OCP": "Assicurazione di responsabilità come vettore per trasporti nazionali",
      "INSURANCE_INTERNATIONAL_OCP": "Assicurazione di responsabilità come vettore per trasporti internazionali",
      "INSURANCE_OC": "Responsabilità azienda terza",
      "INSURANCE_OCS": "Assicurazione di responsabilità'dello spedizioniere",
      "LICENCE": "Licenza",
      "LICENCE_DOMESTIC": "Licenza nazionale",
      "LICENCE_FORWARDING": "Licenza di spedizione merci",
      "LICENCE_INTERNATIONAL": "Licenza internazionale",
      "MAIN_DATA_TYPE": "Dati principali",
      "SECONDARY_DATA_TYPE": "Dati secondari",
      "SENSOR_CALIBRATION_CERTIFICATE": "Certificato calibrazione sensore",
      "SUMMER_MAPPING": "Mappatura estiva",
      "USERS": "Utenti",
      "VEHICLE_MAIN_DATA_TYPE": "Dati modalità di trasporto",
      "WINTER_MAPPING": "Mappatura invernale"
    },
    "service": {
      "arrival": "Arrivo",
      "company": "Azienda",
      "contractorCompanyPlaceholder": "Nome azienda",
      "createTitle": "Programma servizio",
      "departure": "Partenza",
      "description": "Descrizione del servizio",
      "registrationNumber": "Numero di targa",
      "selectCompany": "Seleziona azienda",
      "serviceman": "Nominativo del tecnico dell’assistenza",
      "title": "Servizio #%@1"
    },
    "serviceNotWorking": {
      "content": "la piattaforma è temporaneamente non disponibile. Riprovare più tardi. Ci scusiamo per qualsiasi inconveniente causato.",
      "header": "Gentile utente,",
      "signature": "Il team Logintegra"
    },
    "settings": {
      "backToSettings": "Torna a impostazioni",
      "confirmDelete": "Eliminare questa impostazione?",
      "create": "Aggiungi impostazione",
      "desc": {
        "ADDITIONAL_ADD_FILE_BUTTON": "Pulsante supplementare per l’aggiunta di documenti nel modulo ordine",
        "ADDITIONAL_CONFIRMATION_DURING_REMOVING_GRAY_WINDOW": "Conferma aggiuntiva per la rimozione della finestra grigia bloccata",
        "ADDITIONAL_INFO_ON_PLAIN_TEMPLATE_VIEW": "Informazioni aggiuntive visualizzate nella versione stampabile del modulo ordine",
        "ADDITIONAL_INFO_ON_PLAIN_TEMPLATE_VIEW_EN": "Informazioni aggiuntive visualizzate nella versione stampabile del modulo ordine - versione in inglese",
        "ADD_FILE_TO_TRANSPORT_AFTER_OFFER_ACCEPTATION": "Specifica se il file deve essere aggiunto al trasporto dopo aver vinto l’asta o dopo che l’ordine è stato trasferito al vettore",
        "ADD_FILE_TO_TRANSPORT_AFTER_OFFER_ACCEPTATION_NAME": "Nome del file allegato al trasporto dopo aver vinto l’asta o dopo che l’ordine è stato trasferito al vettore",
        "ADVICE_CREATED_EMAIL_FILE_NAME": "Versione dell’e-mail relativa all’aggiunta dell’avviso",
        "ADVICE_CREATED_EMAIL_SUBJECT": "Oggetto dell’e-mail relativa all’aggiunta dell’avviso",
        "ADVICE_UPDATED_EMAIL_FILE_NAME": "Versione dell’e-mail relativa alla modifica dell’avviso",
        "ADVICE_VERIFICATION_EMAIL_ENABLED": "Specifica se i campi correlati all’invio di un’e-mail per verificare i dati prima di ciascun avviso sono visualizzati nei dettagli azienda",
        "ALT_IDENTIFIER_LABEL": "Etichetta della colonna che specifica il numero ordine",
        "ALT_IDENTIFIER_LABEL_EN": "Etichetta della colonna che specifica il numero ordine - in inglese",
        "ALT_IDENTIFIER_RESET_INTERVAL": "La frequenza di ripristino della sequenza di un numero “altIdentifier” generato automaticamente",
        "ANNOUNCEMENTS_COUNTER_POLL_INTERVAL": "Frequenza di aggiornamento dati in ms - contatore comunicazione",
        "API_MESSAGE_URL": "Messaggio API per invio indirizzo",
        "ARCHIVING_JOB_INTERVAL": "Per quanto tempo dalla conferma dello stato finale il trasporto deve essere spostato nell’archivio?",
        "ASSORTMENT_GROUP_LABEL": "Etichetta del campo modulo che specifica il gruppo assortimento",
        "ASSORTMENT_GROUP_LABEL_EN": "Etichetta del campo modulo che specifica il gruppo assortimento - in inglese",
        "AUCTION_ENDED_WITHOUT_OFFERS_EMAIL_FILE_NAME": "Versione di un’e-mail relativa al termine di un’asta senza offerte",
        "AUTOMATIC_DELETING_DEDICATED_WINDOWS_INTERVAL": "Quando, in relazione al loro orario programmato, le finestre dedicate non utilizzate devono essere eliminate (se è selezionata l’opzione “Elimina automaticamente slot dedicati non occupati/occupati”). Ad esempio, se il valore è “-2h”, lo slot dedicato non utilizzato dalle 16:00 (4:00 PM) sarà spostato alle 14:00 (2:00 PM).",
        "AUTOMATIC_DELETING_UNUSED_DEDICATED_WINDOWS_ENABLED": "Elimina automaticamente slot temporali dedicati inutilizzati",
        "AUTOMATIC_DELETING_USED_DEDICATED_WINDOWS_ENABLED": "Elimina slot temporali dedicati utilizzati",
        "AUTOMATIC_GENERATE_DOCUMENT": "Specifica se visualizzare il flag shouldGenerateDocument",
        "AUTOMATIC_WEIGHING": "Abilita la funzionalità di pesatura automatica",
        "AUTO_GENERATED_DELIVERY_NOTE_FIXED_TEXT": "Testo fisso visualizzato in una nota di consegna generata automaticamente",
        "AVAILABLE_LOGIN_PAGE_BACKGROUND_FILE_NAMES": "Nomi file dei background pagine di accesso disponibili",
        "CAN_ARRIVAL_DATE_BE_IN_THE_PAST": "Determina se la data di arrivo può essere nel passato",
        "CAN_EXPORT_INDEXES_TO_XLS": "Abilita la possibilità di esportare indici in un file Excel",
        "CAN_REFRESH_MANUALLY": "Capacità aggiornamento dati manuale",
        "CHANGE_TRANSPORT_INTERVAL": "Fino a quale punto in relazione alla data stimata di carico/scarico il fornitore/vettore può modificare il trasporto.",
        "CHECKPOINT_CONFIRMED_EMAIL_FILE_NAME": "Versione dell’e-mail relativa alla conferma del checkpoint",
        "CHECKPOINT_REJECTED_EMAIL_FILE_NAME": "Versione dell’e-mail relativa al rifiuto del checkpoint",
        "CLASSIFICATIONS_TAB_POLL_INTERVAL": "Frequenza di aggiornamento dati in ms - scheda “Classificazione”",
        "CLASSIFICATION_RESOURCE_COMPLETED_DELAY": "Specifica l’orario dopo il quale l’oggetto non è più visualizzato nella scheda “Linee” se lo stato Partenza non è stato ancora confermato",
        "CLASSIFIED_RESOURCE_DISAPPEARANCE_DELAY": "Specifica il ritardo dopo cui i trasporti classificati non saranno più visibili nella scheda “Classificazione”.",
        "COMPANY_FORM_SHOW_REPRESENTATIVE": "Visibilità dei campi correlati al rappresentante nel modulo azienda.",
        "CONTRACTOR_NUMBER_LABEL": "Nome del campo che specifica il'numero appaltatore",
        "CONTRACTOR_NUMBER_LABEL_EN": "Nome del campo che specifica il'Numero appaltatore - in inglese",
        "CUSTOM_FIELD_SPLIT_VALUE": "Il numero di campi personalizzati definiti in eccesso che saranno divisi in due colonne nel modulo trasporto",
        "DAYS_TO_RESET_PASSWORD": "Con quale frequenza, in giorni, all’utente viene chiesto di modificare una password.",
        "DAYS_TO_SHOW_ADVICE_HISTORY": "Fino a quando (impostare un intervallo di tempo in giorni) devono essere visualizzati i dati degli autisti nell’elenco dei suggerimenti durante la creazione/modifica di un avviso?",
        "DEFAULT_ADVICE_FUTURE_DAYS_LIMIT": "Limite predefinito di giorni disponibili per l’invio di avvisi assegnati a nuovi appaltatori.",
        "DEFAULT_EMAIL_DELAY_INTERVAL": "Ritardo predefinito nell’invio delle e-mail",
        "DEFAULT_NUMBER_OF_DISPLAYED_OFFERS": "Numero predefinito di offerte per l’itinerario nel listino",
        "DEFAULT_TAB_AFTER_LOGIN": "Scheda predefinita visualizzata dopo l’accesso",
        "DELAY_TIME_TO_MOVE_ORDER_TO_ARCHIVE_AFTER_TASK_TIMEOUT": "Ritardo di tempo dopo il quale l’ordine è automaticamente spostato in archivio (solo per attività indicate)",
        "DELAY_TIME_TO_MOVE_TIME_WINDOW_TO_NEXT_FREE_SPOT": "Quantità di ritardo di tempo dopo cui la piattaforma deve spostare la finestra nella finestra più vicino possibile",
        "DISPLAY_INDEXES_IN_SCHEDULE": "Visualizza un elenco indice nella visualizzazione programma",
        "DISPLAY_TRAILER_REG_NUMBER_IN_ADVICE_COLUMN": "Visualizza un Numero di targa rimorchio anziché un numero di targa nella colonna “Avviso”",
        "DOCUMENTS_MONITORING_INTERVAL": "Lunghezza del ritardo nell’aggiunta di documenti, dopo il quale all’utente sarà inviata un’e-mail.",
        "DOCUMENT_SECTION_VISIBLE_IN_TRANSPORT_FORM": "Visibilità della'Sezione documento nel modulo di trasporto",
        "DRIVER_PUNCTUALITY_INTERVAL": "Tempo relativo all’inizio della finestra temporale che determina il ritardo della fase di conferma",
        "EMAIL_NOTIFICATION_CONTAINS_PRODUCTS_SECTION": "Specifica se la sezione prodotti deve essere visibile nell’e-mail",
        "EMAIL_NOTIFICATION_CONTAINS_UNLOAD_SECTION": "Specifica se la sezione scarico deve essere visibile nell’e-mail",
        "ENABLE_TRANSPORT_ROW_HIGHLIGHTING": "Determina se una riga trasporto deve essere evidenziata se selezionata",
        "ENABLE_TRANSPORT_TABLE_DAYS_LIMIT": "Abilita un filtro nella tabella ordini attivi per nascondere gli ordini con slot temporali successivi a una data specificata",
        "END_OF_DAY_IN_WAREHOUSE_INTERVAL": "Il termine della giornata lavorativa nel magazzino usato per il conteggio degli slot temporali avviso. Avviso! Il termine della giornata lavorativa nel magazzino può essere differente. Esempio: Per impostare la fine della giornata alle 6:00 del giorno successivo, il valore del parametro dovrà essere 30h [30.01. 00:00 + 30h = 31,01. 06:00]",
        "ERP_IDENTIFIER_LABEL": "Etichetta della colonna che specifica il numero ordine ERP",
        "ERP_IDENTIFIER_LABEL_EN": "Etichetta della colonna che specifica il numero ordine ERP - in inglese",
        "FILE_TERMS_OF_CONDITIONS_ACCEPTED": "Link ai termini di servizio.",
        "FIND_ADDRESSES_MAX": "Numero di indirizzi restituiti dalla funzione completamento automatico",
        "FOOTER": "Link a un piè di pagina visualizzato nella piattaforma",
        "FOREIGN_DELIVERY_MULTIPLIER": "Il valore per il quale la lunghezza dello scarico sarà moltiplicata in relazione alle consegne internazionali.",
        "HAS_ACCESS_TO_APPLIES": "Specifica se deve essere eseguita la verifica dei'documenti vettore",
        "HAS_ACCESS_TO_MAX_PALLET_PER_DAY": "Specifica se abilitare il limite pallet per ciascun giorno",
        "HAS_ACCESS_TO_MAX_PALLET_PER_RAMP": "Specifica se abilitare il limite pallet per ciascuna rampa",
        "HAS_ACCESS_TO_NUMBER_OF_WINDOWS": "Specifica se visualizzare il numero di slot per un determinato giorno in un magazzino. Dipende dall’inizio e dalla fine della giornata nel magazzino.",
        "HD_MODE_NEXT_DAY_SHIFT_TIME": "Specifica l’orario in cui un programma nella visualizzazione HD deve essere spostato al giorno successivo (formato HH:mm)",
        "HOW_MANY_DAYS_BEFORE_CERTIFICATE_EXPIRATION_SHOULD_MAIL_BE_SENT": "Quanti giorni prima del termine del periodo di validità del del certificato di calibrazione sensore deve essere inviata una notifica e-mail con un promemoria",
        "HOW_MANY_DAYS_BEFORE_DOCUMENT_EXPIRATION_DATE_CARRIER_SHOULD_BE_BLOCKED": "Quanto giorni prima della data di scadenza del documento il vettore deve essere bloccato?",
        "HOW_MANY_DAYS_BEFORE_INSURANCE_EXPIRATION_SHOULD_MAIL_BE_SENT": "Quanti giorni prima del termine del periodo di validità dell’assicurazione deve essere inviata al vettore una notifica e-mail con un promemoria",
        "HOW_MANY_DAYS_BEFORE_MAPPING_EXPIRATION_SHOULD_MAIL_BE_SENT": "Quanti giorni prima del termine del periodo di validità della mappatura deve essere inviata una notifica e-mail con un promemoria",
        "HOW_MUCH_TIME_BEFORE_START_WINDOW_EDITING_ORDER_SENDS_SMS": "Quantità di tempo prima dell’inizio dello slot temporale in cui la modifica dell’avviso attività l’invio di messaggi di testo.",
        "HYPERLINK_TO_LOGO_ON_NAVBAR": "Collegamento ipertestuale al logo visualizzato nella barra di navigazione dopo l’accesso",
        "INVITATION_EXPIRATION_INTERVAL": "Il periodo di validità dell’invito.",
        "LATE_ADVICE_EMAIL_FILE_NAME": "Versione di un’e-mail relativa al ritardo",
        "LIMITED_INVITATION_MODE": "Gli utenti, eccetto un amministratore che può eseguire qualsiasi operazione, possono solo invitare gli utenti in base al loro ruolo e gli appaltatori",
        "LINK_TO_APPLIES_MANUAL": "Link al manuale sulle applicazioni",
        "LOADING_URL": "URL piattaforma",
        "LOCAL_PRINCIPAL_LANGUAGE": "Lingua azienda locale",
        "LOGO_ON_LOGIN_PAGE": "Link al logo visualizzato nella pagina di accesso.",
        "LOGO_ON_NAVBAR": "Link al logo visualizzato nella barra di navigazione dopo l’accesso.",
        "MANAGE_TRANSPORT_INTERVAL": "Fino a quale punto in relazione alla data stimata di carico/scarico è possibile creare o modificare un avviso di trasporto",
        "MAXIMUM_NUMBER_OF_ORDERS_TO_COPY": "Numero massimo di ordini che possono essere creati utilizzando la funzione “Crea simile”",
        "MAXIMUM_PERMISSIBLE_VEHICLE_WEIGHT_SETTING": "Peso massimo consentito veicolo [t]",
        "MAX_CHARACTERS_IN_INDEX_PRODUCTS_COLUMN": "Numero massimo di caratteri visualizzabili nella colonna prodotti",
        "MAX_CHARACTERS_IN_ORDER_NUMBER_COLUMN": "Numero di caratteri visualizzati in colonne con numeri ordine",
        "MAX_CHARACTERS_IN_TRANSPORT_RELATION_COLUMN": "Numero massimo di caratteri visualizzabili nella colonna relazioni",
        "MAX_NUMBER_OF_TIME_WINDOWS_FOR_SUGGEST": "Numero massimo di finestre temporali suggerite",
        "MAX_SIMULTANEOUSLY_ADDED_FILES_COUNT": "Numero massimo di file consentiti che possono essere aggiunti simultaneamente all’ordine",
        "MAX_WINDOW_LENGTH": "Lunghezza massima consentita della finestra temporale",
        "MIN_WEIGHT_TO_CREATE_TRANSPORT": "Peso minimo per creazione automatica trasporto",
        "NAME_TERMS_OF_CONDITIONS_ACCEPTED": "Nome dei termini di servizio.",
        "NAME_TERMS_OF_CONDITIONS_ACCEPTED_EN": "Nome dei termini di servizio - inglese",
        "NEW_ADVICE_EMAIL_RECIPIENTS": "Indirizzi e-mail aggiuntivi ai quali saranno inviate notifiche relative a nuovi avvisi (separati da virgole, ad es. xyz@xyz.com,abc@abc.com)",
        "NEW_AUCTION_EMAIL_FILE_NAME": "Versione di un’e-mail relativa a una nuova asta",
        "NEW_TRANSPORT_EMAIL_FILE_NAME": "Versione di un’e-mail relativa a un nuovo ordine",
        "ORDER_FORM_PRINTING_ENABLED": "Specifica se una versione stampabile è disponibile nel modulo ordine",
        "ORDER_WAITING_FOR_CONFIRMATION_EMAIL_FILE_NAME": "Versione dell’e-mail relativa al trasferimento dell’ordine al vettore",
        "OWN_ADDRESSES_ONLY": "Accesso solo a indirizzi inseriti in un determinato spazio principale (non si applica agli indirizzi principali delle aziende)",
        "PACKAGE_TYPES_FEATURE_ENABLED": "Visibilità della scheda gestione impostazioni confezione",
        "PATRON_IN_CONTRACTOR_DETAILS_ENABLED": "Determina se il campo “Supervisore” è visibile nei dettagli azienda",
        "PERCENTAGE_OF_TRANSPORTS_TAKEN_FOR_RANDOM_INSPECTIONS": "Quale percentuale di trasporti deve essere digitata per le verifiche casuali?",
        "POLL_INTERVAL": "Frequenza di aggiornamento dati in ms - tabella e programma.",
        "QUEUES_TAB_POLL_INTERVAL": "Frequenza di aggiornamento dati in ms - scheda “Linee” e “Stato linee”",
        "RESET_PASSWORD_IS_DISABLED": "Disattiva ripristino obbligatorio della password.",
        "ROLES_WITH_ENABLED_USER_AND_GROUP_FILTER": "Elenco di ruoli con filtro aggiuntivo abilitato (i miei ordini, ordini gruppo, tutti gli ordini). I valori devono essere assegnati dopo la virgola, ad es. “AMMINISTRATORE, GUARDIA”.",
        "ROLE_ADD_CONTRACTOR_GROUP": "La possibilità di aggiungere un nuovo gruppo nella sezione gruppi nei'dettagli appaltatore",
        "ROLE_EDIT_CONTRACTOR_GROUP": "Possibilità di modificare gruppi appaltatore esistenti",
        "SCHEDULE_LEGEND_ENABLED": "Abilita una legenda in un programma",
        "SEND_API_MESSAGE_EVERY_TRANSPORT_UPDATE": "Abilita l’invio di messaggi API dopo l’aggiornamento di ciascun trasporto",
        "SEND_CONTRACTOR_LATE_ADVICE_MAIL": "Specifica se inviare un’e-mail ritardata ad appaltatori esterni quando il creatore dell’ordine è il principale o al creatore del contratto quando il creatore dell’ordine è l’appaltatore.",
        "SEND_FUTURE_PERIODIC_TRANSPORTS_EDIT_MAIL_INTERVAL_IN_DAYS": "Numero di giorni nel futuro in base al quale le notifiche e-mail dovranno essere inviate se gli avvisi periodici sono stati modificati",
        "SEND_SMS_AFTER_CREATING_ORDER": "Invia un messaggio di testo all’autista dopo la creazione di un avviso",
        "SEND_SMS_AFTER_EDITING_ORDER": "Invia un messaggio di testo all’autista dopo la modifica di un avviso",
        "SET_SCHEDULE_LEGEND_TO_FIXED_POSITION": "Determina se la legenda nel programma deve essere sempre visualizzata",
        "SHOULD_RENDER_ALL_TRANSPORT_TYPES": "Per impostazione predefinita, visualizza tutti i tipi di trasporto nella tabella ordini attivi",
        "SHOW_COMPANY_VOIVODESHIP_FIELD": "Visibilità del campo “Voivodato” nei dati dell’azienda",
        "SHOW_PRINCIPAL_ADDRESS_IN_PRINT_VIEW": "Visibilità dell’indirizzo principale nella visualizzazione stampa",
        "SHOW_QUANTITY_SUM_CHECK_MESSAGE": "Visualizza un messaggio quando ci sono più di X pallet in un ordine",
        "SHOW_TERMS_OF_CONDITIONS_ACCEPTED": "Il campo in cui i termini di servizio possono essere accettati deve essere visibile nel modulo di avviso.",
        "SHOW_TRANSPORT_COMBINING_MODAL_ON_SINGLE_OPTION": "Mostra una finestra per i trasporti accorpati, anche con una sola opzione di accorpamento possibile",
        "SHOW_USERS_FOR_SUPPLIER": "Abilita un elenco utente nei dettagli fornitore/destinatario",
        "SORT_TABLE_BY_TRANSPORT_COMPLETED": "Determina se i trasporti nella tabella trasporto saranno ordinati per impostazione predefinita in base allo stato “Completato”",
        "START_OF_DAY_IN_WAREHOUSE_INTERVAL": "L’inizio della giornata lavorativa nel magazzino usato per il conteggio degli slot temporali avviso. Nota! L’inizio della giornata lavorativa può essere differente.",
        "SUPPLIER_FIELD_IN_COMPANY_DETAILS_TRANSLATION_KEY": "Chiave di traduzione campo “Fornitore/destinatario”. Valori possibili: 'CLIENTE','AZIENDA','CORRIERE'",
        "TERMS_OF_SERVICE_FILE_EXT": "Estensione del file Termini di servizio, ad es. docx, pdf",
        "TIME_TO_ACCEPT_AUTO_ROUTE_OFFER_PASS": "Tempo per accettare l’ordine in caso di trasferimento automatico degli ordini",
        "TIME_TO_ACCEPT_TRANSPORT_INTERVAL": "Tempo per accettare un ordine",
        "TIME_TO_RESTORE_ORDER_FROM_ARCHIVE": "Tempo prima del quale l’ordine può essere ripristinato dall’archivio",
        "TIME_WINDOW_OFFSET_INTERVAL": "Numero di minuti entro cui gli slot temporali possono essere avviati. I valori possibili sono 15, 30 o 60",
        "TRANSPORTS_LIST_LABEL": "Etichetta elenco trasporti",
        "TRANSPORTS_LIST_LABEL_EN": "Etichetta elenco trasporti - Inglese",
        "TRANSPORT_DESCRIPTION_COLUMN_MAX_CHARS": "Numero massimo di caratteri visualizzati nella colonna Note",
        "TRANSPORT_FREE_SLOT_SEARCH_INTERVAL_IN_DAYS": "Intervallo (in giorni) in cui il programma deve visualizzare una finestra senza limiti di tempo durante la creazione di un avviso periodico",
        "TRANSPORT_IDENTIFIER_TYPE": "Specifica il valore con cui l’utente con accesso a REST API avrà accesso ai trasporti. Le opzioni disponibili sono: ID, ERP_IDENTIFIER, ALT_IDENTIFIER.",
        "TRANSPORT_PAGINATION_DEFAULT_MAX_ROWS": "Specifica il numero massimo predefinito di righe per pagina per l’impaginazione tabella trasporto",
        "TRANSPORT_TABLE_DAYS_LIMIT": "La quantità di tempo in più (in giorni) dalla data attuale correlata agli ordini che saranno nascosti nella tabella ordini attivi",
        "USE_ADFS_AUTH_FOR_API_MESSAGE": "Enables authentication for the ADFS service (when sending API messages)",
        "USE_WEBSOCKETS": "Abilita supporto WebSocket",
        "WAREHOUSE_LABEL": "Etichetta del campo modulo che specifica il magazzino",
        "WAREHOUSE_LABEL_EN": "Etichetta del campo modulo che specifica il magazzino - in inglese",
        "WAREHOUSE_ZONE_LABEL": "Etichetta del campo modulo che specifica la zona magazzino",
        "WAREHOUSE_ZONE_LABEL_EN": "Etichetta del campo modulo che specifica la zona magazzino - in inglese",
        "WEEK_SCHEDULE_START_DATE_SHIFT": "Numero di giorni aggiunti all’inizio di un programma nella visualizzazione della settimana",
        "WEIGHT_OF_PALLET": "Indica il peso di un pallet in chilogrammi",
        "WEIGHT_SELECTOR": "Indicazione di un elemento con peso nella pagina",
        "WEIGHT_URL": "Link all’applicazione scala",
        "WINDOW_CHANGE_MAIL_DELAY_INTERVAL": "Lunghezza del ritardo dopo il quale un’e-mail sarà inviata dopo l’avvio dello slot temporale.",
        "WINDOW_CONSTANT_TIME": "Valore costante in minuti aggiunto alla lunghezza dello scarico negli slot.",
        "XLS_ADDRESSES_ENABLED": "Include la colonna “Indirizzi” nel report XLS",
        "XLS_DESCRIPTION_ENABLED": "Include la colonna “Note” nel report XLS",
        "XLS_EXTERNAL_ADDRESSES_DATA_ENABLED": "Include la colonna “Dati indirizzi esterni” nel report XLS",
        "XLS_TOP_ROW_ENABLED": "Abilita intestazione report XLS."
      },
      "description": "Descrizione",
      "edit": "Modifica impostazione",
      "editable": "Modificabile",
      "label": "Impostazioni principali",
      "name": "Nome",
      "other": "Altre impostazioni",
      "restSettingLabel": "Altre impostazioni",
      "roleGroups": {
        "actionIsPossible": "azione possibile ",
        "actionType": "Autorità",
        "actionTypeDescription": {
          "ACCEPT_AND_REJECT_TRANSPORT": "Questa autorizzazione consente l’accettazione e il rifiuto di ordini trasferiti all’appaltatore.",
          "ACCESS_CARRIER_COMPANY_FIELD": "Imposta la visibilità del campo “Vettore” nel modulo trasporto. Richiede anche l’autorizzazione “Accesso a tipo di trasporto”",
          "ACCESS_CUSTOM_FIELD": "Imposta la visibilità di un campo personalizzato nel modulo trasporto",
          "ACCESS_DESCRIPTION_FIELD": "Imposta la visibilità del campo “Note” nel modulo trasporto. Richiede anche l’autorizzazione “Accesso a tipo di trasporto”",
          "ACCESS_INDEX": "Un utente con questo ruolo ha accesso alla sezione indice.",
          "ACCESS_PACKAGES": "Imposta la visibilità della sezione “Confezioni” nel modulo trasporto Richiede anche l’autorizzazione: “Accesso a tipo di trasporto”",
          "ACCESS_SERVICE_COMPANY_FIELD": "Imposta la visibilità del campo “Servizio” nel modulo trasporto. Richiede anche l’autorizzazione “Accesso a tipo di trasporto”",
          "ACCESS_SUPPLIER_COMPANY_FIELD": "Imposta la visibilità del campo “Fornitore/Destinatario” nel modulo trasporto. Richiede anche l’autorizzazione “Accesso a tipo di trasporto”",
          "ACCESS_TRANSPORT": "Abilita l’accesso a un determinato tipo di trasporto (la visibilità del tipo di trasporto nella tabella degli ordini)",
          "ADD_ADVICE": "Abilita la possibilità di aggiungere dati avviso.",
          "ADD_DOCUMENTS": "Abilita la possibilità di allegare documenti all’ordine. È necessario l’accesso alla colonna con documenti nella tabella.",
          "ADD_LOAD_STEP_FROM_LOAD": "Aggiunta di una fase di carico dopo una fase di carico.  È necessario anche abilitare l’impostazione tipo trasporto: Visibilità di una sezione fasi in un modulo trasporto”.",
          "ADD_LOAD_STEP_FROM_UNLOAD": "Aggiunta di una fase di carico dopo una fase di scarico. È necessario anche abilitare l’impostazione tipo trasporto: Visibilità di una sezione fasi in un modulo trasporto”.",
          "ADD_NOTES": "Abilita la possibilità di aggiungere una nota a un trasporto nella tabella ordine. È necessario l’accesso alla colonna con note nella tabella.",
          "ADD_PACKAGES": "Abilita l’opzione di aggiungere una confezione nella sezione “Confezioni” nel modulo trasporto. Richiede anche l’autorizzazione: “Visibilità sezione confezione”",
          "ADD_TIME_WINDOW": "Abilita la possibilità di aggiungere una finestra temporale.",
          "ADD_UNLOAD_STEP_FROM_LOAD": "Aggiunta di una fase di scarico dopo una fase di carico.  È necessario anche abilitare l’impostazione tipo trasporto: Visibilità di una sezione fasi in un modulo trasporto”.",
          "ADD_UNLOAD_STEP_FROM_UNLOAD": "Aggiunta di una fase di scarico dopo una fase di scarico. È necessario anche abilitare l’impostazione tipo trasporto: Visibilità di una sezione fasi in un modulo trasporto”.",
          "ADVICE_ONLY_ON_DEDICATED_WINDOWS": "Aggiunta di finestre temporali solo nelle finestre dedicate",
          "BID_AUCTION": "Abilita la possibilità di partecipare all’asta e di inviare offerte.",
          "CREATE_AUCTION": "L’utente con questa autorizzazione l’opzione di creare un’asta.",
          "CREATE_TRANSPORT": "Consente di creare un nuovo trasporto. Richiede anche l’autorizzazione: “Accesso a tipo di trasporto”.",
          "DELETE_DOCUMENTS": "Consente all’utente di eliminare il documento allegato per il trasporto. È necessario l’accesso alla colonna con documenti nella tabella.",
          "DELETE_INDEX": "Consente di eliminare l’indice aggiunto per il trasporto.",
          "DELETE_LOAD_STEPS": "Abilita l’opzione di eliminazione delle fasi di carico.  È necessario anche abilitare l’impostazione tipo trasporto: Visibilità di una sezione fasi in un modulo trasporto”.",
          "DELETE_PACKAGES": "Abilita l’opzione di eliminare una confezione nella sezione “Confezioni” nel modulo trasporto. Richiede anche l’autorizzazione: “Visibilità sezione confezione”",
          "DELETE_TRANSPORT": "L’utente con questo livello di autorizzazione può eliminare trasporti creati in precedenza. Richiede anche l’autorizzazione: “Accesso a tipo di trasporto”.",
          "DELETE_UNLOAD_STEPS": "Abilita l’opzione di eliminazione delle fasi di scarico.  È necessario anche abilitare l’impostazione tipo trasporto: Visibilità di una sezione fasi in un modulo trasporto”.",
          "DISABLE_DATA_FIELDS": "Specifica se i dati nel modulo trasporto devono essere disabilitati",
          "EDIT_COURIER_INVOICE_SECTION": "Abilita l’opzione per la modifica della sezione “Fattura per merci”",
          "EDIT_DESCRIPTION_FIELD": "Abilita la possibilità di modificare il campo “Note” nei dettagli ordine. Richiede anche l’autorizzazione: “Accesso a tipo di trasporto”",
          "EDIT_INDEX": "Abilita la possibilità di modificare gli indici.",
          "EDIT_PACKAGES": "Abilita la modifica della confezione nella sezione “Confezioni” nel modulo trasporto. Richiede anche l’autorizzazione: “Visibilità sezione confezione”",
          "EDIT_TRANSACTION_FREIGHT": "Abilita l’opzione di modifica della merce nell’ordine trasferito al vettore",
          "MOVE_ADVICE_WINDOW": "Abilita la possibilità di spostare l’avviso slot temporali",
          "MOVE_LOAD_STEPS": "Abilita l’opzione di spostamento delle fasi di carico nel modulo.  È necessario anche abilitare l’impostazione tipo trasporto: Visibilità di una sezione fasi in un modulo trasporto”.",
          "MOVE_UNLOAD_STEPS": "Abilita l’opzione di spostamento delle fasi di scarico nel modulo. È necessario anche abilitare l’impostazione tipo trasporto: Visibilità di una sezione fasi in un modulo trasporto”.",
          "PASS_COURIER": "Abilita la possibilità di trasferire il trasporto al corriere.",
          "PASS_TRANSPORT": "Abilita la possibilità di trasferire il trasporto all’appaltatore.",
          "RESIZE_ADVICE_WINDOW": "Consente di modificare la dimensione dell’avviso slot temporali. Richiede anche l’autorizzazione: “Sposta avviso slot temporale” per funzionare correttamente.",
          "ROLE_ACCESS_AUCTION_DATA": "Abilita la visibilità dei dati asta",
          "ROLE_ACCESS_COURIER_INVOICE_SECTION": "Imposta la visibilità della sezione “Fattura per merci”",
          "ROLE_ACCESS_FREIGHT_DATA": "Abilita la visibilità della tariffa merce",
          "ROLE_ACCESS_TO_INSPECTION_BUTTON": "Visibilità del pulsante “Ispezione”",
          "ROLE_ACCESS_TO_INSPECTION_PANEL": "Visibilità del pannello “Ispezione”",
          "ROLE_ACCESS_TO_LOCATION": "Visibility of the 'Sede' field.",
          "ROLE_ACCESS_TRANSPORT_ADDITIONAL_DESCRIPTION": "Visibilità del campo “Note aggiuntive”",
          "ROLE_ACCESS_WEIGHT_SECTION": "Imposta la visibilità della sezione “Pesatura” nel modulo trasporto. Richiede anche l’autorizzazione: “Accesso a tipo di trasporto”",
          "ROLE_ACCESS_WORKER_FIELD": "L’autorizzazione consente la visibilità del campo “Lavoratori” nel modulo creazione trasporto",
          "ROLE_AUTOMATIC_PASS_TRANSPORT_TO_ROUTE_OFFERS": "Abilita il trasferimento automatico del trasporto ad appaltatori successivi.",
          "ROLE_CAN_ACCESS_ARCHIVED_TRUCK_DOCUMENTS": "Accesso ai documenti di archivio nelle sezioni della modalità di trasporto",
          "ROLE_CAN_ACCESS_COURIERS_SECTION": "Imposta l’accesso alla sezione “Corrieri” nel modulo trasporto",
          "ROLE_CAN_ACCESS_TASK": "Abilita la visibilità dell’attività",
          "ROLE_CAN_ACCESS_TRANSPORT_SUPERVISOR": "Visibilità del campo “Persona di contatto” nel modulo trasporto",
          "ROLE_CAN_ADD_HAS_TO_WAIT_FOR_RELATION": "Abilita la possibilità di aggiungere un link tra i trasporti",
          "ROLE_CAN_ADD_ROUTE": "Possibilità di aggiungere un nuovo itinerario al listino prezzi",
          "ROLE_CAN_ADD_TRANSPORT_COMBINING_RELATION": "Abilita la possibilità di collegare insieme i trasporti. I trasporti abbinati hanno notifica, finestra o stati comuni.",
          "ROLE_CAN_AUTOMATICALLY_CONFIRM_PREVIOUS_CHECKPOINTS": "Confermando lo stato indicato, l’utente conferma automaticamente anche tutti gli stati precedentemente non confermati",
          "ROLE_CAN_BLOCK_TASK": "Abilita l’opzione di blocco conferma attività",
          "ROLE_CAN_CHOOSE_MORE_THAN_ONE_LOCATION": "Abilita la selezione di più di 1 valore nel campo “Sede” nei dettagli ordine. Richiede anche l’autorizzazione: “Accesso a tipo di trasporto”",
          "ROLE_CAN_CONFIRM_TASK": "Abilita l’opzione di conferma attività",
          "ROLE_CAN_CREATE_DELIVERY": "Abilita la possibilità di creare consegne nella scheda “Pianificazione”",
          "ROLE_CAN_CREATE_TRANSPORT_FROM_SCHEDULE": "Creazione di un trasporto direttamente da un programma",
          "ROLE_CAN_DELETE_DELIVERY": "Abilita l’eliminazione di consegne nella scheda “Pianificazione”",
          "ROLE_CAN_DELETE_RELATION": "Abilita la possibilità di slegare i trasporti. L’autorizzazione consente di scollegare uno dei trasporti.",
          "ROLE_CAN_DELETE_ROUTE": "Possibilità di eliminare itinerari nei listini prezzi",
          "ROLE_CAN_DOWNLOAD_REGISTRY_OF_PERSONS_XLS": "L’autorizzazione consente di esportare il report registri personale in un file Excel",
          "ROLE_CAN_EDIT_COURIERS_SECTION": "Consente di modificare i campi nella sezione “Corrieri” nel modulo trasporto. Richiede l’autorizzazione “Accedi alla sezione corrieri”",
          "ROLE_CAN_EDIT_DELIVERY": "Abilita la possibilità di modificare consegne nella scheda “Pianificazione”",
          "ROLE_CAN_EDIT_READY_TO_PLAN": "Abilita la possibilità di modificare il flag “Pronto per pianificazione” nel modulo trasporto",
          "ROLE_CAN_EDIT_ROUTE": "Possibilità di modificare itinerari nei listini prezzi",
          "ROLE_CAN_EDIT_TRANSPORT_PALLETS_WITHOUT_CHANGING_TIME_WINDOW_SIZE": "Possibilità di aumentare il numero di confezioni nel trasporto nonostante la mancanza di spazio, per ampliare la finestra temporale conformemente all’algoritmo.",
          "ROLE_CAN_EDIT_TRANSPORT_SUPERVISOR": "Possibilità di modificare il campo “Persona di contatto” nel modulo trasporto",
          "ROLE_CAN_MULTI_ARCHIVE_TRANSPORTS": "Archiviazione di gruppo dei trasporti",
          "ROLE_CAN_SCAN_BARCODES": "Abilita la possibilità di eseguire la scansione di codici a barre per cercare i trasporti",
          "ROLE_CAN_SEND_EMAIL_ADVICE": "Abilita la possibilità di inviare una richiesta di fornitura dati avviso nell’avviso e-mail",
          "ROLE_CAN_SEND_EMAIL_ADVICE_CF": "Abilita la possibilità di inviare una richiesta di fornitura dati ordine nell’avviso e-mail",
          "ROLE_CAN_SKIP_CHECKPOINT_CONFIRMATION": "Consente di saltare una fase nonostante la conferma ordine necessaria per ciascuna fase",
          "ROLE_CREATE_SIMILAR": "Consente di creare un ordine simile (una copia dell’ordine).",
          "ROLE_CREATE_SIMILAR_MULTIPLE": "Crea simile - possibilità di definire il numero di copie dell’ordine",
          "ROLE_CREATE_WORKER": "Possibilità di creare un  lavoratore nella scheda secondaria “Elenco lavoratori”",
          "ROLE_DELETE_WORKER": "Possibilità di eliminare un lavoratore nella scheda secondaria “Elenco lavoratori”",
          "ROLE_EDIT_TRANSPORT_ADDITIONAL_DESCRIPTION": "Abilita la possibilità di modificare il campo “Note aggiuntive”",
          "ROLE_EDIT_WAREHOUSE_LOCATION": "Abilita la possibilità di modificare il campo “Sede” nei dettagli ordine. Richiede anche l’autorizzazione: “Accesso a tipo di trasporto”",
          "ROLE_EDIT_WEIGHT": "Modifica del peso nella sezione “Pesatura”",
          "ROLE_EDIT_WORKER": "Possibilità di modificare un lavoratore nella scheda secondaria “Elenco lavoratori”",
          "ROLE_EDIT_WORKER_FIELD": "L’autorizzazione consente di modificare il campo “Lavoratori” nel modulo creazione trasporto",
          "ROLE_HIDE_TRANSPORTS_WITHOUT_ACTIVE_WAREHOUSE_STATUSES": "The permission hides transports that do not have active statuses with the user's warehouse",
          "SWAP_ADVICE_WINDOWS": "Consente lo scambio di posizioni di avviso slot temporali.",
          "UPDATE_ADVICE": "Consente di modificare i dati notifica.",
          "UPDATE_CARRIER_COMPANY_FIELD": "Abilita la possibilità di modificare il campo “Vettore” nei dettagli ordine. Richiede anche l’autorizzazione: “Accesso a tipo di trasporto”",
          "UPDATE_CUSTOM_FIELD": "Abilita la possibilità di modificare un campo personalizzato nel modulo trasporto.",
          "UPDATE_MAIN_DATA_SECTION": "Abilita la possibilità di modificare il trasporto. Si applica a tutti i campi eccetto i dati avviso, i campi personalizzati e il campo “Note”. Richiede anche l’autorizzazione: “Accesso a tipo di trasporto”.",
          "UPDATE_SERVICE_COMPANY_FIELD": "Abilita la possibilità di modificare il campo “Servizio” nei dettagli ordine. Richiede anche l’autorizzazione: “Accesso a tipo di trasporto”",
          "chooseActionType": "Scegli un livello autorità"
        },
        "addPermission": "Aggiungi autorizzazione",
        "authorityLabels": {
          "ACCEPT_AND_REJECT_TRANSPORT": "Accetta/rifiuta il trasporto che è stato trasferito",
          "ACCESS_CARRIER_COMPANY_FIELD": "Visibilità campo “Vettore”",
          "ACCESS_CUSTOM_FIELD": "Visibilità campo personalizzato",
          "ACCESS_DESCRIPTION_FIELD": "Visibilità campo “Note”",
          "ACCESS_INDEX": "Accedi alla sezione Indici",
          "ACCESS_PACKAGES": "Visibilità sezione collo",
          "ACCESS_SERVICE_COMPANY_FIELD": "Visibilità campo “Servizio”",
          "ACCESS_SUPPLIER_COMPANY_FIELD": "Visibilità campo “Fornitore/Destinatario”",
          "ACCESS_TO_TRANSPORT_DETAILS": "Accedi a dettagli trasporto",
          "ACCESS_TRANSPORT": "Accesso a un tipo di trasporto",
          "ADD_ADVICE": "Aggiungi dati avviso",
          "ADD_DOCUMENTS": "Aggiungi documenti",
          "ADD_LOAD_STEP_FROM_LOAD": "Aggiunta di una fase di carico dopo una fase di carico.",
          "ADD_LOAD_STEP_FROM_UNLOAD": "Aggiunta di una fase di carico dopo una fase di scarico.",
          "ADD_NOTES": "Aggiungi note alla tabella",
          "ADD_PACKAGES": "Aggiunta di una confezione",
          "ADD_TIME_WINDOW": "Aggiungi finestra temporale",
          "ADD_UNLOAD_STEP_FROM_LOAD": "Aggiunta di una fase di scarico dopo una fase di carico.",
          "ADD_UNLOAD_STEP_FROM_UNLOAD": "Aggiunta di una fase di scarico dopo una fase di scarico.",
          "BID_AUCTION": "Partecipazione a un’asta",
          "CAN_DELETE_APPLIES_FILES": "Elimina documenti dalla richiesta'vettore",
          "CAN_DELETE_MOT_FILES": "Elimina documenti dalla modalità di trasporto",
          "CREATE_AUCTION": "Crea un’asta",
          "CREATE_TRANSPORT": "Crea un trasporto",
          "DELETE_DOCUMENTS": "Elimina documenti",
          "DELETE_INDEX": "Elimina un indice",
          "DELETE_LOAD_STEPS": "Elimina una fase di carico",
          "DELETE_PACKAGES": "Rimuovi confezione",
          "DELETE_TRANSPORT": "Elimina un trasporto",
          "DELETE_UNLOAD_STEPS": "Elimina una fase di scarico",
          "DISABLE_DATA_FIELDS": "Nascondere dati in un modulo trasporto",
          "EDIT_COURIER_INVOICE_SECTION": "Abilita l’opzione per la modifica della sezione “Fattura per merci”",
          "EDIT_DESCRIPTION_FIELD": "Abilita la possibilità di modificare il campo “Note”",
          "EDIT_INDEX": "Aggiorna un indice",
          "EDIT_PACKAGES": "Modifica confezione",
          "EDIT_TRANSACTION_FREIGHT": "Modifica merce",
          "MOVE_ADVICE_WINDOW": "Sposta un avviso slot temporale",
          "MOVE_LOAD_STEPS": "Sposta fasi di carico",
          "MOVE_UNLOAD_STEPS": "Sposta fasi di scarico",
          "PASS_COURIER": "Trasferimento del trasporto al corriere",
          "PASS_TRANSPORT": "Trasferisci un trasporto",
          "RESIZE_ADVICE_WINDOW": "Ridimensiona un avviso slot temporale",
          "ROLE_ACCESS_ALL_DEDICATED_WINDOWS": "Possibilità di creare un avviso negli slot temporali di qualsiasi azienda",
          "ROLE_ACCESS_ALL_QUEUES": "Accedi a tutte le linee",
          "ROLE_ACCESS_ALL_RAMPS": "Accedi a tutte le rampe/slot nel programma",
          "ROLE_ACCESS_ALL_USER_GROUP_DEDICATED_WINDOWS": "Possibilità di creare un avviso negli slot temporali di qualsiasi reparto",
          "ROLE_ACCESS_API": "Accedi a API REST",
          "ROLE_ACCESS_COURIER_INVOICE_SECTION": "Imposta la visibilità della sezione “Fattura per merci”",
          "ROLE_ACCESS_PALLETS_SUMMARY": "Accedi a dati limiti pallet",
          "ROLE_ACCESS_PRODUCT": "Accedi a dettagli prodotto",
          "ROLE_ACCESS_QUEUES_ACCESSIBILITY": "Visibilità accesso a stalli e linee",
          "ROLE_ACCESS_TASK_DATA": "Accedi a dati attività",
          "ROLE_ACCESS_TO_APPLY_HISTORY": "Accedi alla cronologia richiesta'vettore",
          "ROLE_ACCESS_TO_NUMBER_OF_WINDOWS": "Visualizza contatore slot temporale nella tabella Programma",
          "ROLE_ACCESS_TO_WAREHOUSE_TEMPLATES": "Accedi a modelli programma ",
          "ROLE_ACCESS_TRANSPORT_TYPE_DATA": "Accedi a dati tipo di trasporto",
          "ROLE_ACCESS_TRUCKS": "Accedi a elenco camion",
          "ROLE_ACCESS_USERS_ACTIVITY": "Accedi a cronologia attività utente",
          "ROLE_ACCESS_WAREHOUSE_DATA": "Accedi a dati magazzino",
          "ROLE_ACCESS_WEIGHT_SECTION": "Visibilità sezione Pesatura",
          "ROLE_ACCESS_WORKER_FIELD": "Visibilità del campo “Lavoratori”",
          "ROLE_ACCESS_WORKFLOW_DATA": "Accedi a dati flusso di lavoro",
          "ROLE_ADD_CONTRACTOR_GROUP": "Aggiunta di un gruppo per un appaltatore",
          "ROLE_ADD_NEW_ADDRESS_IN_COMPANY_DETAILS": "Aggiunta di nuovi indirizzi nella sezione dettagli azienda",
          "ROLE_ADD_WINDOW_NOTES": "Aggiunta nota a uno slot temporale disattivo (bloccato)",
          "ROLE_ADVICE_EXCEL": "Scarica ordini in un file Excel",
          "ROLE_ALLOW_CONCURRENT_SESSIONS": "Consente sessione concomitante per un utente",
          "ROLE_ANNOUNCEMENTS_TAB": "Accedi alla scheda Annunci",
          "ROLE_API_MESSAGE_RESENDING": "Nuovo invio messaggio API",
          "ROLE_APPROVE_MOT": "Approvazione della modalità di trasporto",
          "ROLE_ARCHIVE_TAB": "Accedi alla scheda “Archivio”",
          "ROLE_AUTOMATIC_PASS_TRANSPORT_TO_ROUTE_OFFERS": "Trasferimento automatico trasporto",
          "ROLE_BLOCK_RAMPS": "Creazione blocco in un programma in corso",
          "ROLE_CAN_ACCESS_COMPANY_TRANSPORT_LIMIT": "Visibilità campi limite trasporto per l’azienda",
          "ROLE_CAN_ACCESS_COURIERS_SECTION": "Accedi a sezione Corrieri",
          "ROLE_CAN_ACCESS_TASK": "Visibilità attività",
          "ROLE_CAN_ACCESS_TRANSPORT_SUPERVISOR": "Visibilità del campo “Persona di contatto”",
          "ROLE_CAN_ADD_CONFIRMATION_DATE_TASK": "Conferma data attività",
          "ROLE_CAN_ADD_HAS_TO_WAIT_FOR_RELATION": "Aggiunta di un collegamento tra i trasporti",
          "ROLE_CAN_ADD_INVOICE_IN_TRANSACTION": "Aggiungi dati fattura",
          "ROLE_CAN_ADD_MOT": "Aggiungi una modalità di trasporto",
          "ROLE_CAN_ADD_ROUTE": "Aggiunta di un itinerario nel listino prezzi",
          "ROLE_CAN_ADD_TRANSPORT_COMBINING_RELATION": "Aggiunta di un vincolo per trasporti combinati",
          "ROLE_CAN_AUTOMATICALLY_CONFIRM_PREVIOUS_CHECKPOINTS": "Conferma automatica di tutte le fasi precedenti",
          "ROLE_CAN_BLOCK_TASK": "Blocco conferma attività",
          "ROLE_CAN_CHOOSE_MORE_THAN_ONE_LOCATION": "Selezione di più di 1 valere nel campo “Sede”",
          "ROLE_CAN_CONFIRM_ROUTE_OFFER": "Approvazione offerta nel listino prezzi",
          "ROLE_CAN_CONFIRM_TASK": "Conferma attività",
          "ROLE_CAN_CREATE_DELIVERY": "Abilita la possibilità di creare spedizioni nella scheda “Pianificazione”",
          "ROLE_CAN_CREATE_MAX_PALLET": "Possibilità di impostare un limite pallet",
          "ROLE_CAN_CREATE_ROUTE_OFFER": "Aggiunta offerta nel listino prezzi",
          "ROLE_CAN_CREATE_TRANSPORT_FROM_SCHEDULE": "Creazione di un trasporto direttamente da un programma",
          "ROLE_CAN_DELETE_DELIVERY": "Abilita l’eliminazione di consegne nella scheda “Pianificazione”",
          "ROLE_CAN_DELETE_RELATION": "Eliminazione del vincolo di trasporti",
          "ROLE_CAN_DELETE_ROUTE": "Eliminazioni degli itinerari dai listini prezzi",
          "ROLE_CAN_DELETE_ROUTE_OFFER": "Rimozione offerta dal listino prezzi",
          "ROLE_CAN_DOWNLOAD_ENTRY_PASS": "Abilita il download del pass di ingresso",
          "ROLE_CAN_EDIT_ADDRESSES": "Modifica indirizzi",
          "ROLE_CAN_EDIT_COMPANY_TRANSPORT_LIMIT": "Modifica campo con il limite di trasporti per l’azienda",
          "ROLE_CAN_EDIT_COURIERS_SECTION": "Modifica dei campi nella sezione Corrieri",
          "ROLE_CAN_EDIT_DELIVERY": "Abilita la possibilità di modificare spedizioni nella scheda “Pianificazione”",
          "ROLE_CAN_EDIT_INVOICE_IN_TRANSACTION": "Modifica dati fattura API",
          "ROLE_CAN_EDIT_READY_TO_PLAN": "Modifica del flag “Pronto per pianificazione” nel modulo trasporto",
          "ROLE_CAN_EDIT_ROUTE": "Modifica degli itinerari nei listini prezzi",
          "ROLE_CAN_EDIT_ROUTE_OFFER": "Modifica offerta nel listino prezzi",
          "ROLE_CAN_EDIT_TRANSPORT_SUPERVISOR": "Abilita la modifica del campo “Persona di contatto”",
          "ROLE_CAN_EXCEED_MAX_PALLET": "Invio avvisi superamento limite pallet",
          "ROLE_CAN_EXCEED_MAX_PALLET_ON_DEDICATED_WINDOWS": "Avvisi relativi al limite pallet in finestre dedicate",
          "ROLE_CAN_GENERATE_FULL_SETTLEMENT_REPORT": "Scarica report fatturazione in un file Excel",
          "ROLE_CAN_GENERATE_LIST_OF_POINTS_REPORT": "Download elenco punti in un file Excel in corso.",
          "ROLE_CAN_GENERATE_SHORT_SETTLEMENT_REPORT": "Generazione report avviso in corso",
          "ROLE_CAN_MOVE_WINDOW_OUT_OF_ASSORTMENT_GROUP": "Consente di spostare uno slot temporale al di fuori di un gruppo assortimento",
          "ROLE_CAN_PLAN_ADVICE_IN_THE_PAST": "Programmazione di un avviso nel passato",
          "ROLE_CAN_REJECT_TASK": "Rifiuto attività",
          "ROLE_CAN_REVOKE_TASK": "Revoca attività",
          "ROLE_CAN_SEE_CURRENT_PLACE_IN_AUCTION": "Visibilità posizione attualmente occupata in un’asta in corso",
          "ROLE_CAN_SEND_EMAIL_ADVICE": "Avviso e-mail - invio di una richiesta di dati avviso",
          "ROLE_CAN_SEND_EMAIL_ADVICE_CF": "Avviso e-mail - invio di una richiesta di completamento dati",
          "ROLE_CAN_SKIP_CHECKPOINT_CONFIRMATION": "Salta conferma della fase",
          "ROLE_CAN_UPDATE_VEHICLE_MAIN_DATA_SECTION": "Modifica dati principali mezzi di trasporto",
          "ROLE_CARRIERS_TAB": "Accedi alla scheda “Vettori”",
          "ROLE_CHANGE_WAREHOUSE": "Seleziona un magazzino in un programma",
          "ROLE_CLASSIFICATION_TAB": "Accedi alla scheda “Classificazione”",
          "ROLE_COMPANIES_TAB": "Accedi alla scheda “Aziende”",
          "ROLE_CREATE_ASSORTMENT_GROUP": "Crea un gruppo assortimento",
          "ROLE_CREATE_BLOCKED_WINDOWS": "Crea slot bloccati",
          "ROLE_CREATE_COMPANY": "Crea una nuova azienda",
          "ROLE_CREATE_CUSTOM_FIELD_DEFINITIONS": "Accesso alle impostazioni dei campi personalizzati",
          "ROLE_CREATE_DISCREPANCY_PROTOCOL": "Generazione di un record delle divergenze",
          "ROLE_CREATE_DRIVER": "Crea un nuovo autista",
          "ROLE_CREATE_GUARD_ROLE_GROUP": "Creazione di un ruolo Guardia",
          "ROLE_CREATE_PLACE": "Creazione posizioni",
          "ROLE_CREATE_PRODUCT": "Crea un prodotto",
          "ROLE_CREATE_QUEUE": "Creazione linee",
          "ROLE_CREATE_RAMP": "Crea una nuova rampa",
          "ROLE_CREATE_ROLE_GROUP": "Possibilità di creare un nuovo gruppo ruolo",
          "ROLE_CREATE_SIMILAR": "Crea un trasporto simile",
          "ROLE_CREATE_SIMILAR_MULTIPLE": "Crea simile - possibilità di definire il numero di copie dell’ordine",
          "ROLE_CREATE_SQUARE": "Creazione stalli",
          "ROLE_CREATE_TRUCK": "Crea un nuovo camion",
          "ROLE_CREATE_USED_FOR_DELIVERIRES_ROLE_GROUP": "Creazione di un ruolo Pianificazione consegne",
          "ROLE_CREATE_USER": "Crea un nuovo utente",
          "ROLE_CREATE_WAREHOUSE": "Crea un nuovo magazzino",
          "ROLE_CREATE_WAREHOUSE_ZONE": "Crea una zona magazzino",
          "ROLE_DELETE_COMPANY": "Disabilita un’azienda",
          "ROLE_DELETE_DRIVER": "Elimina un autista",
          "ROLE_DELETE_INVITATION": "Eliminazione invito attivo",
          "ROLE_DELETE_MULTIPLE_PERIODIC_TRANSPORTS": "Rimozione di avvisi periodici multipli",
          "ROLE_DELETE_PLACE": "Rimozione della posizione",
          "ROLE_DELETE_PRODUCT": "Elimina un prodotto",
          "ROLE_DELETE_QUEUE": "Rimozione della linea",
          "ROLE_DELETE_RAMP": "Elimina una rampa",
          "ROLE_DELETE_SQUARE": "Rimozione dello stallo",
          "ROLE_DELETE_TRUCK": "Elimina un veicolo",
          "ROLE_DELETE_USER": "Elimina un’utente",
          "ROLE_DELETE_WAREHOUSE": "Elimina un magazzino",
          "ROLE_DEV_TOOLS": "Strumenti dev. aggiuntivi ",
          "ROLE_DISABLE_USER": "Disabilita un utente",
          "ROLE_EDIT_ALL_USERS": "Modifica di tutti gli utenti",
          "ROLE_EDIT_ASSORTMENT_GROUP": "Modifica un gruppo assortimento",
          "ROLE_EDIT_COMPANY": "Elimina campi di un’azienda selezionati",
          "ROLE_EDIT_CONTRACTOR_GROUP": "Modifica Gruppo appaltatore",
          "ROLE_EDIT_GUS_DATA": "Modifica dei dati scaricati dall’Ufficio centrale di statistica",
          "ROLE_EDIT_INCOMPLETE_LOADING": "Gestione casella di controllo “Carico non completo”.",
          "ROLE_EDIT_MULTIPLE_PERIODIC_TRANSPORTS": "Modifica di avvisi periodici multipli",
          "ROLE_EDIT_MY_NOTIFICATION": "Modifica delle impostazioni delle proprie notifiche",
          "ROLE_EDIT_MY_QUEUES": "Modifica dell’accesso alle proprie linee e stalli",
          "ROLE_EDIT_MY_RAMP": "Modifica dell’accesso alle proprie rampe",
          "ROLE_EDIT_PLACE": "Modifica posizione",
          "ROLE_EDIT_PRODUCT": "Aggiorna un prodotto",
          "ROLE_EDIT_QUEUE": "Modifica linee",
          "ROLE_EDIT_RAMP": "Modifica una rampa",
          "ROLE_EDIT_ROLE_GROUP": "Modifica ruolo utente",
          "ROLE_EDIT_SQUARE": "Modifica stalli",
          "ROLE_EDIT_USERS_MAIN_DATA": "Modifica dell’anagrafica utente",
          "ROLE_EDIT_USERS_NOTIFICATION": "Modifica delle notifiche",
          "ROLE_EDIT_USERS_RAMP": "Modifica dell’accesso utente alle rampe",
          "ROLE_EDIT_WAREHOUSE": "Modifica un magazzino",
          "ROLE_EDIT_WAREHOUSE_LOCATION": "Modifica campo sede magazzino",
          "ROLE_EDIT_WAREHOUSE_ZONE": "Modifica una zona magazzino",
          "ROLE_EDIT_WEIGHT": "Modifica peso nella sezione “Pesatura”",
          "ROLE_EDIT_WORKER_FIELD": "Modifica del campo “Lavoratori”",
          "ROLE_EDIT_WORKFLOW": "Modifica impostazioni stato",
          "ROLE_FILTER_ACTIVE_TRANSPORTS_TABLE": "La visibilità dei filtri in base alle colonne nella tabella ordini attivi",
          "ROLE_FULL_EDIT_COMPANY": "Modifica tutti i campi di un’azienda",
          "ROLE_HIDE_TRANSPORTS_WITHOUT_ACTIVE_WAREHOUSE_STATUSES": "Nascondere trasporti dopo la conferma dell’ultimo stato nel magazzino disponibile",
          "ROLE_INVITATIONS_TAB": "Accedi alla scheda “Inviti”",
          "ROLE_INVITE_AS_TEMPORARY_USER_WHEN_ACCOUNT_LIMIT_EXCEEDED": "Invito di utenti temporanei che superano i limiti dell’azienda",
          "ROLE_INVOICE_COLUMN": "Visualizza colonna fattura",
          "ROLE_MANAGE_ADMIN_ANNOUNCEMENTS": "Gestione annunci amministrativi",
          "ROLE_MANAGE_ADVICE_VERIFICATION_MAIL": "Gestisci una notifica e-mail per le richiesta di verifica della data",
          "ROLE_MANAGE_ALLOWED_DOMAINS": "Accedi a gestione domini e-mail consentiti per l’azienda",
          "ROLE_MANAGE_ANNOUNCEMENTS": "Possibilità di gestire annunci",
          "ROLE_MANAGE_APPLIES_SETTINGS": "Accedi a impostazioni gestione richieste",
          "ROLE_MANAGE_APPLY_SECTIONS": "Gestione vettori' applicazioni",
          "ROLE_MANAGE_ARTR_SETTINGS": "Gestione impostazioni ANPR",
          "ROLE_MANAGE_AUCTION_SETTINGS": "Accedi a impostazioni gestione asta",
          "ROLE_MANAGE_AUTHORITIES": "Possibilità di gestire autorizzazioni per un ruolo",
          "ROLE_MANAGE_CMR_SETTINGS": "Gestione impostazioni file CMR",
          "ROLE_MANAGE_COLUMN_VISIBILITY": "Gestisci visibilità colonna",
          "ROLE_MANAGE_COMPANY_PRICING_PLAN": "Modifica opzione iscrizione per l’azienda",
          "ROLE_MANAGE_COMPANY_RAMP": "Accesso a modifica rampa per l’azienda",
          "ROLE_MANAGE_DEDICATED_WINDOWS": "Gestisci slot dedicati",
          "ROLE_MANAGE_LOCAL_ADMINS": "Gestione di amministratori locali in aziende appaltatore",
          "ROLE_MANAGE_MAX_COMPANY_ACCOUNT_NUMBER": "Modifica numero massimo di account per l’azienda",
          "ROLE_MANAGE_MOT_SECTIONS": "Gestione delle modalità di trasporto",
          "ROLE_MANAGE_NOTIFICATIONS": "Gestisci notifiche",
          "ROLE_MANAGE_PACKAGE_TYPE_TRANSPORT_TYPE": "Gestisci impostazioni pacchetto",
          "ROLE_MANAGE_PARTNERSHIP": "Gestisci connessioni partnership",
          "ROLE_MANAGE_PRINCIPAL_DEDICATED_WINDOWS": "Creazione finestre dedicate alle aziende clienti",
          "ROLE_MANAGE_QUEUES_ACCESSIBILITY": "Gestione dell’accesso a stalli e linee",
          "ROLE_MANAGE_SETTINGS": "Impostazioni gestione",
          "ROLE_MANAGE_TRANSPORT_TYPE": "Gestisci impostazioni tipo di trasporto",
          "ROLE_MANAGE_USER_GROUPS": "Gestione gruppo all’interno dell’azienda'dell’appaltatore",
          "ROLE_MANAGE_USER_ROLE_INVITATIONS": "Gestisci inviti ruolo utente",
          "ROLE_MODE_OF_TRANSPORTATION_TAB": "Accedi alla scheda “Modalità di trasporto”",
          "ROLE_MOVE_BLOCKED_WINDOWS": "Sposta slot bloccati",
          "ROLE_MOVE_DEDICATED_WINDOWS": "Sposta slot dedicati",
          "ROLE_MOVE_WINDOWS_BETWEEN_WAREHOUSE_ZONES": "Spostamento degli slot temporali tra zone magazzino",
          "ROLE_OWN_COLUMNS_EXCEL": "Genera un report in base alle colonne della tabella",
          "ROLE_OWN_COMPANY_TAB": "Accedi alla scheda “Azienda” nell’account dell’appaltatore",
          "ROLE_PERFORM_ACTION_IN_WAREHOUSE": "Esecuzione azioni in un magazzino",
          "ROLE_PLANNING_TAB": "Accedi alla scheda Pianificazione",
          "ROLE_PRICE_LIST_TAB": "Accedi alla scheda “Listini prezzi”",
          "ROLE_PRODUCTS_TAB": "Accedi a elenco prodotti",
          "ROLE_QUEUES_TAB": "Accedi alla scheda “Linee”",
          "ROLE_QUEUE_STATUS_TAB": "Accedi alla scheda “Stato linea”",
          "ROLE_READ_WINDOW_NOTES": "Visibilità note in uno slot temporale disattivo (bloccato)",
          "ROLE_REJECT_CHECKPOINT": "Rifiuto checkpoint",
          "ROLE_RESIZE_BLOCKED_WINDOWS": "Ridimensiona slot bloccati",
          "ROLE_RESIZE_DEDICATED_WINDOWS": "Ridimensiona slot dedicati",
          "ROLE_RESTORE_ORDER_FROM_ARCHIVE": "Possibilità di ripristinare ordini dall’archivio",
          "ROLE_REVOKE_CHECKPOINT": "Revoca un checkpoint",
          "ROLE_SEND_SMS_TO_ALL_DRIVERS_IN_QUEUE": "Possibilità di inviare notifiche di testo a tutti gli autisti in una determinata linea",
          "ROLE_SETTINGS_TAB": "Accedi alla scheda “Impostazioni”",
          "ROLE_SHOW_API_INTEGRATION_LOGS": "Accedi alla sezione integrazione",
          "ROLE_SHOW_AWAITING_INVITATIONS_TABLE": "Visualizza elenco inviti in attesa",
          "ROLE_SHOW_CUSTOM_FIELD_DEFINITIONS": "Gestione campi personalizzati",
          "ROLE_SHOW_PARTNERSHIP_TABLE": "Visualizza elenco partnership",
          "ROLE_SHOW_TRANSPORT_LOGS": "Accedi alla cronologia ordine",
          "ROLE_SORT_TRANSPORT_TABLE": "Ordinamento della tabella trasporto",
          "ROLE_STATISTICS_REPORT_TAB": "Accedi alla scheda “Statistiche”",
          "ROLE_SUPER_USER": "Autorizzazioni utente speciale",
          "ROLE_TRANSPORT_TABLE": "Accedi alla tabella trasporto",
          "ROLE_USERS_TAB": "Accedi alla scheda “Utenti”",
          "ROLE_WAREHOUSE_TAB": "Accedi alla scheda “Programma”",
          "SWAP_ADVICE_WINDOWS": "Scambia posizioni avviso slot temporali",
          "UNLIMITED_ACCESS_TO_ARCHIVED_TRANSPORTS": "Accesso illimitato agli ordini in archivio",
          "UPDATE_ADVICE": "Aggiorna un avviso",
          "UPDATE_CARRIER_COMPANY_FIELD": "Aggiorna campo “Vettore”",
          "UPDATE_CUSTOM_FIELD": "Aggiorna un campo personalizzato",
          "UPDATE_MAIN_DATA_SECTION": "Aggiorna campi trasporto principale",
          "UPDATE_SERVICE_COMPANY_FIELD": "Aggiorna campo “Servizio”"
        },
        "authorityRangeLabel": "Intervallo autorità",
        "authorityRangeLabels": {
          "ACTIVE": "Se l’ordine non è ancora stato archiviato.",
          "ACTIVE_AUCTION_BASED": "Dipende dall’asta attiva assegnata all’ordine.",
          "ADVICE_RESTRICTION_BASED": "Durante un intervallo di tempo specificato per un avviso.",
          "AFTER_CHECKPOINT_BASED": "Dopo la conferma del checkpoint.",
          "BEFORE_CHECKPOINT_BASED": "Fino alla conferma del checkpoint.",
          "BLOCKED": "Azione bloccata.",
          "NO_ROUTE_OFFER": "Dipende dall’accettazione del trasporto da parte del vettore alla tariffa indicata nel listino prezzi.",
          "OWN_COMPANY_BASED": "Dipende dall’azienda del creatore di questo ordine.",
          "TIME_BASED": "Dipendente dal tempo",
          "UNLIMITED": "Senza condizioni aggiuntive"
        },
        "authorityRangePhrases": {
          "ACTIVE": "Fino a quando l’ordine  è ancora archiviato.",
          "ACTIVE_AUCTION_BASED": "se il trasporto non ha un’asta attiva.",
          "ADVICE_RESTRICTION_BASED": "durante un intervallo di tempo specificato per un avviso.",
          "AFTER_CHECKPOINT_BASED": "dopo la conferma del checkpoint {checkpointName}.",
          "BEFORE_CHECKPOINT_BASED": "fino alla conferma del checkpoint {checkpointName}.",
          "BLOCKED": "azione bloccata.",
          "CHECKPOINT_BASED": "In base al checkpoint {checkpointName}.",
          "NO_ROUTE_OFFER": "in assenza di offerte preferite o quando i vettori con offerte valide nel listino prezzi non hanno accettato l’esecuzione del trasporto.",
          "OWN_COMPANY_BASED": "all’interno dell’azienda del creatore.",
          "TIME_BASED": "entro {timeInterval} {afterOrBefore} {timeReference}.",
          "TIME_BASED_CURRENT_DATE_AFTER": "{timeInterval} dopo la data attuale.",
          "TIME_BASED_CURRENT_DATE_BEFORE": "fino a {timeInterval} dalla data attuale.",
          "UNLIMITED": "senza condizioni aggiuntive."
        },
        "checkpoint": "Stato",
        "conditions": "Condizioni",
        "create": {
          "copyFrom": "Copia autorizzazioni da altro ruolo:",
          "info": "Le autorizzazioni possono essere modificate dopo il salvataggio del ruolo",
          "isGuard": "Ruolo Guardia",
          "isGuardInfo": "Ruolo con conferme checkpoint semplificate",
          "label": "Aggiungi un ruolo",
          "nameEn": "Nome - IT",
          "namePl": "Nome",
          "noPartnershipInfo": "Nella creazione di un ruolo principale non risulterà alcuna partnership",
          "partnershipType": "Tipo di partnership:",
          "usedForDeliveries": "Ruolo pianificazione consegna",
          "usedForDeliveriesInfo": "Ruolo per pianificazione consegne nella scheda Pianificazione"
        },
        "customFieldDefinition": "Campo personalizzato",
        "dateAfterPhrase": "dopo la data di specifica",
        "dateBeforePhrase": "prima della data di specifica",
        "deleteAllAuthoritiesTitle": "Pulsante rimozione di tutte le autorizzazioni da {title}",
        "generalAuthorityDescriptions": {
          "ACCESS_TO_TRANSPORT_DETAILS": "Abilita l’accesso a dettagli ordine",
          "ADVICE_ONLY_ON_DEDICATED_WINDOWS": "Il diritto limita la possibilità di aggiungere una finestra temporale solo al posto di una finestra dedicata disponibile per l’utente che aggiunge la finestra",
          "CAN_DELETE_APPLIES_FILES": "Possibilità di eliminare documenti allegati'della richiesta vettore.",
          "CAN_DELETE_MOT_FILES": "Consente di eliminare documenti allegati a una modalità di trasporto.",
          "ROLE_ACCESS_ALL_DEDICATED_WINDOWS": "Consente di creare un avviso negli slot temporali dedicati di qualsiasi azienda",
          "ROLE_ACCESS_ALL_QUEUES": "Accedi a tutte le linee.",
          "ROLE_ACCESS_ALL_RAMPS": "Accedi a tutte le rampe/slot nel programma.",
          "ROLE_ACCESS_ALL_USER_GROUP_DEDICATED_WINDOWS": "Consente di creare un avviso negli slot temporali dedicati di qualsiasi reparto",
          "ROLE_ACCESS_API": "Consente l’accesso ai metodi API REST per l’utente",
          "ROLE_ACCESS_AUCTION_DATA": "Visibilità dati asta",
          "ROLE_ACCESS_FREIGHT_DATA": "Visibilità tariffa merce",
          "ROLE_ACCESS_PALLETS_SUMMARY": "Accedi a dati limiti pallet",
          "ROLE_ACCESS_PRODUCT": "Aggiunge gli accessi ai dettagli del prodotto.",
          "ROLE_ACCESS_QUEUES_ACCESSIBILITY": "L’autorizzazione consente la gestione della visibilità degli accessi a stalli e linee. Se questa autorizzazione è abilitata, l’articolo è visibile nei dettagli utente.",
          "ROLE_ACCESS_TASK_DATA": "Accedi a dati attività",
          "ROLE_ACCESS_TO_APPLY_HISTORY": "Accesso alla cronologia'della richiesta vettore.",
          "ROLE_ACCESS_TO_INSPECTION_BUTTON": "Visibilità del pulsante di controllo nella tabella trasporto nella colonna “Ispezione”",
          "ROLE_ACCESS_TO_INSPECTION_PANEL": "Visibilità del pannello “Ispezione”",
          "ROLE_ACCESS_TO_LOCATION": "Visibility of the 'Sede' field.",
          "ROLE_ACCESS_TO_NUMBER_OF_WINDOWS": "Abilita la visualizzazione di un contatore slot temporale nel Programma",
          "ROLE_ACCESS_TO_WAREHOUSE_TEMPLATES": "Consente di gestire modelli di slot temporali nel programma.",
          "ROLE_ACCESS_TRANSPORT_ADDITIONAL_DESCRIPTION": "Visibilità del campo “Note aggiuntive”",
          "ROLE_ACCESS_TRANSPORT_TYPE_DATA": "Accedi a dati tipo di trasporto",
          "ROLE_ACCESS_TRUCKS": "Accedi a elenco camion",
          "ROLE_ACCESS_USERS_ACTIVITY": "Abilita l’accesso alla cronologia dell’attività utente",
          "ROLE_ACCESS_WAREHOUSE_DATA": "Accedi a dati magazzino",
          "ROLE_ACCESS_WORKFLOW_DATA": "Accedi a dati flusso di lavoro",
          "ROLE_ADD_CONTRACTOR_GROUP": "Possibilità di aggiungere un nuovo gruppo per un appaltatore",
          "ROLE_ADD_NEW_ADDRESS_IN_COMPANY_DETAILS": "Consente all’utente di aggiungere un nuovo indirizzo nella sezione dettagli azienda",
          "ROLE_ADD_WINDOW_NOTES": "Abilita la possibilità di aggiungere note a slot temporali disattivati.",
          "ROLE_ADVICE_EXCEL": "Consente di esportare dati ordine in un file Excel.",
          "ROLE_ALLOW_CONCURRENT_SESSIONS": "Consente sessione concomitante per un utente da browser multipli",
          "ROLE_ANNOUNCEMENTS_TAB": "Abilita l’accesso alla scheda Annunci",
          "ROLE_API_MESSAGE_RESENDING": "Possibilità di inviare nuovamente un messaggio API",
          "ROLE_APPROVE_MOT": "Consente di approvare una modalità di trasporto.",
          "ROLE_ARCHIVE_TAB": "Abilita l’accesso alla scheda “Archivio”.",
          "ROLE_BLOCK_RAMPS": "Possibilità di bloccare uno slot nel programma e di bloccare l’intera rampa dopo aver fatto clic sull’intestazione della rampa.",
          "ROLE_CAN_ACCESS_ARCHIVED_TRUCK_DOCUMENTS": "Abilita l’accesso ai documenti di archivio nei dettagli della modalità di trasporto nella scheda “Modalità di trasporto”.",
          "ROLE_CAN_ACCESS_COMPANY_TRANSPORT_LIMIT": "Abilita la visibilità dei campi correlati al limite di trasporti per l’azienda",
          "ROLE_CAN_ACCESS_COURIERS_SECTION": "Imposta l’accesso alla sezione “Corrieri” nel modulo trasporto",
          "ROLE_CAN_ADD_HAS_TO_WAIT_FOR_RELATION": "Abilita la possibilità di aggiungere un link tra i trasporti",
          "ROLE_CAN_ADD_INVOICE_IN_TRANSACTION": "Consente di aggiungere dati fattura a una transazione dalla tabella trasporto",
          "ROLE_CAN_ADD_MOT": "Consente di aggiungere una modalità di trasporto.",
          "ROLE_CAN_ADD_ROUTE": "Possibilità di aggiungere un nuovo itinerario al listino prezzi",
          "ROLE_CAN_ADD_TRANSPORT_COMBINING_RELATION": "Abilita la possibilità di collegare insieme i trasporti. I trasporti abbinati hanno notifica, finestra o stati comuni.",
          "ROLE_CAN_AUTOMATICALLY_CONFIRM_PREVIOUS_CHECKPOINTS": "Confermando lo stato indicato, l’utente conferma automaticamente anche tutti gli stati precedentemente non confermati",
          "ROLE_CAN_CHOOSE_MORE_THAN_ONE_LOCATION": "Abilita la selezione di più di 1 valore nel campo “Sede” nei dettagli ordine. Richiede anche l’autorizzazione: “Accesso a tipo di trasporto”",
          "ROLE_CAN_CREATE_DELIVERY": "Abilita la possibilità di creare spedizioni nella scheda “Pianificazione”",
          "ROLE_CAN_CREATE_MAX_PALLET": "Possibilità di impostare il limite pallet.",
          "ROLE_CAN_CREATE_ROUTE_OFFER": "Possibilità di aggiungere un nuova offerta al listino prezzi",
          "ROLE_CAN_DELETE_DELIVERY": "Abilita l’eliminazione di consegne nella scheda “Pianificazione”",
          "ROLE_CAN_DELETE_RELATION": "Abilita la possibilità di slegare i trasporti. L’autorizzazione consente di scollegare uno dei trasporti.",
          "ROLE_CAN_DELETE_ROUTE": "Possibilità di eliminare itinerari nei listini prezzi",
          "ROLE_CAN_DELETE_ROUTE_OFFER": "Possibilità di rimuovere un’offerta dal listino prezzi",
          "ROLE_CAN_DOWNLOAD_ENTRY_PASS": "Download pass di ingresso",
          "ROLE_CAN_DOWNLOAD_REGISTRY_OF_PERSONS_XLS": "Download di registri delle persone nell’impianto",
          "ROLE_CAN_EDIT_ADDRESSES": "Possibilità di modificare e rimuovere indirizzi nella scheda “Azienda”.",
          "ROLE_CAN_EDIT_COMPANY_TRANSPORT_LIMIT": "Abilita la modifica del campo con il limite di trasporti per l’azienda",
          "ROLE_CAN_EDIT_COURIERS_SECTION": "Consente di modificare i campi nella sezione “Corrieri” nel modulo trasporto. Richiede l’autorizzazione “Accedi alla sezione corrieri”.",
          "ROLE_CAN_EDIT_DELIVERY": "Abilita la possibilità di modificare spedizioni nella scheda “Pianificazione”",
          "ROLE_CAN_EDIT_INVOICE_IN_TRANSACTION": "Consente di modificare dati fattura a una transazione trasporto tramite API pubblico",
          "ROLE_CAN_EDIT_READY_TO_PLAN": "Abilita la possibilità di modificare il flag “Pronto per pianificazione” nel modulo trasporto",
          "ROLE_CAN_EDIT_ROUTE": "Possibilità di modificare itinerari nei listini prezzi",
          "ROLE_CAN_EDIT_ROUTE_OFFER": "Possibilità di modificare un’offerta esistente al listino prezzi",
          "ROLE_CAN_EDIT_TRANSPORT_PALLETS_WITHOUT_CHANGING_TIME_WINDOW_SIZE": "Consente di aumentare il numero di confezioni nel trasporto, anche nonostante la mancanza di spazio, per ampliare la finestra temporale conformemente all’algoritmo.",
          "ROLE_CAN_EXCEED_MAX_PALLET": "Possibilità di avviso superamento limite pallet.",
          "ROLE_CAN_EXCEED_MAX_PALLET_ON_DEDICATED_WINDOWS": "Possibilità di avviso superamento limite pallet in caso di finestra temporale situata interamente nelle finestre temporali.",
          "ROLE_CAN_GENERATE_FULL_SETTLEMENT_REPORT": "Consente di esportare dati report fatturazione in un file Excel.",
          "ROLE_CAN_GENERATE_LIST_OF_POINTS_REPORT": "L’autorizzazione consente di esportare un elenco di punti in un file Excel.",
          "ROLE_CAN_GENERATE_SHORT_SETTLEMENT_REPORT": "Consente di generare un report avviso in formato file Excel",
          "ROLE_CAN_MOVE_WINDOW_OUT_OF_ASSORTMENT_GROUP": "Consente di spostare lo slot temporale al di fuori di un gruppo assortimento",
          "ROLE_CAN_MULTI_ARCHIVE_TRANSPORTS": "Archiviazione di gruppo dei trasporti",
          "ROLE_CAN_PLAN_ADVICE_IN_THE_PAST": "Consente di programmare un avviso nel passato",
          "ROLE_CAN_SCAN_BARCODES": "Scansiona codici a barre",
          "ROLE_CAN_SEE_CURRENT_PLACE_IN_AUCTION": "Visibilità posizione attualmente occupata in un’asta in corso",
          "ROLE_CAN_SKIP_CHECKPOINT_CONFIRMATION": "Consente di saltare una fase nonostante la conferma ordine necessaria per ciascuna fase",
          "ROLE_CAN_UPDATE_VEHICLE_MAIN_DATA_SECTION": "Modifica dati principali dei mezzi di trasporto",
          "ROLE_CARRIERS_TAB": "Abilita l’accesso alla scheda “Vettori”.",
          "ROLE_CHANGE_WAREHOUSE": "Possibilità di modificare il magazzino nel programma.",
          "ROLE_CLASSIFICATION_TAB": "Abilita l’accesso alla scheda “Classificazione”",
          "ROLE_COMPANIES_TAB": "Abilita l’accesso alla scheda “Aziende”.",
          "ROLE_CREATE_ASSORTMENT_GROUP": "Possibilità di aggiungere un nuovo gruppo assortimento.",
          "ROLE_CREATE_BLOCKED_WINDOWS": "Abilita la possibilità di creare slot temporali disattivati nella visualizzazione programma.",
          "ROLE_CREATE_COMPANY": "Questa autorizzazione consente di creare una nuova azienda senza l’invio di un invito.",
          "ROLE_CREATE_CUSTOM_FIELD_DEFINITIONS": "Questa autorizzazione consente l’accesso a impostazioni campo personalizzato.",
          "ROLE_CREATE_DISCREPANCY_PROTOCOL": "Consente di generare un record delle divergenze",
          "ROLE_CREATE_DRIVER": "Possibilità di aggiungere un nuovo autista",
          "ROLE_CREATE_GUARD_ROLE_GROUP": "Possibilità di creare un ruolo Guardia",
          "ROLE_CREATE_PLACE": "Il ruolo consente di creare una nuova posizione",
          "ROLE_CREATE_PRODUCT": "Abilita la possibilità di aggiungere un nuovo prodotto. Richiede anche il livello autorizzazione: “Accesso ai prodotti”.",
          "ROLE_CREATE_QUEUE": "Il ruolo consente di creare una nuova linea",
          "ROLE_CREATE_RAMP": "Possibilità di aggiungere una nuova rampa a un magazzino esistente. Ruolo richiesto: “Modifica un magazzino”.",
          "ROLE_CREATE_ROLE_GROUP": "Consente di creare un nuovo gruppo ruolo",
          "ROLE_CREATE_SIMILAR": "Possibilità di creare un ordine simile (copia dell’ordine).",
          "ROLE_CREATE_SIMILAR_MULTIPLE": "Abilita la possibilità di definire il numero di ordini da creare",
          "ROLE_CREATE_SQUARE": "Il ruolo consente di creare un nuovo stallo",
          "ROLE_CREATE_TRUCK": "Possibilità di aggiungere un nuovo veicolo",
          "ROLE_CREATE_USED_FOR_DELIVERIRES_ROLE_GROUP": "Possibilità di creare il ruolo di creazione consegne",
          "ROLE_CREATE_USER": "Questa autorizzazione consente di creare un nuovo utente senza l’invio di un invito.",
          "ROLE_CREATE_WAREHOUSE": "Possibilità di aggiungere un nuovo magazzino. Ruolo richiesto: “Modifica un magazzino”. ",
          "ROLE_CREATE_WAREHOUSE_ZONE": "Possibilità di creare una nuova zona magazzino.",
          "ROLE_CREATE_WORKER": "Crea nuovo lavoratore",
          "ROLE_DELETE_COMPANY": "Possibilità di disabilitare un’azienda.",
          "ROLE_DELETE_DRIVER": "Possibilità di rimuovere un autista. ",
          "ROLE_DELETE_INVITATION": "Consente di eliminare un invito attivo",
          "ROLE_DELETE_MULTIPLE_PERIODIC_TRANSPORTS": "Possibilità di rimuovere avvisi periodici multipli",
          "ROLE_DELETE_PLACE": "Consente di rimuovere la posizione",
          "ROLE_DELETE_PRODUCT": "Un utente con questo livello di autorizzazione può eliminare un prodotto esistente. Richiede anche il livello autorizzazione “Accesso ai prodotti”.",
          "ROLE_DELETE_QUEUE": "Consente di rimuovere la linea",
          "ROLE_DELETE_RAMP": "Possibilità di eliminare una rampa. Ruolo richiesto: “Modifica un magazzino”.",
          "ROLE_DELETE_SQUARE": "Consente di rimuovere lo stallo",
          "ROLE_DELETE_TRUCK": "Possibilità di eliminare un veicolo.",
          "ROLE_DELETE_USER": "Possibilità di eliminare un’utente. Una volta eliminato, l’utente non avrà più accesso alla piattaforma.",
          "ROLE_DELETE_WAREHOUSE": "Possibilità di eliminare un magazzino. Ruolo richiesto: “Modifica un magazzino”.",
          "ROLE_DELETE_WORKER": "Elimina lavoratore",
          "ROLE_DEV_TOOLS": "Strumenti dev. aggiuntivi",
          "ROLE_DISABLE_USER": "Possibilità di disabilitare l’utente. Richiede l’autorizzazione “Modifica anagrafica utente”. In caso di utenti terze parti, è richiesta inoltre l’autorizzazione “Modifica tutti gli utenti”.",
          "ROLE_EDIT_ALL_USERS": "Gli utenti di terze parti possono essere modificati. Richiede una tra le seguenti autorizzazioni: “Modifica anagrafica utente”, “Modifica accesso utente alle rampe”, “Modifica notifiche utente”.",
          "ROLE_EDIT_ASSORTMENT_GROUP": "Possibilità di modificare un gruppo assortimento.",
          "ROLE_EDIT_COMPANY": "Possibilità di modificare'il nome abbreviato dell’azienda dell’appaltatore, il numero di contratto e i dettagli aggiuntivi",
          "ROLE_EDIT_CONTRACTOR_GROUP": "Possibilità di modificare gruppi appaltatore esistenti",
          "ROLE_EDIT_GUS_DATA": "Possibilità di modificare i dati azienda, che sono stati verificati dall’Ufficio centrale di statistica",
          "ROLE_EDIT_INCOMPLETE_LOADING": "Possibilità di selezionare/deselezionare la casella di controllo “Carico non completo”.",
          "ROLE_EDIT_MULTIPLE_PERIODIC_TRANSPORTS": "Possibilità di modificare avvisi periodici multipli",
          "ROLE_EDIT_MY_NOTIFICATION": "Modifica delle impostazioni delle proprie notifiche",
          "ROLE_EDIT_MY_QUEUES": "Modifica dell’accesso alle proprie linee e stalli",
          "ROLE_EDIT_MY_RAMP": "Modifica dell’accesso alle proprie rampe",
          "ROLE_EDIT_PLACE": "Il ruolo consente di modificare una posizione",
          "ROLE_EDIT_PRODUCT": "Consente di modificare un prodotto esistente. Richiede anche il livello autorizzazione: “Accesso ai prodotti”.",
          "ROLE_EDIT_QUEUE": "Il ruolo consente di modificare una linea",
          "ROLE_EDIT_RAMP": "La rampa può essere modificata.",
          "ROLE_EDIT_ROLE_GROUP": "Possibilità di modificare il ruolo nei dettagli utente.",
          "ROLE_EDIT_SQUARE": "Il ruolo consente di modificare uno stallo",
          "ROLE_EDIT_TRANSPORT_ADDITIONAL_DESCRIPTION": "Abilita la possibilità di modificare il campo “Note aggiuntive”",
          "ROLE_EDIT_USERS_MAIN_DATA": "Possibilità di modificare nome, indirizzo e-mail, numero di telefono e password",
          "ROLE_EDIT_USERS_NOTIFICATION": "Consente all’utente di modificare le notifiche ricevute",
          "ROLE_EDIT_USERS_RAMP": "Possibilità di modificare l’accesso utente alle rampe e ai magazzini",
          "ROLE_EDIT_WAREHOUSE": "Possibilità di modificare i dati magazzino",
          "ROLE_EDIT_WAREHOUSE_ZONE": "Possibilità di modificare una zona magazzino.",
          "ROLE_EDIT_WORKER": "Modifica lavoratore",
          "ROLE_EDIT_WORKFLOW": "Possibilità di modificare impostazioni stato dettagliate per ciascun tipo di trasporto.",
          "ROLE_FILTER_ACTIVE_TRANSPORTS_TABLE": "Visualizza i filtri sopra le colonne nella tabella trasporti attivi",
          "ROLE_FULL_EDIT_COMPANY": "Possibilità di modificare'i dati azienda dell’appaltatore.",
          "ROLE_INVITATIONS_TAB": "Abilita l’accesso alla scheda “Inviti”.",
          "ROLE_INVITE_AS_TEMPORARY_USER_WHEN_ACCOUNT_LIMIT_EXCEEDED": "Invito di utenti temporanei che superano i limiti dell’azienda",
          "ROLE_INVOICE_COLUMN": "Visualizza colonna fattura",
          "ROLE_MANAGE_ADMIN_ANNOUNCEMENTS": "Possibilità di gestire annunci amministrativi. Autorizzazioni richieste: “Accedi a scheda Annunci” e “Possibilità di gestire annunci”",
          "ROLE_MANAGE_ADVICE_VERIFICATION_MAIL": "L’autorizzazione consente di abilitare/disabilitare notifiche e-mail con una richiesta di verificare i dati dell’avviso e consente di modificare l’orario di invio dell’e-mail. Dopo l’abilitazione dell’autorizzazione, l’articolo è visibile nei dettagli dell’appaltatore.",
          "ROLE_MANAGE_ALLOWED_DOMAINS": "Accesso e gestione di domini e-mail consentiti nella sezione “Domini consentiti”'nei dettagli appaltatore.",
          "ROLE_MANAGE_ANNOUNCEMENTS": "Abilita l’accesso alla gestione annunci. Livello di autorizzazione richiesto: “Accedi alla scheda Annunci”.",
          "ROLE_MANAGE_APPLIES_SETTINGS": "Accedi a impostazioni gestione richieste",
          "ROLE_MANAGE_APPLY_SECTIONS": "Gestione'richieste vettori. Possibilità di accettare e rifiutare sezioni individuali'della richiesta vettore.",
          "ROLE_MANAGE_ARTR_SETTINGS": "Gestione impostazioni ANPR",
          "ROLE_MANAGE_AUCTION_SETTINGS": "Accedi a impostazioni gestione asta",
          "ROLE_MANAGE_AUTHORITIES": "Consente di creare, modificare ed eliminare autorizzazioni per ruoli (autorizzazioni generali e autorizzazioni relative ai tipi di trasporto)",
          "ROLE_MANAGE_CMR_SETTINGS": "Gestione impostazioni file CMR",
          "ROLE_MANAGE_COLUMN_VISIBILITY": "Consente di gestire la visibilità delle colonne nella visualizzazione autorizzazioni in base al tipo di trasporto",
          "ROLE_MANAGE_COMPANY_PRICING_PLAN": "L’autorizzazione consente di modificare l’opzione di iscrizione per l’azienda",
          "ROLE_MANAGE_COMPANY_RAMP": "Abilita l’accesso alla sezione “Modifica rampa” nei dettagli appaltatore",
          "ROLE_MANAGE_DEDICATED_WINDOWS": "Concessione dell’autorità di creare ed eliminare slot temprali dedicati.",
          "ROLE_MANAGE_LOCAL_ADMINS": "Abilita la possibilità di gestire amministratore locale nella aziende appaltatore",
          "ROLE_MANAGE_MAX_COMPANY_ACCOUNT_NUMBER": "L’autorizzazione consente di modificare il numero massimo di account per le aziende",
          "ROLE_MANAGE_MOT_SECTIONS": "Gestione delle modalità di trasporto. Possibilità di accettare e rifiutare sezioni individuali della modalità di trasporto.",
          "ROLE_MANAGE_NOTIFICATIONS": "Allows to manage notifications",
          "ROLE_MANAGE_PACKAGE_TYPE_TRANSPORT_TYPE": "Gestisci impostazioni pacchetto",
          "ROLE_MANAGE_PARTNERSHIP": "Consente di aggiungere e eliminare il collegamento tra appaltatori",
          "ROLE_MANAGE_PRINCIPAL_DEDICATED_WINDOWS": "Possibilità di creare finestre dedicate per l’azienda cliente. L’utente dell’azienda clienti sarà in grado di aggiungere a queste finestre qualsiasi avviso.",
          "ROLE_MANAGE_QUEUES_ACCESSIBILITY": "L’autorizzazione consente di abilitare/disabilitare stalli e linee.",
          "ROLE_MANAGE_SETTINGS": "Abilita la gestione delle impostazioni",
          "ROLE_MANAGE_TRANSPORT_TYPE": "Gestisci impostazioni tipo di trasporto.",
          "ROLE_MANAGE_USER_GROUPS": "Gestione gruppo all’interno dell’azienda'dell’appaltatore",
          "ROLE_MANAGE_USER_ROLE_INVITATIONS": "Consente di assegnare un elenco di ruoli da invitare per uno specifico ruolo utente",
          "ROLE_MODE_OF_TRANSPORTATION_TAB": "Abilita l’accesso alla scheda “Modalità di trasporto”",
          "ROLE_MOVE_BLOCKED_WINDOWS": "Consente di spostare slot temporali disattivati.",
          "ROLE_MOVE_DEDICATED_WINDOWS": "Consente di spostare slot temporali dedicati.",
          "ROLE_MOVE_WINDOWS_BETWEEN_WAREHOUSE_ZONES": "Consente di spostare gli slot temporali tra le zone magazzino",
          "ROLE_OWN_COLUMNS_EXCEL": "L’autorizzazione consente di generare un report di mappatura della visibilità di colonne dalla tabella trasporto.",
          "ROLE_OWN_COMPANY_TAB": "Abilita l’accesso alla scheda “Azienda” nell’account'dell’appaltatore.",
          "ROLE_PERFORM_ACTION_IN_WAREHOUSE": "Il ruolo consente di eseguire azioni nel magazzino",
          "ROLE_PLANNING_TAB": "Abilita l’accesso alla scheda Pianificazione",
          "ROLE_PRICE_LIST_TAB": "Abilita la possibilità di approvare un’offerta aggiunta al listino",
          "ROLE_PRODUCTS_TAB": "Aggiunge gli accessi alla scheda correlata i prodotti.",
          "ROLE_QUEUES_TAB": "Abilita l’accesso alla scheda “Linee”",
          "ROLE_QUEUE_STATUS_TAB": "Abilita l’accesso alla scheda “Stato linea”",
          "ROLE_READ_WINDOW_NOTES": "Abilita la visibilità delle note negli slot disabilitati per gli utenti con questo livello di autorizzazione.",
          "ROLE_REJECT_CHECKPOINT": "Consente di rifiutare un checkpoint.",
          "ROLE_RESIZE_BLOCKED_WINDOWS": "Consente di modificare la dimensione degli slot temporali disattivati (slot temporali bloccati). Richiede anche l’autorizzazione: “Sposta slot bloccati” per funzionare correttamente.",
          "ROLE_RESIZE_DEDICATED_WINDOWS": "Consente di modificare la dimensione degli slot temporali dedicati. Richiede anche l’autorizzazione: “Sposta slot dedicati” per funzionare correttamente.",
          "ROLE_RESTORE_ORDER_FROM_ARCHIVE": "Possibilità di ripristinare ordini dall’archivio dopo l’archiviazione automatica in caso di ritardo",
          "ROLE_REVOKE_CHECKPOINT": "Consente di revocare un checkpoint.",
          "ROLE_SEND_SMS_TO_ALL_DRIVERS_IN_QUEUE": "Abilita l’opzione di invio di notifiche di testo a tutti gli autisti in una determinata linea (la scheda Stato linea)",
          "ROLE_SETTINGS_TAB": "Abilita l’accesso alla scheda “Impostazioni”.",
          "ROLE_SHOW_API_INTEGRATION_LOGS": "Visualizza la sezione “Integrazione” nel modulo trasporto.",
          "ROLE_SHOW_ARTR_API_LOGS": "Visualizza la sezione “Log ANPR” nel modulo trasporto.",
          "ROLE_SHOW_AWAITING_INVITATIONS_TABLE": "Questa autorizzazione consente di visualizzare l’elenco degli inviti in attesa nei dettagli di una determinata azienda.",
          "ROLE_SHOW_CUSTOM_FIELD_DEFINITIONS": "Questa autorizzazione consente di aggiungere, modificare ed eliminare campi personalizzati. È richiesta inoltre l’autorizzazione “Accedi a impostazioni campo personalizzato”.",
          "ROLE_SHOW_PARTNERSHIP_TABLE": "Questa autorizzazione consente di visualizzare l’elenco di appaltatori correlati nei dettagli di una determinata azienda. Le partnership sono generate attraverso gli inviti.",
          "ROLE_SHOW_TRANSPORT_LOGS": "Visualizza la sezione “Cronologia” nel modulo trasporto.",
          "ROLE_SORT_TRANSPORT_TABLE": "Il ruolo consente di filtrare la tabella trasporto in base a una colonna selezionata",
          "ROLE_STATISTICS_REPORT_TAB": "Accedi alla scheda “Statistiche”",
          "ROLE_SUPER_USER": "Privilegi utente speciale.",
          "ROLE_TRANSPORT_TABLE": "Un utente con questo ruolo ha accesso alla tabella trasporto.",
          "ROLE_USERS_TAB": "Abilita l’accesso alla scheda “Utenti”. ",
          "ROLE_WAREHOUSE_TAB": "Abilita l’accesso alla scheda “Programma”.",
          "UNLIMITED_ACCESS_TO_ARCHIVED_TRANSPORTS": "Imposta l’accesso a tempo indeterminato agli ordini"
        },
        "generalPermissions": "Autorizzazioni generali",
        "header": "Autorizzazioni per un ruolo ",
        "invitationSection": "L’utente può invitare ruoli",
        "invitationSettings": "Impostazioni invito",
        "label": "Impostazioni gruppo ruolo",
        "noAuthorities": "Nessuna autorità.",
        "notificationSection": "Impostazioni notifica",
        "notificationSettings": "Impostazioni notifica",
        "permissions": "Autorizzazioni",
        "pricingPlans": "per aziende con piano prezzi {pricingPlan}",
        "successCreate": "Ruolo creato correttamente",
        "successDelete": "Autorità disabilitata correttamente",
        "successUpdate": "Modifiche salvate correttamente",
        "sureToDelete": "Disabilitare questa autorità?",
        "sureToDeleteAll": "Disabilitare tutte le autorità per questo gruppo?",
        "timeIntervalLabel": "Intervallo",
        "timeReferenceLabel": "Riferimento temporale",
        "timeReferences": {
          "EARLIEST_TIME_WINDOW_CURRENT_DATE": "inizio del primo slot temporale relativo alla data attuale",
          "EARLIEST_TIME_WINDOW_START_AFTER_CHANGE": "inizio del primo slot temporale (dopo la movimentazione)",
          "EARLIEST_TIME_WINDOW_START_BEFORE_CHANGE": "inizio del primo slot temporale (prima della movimentazione)",
          "LATEST_TIME_WINDOW_STOP": "fine dell’ultimo slot temporale",
          "TRANSPORT_DATE_CREATED": "data di creazione trasporto"
        },
        "usersList": "Elenco utenti"
      },
      "transportTypes": {
        "addAuctionSetting": "Aggiungi impostazioni asta",
        "addTransactionSetting": "Aggiungi impostazioni transazione",
        "auctionSetting": "Impostazioni asta",
        "create": "Crea tipo di trasporto",
        "englishName": "Nome inglese",
        "group": "Gruppo",
        "invalidCustomFieldId": "Il campo personalizzato con ID assegnato non esiste",
        "label": "Impostazioni tipo di trasporto",
        "name": "Nome",
        "noAuctionSetting": "Le impostazioni relative alle aste non sono ancora state aggiunte",
        "noTransactionSetting": "Le impostazioni relative al trasferimento di un ordine al vettore non sono ancora state aggiunte",
        "packageTypeSettings": "Impostazioni tipo confezione",
        "permissions": "Autorizzazioni",
        "permissionsSaveSuccess": "Impostazioni autorizzazioni salvate correttamente.",
        "selectRoleGroups": "Seleziona i ruoli che potranno creare i tipi di trasporto",
        "settings": "Impostazioni tipo di trasporto",
        "settingsDescription": {
          "adviceRequiresPhoneNumber": "Definisce se il campo: “Numero di telefono cellulare” sarà obbligatorio durante la creazione di un avviso",
          "adviceRestrictionInEveryStep": "Specifica se il campo “Intervallo di tempo avviso“ è visibile in ogni fase",
          "adviceSavedWithTransport": "I dati dell’avviso saranno salvati con il trasporto, l’inserimento dei dati avviso sarà effettuato nel modulo trasporto",
          "adviceSeparatedFromWindow": "Specifica se l’aggiunta di una finestra deve essere una fase separata dall’aggiunta dei dati avviso",
          "allowLoadStepTypeChange": "Specifica se il tipo di fase di carico può essere modificato nel modulo trasporto tra la fase nel Magazzino e la fase dall’Appaltatore",
          "allowUnloadStepTypeChange": "Specifica se il tipo di fase di scarico può essere modificato nel modulo trasporto tra la fase nel Magazzino e la fase dall’Appaltatore",
          "allowsAltIdentifier": "Definisce la visibilità di un identificatore ordine aggiuntivo (altIdentifier). Questo campo nel modulo trasporto nel nome definito nelle impostazioni: “Etichetta colonna numero ordine”.",
          "allowsCarRegistrationNumber": "Specifica la visibilità della “Targa veicolo”",
          "allowsCarrier": "Specifica se l’azienda “Vettore” partecipa al trasporto; specifica la visibilità del campo “Vettore” nel modulo trasporto",
          "allowsContractor": "Specifica se l’azienda “Fornitore” partecipa al trasporto; specifica la visibilità del campo “Fornitore” nel modulo trasporto",
          "allowsDriverDocumentNumber": "Specifica la visibilità del campo “Numero documento autista”",
          "allowsDriverName": "Definisce se il campo: “Autista -'nome e cognome” sarà visibile durante la creazione di un avviso",
          "allowsErpIdentifier": "Definisce la visibilità di un identificatore ordine (erpIdentifier). Questo campo nel modulo trasporto nel nome definito nelle impostazioni: “ERP_IDENTIFIER_LABEL.”",
          "allowsPeriodicTransport": "Specifica se l’opzione avviso periodico sarà disponibile. L’opzione sarà disponibile solo per fornitori/destinatari selezionati.",
          "allowsPhoneNumber": "Definisce se il campo: “Numero di telefono cellulare” sarà visibile durante la creazione di un avviso",
          "allowsPriceList": "Specifica se il tipo di trasporto ha accesso a “Listino prezzi”",
          "allowsRodoCheckbox": "Determina se il requisito GDPR è visibile e obbligatorio nel modulo avviso",
          "allowsService": "Specifica se l’azienda “Servizio” partecipa al trasporto; specifica la visibilità del campo “Servizio” nel modulo trasporto",
          "allowsTrailer": "Definisce se il campo: “Targa rimorchio/semirimorchio” sarà visibile durante la creazione di un avviso",
          "allowsTransportSupervisor": "Specifica la visibilità del campo “Persona di contatto” nel modulo trasporto",
          "altIdentifierFormat": "Specifica il formato del numero automatico “altIdentifier”. Variabili: $ sequenza - numero sequenza generato automaticamente, GG/ MM/AAAA - possibilità di inserimento della data. Esempio: \"IN/$sequenza/MM/AA\".",
          "altIdentifierIsCreatedAutomatically": "Specifica se il campo identificatore trasporto (altIdentifier) sarà creato automaticamente. Se questa opzione è abilitata non sarà possibile modificare l’identificatore nel modulo trasporto.",
          "altIdentifierRegex": "Specifica l’espressione regolare che sarà utilizzata per verificare se il valore fornito nel campo “altIdentifier” è valido.",
          "altIdentifierSequenceName": "Specifica una sequenza che punta a un numero sequenziale nel campo “altIdentifier” generato automaticamente",
          "alternativeStepsLayout": "Ogni fase sarà su una riga separata",
          "calculatingTimeWindowSizeFromAlgorithm": "Specifica se utilizzare l’algoritmo specificato nel magazzino per calcolare la dimensione dello slot temporale",
          "carRegistrationNumberTranslationKey": "Chiave di traduzione campo “Numero targa veicolo”",
          "carrierMustBeSelectedBeforeAdvice": "L’aggiunta di un avviso al trasporto sarà possibile solo dopo che il vettore è stato assegnato al trasporto",
          "checkAmountOfCover": "Valida la quantità di copertura durante la creazione di aste o trasferimento a vettore",
          "dateFilterProperty": "Specifica il campo dopo il quale gli ordini in archivio saranno filtrati per impostazione predefinita.",
          "defaultAmountOfCover": "Valore predefinito campo: “Quantità copertura”",
          "defaultLoadCompanyId": "Azienda predefinita nella fase di carico",
          "defaultLoadStepEndHour": "Orario predefinito per l’inizio dell’intervallo di tempo nelle fasi con i partner",
          "defaultLoadStepStartHour": "Orario predefinito per l’inizio dell’intervallo di tempo nelle fasi con i partner",
          "defaultServicemanCompany": "L’azienda di servizio selezionata sarà automaticamente durante la creazione di un nuovo trasporto",
          "defaultTextForTransportAdditionalDescription": "Testo predefinito nel campo “Note aggiuntive”",
          "defaultUnloadCompanyId": "Azienda predefinita nella fase di scarico",
          "dimensionsTranslationKey": "Chiave di traduzione campo “Dimensioni”()",
          "dimensionsVisibleInContractorStep": "Specifica la visibilità del campo “Numero ordine acquisto” nel modulo trasporto (fase senza magazzino)",
          "dimensionsVisibleInWarehouseStep": "Specifica la visibilità del campo “Numero ordine acquisto” nel modulo trasporto (fase con magazzino)",
          "documentIdentifierTranslationKey": "Chiave di traduzione campo “Numero numero nota consegna” (DELIVERY_NOTE_NUMBER, CUSTOMERS_ORDER_NUMBER, ISSUE_ORDER_NUMBER, REFERENCE_NUMBER)",
          "duplicateStepCountryFieldToNewSteps": "Determina se il campo “Paese” deve essere compilato automaticamente nel nuovo esecutore fase aggiunto in base al valore dalla prima fase diversa da stoccaggio",
          "generateTransportOrder": "Determina se l’ordine di trasporto assegnato sarà generato quando il trasporto viene creato",
          "generatingWeightReceiptEnabled": "Abilita l’opzione di download di un ticket peso per trasporto. La ricevuta può essere scaricata nel modulo trasporto dalla sezione “Pesatura”. Inoltre, un account con il ruolo GUARDIA può scaricare un ticket direttamente dalla tabella trasporto",
          "guardCheckpointConfirmationSingleColumnLayout": "Specifica se sarà generata una visualizzazione in cui i pulsanti e le date di conferma stato dagli esecutori fasi sono visibili alla sicurezza in una singola colonna per un determinato esecutore fase",
          "hasCmrDocumentEnabled": "Specifica se il tipo di trasporto ha un documento CMR, se sarà disponibile per il download nella sezione “Documenti” e nella tabella trasporto nella colonna documento",
          "hasDiscrepancyReportEnabled": "Specifica la visibilità della sezione “Record divergenze” nel modulo trasporto",
          "hasEntrancePassDocumentEnabled": "Specifica se il tipo di trasporto ha un documento CMR, se sarà disponibile per il download nella sezione “Documenti” e nella tabella trasporto nella colonna documento",
          "hasProductSpecificationDocumentEnabled": "Specifica se il tipo di trasporto ha un documento “Specifiche prodotto”, se sarà disponibile per il download nella sezione “Documenti” e nella tabella trasporto nella colonna documento",
          "hasVisibleSteps": "Specifica se i campi correlati alle fasi di trasporto saranno visibili nel modulo",
          "idx": "Determina l’ordine con cui i trasporti sono visualizzati nell’elenco",
          "isAmountOfCoverVisible": "Visibilità campo: “Quantità copertura”",
          "isInternalReleaseNumberVisibleInContractorStep": "Specifica la visibilità del campo “Numero nota consegna” nel modulo trasporto (fase senza magazzino)",
          "isInternalReleaseNumberVisibleInWarehouseStep": "Specifica la visibilità del campo “Numero nota consegna” nel modulo creazione trasporto (fase con magazzino)",
          "isLoadStepInWarehouseByDefault": "Specifica se, per impostazione predefinita, la fase di carico avverrà nel magazzino durante la creazione dell’ordine (può essere modificata quando “allowLoadStepTypeChange == true”)",
          "isReadyToPlanObligatory": "Obbligatorietà campo: “Pronto per pianificazione” nel modulo trasporto",
          "isReadyToPlanVisible": "Visibilità campo: “Pronto per pianificazione” nel modulo trasporto",
          "isUnloadStepInWarehouseByDefault": "Specifica se, per impostazione predefinita, la fase di scarico avverrà nel magazzino durante la creazione dell’ordine (può essere modificata quando “allowUnloadStepTypeChange == true”)",
          "isVolumeVisibleInContractorStep": "Specifica la visibilità del campo “Volume” nel modulo trasporto (fase senza magazzino)",
          "isVolumeVisibleInWarehouseStep": "Specifica la visibilità del campo “Volume” nel modulo trasporto (fase con magazzino)",
          "isWarehouseLocationObligatory": "Obbligatorietà campo: “Sede” nel modulo trasporto",
          "isWarehouseLocationVisible": "Visibilità campo: “Sede” nel modulo trasporto",
          "isWarehouseZoneVisible": "Specifica la visibilità del campo “Zona magazzino/Assortimento” nel modulo trasporto",
          "isWeightVisibleInContractorStep": "Specifica la visibilità del campo “Peso” nel modulo trasporto (fase senza magazzino)",
          "isWeightVisibleInWarehouseStep": "Specifica la visibilità del campo “Peso” nel modulo trasporto (fase con magazzino)",
          "maximumPermissableVehicleWeight": "Specifica la visibilità del messaggio eccedenza pesa durante la pesatura",
          "newAddressAsDefaultInLoadStep": "Dopo la scelta dell’azienda nella fase di carico sarà visualizzata l’opzione di aggiunta nuovo indirizzo",
          "newAddressAsDefaultInUnloadStep": "Dopo la scelta dell’azienda nella fase di scarico sarà visualizzata l’opzione di aggiunta nuovo indirizzo",
          "newAddressCityAutocompleteEnabled": "Completamento automatico nel campo “città” durante l’aggiunta del nuovo indirizzo nel modulo ordine",
          "numberOfDaysAddedToFirstStep": "Specifica il numero di giorni da aggiungere alla data o all’intervallo di tempo pianificati nel modulo trasporto per la prima fase:",
          "numberOfDaysAddedToFirstStepEnd": "Specifica il numero di giorni da aggiungere al termine della data o dell’intervallo di tempo pianificati nel modulo trasporto per la prima fase:",
          "numberOfDaysAddedToNextSteps": "Specifica il numero di giorni da aggiungere alla data o all’intervallo di tempo pianificati nel modulo trasporto per la seconda fase e per tutte le successive:",
          "numberOfDaysAddedToNextStepsEnd": "Specifica il numero di giorni da aggiungere al termine della data o all’intervallo di tempo pianificati nel modulo trasporto per la seconda fase e per tutte le successive:",
          "pastingFromExcelEnabled": "Possibilità di incollare dati avviso da Excel",
          "purchaseOrderNumberTranslationKey": "Chiave di traduzione campo “Numero ordine di acquisto”(SD_NUMBER)",
          "purchaseOrderNumberVisibleInContractorStep": "Specifica la visibilità del campo “Numero ordine acquisto” nel modulo trasporto (fase senza magazzino)",
          "purchaseOrderNumberVisibleInWarehouseStep": "Specifica la visibilità del campo “Numero ordine acquisto” nel modulo trasporto (fase con magazzino)",
          "quantitySumCheckValue": "Specifica il numero di pallet dopo il quale il messaggio di conferma sarà visualizzato nel modulo trasporto",
          "randomTransportInspection": "Specifica se il trasporto può essere preso da un’ispezione casuale",
          "requiresAddress": "Specifica se un indirizzo deve essere aggiunto alla fase di trasporto non magazzino",
          "requiresAdvice": "Dati avviso obbligatori - Disabilitando questa opzione il modulo avviso e i dettagli avviso rimarranno vuoti",
          "requiresAdviceDateRestrictions": "Specifica se il campo: “Intervallo di tempo per avviso” sarà visibile e obbligatorio durante la creazione di un trasporto",
          "requiresAltIdentifier": "Specifica se il campo “altIdentifier” sarà obbligatorio e visibile  durante la creazione del trasporto. Questo campo nel modulo trasporto nel nome definito nelle impostazioni: “Etichetta colonna numero ordine”",
          "requiresArrivalAndDepartureDate": "Determina se i campi “Data prelievo richiesta”/”Data consegna richiesta” sono visibili nel modulo trasporto (fase con appaltatore)",
          "requiresArrivalAndDepartureDateInWarehouseStep": "Determina se i campi “Data prelievo richiesta”/”Data consegna richiesta” sono visibili nel modulo trasporto (fase con appaltatore)",
          "requiresCarRegistrationNumber": "Definisce se il campo: “Targa automezzo” sarà obbligatorio durante la creazione di un avviso",
          "requiresCarrier": "Specifica se il campo: “Vettore” sarà obbligatorio durante la creazione di un trasporto",
          "requiresContractor": "Per salvare il trasporto, sarà necessario fornire i dettagli “Fornitore/Destinatario”. Se le fasi non correlate al magazzino saranno visibili nel trasporto, l’appaltatore potrà essere selezionato in questa sezione. In caso contrario, sarà possibile selezionare l’appaltatore nella parte principale del modulo trasporto.",
          "requiresContractorStepCompanyGroup": "Specifica se il campo: “Gruppo appaltatore” sarà obbligatorio durante la creazione di un trasporto",
          "requiresDriverDocumentNumber": "Definisce se il campo: “Autista -'numero documento” sarà obbligatorio durante la creazione di un avviso",
          "requiresDriverLanguage": "Specifica se il campo'“Lingua autista” è visibile e obbligatorio",
          "requiresDriverName": "Definisce se il campo: “Autista -'nome e cognome” sarà obbligatorio durante la creazione di un avviso",
          "requiresErpIdentifier": "Specifica se il campo “erpIdentifier” sarà visibile e obbligatorio durante la creazione del trasporto. Questo campo nel modulo trasporto nel nome definito nelle impostazioni: “ERP_IDENTIFIER_LABEL”",
          "requiresModeOfTransportationVerification": "Specifica se tutti i veicoli del vettore o solo i veicoli verificati sono indicati durante l’avviso",
          "requiresService": "Specifica se il campo: “Servizio” sarà obbligatorio durante la creazione di un trasporto",
          "requiresStepsWeight": "Specifica se il campo: “Peso” sarà obbligatorio durante la creazione di un trasporto",
          "requiresTimeWindow": "Per salvare il trasporto è necessario selezionare uno slot temporale. Ciò significa che dopo aver salvato il modulo trasporto, l’utente sarà indirizzato direttamente alla programmazione per selezionare spazio disponibile per lo slot temporale.",
          "requiresTrailer": "Definisce se il campo: “Targa rimorchio/semirimorchio” sarà obbligatorio durante la creazione di un avviso",
          "requiresTransportSupervisor": "Obbligatorietà del campo “Persona di contatto”",
          "requiresUniqueErpIdentifier": "Specifica se il campo: “erpIdentifier” deve avere valori univoci. Nella verifica dell’unicità gli ordini eliminati non vengono considerati.",
          "requiresWarehouse": "Per salvare il trasporto è necessario selezionare un magazzino. Opzione di selezione: “Selezione obbligatoria dello slot temporale durante la creazione del trasporto” richiederà anche di specificare il magazzino.",
          "requiresWorkers": "Obbligatorietà del campo “Lavoratori”",
          "selectionOfWindowBeforeAdvice": "Specifica se la selezione finestra deve essere eseguita prima dell’aggiunta dei dati avviso",
          "shouldCopyAdviceOnTransportCopy": "Specifica sei dati avviso devono essere copiati con l’opzione “Crea simile”",
          "showAddressFieldsInContractorSteps": "Specifica se i campi indirizzo devono essere visibili nelle fasi appaltatore",
          "transportSupervisorTranslationKey": "Specifica il nome visualizzato del campo “Persona di contatto” nel modulo creazione trasporto",
          "transportTypeName": "Specifica il nome del tipo di trasporto",
          "transportTypeNameEn": "Specifica il nome inglese del tipo di trasporto",
          "usedForDeliveries": "Possibilità di creare consegne",
          "windowColorDependsOnWarehouse": "Specifica se il colore dello slot temporale dipende solo dallo stato associato con uno slot specifico",
          "workerFieldTranslationKey": "Specifica il nome visualizzato del campo “Lavoratore” nel modulo creazione trasporto (WORKERS, GUESTS, SERVICEMAN)"
        },
        "settingsGroup": {
          "advice": "Impostazioni correlate a un avviso",
          "adviceForm": "Impostazioni correlate a un modulo avviso",
          "auctionMails": "Impostazioni correlate alle notifiche e-mail",
          "auctionOffers": "Impostazioni correlate alle offerte' prezzi",
          "auctionPass": "Impostazioni correlate al trasferimento dell’ordine al vettore",
          "general": "Impostazioni generali",
          "identifier": "Impostazioni correlate a un identificatore trasporto",
          "index": "Impostazioni correlate agli indici",
          "offerForm": "Impostazioni correlate al modulo offerta",
          "others": "Altri",
          "step": "Impostazioni correlate alle fasi di trasporto",
          "transaction": "Impostazioni correlate al trasferito di un trasporto a un vettore",
          "transport": "Impostazioni trasporto",
          "transportForm": "Impostazioni correlate a modulo trasporto",
          "transportType": "Impostazioni correlate all’intero tipo di trasporto"
        },
        "settingsLabel": {
          "adviceRequiresPhoneNumber": "Obbligatorietà campo: Autista'- numero di telefono",
          "adviceRestrictionInEveryStep": "Visibilità campo “Intervallo di tempo per avviso” in ogni fase",
          "adviceSavedWithTransport": "I dati dell’avviso saranno salvati con il trasporto",
          "adviceSeparatedFromWindow": "Specifica se l’aggiunta di una finestra deve essere una fase separata dall’aggiunta dei dati avviso",
          "allowLoadStepTypeChange": "Visibilità casella di controllo “Magazzino” nella fase di carico",
          "allowUnloadStepTypeChange": "Visibilità casella di controllo “Magazzino” nella fase di scarico",
          "allowsAltIdentifier": "Visibilità ordine identificatore aggiuntivo nel modulo - “altIdentifier”",
          "allowsCarRegNumberDuringTransportCreation": "Visibilità campo: “Targa veicolo” nel campo creazione ordine",
          "allowsCarRegistrationNumber": "Specifica la visibilità della “Targa veicolo”",
          "allowsCarrier": "Includi Azienda vettore nel trasporto",
          "allowsContractor": "Includi Azienda fornitore nel trasporto",
          "allowsDriverDocumentNumber": "Visibilità del campo “Numero documento autista”",
          "allowsDriverName": "Obbligatorietà campo: Autista'- primo nome e cognome",
          "allowsErpIdentifier": "Visibilità ordine identificatore nel modulo - “erpIdentifier”",
          "allowsPeriodicTransport": "Specifica se gli avvisi periodici possono essere aggiunti",
          "allowsPhoneNumber": "Visibilità di “Autista'- numero di telefono“ nel modulo avviso",
          "allowsPriceList": "Specifica se il tipo di trasporto ha accesso a “Listino prezzi”",
          "allowsRodoCheckbox": "Abilitazione condizione GDPR in corso",
          "allowsService": "Includi Azienda servizi nel trasporto",
          "allowsTrailer": "Visibilità campo: “Targa rimorchio/semirimorchio”",
          "allowsTransportSupervisor": "Visibilità campo “Persona di contatto” nel modulo trasporto",
          "altIdentifierFormat": "Formato del numero “altIdentifier” generato automaticamente",
          "altIdentifierIsCreatedAutomatically": "Generazione automatica di un numero ordine aggiuntivo - “altIdentifier”",
          "altIdentifierRegex": "Espressione regolare utilizzata per la validazione del campo “altIdentifier”",
          "altIdentifierSequenceName": "Nome sequenza per il campo “altIdentifier”",
          "alternativeStepsLayout": "Visualizzazione fase alternativa nel modulo ordine",
          "auctionSetting": {
            "addFileToTransportAfterOfferAcceptation": "Allega automaticamente un file all’ordine dopo che l’ordine è stato accettato dal vettore",
            "addFileToTransportAfterOfferAcceptationName": "Nome del file allegato all’ordine dopo che l’ordine è stato accettato dal vettore",
            "auctionEmailSubjectEn": "Oggetto della notifica e-mail relativa all’asta - Inglese",
            "auctionEmailSubjectPl": "Oggetto della notifica e-mail relativa all’asta",
            "auctionEndedWithoutOffersEmailFileName": "Versione dell’e-mail relativa al termine di un’asta senza offerte",
            "auctionProlongationOffsetInterval": "Tempo di estensione dell’asta in caso di modifica del leader",
            "canAcceptWithMinPrice": "Il vettore ha vinto un’asta dopo aver raggiunto il prezzo di riserva",
            "defaultAuctionFinishDelayInterval": "Durata predefinita dell’asta",
            "defaultCurrency": "La valuta predefinita dell’asta",
            "exportAuctionInfoToExcel": "Esporta dati asta in un file di Excel con ordini",
            "minOfferReductionSize": "Valore minimo a cui l’offerta deve essere ridotta",
            "newAuctionEmailFileName": "Versione dell’e-mail relativa alla nuova asta",
            "reminderTimeAboutAuction": "Il tempo che determina quando inviare un promemoria relativo a un’asta in corso",
            "requireMaxPrice": "Richiede un’offerta massima durante la creazione di un’asta",
            "requireMinOfferReductionSizeValidation": "Applica validazione al valore minimo della riduzione di prezzo",
            "requireMinPrice": "Richiede un prezzo riservato durante la creazione di un’asta",
            "requireReasonIfNotBestOfferIsChosen": "Richiede motivi in caso di non selezione dell’offerta migliore",
            "selectAllCarriersByDefault": "Per impostazione predefinita, seleziona tutti i vettori per l’asta",
            "shouldPassToCarrierAfterAuction": "Trasferimento automatico ordine al vincitore dell’asta",
            "showBlockedCompaniesSection": "Visibilità sezione con con aziende bloccate durante la creazione di un’asta",
            "showCompanyGroupsSection": "Visibilità sezione con raggruppamento di vettori durante la creazione di un’asta",
            "showDescriptionSection": "Visibilità campo note nel modulo invio offerta",
            "showFinishAuctionButton": "Visibilità pulsante “Termina asta” per un’asta in corso",
            "showPassToCarrierButton": "Visibilità del pulsante “Passa”",
            "showPriceRangeFields": "Visibilità intervallo prezzi durante la creazione di un’asta",
            "showQuantityFieldInOfferForm": "Visibilità del numero di pallet nel modulo invio offerta",
            "showRevokeOfferButton": "Visibilità pulsante annulla offerta",
            "showStepDataInOfferForm": "Visibilità posizioni carico/scarico nel modulo invio offerta",
            "translationKey": "Chiave di traduzione"
          },
          "calculatingTimeWindowSizeFromAlgorithm": "Conteggio dimensione slot temporale basato su un algoritmo",
          "carRegistrationNumberTranslationKey": "Chiave di traduzione campo “Numero targa veicolo” (TRUCK, VEHICLE)",
          "carrierMustBeSelectedBeforeAdvice": "Specifica se un avviso può essere aggiunto senza selezionare un vettore",
          "checkAmountOfCover": "Valida la quantità di copertura durante la creazione di aste o trasferimento a vettore",
          "dateFilterProperty": " Il filtro predefinito nell’archivio: ",
          "defaultAmountOfCover": "Valore predefinito campo: “Quantità copertura”",
          "defaultLoadCompanyId": "Azienda predefinita nella fase di carico",
          "defaultLoadStepEndHour": "Orario predefinito per l’inizio dell’intervallo di tempo nelle fasi con i partner",
          "defaultLoadStepStartHour": "Orario predefinito per l’inizio dell’intervallo di tempo nelle fasi con i partner",
          "defaultServicemanCompany": "Azienda tecnico dell’assistenza predefinita",
          "defaultTextForTransportAdditionalDescription": "Testo predefinito nel campo “Note aggiuntive”",
          "defaultUnloadCompanyId": "Azienda predefinita nella fase di scarico",
          "dimensionsTranslationKey": "Chiave di traduzione campo “Dimensioni”",
          "dimensionsVisibleInContractorStep": "Visibilità campo “Dimensioni” nel modulo ordine (fase senza magazzino)",
          "dimensionsVisibleInWarehouseStep": "Visibilità campo “Dimensioni” nel modulo ordine (fase con magazzino)",
          "documentIdentifierTranslationKey": "Chiave di traduzione campo “Numero nota consegna”",
          "duplicateStepCountryFieldToNewSteps": "Determina se il campo “Paese” deve essere compilato automaticamente nel nuovo esecutore fase aggiunto in base al valore dalla prima fase diversa da stoccaggio",
          "generateTransportOrder": "Determina se l’ordine di trasporto assegnato sarà generato quando il trasporto viene creato",
          "generatingTransportWeightReceiptEnabled": "Determina se una ricevuta peso deve essere generata nel tipo",
          "generatingWeightReceiptEnabled": "Capacità di generare un ticket peso",
          "guardCheckpointConfirmationSingleColumnLayout": "Specifica se sarà generata una visualizzazione in cui i pulsanti e le date di conferma stato dagli esecutori fasi sono visibili alla sicurezza in una singola colonna per un determinato esecutore fase",
          "hasCmrDocumentEnabled": "Specifica se il tipo di trasporto ha un documento CMR",
          "hasDiscrepancyReportEnabled": "Visibilità sezioni: “Record divergenze” nel modulo trasporto",
          "hasEntrancePassDocumentEnabled": "Specifica se il tipo di trasporto ha un documento CMR",
          "hasProductSpecificationDocumentEnabled": "Specifica se il tipo di trasporto ha un documento “Specifiche prodotto”",
          "hasVisibleSteps": "Visibilità di una sezione fasi trasporto in un modulo",
          "idx": "Idx",
          "isAmountOfCoverVisible": "Visibilità campo: “Quantità copertura”",
          "isContactDetailsFieldsRequired": "Campi obbligatori - persona di contatto, e-mail e numero di telefono della persona di contatto",
          "isContactDetailsFieldsVisible": "Visibilità dei campi - persona di contatto, e-mail e numero di telefono della persona di contatto nel modulo ordine",
          "isInternalReleaseNumberVisibleInContractorStep": "Visibilità campo “Numero nota consegna” nel modulo ordine (fase senza magazzino)",
          "isInternalReleaseNumberVisibleInWarehouseStep": "Visibilità campo “Numero nota consegna” nel modulo ordine (fase con magazzino)",
          "isLoadStepInWarehouseByDefault": "Fase di carico nel magazzino per impostazione predefinita",
          "isReadyToPlanObligatory": "Obbligatorietà campo: “Pronto per pianificazione” nel modulo trasporto",
          "isReadyToPlanVisible": "Visibilità campo: “Pronto per pianificazione” nel modulo trasporto",
          "isUnloadStepInWarehouseByDefault": "Fase di scarico nel magazzino per impostazione predefinita",
          "isVolumeVisibleInContractorStep": "Visibilità campo “Volume” nel modulo ordine (fase senza magazzino)",
          "isVolumeVisibleInWarehouseStep": "Visibilità campo “Volume” nel modulo ordine (fase con magazzino)",
          "isWarehouseLocationObligatory": "Obbligatorietà campo: “Sede” nel modulo trasporto",
          "isWarehouseLocationVisible": "Visibilità campo: “Sede” nel modulo trasporto",
          "isWarehouseZoneVisible": "Visibilità campo “Zona magazzino/Assortimento” nel modulo trasporto",
          "isWeightVisibleInContractorStep": "Visibilità campo “Peso” nel modulo ordine (fase senza magazzino)",
          "isWeightVisibleInWarehouseStep": "Visibilità campo “Peso” nel modulo ordine (fase con magazzino)",
          "maximumPermissableVehicleWeight": "Visualizza un messaggio relativo al superamento del peso consentito per il veicolo",
          "newAddressAsDefaultInLoadStep": "Dopo la scelta dell’azienda nella fase di carico sarà visualizzata l’opzione di aggiunta nuovo indirizzo",
          "newAddressAsDefaultInUnloadStep": "Dopo la scelta dell’azienda nella fase di scarico sarà visualizzata l’opzione di aggiunta nuovo indirizzo",
          "newAddressCityAutocompleteEnabled": "Completamento automatico nel campo “città” durante l’aggiunta del nuovo indirizzo nel modulo ordine",
          "numberOfDaysAddedToFirstStep": "Il numero di giorni da aggiungere alla data o all’intervallo di tempo pianificati nel modulo trasporto per la prima fase:",
          "numberOfDaysAddedToFirstStepEnd": "Il numero di giorni da aggiungere al termine della data o dell’intervallo di tempo pianificati nel modulo trasporto per la prima fase:",
          "numberOfDaysAddedToNextSteps": "Il numero di giorni da aggiungere alla data o all’intervallo di tempo pianificati nel modulo trasporto per la seconda fase e per tutte le successive:",
          "numberOfDaysAddedToNextStepsEnd": "Il numero di giorni da aggiungere al termine della data o all’intervallo di tempo pianificati nel modulo trasporto per la seconda fase e per tutte le successive:",
          "pastingFromExcelEnabled": "Possibilità di incollare dati avviso da Excel",
          "purchaseOrderNumberTranslationKey": "Chiave di traduzione campo “Numero ordine di acquisto”",
          "purchaseOrderNumberVisibleInContractorStep": "Visibilità campo “Numero ordine di acquisto” nel modulo ordine (fase senza magazzino)",
          "purchaseOrderNumberVisibleInWarehouseStep": "Visibilità campo “Numero ordine di acquisto” nel modulo ordine (fase con magazzino)",
          "quantitySumCheckValue": "Numero quantità dopo cui sarà visualizzato un avviso",
          "randomTransportInspection": "Attivazione di controlli random",
          "requiresAddress": "Dati indirizzo obbligatori in caso di fase senza un magazzino",
          "requiresAdvice": "Obbligatorietà dati dell’avviso",
          "requiresAdviceDateRestrictions": "Visibilità o obbligatorietà campo “Intervallo di tempo per avviso”",
          "requiresAltIdentifier": "Obbligatorietà campo: “altIdentifier”",
          "requiresArrivalAndDepartureDate": "Visibilità e campo obbligatorio: “Data ricezione richiesta”/”Data consegna richiesta” (fase con appaltatore)",
          "requiresArrivalAndDepartureDateInWarehouseStep": "Visibilità e campo obbligatorio: “Data ricezione richiesta”/”Data consegna richiesta” (fase con magazzino)",
          "requiresCarRegistrationNumber": "Obbligatorietà campo: “Targa veicolo”",
          "requiresCarrier": "Obbligatorietà campo: “Vettore”",
          "requiresContractor": "Obbligatorietà data: Fornitore/Destinatario",
          "requiresContractorStepCompanyGroup": "Obbligatorietà campo: “Gruppo appaltatore”",
          "requiresDriverDocumentNumber": "Obbligatorietà del campo: “Autista'- numero documento”",
          "requiresDriverLanguage": "Visibilità e obbligatorietà del campo'“Lingua autista”",
          "requiresDriverName": "Obbligatorietà del campo: “Nome e cognome autista”",
          "requiresErpIdentifier": "Obbligatorietà campo: “erpIdentifier”",
          "requiresModeOfTransportationVerification": "Modalità di verifica trasporto (funzionalità utilizzabile solo con  “trasferimento al vettore” abilitata)",
          "requiresService": "Obbligatorietà campo: “Servizio”",
          "requiresStepsWeight": "Obbligatorietà campo: “Peso”",
          "requiresTimeWindow": "Obbligatorietà della selezione dello slot temporale durante la creazione di un trasporto",
          "requiresTrailer": "Obbligatorietà campo: “Targa rimorchio/semirimorchio”",
          "requiresTransportSupervisor": "Obbligatorietà del campo “Persona di contatto”",
          "requiresUniqueErpIdentifier": "Unicità campo “erpIdentifier”",
          "requiresWarehouse": "Obbligatorietà della selezione del magazzino durante la creazione di un trasporto",
          "requiresWorkers": "Obbligatorietà del campo “Lavoratori”",
          "selectionOfWindowBeforeAdvice": "Specifica se la selezione finestra deve essere eseguita prima dell’aggiunta dei dati avviso",
          "shouldCopyAdviceOnTransportCopy": "Specifica sei dati avviso devono essere copiati con l’opzione “Crea simile”",
          "showAddressFieldsInContractorSteps": "Visibilità campi indirizzo nelle fasi con appaltatori",
          "supplierFieldTranslationKey": "Chiave di traduzione campo “Fornitore/Destinatario” CLIENT , COMPANY, COURIER)",
          "transportSupervisorTranslationKey": "Chiave di traduzione campo “Persona di contatto” (CONTACT_PERSON, INVITEE, ORDERER, PERSON_TO_NOTIFY, HOST_PERSON)",
          "transportTypeName": "Nome del tipo di trasporto",
          "transportTypeNameEn": "Nome del tipo di trasporto in inglese",
          "usedForDeliveries": "Possibilità di creare consegne",
          "windowColorDependsOnWarehouse": "Specifica se il colore dello slot temporale dipende solo dallo stato associato con lo slot specifico",
          "workerFieldTranslationKey": "Chiave di traduzione campo “Lavoratori” (WORKERS, GUESTS, SERVICEMEN)"
        },
        "settingsSaveSuccess": "Impostazioni tipo trasporto salvate correttamente",
        "transactionSetting": "Impostazioni transazione",
        "workflow": "Fasi e stati (flusso di lavoro)"
      },
      "updateSuccessMessage": "Le impostazioni sono state aggiornate.",
      "value": "Valore"
    },
    "sezione-anti-corruzione": {
      "errors": {
        "signedFiles": "Per inviare una richiesta di verifica, devi aggiungere la dichiarazione anti-corruzione firmata"
      },
      "internalNotes": "Note",
      "patternFileDescription": "Modello modulo",
      "signedFile": "Aggiungi documento firmato",
      "statementFileDescription": "Dichiarazione firmata da {company} per download"
    },
    "sezione-azione": {
      "noCommentError": "Inserire il motivo del rifiuto.",
      "rejectionComment": "Fornisci il motivo'del rifiuto di questa sezione"
    },
    "sezione-principale": {
      "errors": {
        "hasErrors": "La sezione dati principale contiene errori. Compilare correttamente i campi richiesti.",
        "krsFile": "Per inviare una richiesta di verifica, è necessario aggiungere il documento richiesto come prova KRS.",
        "nipFile": "Per inviare una richiesta di verifica, è necessario aggiungere il documento richiesto come prova NIP.",
        "regonFile": "Per inviare una richiesta di verifica, è necessario aggiungere il documento richiesto come prova REGON."
      }
    },
    "sezione-secondaria": {
      "bankAccountNumber": "Numero di conto bancario",
      "bankFilePattern": {
        "label": "Modello modulo"
      },
      "errors": {},
      "internalNotes": "Note interne",
      "label": "Dati aggiuntivi",
      "otherAttachments": "Altri allegati"
    },
    "square": {
      "SQUARE": {
        "addQueue": "Aggiungi linea",
        "buffer": "Tempo di riserva",
        "carsInFrontOfSmelter": "Veicoli prima del controllo qualità",
        "classificationTab": "Classificazione",
        "create": "Crea stallo materie prime",
        "createPlace": "Aggiungi posizione",
        "label": "Stalli materie prime",
        "placeLabel": "Posizione",
        "queueLabel": "Linea",
        "queueStatusTab": "Stato linea",
        "queuesStatus": "Stato linea (dopo controllo qualità)",
        "queuesStatusTableHeader": "Riepilogo stato linea attuale",
        "queuesTab": "Linee",
        "square": "Stallo",
        "unload": "Scarico"
      },
      "createQueueAfterSave": "Sarà possibile configurare le linee dopo aver salvato il deposito.",
      "disableConfirm": "Disabilitare questo stallo?",
      "updateSuccessMessage": "Le impostazioni stallo {squareName} sono state aggiornate correttamente."
    },
    "squareSetting": {
      "bufferSecondTableColumnVisibility": "Visibilità colonna (2 tabelle) “Tempo di riserva”",
      "carRegistrationFirstTableColumnVisibility": "Visibilità colonna (1 tabella) “Numero targa veicolo”",
      "hdsFirstTableColumnVisibility": "Visibilità colonna (1 tabella) “HDS”",
      "label": "Impostazioni stallo e linea",
      "orderNumberFirstColumnVisibility": "Visibilità colonna (1 tabella) “Numero ordine”",
      "queueFirstTableColumnVisibility": "Visibilità colonna (1 tabella) “Linea”",
      "queueSecondTableColumnVisibility": "Visibilità colonna (2 tabelle) “Linea”",
      "squareFirstTableColumnVisibility": "Visibilità colonna (1 tabella) “Stallo”",
      "squareSecondTableColumnVisibility": "Visibilità colonna (2 tabella) “Stallo”",
      "translationKey": "Chiave di traduzione",
      "unloadSecondTableColumnVisibility": "Visibilità colonna (2 tabelle) “Scarico”"
    },
    "statuses": {
      "deleted": "Eliminato",
      "finished": "Completato",
      "initial": "Avvisato",
      "movedToArchiveDueDelay": "Ritardato",
      "waitingForConfirmation": "In attesa di conferma di"
    },
    "statute": {
      "accept": {
        "disabled.title": "Selezionare l’accettazione della dichiarazione relativa ai Termini di servizio.",
        "header": "Gentile utente,",
        "inTouch": "Ho compreso e se necessario vi contatterò.",
        "informed": "Ho compreso è mi ritengo informato",
        "label": "Ho compreso e fornisco il mio consenso a quanto in precedenza",
        "manual": "Ho letto, compreso e accetto il Manuale di sicurezza.",
        "paragraph_1": "1. LOGINTEGRA Sp. z o. o. con sede a Danzica, Lęborska 3b, KRS 0000426238, NIP 584-272-80-64, REGON 221707056 è il titolare del trattamento dei Suoi dati personali archiviati nel Sistema, come specificato nel GDPR.  Informazioni di contatto del titolare del trattamento: telefono: +48 58 535 78 06, e-mail: info@logintegra.com. I Suoi dati potranno essere condivisi con altri utenti del sistema e altri soggetti descritti di seguito.",
        "paragraph_10": "10. In caso di domande, dubbi o richieste relative ai propri dati, il ​​modo più semplice per contattarci è utilizzare il seguente indirizzo e-mail: info@logintegra.com",
        "paragraph_2": "2. I dati personali dell’Utente saranno trattati esclusivamente per finalità legate all’esecuzione dei contratti stipulati tra il Titolare e gli altri Utenti, per l’esecuzione di obblighi e diritti connessi ai contratti stipulati, per l’esecuzione di obblighi derivanti da disposizioni di legge e nell’ambito in cui l’Utente ha acconsentito al trattamento dei dati. ",
        "paragraph_3": "3. Questo Sistema viene utilizzato per la cooperazione tra aziende e la gestione di un processo logistico delle aziende degli Utenti. Il Sistema funziona in base al principio secondo cui i dati personali dell’Utente possono essere visibili ad altri Utenti del Sistema e possono essere a loro inviati o trasferiti in altro modo. I dati personali dell’utente potranno essere trasferiti anche in altri casi previsti da disposizioni di legge, in particolare: ",
        "paragraph_3_1": "alle autorità fiscali, nella misura in cui il conferimento dei dati personali è obbligatorio come stabilito da disposizioni fiscali; ",
        "paragraph_3_2": "alle autorità pubbliche, tra cui, forze dell’ordine, tribunali, procura, autorità di previdenza sociale, nella misura in cui il trasferimento dei dati personali è obbligatorio come stabilito dalle disposizioni di legge;",
        "paragraph_3_3": "subappaltatori di fiducia, che forniscono supporto tecnico o servizi di hosting per nostro conto;",
        "paragraph_3_4": "soggetti che forniscono servizi contabili, fiscali e legali.",
        "paragraph_4": "4. I dati personali dell’Utente saranno trattati solo per il periodo indispensabile all’esecuzione delle finalità previste al Par. 2 o fino alla revoca del consenso. Alcune categorie di dati possono essere conservate più a lungo se tale obbligo è richiesto da disposizioni di legge, ad es. i dati forniti nelle fatture emesse saranno conservati dal titolare fino alla scadenza del termine legale per la conservazione delle fatture ai fini fiscali.",
        "paragraph_5": "5. L’Utente fornisce i dati personali volontariamente: tuttavia, data la natura del Sistema, è necessario fornire determinate categorie di dati per potere utilizzare il sistema. Se l’Utente non fornisce tutti i dati personali richiesti nel modulo di registrazione o revoca il proprio consenso al trattamento, non sarà possibile utilizzare il Sistema.",
        "paragraph_6": "6. Ogni Utente che è una persona fisica ha il diritto di:",
        "paragraph_6_1": "accedere a propri dati;",
        "paragraph_6_2": "rettificare i propri dati;",
        "paragraph_6_3": "cancellare o limitare il trattamento dei propri dati;",
        "paragraph_6_4": "opporsi al trattamento dei propri dati, nonché;",
        "paragraph_6_5": "trasferire i propri dati;",
        "paragraph_6_6": "revocare il proprio consenso al trattamento dei dati in qualsiasi momento, senza che ciò pregiudichi il rispetto del diritto al trattamento effettuato in conformità al consenso prima della revoca dello stesso.",
        "paragraph_6_bottom": "Inoltre, l’Utente ha anche il diritto di presentare un reclamo a un’autorità di controllo quando ritiene che il trattamento dei suoi dati personali violi la normativa sulla protezione dei dati personali.",
        "paragraph_7": "7. Le questioni dettagliate relative alla protezione dei dati sono state specificate nei seguenti nostri documenti:",
        "paragraph_7_1": "Politica sul trattamento dei dati;",
        "paragraph_7_2": "Manuale di sicurezza;",
        "paragraph_7_3": "Termini di servizio del Sistema.",
        "paragraph_7_bottom": "Per accedere  ai documenti, fare clic sul relativo nome.",
        "paragraph_8": "8. Il nostro Sistema utilizza cookie.",
        "paragraph_9": "9. È necessario ricordare che i dati personali sono trattati nel Sistema. Quando si inseriscono nel Sistema dati personali di terze parti, ad es. autisti, è necessario verificare che siano stati rispettati gli obblighi a carico del Titolare del trattamento dei dati personali, come specificati nel GDPR. Gli obblighi includono, ma non si limitano a, la notifica all’interessato in relazione a:",
        "paragraph_9_1": "identità del Titolare ed i suoi dati di contatto;",
        "paragraph_9_2": "finalità del trattamento cui sono destinati i dati personali, nonché la base giuridica del trattamento;",
        "paragraph_9_3": "destinatari o le categorie di destinatari dei dati personali, se presenti;",
        "paragraph_9_4": "periodo durante il quale i dati personali saranno conservati;",
        "paragraph_9_5": "esistenza del diritto di chiedere al Titolare l’accesso e la rettifica o la cancellazione dei dati personali che lo riguardano o la limitazione del trattamento che lo riguarda, o di opporsi al trattamento, nonché il diritto alla portabilità dei dati;",
        "paragraph_9_6": "qualora il trattamento venga effettuato in base al consenso di una determinata persona, l’esistenza del diritto di revocare il consenso in qualsiasi momento, senza influire sulla legittimità del trattamento basata sul consenso fornito prima della revoca;",
        "paragraph_9_7": "diritto di presentare un reclamo ad un’autorità di controllo;",
        "paragraph_9_8": "se la fornitura di dati personali è un obbligo legale o contrattuale, oppure un requisito necessario per stipulare un contratto, nonché se l’interessato è obbligato a fornire i dati personali e le possibili conseguenze della mancata fornitura di tali dati.",
        "paragraph_9_bottom": "Ricordiamo di verificare che gli obblighi del Titolare siano rispettati. Se l’Utente viene a conoscenza di una possibile violazione delle norme sulla protezione dei dati personali, deve avvisarci immediatamente.",
        "policy": "Ho letto, compreso e accetto la Politica sul trattamento dei dati.",
        "statute": "Ho letto, compreso e accetto i Termini di servizio del Sistema.",
        "subHeader": "Il 25 maggio 2018 sono entrate in vigore le disposizioni del REGOLAMENTO (UE) 2016/679 DEL PARLAMENTO EUROPEO E DEL CONSIGLIO relative alla protezione delle persone fisiche con riferimento al trattamento dei dati personali e alla libera circolazione di tali dati; tali disposizioni hanno abrogato la Direttiva 95/46/CE (Regolamento generale sulla protezione dei dati) pubblicata sulla Gazzetta ufficiale dell’Unione europea, serie L 119 dal 2016, p. 1. Per ragioni di chiarezza dei contenuti la direttiva indicata in precedenza verrà di seguito denominata GDPR. In ottemperanza agli obblighi previsti dal GDPR a carico dei titolari e dei responsabili del trattamento dei dati personali, con la presente La informiamo in merito ad alcune importanti questioni ad esso correlate. Le chiediamo di familiarizzare attentamente con i contenuti che troverà di seguito: "
      },
      "appliesManualLabel": "Apri il manuale di gestione dell’applicazione",
      "label": "Termini di servizio",
      "label.ablative": "Termini di servizio",
      "links": {
        "manual": "https://pl.logintegra.com/static/Manual.pdf",
        "policy": "https://pl.logintegra.com/static/Policy.pdf",
        "statute": "https://pl.logintegra.com/static/Statute-2018-05-24.pdf"
      },
      "openKnowledgeBase": "Apri la knowledge base",
      "policyLabel": "Politica sul trattamento dei dati",
      "reject": {
        "button": "Annulla (questo account sarà bloccato)",
        "buttonForInvitations": "Rinuncia",
        "confirmation": "Continuare? Il tuo account sarà bloccato."
      },
      "safetyManualLabel": "Manuale di sicurezza"
    },
    "step": {
      "CUSTOMERS_ORDER_NUMBER.label": "Numero ordine cliente",
      "add": {
        "title": "Aggiungi un’altra fase"
      },
      "address": "Indirizzo",
      "canMoveLoadStep": "È possibile spostare solo le fasi di carico al tuo livello di autorizzazione",
      "canMoveUnloadStep": "È possibile spostare solo le fasi di scarico al tuo livello di autorizzazione",
      "companyGroup": "Gruppo appaltatore",
      "contactPerson": {
        "email": "Indirizzo e-mail",
        "label": "Persona di contatto",
        "phoneNumber": "Numero di telefono"
      },
      "delete": {
        "confirmMessage": "Eliminare questa fase da questo trasporto?",
        "confirmMessageWithIndexes": "Eliminare questa fase da questo trasporto? Tutti gli indici associati saranno eliminati insieme a questa fase",
        "title": "Rimuovi fase da questo trasporto"
      },
      "dimensions": {
        "DEFAULT.label": "Dimensioni",
        "DIMENSIONS.label": "Dimensioni"
      },
      "documentIdentifier": {
        "CUSTOMERS_ORDER_NUMBER.label": "Numero ordine clienti",
        "DEFAULT.label": "Numero nota consegna",
        "DELIVERY_NOTE_NUMBER.label": "Numero nota consegna",
        "ISSUE_ORDER_NUMBER.label": "Emetti numero ordine",
        "REFERENCE_NUMBER.label": "Numero di riferimento"
      },
      "errors": {
        "arrivalDate": {
          "isInPast": "La data di consegna non può essere nel passato."
        },
        "company": {
          "isRequired": "Questo tipo di trasporto richiede l’aggiunta di un appaltatore. Se non è visibile alcuna azienda, avvia una partnership."
        },
        "companyGroup": {
          "blank": "È necessario selezionare almeno un gruppo appaltatore.",
          "wrongValue": "Il gruppo appaltatore selezionato non è uno dei gruppi dell’azienda fornitore selezionata."
        },
        "contactPersonEmail": {
          "invalidEmailAddress": "Inserire un indirizzo e-mail valido"
        },
        "dimensions": {
          "length": "Il testo inserito è troppo lungo. La lunghezza massima è 255 caratteri."
        },
        "maxAdviceDate": {
          "beforeWindowStop": "Impossibile impostare l’intervallo indicato perché uno slot temporale avviso aggiunto in precedenza non rientrerebbe nell’intervallo. Spostare per prima cosa lo slot temporale.",
          "earlierThanMinAdviceDate": "L’orario massimo possibile avviso non può essere impostato come precedente all’orario minimo.",
          "inThePast": "Per poter creare l’avviso la fine dell’intervallo di tempo avviso deve essere nel futuro.",
          "timeHasPassed": "Spiacenti, l’orario consentito per l’avviso è scaduto. Contattare l’amministratore."
        },
        "minAdviceDate": {
          "afterWindowStart": "Impossibile impostare l’intervallo indicato perché una finestra temporale avviso aggiunta in precedenza non rientrerebbe nell’intervallo. Spostare per prima cosa lo slot temporale."
        },
        "minQuantityValue": "Il numero minimo di pallet consentiti è {minQuantityValue}",
        "outsideOfInterval": "L’intervallo consentito è {windowInterval} minuti, perché è l’intervallo di tempo per lo spostamento degli slot temporali nel magazzino selezionato.",
        "outsideWarehouseWorkHours": "La data selezionata non rientra nell’orario di lavoro del magazzino: {workStart}-{workEnd}.",
        "purchaseOrderNumber": {
          "length": "Il testo inserito è troppo lungo. La lunghezza massima è 255 caratteri."
        },
        "warehouse": {
          "sameWarehousesInTransfer": "Non puoi creare un trasferimento all’interno di un magazzino"
        }
      },
      "label": "Fase",
      "load": {
        "place": "Posizione di carico",
        "term": "Data ritiro"
      },
      "maxAdviceDate": "Orario massimo possibile avviso",
      "minAdviceDate": "Orario minimo possibile avviso",
      "orderInTransport": "Sequenza in un ordine",
      "purchaseOrderNumber": {
        "DEFAULT.label": "Numero SD",
        "SD_NUMBER.label": "Numero SD"
      },
      "quantityLoadAndUnloadSumCheckMessage": "Creare un avviso per più di un rimorchio carico di merce in carico/scarico?",
      "quantityLoadSum": "Numero pallet in fase di carico",
      "quantityLoadSumCheckMessage": "Creare un avviso per più di un rimorchio carico di merce in carico?",
      "quantityUnloadSum": "Numero pallet in fase di scarico",
      "quantityUnloadSumCheckMessage": "Creare un avviso per più di un rimorchio carico di merce in scarico?",
      "timeRangeForAdvice": "Intervallo di tempo per un avviso",
      "timeRangeForAdviceDoesNotApply": "Solo a scopo informativo - Non si applica all’utente",
      "timeRangeForLoad": "Intervallo di tempo per carico",
      "timeRangeForUnload": "Intervallo di tempo per scarico",
      "timeWindow": "Slot temporale",
      "unload": {
        "place": "Posizione di scarico",
        "term": "Data consegna"
      },
      "weightLoadSum": "Peso totale fasi di carico",
      "weightUnloadSum": "Peso totale fasi di scarico"
    },
    "table": {
      "loadMore": "Carica più dati",
      "loading": "I trasporti sono stati caricati",
      "numberOfTransports": "{n} trasporti",
      "scanBarcode": "Scansione codice a barre",
      "scanBarcodeError": "Si è verificato un errore durante la scansione del codice a barre. Verifica il dispositivo di scansione e riprova, oppure contatta il tuo amministratore.",
      "transports": {
        "scrollToType": "Scorri alla sezione trasporti di questo tipo.",
        "selectType": "Seleziona tipo",
        "showAll": "Visualizza tutti i trasporti",
        "showAllActive": "Visualizza tutti gli ordini attivi",
        "showLimited": "Visualizza ordini limitati",
        "showMine": "Visualizza solo i miei trasporti",
        "showWithinGroup": "Visualizza trasporti nel mio gruppo"
      }
    },
    "tabs": {
      "announcements": "Comunicazioni",
      "archive": "Archivio",
      "carriers": "Vettori",
      "companies": "Aziende",
      "invitations": "Inviti",
      "modeOfTransportation": "Modalità di trasporto",
      "planning": "Pianificazione",
      "priceLists": "Listini prezzi",
      "report": "Statistiche",
      "schedule": "Programma",
      "settings": "Impostazioni",
      "superAdmin": "AMMIN",
      "transports": "Trasporti",
      "users": "Utenti"
    },
    "task": {
      "alias": "Alias fase",
      "altCheckpointColor": "Colore stato alternativo",
      "altCheckpointName": "Nome alternativo fase IT",
      "altCheckpointNameEn": "Nome alternativo fase EN",
      "associations": {
        "CONTRACTOR": "Appaltatore",
        "WAREHOUSE": "Magazzino",
        "WAREHOUSE_AND_CONTRACTOR": "Magazzino e Appaltatore",
        "label": "Attività associata a"
      },
      "beforeConfirmation": {
        "willChangeColorTo": "e la relativa riga tabella e slot temporale saranno",
        "willChangeStateTo": "Fino alla conferma di questa fase, lo stato del trasporto sarà il seguente "
      },
      "blockade": {
        "customFieldsRestriction": "Lo stato può essere confermato solo dopo che i campi nel modulo trasporto sono stati completati e salvati:",
        "documentRestriction": "Lo stato può essere confermato solo dopo l’aggiunta dei documenti di trasporto"
      },
      "canBeBlocked": "Lo stato può essere bloccato (sarà possibile sbloccarlo finché non è bloccato), richiede inoltre l’autorità ROLE_CAN_BLOCK_TASK",
      "canBeConfirmAfterAddingDocumentsForTransport": "Può essere confermato dopo l’aggiunta dei documenti di trasporto",
      "canBeConfirmedByGuard": "Può essere confermato da una guardia",
      "canBeConfirmedWithSms": "La fase può essere confermata con un SMS",
      "canBeRejected": "Lo stato può essere rifiutato",
      "canBeRevoked": "Lo stato può essere revocato",
      "canConfirmPreviousConfirm": "Confermare la partenza di questo ordine? Gli stati scarico non sono stati approvati.",
      "checkpointName": "Nome fase IT",
      "checkpointNameEn": "Nome fase EN",
      "checkpointSortId": "Fase ordine nell’ordinamento",
      "checkpointSortIdSettingLabel": "Ordine fase durante l’ordinamento degli stati nella tabella trasporto",
      "color": "Colore STATO",
      "confirmCheckpointAfterCarrierSelect": "Conferma automatica dopo l’assegnazione del vettore",
      "confirmCheckpointAfterCourierDelivered": "Conferma automatica dopo conferma stato spedizione da parte del corriere",
      "confirmCheckpointAfterCourierPickup": "Conferma automatica dopo conferma stato ritiro da parte del corriere",
      "customFieldDefinition": "Attività associata al campo personalizzato",
      "customFieldOption": "Opzione campo personalizzato correlato",
      "customModalComponentName": "Nome componente utilizzato come pop-up modale, se l’attività ha l’impostazione openCustomModal abilitata (fare riferimento a quanto indicato in precedenza)",
      "defaultStagePrefix": "Confermato",
      "disableConfirm": "Disabilitare questa fase?",
      "editLabel": "Modifica fase: {id} - {transportType}",
      "errors": {},
      "expectedDateMode": {
        "reference": "entro"
      },
      "expectedDateReference": "Riferimento",
      "group": {
        "0": "Preparazione",
        "1": "Carichi e scarichi",
        "2": "Termine"
      },
      "isDriverCheckInTask": "Un flag che supporto la ricerca del trasporto per Infokiosk. Il trasporto che si sta cercando non può avere questo stato come confermato.",
      "label": "Fase",
      "markIfWasDelayed": {
        "disabled": {
          "label": "Contrassegna un ritardo",
          "title": "Fai clic sull’icona per abilitare una cornice rossa costante attorno a uno slot temporale se la fase viene confermata in ritardo."
        },
        "enabled": {
          "label": "Contrassegna un ritardo",
          "title": "Se una fase viene confermata troppo tardi, lo slot temporale sarà contrassegnato con una cornice rossa. Per modificare le impostazioni fai clic sull’icona."
        }
      },
      "moveTransportToArchiveIfDelayed": "Assegnazione ordine automatica all’archivio in caso di ritardo",
      "newSubTasksCheckpoints": "Saranno generati nuovi checkpoint",
      "openCustomModal": "Per confermare questa attività, devi confermare il pop-up modale.",
      "recreateMasterTaskSubTasks": "Dopo la conferma, creare nuovamente tutte le attività secondarie dell’attività principale di questa attività (attività principale :: {n}).",
      "reference": {
        "after": {
          "earliestStepStart": "inizio del primo carico/scarico",
          "latestStepStop": "fine del del primo carico/scarico",
          "minAdviceDate": "la data “da” nell’intervallo di date per l’invio di un avviso",
          "stepStart": "inizio di",
          "stepStop": "fine di",
          "transportCreated": "creazione trasporto"
        },
        "before": {
          "earliestStepStart": "inizio del primo carico/scarico",
          "latestStepStop": "fine del del primo carico/scarico",
          "minAdviceDate": "la data “da” nell’intervallo di date per l’invio di un avviso",
          "stepStart": "inizio di",
          "stepStop": "fine di",
          "transportCreated": "creazione trasporto"
        }
      },
      "repeatSendApiMessage": "Nuovo invio messaggio in caso di errore",
      "requiresWarehouseLocation": "Richiede una sede magazzino per confermare lo stato",
      "saveSubTasks": "Salva tutte le attività secondarie",
      "sendApiMessage": "Invio messaggio API",
      "sendDocumentDelayedEmail": "Invio di notifica e-mail relativa al ritardo nell’aggiunta di documenti",
      "settingsLabel": "Impostazioni fase",
      "shouldBeApprovedWithAdvice": "Conferma automatica stato dopo aggiunta di un avviso",
      "shouldBeApprovedWithCFTypeEmailAdvice": "Conferma automatica stato dopo aggiunta dati trasporto da avviso e-mail di tipo CF_DATA.",
      "shouldBeApprovedWithTimeWindow": "Conferma automatica stato dopo aggiunta di finestra temporale",
      "shouldBeConfirmedBy": "deve essere confermato da",
      "shouldBeConfirmedByRelatedCheckpoint": "[DELIVERY] Specifica il checkpoint che sarà approvato quando il checkpoint corrispondente con il flag “shouldConfirmRelatedCheckpoint” sarà approvato",
      "shouldBeSavedAsApproved": "Conferma automatica stato durante la creazione di un ordine",
      "shouldConfirmRelatedCheckpoint": "[DELIVERY] Specifica un checkpoint che, quando approvato, approverà anche il checkpoint corrispondente con il flag “shouldBeConfirmedByRelatedCheckpoint”",
      "shouldFindFreeSpotAfterDelay": "Trasferimento della finestra temporale in caso di ritardo",
      "shouldGenerateDocument": "Generazione automatica nota di consegna",
      "shouldSendLpToSapMessages": "[DELIVERY] Definisce il checkpoint dopo il quale sarà inviato un messaggio al microservizio MSP",
      "showPunctualityAlertAfterConfirmation": "Visualizza informazioni sulla puntualità della conferma dello stato",
      "sign": "Prima/Dopo",
      "stageName": "Nome stato prima della conferma dello stato IT",
      "stageNameEn": "Nome stato prima della conferma dello stato EN",
      "stayAtTheFacility": "Fase associata al mantenimento della posizione nella sede ",
      "stepType": {
        "firstLoad": "primo carico",
        "firstUnload": "primo scarico",
        "lastLoad": "ultimo carico",
        "lastUnload": "ultimo scarico",
        "load": "carico",
        "placeholder": "Tipo di azione",
        "unload": "scarico"
      },
      "taskIsNotSavedHint": "Questa fase non è stata ancora salvata, per poter modificare la fase aggiunta fai clic su Salva",
      "timeWindowCssClassName": "Classe css aggiuntiva per la finestra temporale",
      "tracked": {
        "disabled": {
          "label": "",
          "title": "La scadenza prevista per la fase di conferma sarà visualizzata solo a scopo illustrativo - se una fase non è confermata in tempo, il colore della riga o dello slot non saranno modificati e non sarà inviata alcuna notifica relativa al ritardo. Per modificare le impostazioni fai clic sull’icona."
        },
        "enabled": {
          "label": "monitoraggio puntualità",
          "title": "se una fase non è confermata in tempo, lo stato del trasporto sarà modificato in “ritardo” - la riga nella tabella e lo slot temporale saranno di colore rosso e saranno inviate e-mail relative al ritardo. Per modificare le impostazioni fai clic sull’icona."
        }
      },
      "unit": "Unità",
      "userHasToAddCheckpointConfirmationDate": "La data conferma stato è richiesta",
      "visibleInDeliveryDocument": "Visibile nella nota di consegna",
      "weighingIncluded": "Fase di pesatura"
    },
    "tasks": {
      "empty": "Nessuna attività presente qui."
    },
    "temporaryAccountAlert": {
      "daysLeft": "Giorni rimasti prima del blocco: {days}.",
      "limitReached": "La tua azienda ha già raggiunto il limite di utenti senza il suo piano prezzi, quindi questo account è temporaneo.",
      "pricingPlans": "listino prezzi.",
      "title": "È stato raggiunto il limite di utenti",
      "upgrade": "Se desideri aumentare il limite di utenti devi acquistare un piano prezzi differente. I dettagli sono disponibili nel nostro"
    },
    "termini di servizio": {
      "errors": {
        "unchecked": "Per inviare una richiesta di verifica, devi confermare i TERMINI DI SERVIZIO"
      },
      "label": {
        "acquiant": "Ho letto: ",
        "confirmed": "Sono stati accetti il {confirmationDate} dall’utente {user}",
        "documents": "Documenti Termini di servizio:"
      },
      "name": "TERMINI DI SERVIZIO",
      "shortName": "TDS"
    },
    "timeWindow": {
      "cancelCreation": "Annulla (modifica la posizione di uno slot temporale)",
      "errors": {
        "start": {
          "notConsistentWithWarehouseOffset": "L’inizio dello slot temporale non è compatibile con le impostazioni magazzino. Possibili inizi dello slot temporale per l’orario selezionato: {possibleWindowStarts}",
          "outsideWarehouseWorkHours": "La data selezionata non rientra nell’orario di lavoro del magazzino: {workStart}-{workEnd}.",
          "startsEarlierThenPreviousWindowStops": "L’inizio della finestra temporale non può essere precedente al termine della finestra temporale precedente nel trasporto."
        },
        "stop": {
          "notConsistentWithWarehouseOffset": "La fine dello slot temporale non è compatibile con le impostazioni magazzino. Possibile fine dello slot temporale per l’orario selezionato: {possibleWindowStops}",
          "outsideWarehouseWorkHours": "La data selezionata non rientra nell’orario di lavoro del magazzino: {workStart}-{workEnd}."
        },
        "time": {
          "outsideOfAdviceRange": "Impossibile aggiungere questo slot temporale qui perché sarebbe al di fuori dell’intervallo di tempo dell’avviso: {minAdviceDate} - {maxAdviceDate}."
        },
        "warehouseHasNoSpaceThisDay": "Nessuno slot temporale disponibile il <date>. Selezionare una data differente.",
        "wrongWarehouse": "Non puoi inserire uno slot temporale in un magazzino non assegnato per un trasporto"
      },
      "ramp": "Rampa",
      "start": "Inizio di uno slot temporale",
      "stop": "Fine di uno slot temporale"
    },
    "transaction": {
      "accept": "Accetta",
      "autoPass": "Trasferisci aut.",
      "autoPassConfirmation": "Abilitare il trasferimento automatico ai vettori successivi per questo trasporto?",
      "carrier": "Vettore",
      "carrierMustAcceptTransport": "Il trasferimento richiede'l’accettazione del vettore",
      "carrierMustAcceptTransportHint": {
        "checked": "Dopo il trasferimento dell’ordine il vettore selezionato potrà accettare o rifiutare l’ordine. Il vettore deve confermare l’ordine affinché gli venga assegnato.",
        "unchecked": "L’ordine sarà assegnato immediatamente all’azienda del vettore selezionato."
      },
      "chooseCarrier": "Seleziona un vettore",
      "chooseCourier": "Seleziona un corriere",
      "courier": "Corriere",
      "currency": "Valuta",
      "dateCreated": "Creato alle",
      "errors": {
        "expectedConfirmationDate": {
          "isInPast": "La data inserita non può essere nel passato"
        },
        "invoicePrice": {
          "wrongNumberFormat": "Il prezzo deve essere un numero"
        }
      },
      "expectedConfirmationDate": "Scadenza della conferma",
      "invoiceCurrency": "Valuta fattura",
      "invoiceNumber": "Numero fattura",
      "invoicePrice": "Prezzo fattura",
      "isRejected": "Rifiutato",
      "label": "Transazione",
      "notYetImplemented": "Questa funzionalità non è stata ancora implementata.",
      "offer": "Offerta",
      "pass": "Trasferisci",
      "price": "Prezzo",
      "rate": "Tariffa",
      "reject": "Rifiuta",
      "transport": "Trasporto",
      "updateDisabledInfo": {
        "route": "La tariffa è relativa all’itinerario {routeId}, quindi'Non è possibile modificarla."
      },
      "waitingForAcceptanceInfo": "In attesa dell’accettazione",
      "withInvoice": "con fattura",
      "withoutInvoice": "senza fattura"
    },
    "transactionSetting": {
      "description": {
        "canChooseCarrierFromTransportTable": "Determina se il pulsante “Trasferisci” sarà disponibile nella tabella trasporto nella riga trasporto (con opzioni Limitazione Autorità correttamente impostate).",
        "defaultCurrency": "Specifica la valuta predefinita selezionata nel modulo trasferimento.",
        "defaultInvoiceCurrency": "Specifica la valuta predefinita selezionata nel modulo fattura.",
        "priceLabel": "Specifica l’etichetta prezzo predefinito.",
        "requireCarrierConfirmation": "Specifica se la conferma è richiesta quando l’ordine viene trasferito al vettore. Se il flag “Il trasferimento richiede'l’accettazione del vettore” è TRUE, definisce il valore predefinito del campo “Il trasferimento richiede'l’accettazione del vettore”.",
        "requiresReasonField": "Campo “Motivo” richiesto.",
        "showAndRequireReasonFieldIfPriceIsZero": "Visibilità e obbligo del campo “Motivo” quando un valore di 0 è inserito nel campo “Merce”",
        "showCarrierConfirmationCheckbox": "Specifica se la casella di controllo deve essere visibile nel modulo trasferimento: campo “Il trasferimento richiede'l’accettazione del vettore”",
        "showExpectedConfirmationDate": "Specifica se la casella di controllo deve essere visibile nel modulo trasferimento: “Scadenza conferma”",
        "showPriceField": "Specifica se la casella di controllo deve essere visibile nel modulo trasferimento: Campo “Prezzo”",
        "showPriceFieldForDeliveries": "Visibilità campo “Merce” per ciascuna consegna",
        "showReasonField": "Visibilità campo “Motivo”."
      },
      "label": {
        "canChooseCarrierFromTransportTable": "Mostra il pulsante “Passa” nella tabella trasporto",
        "defaultCurrency": "Valuta transazione predefinita",
        "defaultInvoiceCurrency": "Valuta fattura predefinita",
        "priceLabel": "Etichetta prezzo predefinito",
        "requireCarrierConfirmation": "Richiede la conferma della transazione da parte del vettore",
        "requiresReasonField": "Campo “Motivo” richiesto.",
        "showAndRequireReasonFieldIfPriceIsZero": "Visibilità e obbligo del campo “Motivo” quando un valore di 0 è inserito nel campo “Merce”",
        "showCarrierConfirmationCheckbox": "Visibilità campo “Il trasferimento richiede'l’accettazione del vettore”",
        "showExpectedConfirmationDate": "Visibilità di “Scadenza conferma”",
        "showPriceField": "Visibilità campo “Prezzo”",
        "showPriceFieldForDeliveries": "Visibilità campo “Merce” per ciascuna consegna",
        "showReasonField": "Visibilità campo “Motivo”."
      },
      "priceLabel": {
        "FREIGHT": "Merce",
        "FREIGHT_FOR_CARRIER": "Merce per vettore",
        "PRICE": "Prezzo",
        "RATE": "Tariffa"
      },
      "saveSuccess": "Impostazioni transazione salvate correttamente"
    },
    "transport": {
      "ARTR": "Stato comunicazione ANPR",
      "CARTONS": "Cartoni",
      "CHEP_PALLETS": "Pallet CHEP",
      "DISPOSABLE_PALLETS": "Pallet monouso",
      "Delivery": "Consegna",
      "EURO_PALLETS": "Pallet EURO",
      "LOADING_PLACES": "Posizioni di carico",
      "PALLETS": "Pallet",
      "PALLET_PLACES": "Posizioni pallet",
      "THE_NUMBER_OF_ITEMS": "Numero di articoli",
      "accountExpired": "Account sospeso a causa di assenza di attività",
      "actionType": "Tipo di azione",
      "addNewCompany": {
        "assignmentCompany": "Assegnare questa azienda ad un appaltatore di trasporto?",
        "cooperatingCompanies": "Stai già collaborando con un’azienda con nome simile",
        "emailInfo": "Un invito per la collaborazione sarà inviato all’indirizzo e-mail fornito.",
        "emailRequired": "Se desideri inviare un invito a collaborare, devi indicare un indirizzo e-mail",
        "existingCompany": "Il nostro database contiene un’azienda con Codice di identificazione fiscale (CIF) selezionato",
        "existingCompanyAndPartnership": "L’azienda con Codice di identificazione fiscale (CIF) {taxIdNumber} è già registrata nel sistema e appartiene ai tuoi colleghi",
        "label": "Aggiungi una nuova azienda che non è stata ancora inserita nel sistema.",
        "sendInvitation": "Invia un invito a collaborare",
        "wrongEmail": "L’indirizzo e-mail inserito non è corretto"
      },
      "additionalDescription": "Note aggiuntive",
      "advice": "Avviso",
      "altIdentifier": "Numero ordine alternativo",
      "amountOfCover": "Valore copertura",
      "archived": "Archiviato",
      "arrivalDate": "Data ritiro",
      "assetId": "ID asset",
      "authorityRange": "Intervallo autorità",
      "canNotEditAfterProcessStarts": "È possibile modificare i dati di un avviso creato e prima dell’arrivo dell’autista.",
      "canNotEditBecauseOfOtherContractors": "Non è possibile modificare i dati di un avviso da appaltatori multipli.",
      "carrier": {
        "label": "Vettore"
      },
      "carrierCompany": "Azienda vettore",
      "carrierCompanyChooser": "Persona che ha selezionato il vettore",
      "carrierCompanySelectedBy": "Vettore selezionato da {companyName}",
      "combinedWeight": "Peso totale trasporti: ",
      "company": "Fornitore",
      "company.autoCompletePlaceholder": "Per cercare un’azienda inizia a digitare",
      "companyNotQualified": "La tua azienda non soddisfa le condizioni",
      "completed": "Completato",
      "courier": {
        "dateInPast": "La data di ritiro indicata è nel passato. Per inoltrare la spedizione al corriere, aggiornare la data di ritiro nei dettagli dell’ordine.",
        "dhlMissingDocument": "Il paese/i paesi selezionati sono al di fuori dell’UE e richiedono PLT. Per trasferire il trasporto a DHL Express impostare la data e il numero della fattura e allegare la fattura in formato pdf nella sezione Fattura per merci del trasporto.",
        "dhlMissingExportDeclaration": "Il paese/i paesi selezionati sono al di fuori dell’UE. Per trasferire il trasporto a DHL Express impostare la data e il numero della fattura nella sezione Fattura per merci del trasporto.",
        "label": "Corriere",
        "loadCity.label": "Sede di carico:",
        "missingContactDetails": "Se il nominativo, il numero di telefono o l’indirizzo e-mail della persona di contatto manca in una qualsiasi fase di trasporto, il trasporto non può essere trasferito al corriere.",
        "pickUpDate.label": "Data ritiro",
        "pickUpHour.label": "Ora ritiro",
        "service.label": "Servizio",
        "unloadCity.label": "Sede di scarico:"
      },
      "courierInvoiceSection": {
        "button": "Aggiungi fattura",
        "date": "Data fattura",
        "file": "Fattura allegata",
        "label": "Fattura per merci",
        "number": "Numero fattura"
      },
      "courierSection": {
        "deliveryDate": "Data di consegna",
        "goodsValue": "Valore merci",
        "goodsValueCurrency": "Valuta",
        "incoterms": "Incoterms",
        "label": "Corrieri",
        "orderBy": "Ordine di",
        "serviceType": "Tipo di servizio",
        "totalPrice": "Prezzo totale"
      },
      "createSimilarMultiple": "Crea trasporti simili",
      "createSimilarMultipleInProgress": "Copia trasporto in corso, la finestra si chiuderà quando tutti i trasporti saranno creati",
      "createSimilarMultiplePlaceholder": "Numero di copie",
      "createSimilarMultipleValue": "Numero di trasporti da creare",
      "createTimeWindow": "Crea finestra temporale",
      "createTitle": "Programma consegna",
      "createdAsCopy": "come copia dell’ordine",
      "createdBy": "Creato da",
      "createdMessage": "Creato il {date} da {person} ({company}).",
      "creator": "Creatore",
      "dateCreated": "Data di creazione",
      "dateOfAdvice": "Data avviso",
      "dateOfArchiving": "Data di archiviazione",
      "dateOfDeletion": "Data di eliminazione",
      "delete": "Elimina trasporto",
      "deleteTransport": "Eliminare questo trasporto?",
      "departureDate": "Data consegna stabilita",
      "description": "Note",
      "details": "Dettagli consegna",
      "documentType": "Tipo documento",
      "documents": {
        "dragAndDrop": "Trascina e rilascia documenti o fai clic",
        "empty": "A questa consegna non è stato ancora aggiunto nessun documento.",
        "header": "Documenti di consegna {identifier}",
        "label": "Documenti"
      },
      "earliestTimeWindowStart": "Inizio del primo slot temporale",
      "edit": "Modifica trasporto",
      "email": "Indirizzo e-mail",
      "erpIdentifier": "Numero ordine",
      "erpIdentifier.placeholder": "ad es. 110011",
      "errors": {
        "altIdentifier": {
          "regex": "Il valore fornito non corrisponde all’espressione regolare in Impostazioni"
        },
        "carrierCompany": {
          "info": "Questo tipo di trasporto richiede l’inserimento dell’azienda vettore. Se non'vedi nessuna azienda, avvia una partnership."
        },
        "contractors": {
          "notUnique": "Fornire una lista unica di appaltatori"
        },
        "dateOfAdvice": {
          "isInPast": "Non puoi programmare consegne nel passato"
        },
        "erpIdentifier": {
          "minLength": "Un identificatore ERP deve essere composto da almeno 3 caratteri"
        },
        "firstContractor": {
          "info": "Questo tipo di trasporto richiede l’inserimento dell’azienda appaltatrice Se non'vedi nessuna azienda, avvia una partnership."
        },
        "requiresWorker": {
          "info": "Scegliere lavoratori."
        }
      },
      "forAcceptOrReject": " accettare/rifiutare spedizioni ordinate",
      "forAuction": " per partecipare all’invio di offerte per aste.",
      "forProcess": " per aggiungere un avviso a questo processo.",
      "generateLOPReport": "Scarica report con elenco dei punti",
      "getCourierQuotes": "Richiedi offerta corriere",
      "goodsValue": "Valore merci",
      "goodsValueCurrency": "Valuta",
      "group": "Gruppo di consegna",
      "history": "Cronologia ordine",
      "incompleteLoading": "Carico non completo",
      "incoterms": "Incoterms",
      "indexes": {
        "overwritePreviousIndexes": "Sovrascrivi indici precedenti",
        "pasteFromExcel": "Incolla da Excel"
      },
      "indicesTransportNumbers": {
        "label": "Tipo di ordine"
      },
      "isCombinedTransport": "Trasporto condiviso con: ",
      "isRequiredFor": "È necessario per: ",
      "isWaitingFor": "In attesa di: ",
      "label": "Trasporto",
      "lastLoginDate": "Accedi al sistema",
      "latestTimeWindowStop": "Fine dell’ultimo slot temporale",
      "load": "Carica",
      "logs": {
        "apiIntegration": "Integrazione",
        "artr": "Registri ANPR",
        "carrierCompany": "Azienda vettore",
        "dateOfArchiving": "Il trasporto è stato rimosso",
        "dateOfDeletion": "Il trasporto è stato rimosso",
        "initialStartInfo": "Lo slot temporale è stato spostato da orario {initialDate} a orario {startDate}",
        "label": "Cronologia modifiche",
        "name": "File",
        "ramp": "Rampa",
        "start": "Inizio della fascia oraria",
        "stop": "Fine della fascia oraria"
      },
      "mobilePhoneNumber": "Numero di telefono",
      "mobilePhonePrefix": "Numero di telefono - prefisso",
      "multiArchive": {
        "alert": "Non hai le autorizzazioni per archiviare i trasporti con i seguenti numeri: ",
        "confirm": "Archiviare i seguenti trasporti? ",
        "error": "Errore imprevisto durante il tentativo di archiviazione dei trasporti in blocco. ",
        "label": "Archiviazione gruppo"
      },
      "noFilesForOldBrowsers": "Puoi non aggiungere file in questa visualizzazione del browser. Aggiungere un file dal livello della tabella.",
      "option": "Opzione selezionata",
      "otherRequirements": "Altri requisiti",
      "packs": {
        "boxes": "Scatole",
        "label": "Confezione",
        "pallets": "Pallet",
        "pallets.abbr": "p."
      },
      "periodicTransport": "Avviso periodico",
      "periodicTransportInternalIndex": "Numero avviso periodico",
      "periodicTransportMessage": "Ordine collegato a numero avviso periodico {periodicTransportInternalIndex}.",
      "print": "Visualizzazione stampa",
      "quantities": {
        "CARTONS": "Numero di scatole",
        "CHEP_PALLETS": "Numero di pallet CHEP",
        "CUBIC_METERS": "Numero di metri cubi",
        "DISPOSABLE_PALLETS": "Numero di pallet monouso",
        "EURO_PALLETS": "Numero di pallet EURO",
        "LOADING_PLACES": "Numero di posizioni di carico",
        "PALLETS": "Numero di pallet",
        "PALLETS_WITH_RETURNED_GOODS": "Numero di pallet con merci restituite",
        "PALLET_PLACES": "Numero di posizioni pallet",
        "RETURNABLE_PALLETS": "Numero di pallet restituibili vuoti",
        "SPATIAL_METERS": "Numero di metri spaziali",
        "THE_NUMBER_OF_ITEMS": "Numero di articoli",
        "shortName": {
          "CARTONS": "Cartoni",
          "CHEP_PALLETS": "CHEP",
          "DISPOSABLE_PALLETS": "Monouso",
          "EURO_PALLETS": "EURO",
          "LOADING_PLACES": "Carico",
          "PALLETS": "Pallet",
          "PALLETS_WITH_RETURNED_GOODS": "Resi",
          "PALLET_PLACES": "Posizioni",
          "RETURNABLE_PALLETS": "Restituibile",
          "THE_NUMBER_OF_ITEMS": "Articoli"
        }
      },
      "quantity": "Numero di pallet",
      "quantity.placeholder": "ad es. 33",
      "readyToPlan": "Pronto per pianificazione",
      "recipient": "Destinatario",
      "relations": {
        "bind": "Crea relazione",
        "combineClearingAlert": "Combinare i trasporti selezionati? La conferma eliminerà le finestre temporali e i dati di avviso relativi ai trasporti selezionati.",
        "combinedTransports": "Trasporti combinati: ",
        "combining": "“Combinazione trasporti” (TRANSPORT_COMBINING)",
        "defaultLabel": "Puoi combinare i trasporti selezionati con il tipo di relazione: ",
        "deletedSuccessfully": "Relazione trasporto rimossa correttamente",
        "hasToWaitFor": "“Trasporto in attesa di” (HAS_TO_WAIT_FOR)",
        "mainTransport": "Trasporto principale: ",
        "maxWeight": "Il peso massimo è: ",
        "noTypeForSelection": "Spiacenti, i trasporti selezionati non consentono nessuna relazione",
        "overweight": "Impossibile combinare i trasporti a causa del superamento del peso massimo",
        "relatedTransports": "Trasporti correlati: ",
        "removeRelation": "Elimina relazione",
        "title": "Relazione trasporti"
      },
      "restoredSuccessfully": "L’ordine è stato ripristinato correttamente",
      "roleGroup": "User's role",
      "routeOffer": "Inoltra offerta",
      "scheduledDateOfAdvice": "Data avviso programmata",
      "selectCountry": "Paese",
      "selectSupplier": "Seleziona fornitore//destinatario",
      "selectTransportType": "Seleziona tipo fornitura",
      "selectUnloadStartDate": "Data min. di scarico",
      "selectUnloadStartDateTitle": "Seleziona la data di scarico minima.",
      "selectUnloadStopDate": "Data max. di scarico",
      "selectUnloadStopDateTitle": "Seleziona la data di scarico massima.",
      "selectWarehouse": "Seleziona magazzino",
      "selectedCombinedTransportsWindows": "Gli slot temporali assegnati ai trasporti ",
      "selectedWindows": "Gli slot temporali assegnati al trasporto {transportIdentifier}",
      "serviceCompany": "Azienda di servizi",
      "stepType": "Tipo di fase",
      "sureToSaveMessage": "Salvare le modifiche? Alcuni campi sono stati modificati da un altro utente.",
      "task": "Attività",
      "title": "Trasporto {ident}",
      "tooManyCopies": "Impossibile creare più di {maxValue} copie di trasporto alla volta",
      "transportRelation": "Relazione",
      "transportSupervisor": {
        "autoCompletePlaceholder": "Per cercare una persona di contatto inizia a digitare",
        "label": "Persona di contatto"
      },
      "transportType": "Tipo di trasporto",
      "truck": "Modalità di trasporto",
      "type": {
        "ALL": "Tutti",
        "COLLECTION": "Raccolta",
        "DELIVERY": "Consegna",
        "SERVICE": "Corriere, servizio, ecc.",
        "SIMPLIFIED_DELIVERY": "Consegna semplificata",
        "TRANSFER_BETWEEN_WAREHOUSES": "Trasferimento tra magazzini",
        "TRANSPORT": "Trasporto"
      },
      "typeOfService": "Courier's service",
      "unload": "Scarica",
      "value": "Valore",
      "volume": "Volume",
      "warehouse": "Magazzino",
      "wasMovedAutomatically": "Finestra temporale spostata automaticamente",
      "weight": "Peso",
      "weight.placeholder": "ad es. 23",
      "weightSection": {
        "downloadWeightReceipt": "Scarica ricevuta peso",
        "entryWeight": "Pesatura 1",
        "errors": {
          "cannotGetWeight": "Purtroppo non è possibile scaricare il peso. Riprovare più tardi. Se il problema persiste, contattare l’amministratore di sistema.",
          "trySaveCheckpointWithoutWeight": "Questo checkpoint richiede la pesatura. Purtroppo non è possibile scaricare il peso. Confermare il checkpoint con peso 0?"
        },
        "exitWeight": "Pesatura 2",
        "label": "Peso",
        "netWeight": "Peso netto",
        "notSynchronized": "Peso non sincronizzato con server",
        "overweightMessage": "Quando caricato, il veicolo supererà il peso massimo. Questo veicolo non deve essere caricato",
        "overweightNote": "Il veicolo ha superato il limite di peso impostato!",
        "unit": "kg",
        "weighAgain": "Pesa nuovamente",
        "weight": "peso"
      },
      "wzDocument": "Numero nota consegna",
      "wzNumber": "Numero nota consegna"
    },
    "transport-type": {
      "errors": {
        "defaultLoadStepEndHour": {
          "regex": "Il valore fornito deve essere un orario nel formato HH:SS"
        },
        "defaultLoadStepStartHour": {
          "regex": "Il valore fornito deve essere un orario nel formato HH:SS"
        }
      }
    },
    "transportPanel": {
      "ADVICE": "Avviso",
      "DISCREPANCY_REPORT": "Registrazione delle divergenze",
      "DOCUMENTS": "Documenti",
      "INSPECTION": "Ispezione",
      "LOGS": "Cronologia modifiche",
      "WEIGHT": "Peso",
      "selectPlaceholder": "Seleziona pannello"
    },
    "transportSupervisorTranslationKeys": {
      "CONTACT_PERSON": "Persona di contatto",
      "HOST_PERSON": "Persona ospitante",
      "INVITEE": "Invitato",
      "ORDERER": "Committente",
      "PERSON_TO_NOTIFY": "Persona a cui notificare"
    },
    "transportsToExcel": {
      "XLSGenerateMessage": "Dopo essere stato creato, il report degli ordini sarà inviato all’indirizzo e-mail della persona che lo ha creato.",
      "activeArchiveType": "Attiva/archivia ordini",
      "advancedExportType": "Avanzata",
      "advancedExportTypeDescription": "La versione avanzata ha due fogli. Il primo foglio contiene gli stessi dati della versione Base, eccetto le informazioni sullo stato del trasporto. Il secondo foglio contiene tutte le informazioni correlate allo stato delle conferme.",
      "adviceReportLabel": "Questa versione contiene report avviso. Il report è disponibile per i seguenti tipi: ",
      "adviceReportLabelEmpty": "Nessun tipo di trasporto disponibile",
      "adviceReportType": "Report avviso",
      "alsoExportActiveOrders": "Esporta anche ordini attivi",
      "basicExportType": "Base",
      "basicExportTypeDescription": "La versione base visualizza i dati in un unico foglio.",
      "billingExportType": "Report fatturazione",
      "billingExportTypeDescription": "Questa versione contiene i dati relativi alla fatturazione.",
      "errors": {
        "DaysLimitExceededMessage": "Errore. Impossibile creare il report. L’intervallo di date consentito per la creazione del report è stato superato.",
        "XLSGenerateEmailErrorMessage": "Il report degli ordini è inviato all’indirizzo e-mail della persona che ha creato il report: completare l’indirizzo e-mail nella scheda Il mio account.",
        "XLSGenerateErrorMessage": "Errore. Impossibile creare il report. Riprovare più tardi o contattare l’indirizzo info@logintegra.com."
      },
      "export": "Esporta",
      "exportType": "Versione file",
      "noTypeChosen": "Scegliere almeno un tipo di trasporto",
      "ownColumnExportType": "Colonne tabella",
      "ownColumnExportTypeDescription": "La versione colonna tabella ha solo informazioni visibile nella tabella trasporto",
      "title": "Parametri file Excel",
      "transportTypes": "Tipi di ordine"
    },
    "truck": {
      "addTruck": "Aggiungi un camion",
      "description": "Descrizione",
      "disableConfirm": "Rimuovere questo camion?",
      "errors": {
        "registrationNumber": {
          "regex": "Il numero di targa deve essere composto da 4 a 50 caratteri e può contenere solo lettere, numeri e caratteri. , ; / \\\\ & _"
        },
        "trailer": {
          "regex": "Il numero di targa deve essere composto da 4 a 50 caratteri e può contenere solo lettere, numeri e caratteri. , ; / \\\\ & _"
        }
      },
      "hds": "Autogru",
      "label": "Camion",
      "lastUpdated": "Ultimo aggiornamento",
      "registrationNumber": "Numero di targa",
      "timeWindow": "Slot temporale",
      "trailer": "Rimorchio/Semirimorchio",
      "truckType": "Digita"
    },
    "trucks": {
      "label": "Camion"
    },
    "unit": {
      "KG": "kg",
      "LITERS": "litri",
      "PIECES": "pezzi",
      "label": "Unità",
      "placeholder": "Seleziona unità"
    },
    "user": {
      "accountCreatedBy": "Account creato da",
      "accountUpdated": "Le modifiche alle impostaizoni9 account sono state salvate.",
      "activeNotifications": "Attiva notifiche",
      "availableQueues": "Linee disponibili",
      "availableRamps": "Rampe disponibili",
      "availableWarehouses": "Magazzini disponibili",
      "contractorGroupName": "Gruppo",
      "createUser": "Crea utente",
      "deleteConfirm": "Eliminare questo utente?",
      "deletedMessage": "Eliminato il {date} da {person} dall’azienda {company}.",
      "deletedUser": "L’utente è stato rimosso",
      "disableConfirm": "Disabilitare questo utente?",
      "disabledUser": "L’utente è stato disabilitato",
      "editUser": "Modifica utente",
      "email": "E-mail",
      "errors": {
        "mobilePhoneNumberLengthError": "Il numero di telefono deve contenere 9 cifre.",
        "mobilePhoneNumberRegexError": "Il numero di telefono può contenere solo cifre."
      },
      "groupName": "Divisione",
      "hasRoleAccessAllRamp": "Gli Amministratori hanno l’autorizzazione “Accedi a tutte le rampe/gli slot nel programma”. Indipendentemente dalla selezione delle rampe disponibili in questa visualizzazione, l’utente avrà accesso a tutte le rampe.",
      "invitedBy": "Invitato: {date} da {person}.",
      "lastLoginDate": "Ultima data di accesso",
      "ldapUser": "Utente per integrazione LDAP",
      "limitedDataHint": "La visualizzazione e la modifica di alcuni dettagli dell’utente {user} sono limitate, la gestione è possibile solo da parte degli Amministratori dell’azienda {company}.",
      "list": "Elenco utenti",
      "localAdmin": "Amministratore locale",
      "mobilePhoneNumber": "Telefono",
      "myAccount": "Il mio account",
      "name": "Nome e cognome",
      "noActiveNotifications": "Nessuna notifica attiva",
      "noAvailableRamps": "Nessuna rampa disponibile",
      "registrationDate": "Data di registrazione",
      "roleDescription": "Descrizione del ruolo",
      "selectRole": "Seleziona ruolo",
      "status": {
        "ACTIVE": "Attiva",
        "DELETED": "Eliminato",
        "EXPIRED": "Non attivo",
        "TEMPORARY": "Temporaneo",
        "label": "Stato utente"
      },
      "temporaryAccountDaysLeft": "Giorni al blocco",
      "userGroup": {
        "allTransports": "Tutti i trasporti",
        "myGroupsTransports": "I trasporti'del mio gruppo",
        "myTransports": "I miei trasporti"
      },
      "username": "Accesso"
    },
    "userGroups": {
      "canSeeOtherGroupsTransports": "Accedi ad altri gruppi trasporti",
      "identifier": "Identificatore",
      "label": "Gruppi utente",
      "name": "Nome"
    },
    "users": {
      "activity": "Attività",
      "data": "Utente'Dati utente",
      "label": "Utenti",
      "notifications": "Notifiche",
      "queues": "Acceso a linee",
      "ramps": "Acceso a rampe/slot"
    },
    "warehouse": {
      "addRamp": "Aggiungi rampa",
      "adviceHasBeenSavedInfo": "L’avviso è stato salvato per questo orario: ",
      "autoCompletePlaceholder": "Inizia a digitare per cercare magazzini, indirizzi, ecc.",
      "backToDeliveries": "Tabella consegne",
      "configureAlgorithmAfterSave": "L’algoritmo per il calcolo della durata dello slot temporale può essere impostato dopo aver salvato il magazzino.",
      "configureAssortmentGroupsAfterSave": "I gruppi assortimento possono essere impostati dopo aver salvato il magazzino.",
      "confirmDeleteDedicateWindow": "Rimuovere lo slot dedicato per l’azienda {company}?",
      "create": "Crea magazzino",
      "createRampsAfterSave": "Le zone di stoccaggio, le sedi e le rampe possono essere configurate dopo aver salvato il magazzino.",
      "createTransportInfo": "Seleziona uno spot libero nel programma per creare un tipo di trasporto: {transportTypeName}.",
      "disableConfirm": "Disabilitare questo magazzino?",
      "disabledMessage": "Magazzino \"{warehouseName}\" eliminato correttamente.",
      "enableConfirm": "Abilitare questo magazzino?",
      "errors": {
        "idx": {
          "invalidIdxNumber": "Il numero ID del magazzino deve essere compreso tra 0 e {numberOfWarehouses}"
        },
        "snapToClosestWindowInterval": {
          "info": "Inserisci la massima distanza tra i limiti per lo spostamento automatico dello slot temporale. I valori possibili sono 0, 15, 30 o 60."
        },
        "timeWindowOffsetInterval": {
          "info": "Inserisci il numero di minuti entro cui lo slot può essere spostato."
        },
        "windowConstantTime": {
          "info": "Inserisci un valore costante in minuti da aggiungere alla durata dello scarico negli slot temporali."
        },
        "workEndHour": {
          "endBeforeStart": "L’orario di chiusura del magazzino deve essere successivo all’orario di apertura.",
          "invalidNumber": "Selezionare un numero compreso tra 1 e 24."
        },
        "workStartHour": {
          "invalidNumber": "Selezionare un numero compreso tra 0 e 23."
        }
      },
      "hdOff": "Spegni HD",
      "hint": "Per selezionare l’orario di consegna, fare clic sullo spazio vuoto nel programma.",
      "idx": "Il numero ID del magazzino.",
      "indexesDetailsTitle": "Fai clic per visualizzare gli indici",
      "integrationCode": "Codice magazzino per integrazione MSP",
      "label": "Magazzino",
      "numberOfRamps": "Numero di rampe",
      "reserveFreePlace": "Riserva uno spazio libero nel programma per {initialStart}",
      "selectCompany": "Dopo aver fatto clic su uno spazio vuoto, creare uno slot dedicato per l’azienda",
      "selectCountry": "Seleziona paese",
      "selectDepartment": "o seleziona reparto",
      "selectNewPlaceForWindow": "Seleziona una nuova posizione per lo slot temporale selezionato",
      "selectWindowInfo": "Seleziona uno spot libero nel programma per aggiungere un avviso di trasporto {identifier}.",
      "selectWindowWarning": "Puoi programmare un avviso al massimo per il {date}.",
      "selectedWindowsCombinedTransports": "Seleziona uno spot libero nel programma per aggiungere un avviso per trasporti combinati",
      "shouldFindFreeSpotAfterDelay": "Trasferimento della finestra temporale in caso di ritardo nella fase indicata",
      "slider": {
        "title": "Ridimensiona righe nel programma"
      },
      "snapToClosestWindowInterval": "Massima distanza tra i limiti per lo spostamento automatico dello slot temporale. Viene spostato solo uno slot -> quello inserito come nuovo.",
      "sumOfPallets": "Totale dei pallet",
      "templates": {
        "copyFrom": "Copia modelli da giorno",
        "datesWithErrorMessage": "Nota: a causa della modifica temporale non è possibile salvare alcuni slot temporali in questi giorni: {windows}.",
        "header": "Modelli di slot temporali per magazzino",
        "label": "Modelli",
        "saveFor": "salva modello per",
        "savedMessage": "Il modello è stato salvato correttamente."
      },
      "timeWindowDefaultLengthInMinutes": "La dimensione predefinita degli slot temporali in minuti",
      "timeWindowLengthLongerThanWarehouseWorkingHours": "Lo slot temporale può essere maggiore dell’orario lavorativo del magazzino",
      "timeWindowMustOccupyEntireRamp": "Lo slot temporale deve occupare l’intera rampa il giorno selezionato",
      "timeWindowMustOccupyEntireRampDisclaimer": "Lo slot temporale in questo magazzino occuperà l’intera rampa",
      "timeWindowOffsetInterval": "Numero di minuti entro cui gli slot temporali possono essere avviati",
      "timeWindowSummaryInfo": "Select a free spot in the schedule to add",
      "timeWindowSummaryInfoSubtitle": "Il trasporto richiede l’aggiunta di {timeWindowsNumber} finestre temporali",
      "transportTypes": "Tipi di trasporto per i quali un magazzino è disponibile",
      "updateSuccessMessage": "Le impostazioni relative al magazzino {warehouseName} sono state aggiornate.",
      "warehouseLocationAdd": "Aggiungi sede magazzino",
      "warehouseLocationLabel": "Sede magazzino",
      "warehouseLocationLabelPlural": "Sedi magazzino",
      "warehouseLocationNewLocation": "Nuova sede magazzino",
      "warehouseLocationNextLocation": "Passare a una sede magazzino differente?",
      "weighingIncluded": "La pesatura sarà effettuata in questo magazzino",
      "windowConstantTime": "Valore costante in minuti aggiunto alla durata dello scarico negli slot temporali.",
      "windowIsLoad": "L",
      "windowIsUnload": "U",
      "windowNoteLabel": "Contenuto nota",
      "windowNoteRemoveWarning": "Eliminare lo slot con nota: {note}?",
      "windowNoteTitle": "Inserisci la nota",
      "windowRemoveWarning": "Rimuovere la finestra di blocco?",
      "workEndHour": "Orario chiusura",
      "workStartHour": "Orario apertura",
      "workTime": "Orari lavorativi magazzino",
      "youAreEditingThisWindow": "stai modificando questo slot temporale",
      "youAreInWarehouse": "Sei in un magazzino",
      "youWereMovedToWarehouse": "Sei stato spostato al magazzino"
    },
    "warehouseZone": {
      "addZone": "Aggiungi zona magazzino",
      "algorithmSettings": "L’algoritmo per il calcolo dello slot temporale",
      "deliveryDefaultZone": "[DELIVERY] Zona predefinita alla quale sono diretti i mezzi di trasporto che non corrispondono a nessuno schema",
      "deliveryModeOfTransport": "[DELIVERY] Mezzi di trasporto diretti a questa zona di stoccaggio separati da un punto e virgola, ad es. : Plandeka Wysoka;Plandeka standard;Plandeka standard EXW",
      "disableConfirm": "Disabilitare questa zona?",
      "englishName": "Nome zona inglese",
      "errors": {},
      "hint": "{warehouseZoneLabel} - è possibile modificarla spostando lo slot temporale nel programma.",
      "idx": "La sequenza della zona",
      "label": "Zona magazzino",
      "noTransportTypesAssigned": "Nessuna zona magazzino assegnata. I tipi di trasporto possono essere assegnati alla zona magazzino nei dettagli zone magazzino.",
      "noTransportTypesSelected": "Lasciare la zona magazzino senza nessuna assegnazione tipo di trasporto? Non è possibile aggiungere un avviso a questa zona.",
      "selectWarehouse": "{warehouseZoneLabel} - selezionare prima una {warehouseLabel}",
      "settings": "Impostazioni zona",
      "transportTypes": "Tipi di trasporto per i quali è disponibile la zona magazzino",
      "updateSuccessMessage": "Le impostazioni della zona sono state modificate correttamente"
    },
    "warehouses": {
      "label": "Magazzini"
    },
    "warehousesToExcel": {
      "label": "Magazzini",
      "placeholder": "Scegli magazzino per export",
      "title": "Parametri file Excel"
    },
    "weightLog": {
      "ENTRY_WEIGHT": "Pesatura all’ingresso",
      "ENTRY_WEIGHT_AGAIN": "Pesatura all’ingresso (nuova pesatura)",
      "EXIT_WEIGHT": "Pesatura all’uscita",
      "EXIT_WEIGHT_AGAIN": "Pesatura all’uscita (nuova pesatura)"
    },
    "worker": {
      "GUESTS": "Ospiti",
      "SERVICEMEN": "Tecnico dell’assistenza",
      "WORKERS": "Lavoratori",
      "addGuest": "Aggiungi nuovo ospite",
      "addServiceman": "Aggiungi nuovo tecnico dell’assistenza",
      "addWorker": "Aggiungi nuovo lavoratore",
      "autoCompletePlaceholder": "Per cercare un dipendente inizia a digitare",
      "chooseWorkers": "Seleziona lavoratori",
      "deleteWorkerMsg": "Eliminare questo lavoratore?",
      "editGuest": "Modifica ospite",
      "editServiceman": "Modifica tecnico dell’assistenza",
      "editWorker": "Modifica lavoratore",
      "errors": {
        "phoneNumber": {
          "regex": "Il numero deve contenere da 9 a 12 cifre"
        }
      },
      "name": "Nome e cognome",
      "phoneNumber": {
        "GUESTS": "Numero di telefono ospite",
        "SERVICEMEN": "Numero di telefono tecnico dell’assistenza",
        "WORKERS": "Numero di telefono lavoratore"
      },
      "trained": "Qualificato",
      "workersList": "Elenco lavoratori"
    },
    "workflows": {
      "addSubTask": "Aggiungi un’attività secondaria",
      "addTask": "Aggiungi un’attività",
      "finalStageNamesLabel": "Dopo la conferma di tutte le fasi, lo stato del trasporto deve essere modifica in",
      "label": "Flusso di lavoro (impostazioni fase e stato)",
      "noTasks": "Nessuna attività assegnata a questa parte di processo",
      "saveSuccessMessage": "Impostazioni fase e stato (flusso di lavoro) salvate correttamente.",
      "updateDisclaimer": "Nota: le modifiche relative all’orario saranno applicate solo ai NUOVI trasporti."
    },
    "wrongUrl": "Nessun accesso o la pagina richiesta non esiste"
  };
  _exports.default = _default;
});