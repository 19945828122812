define("apollo/pods/components/transport/create-delivery-from-transport/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    modalService: Ember.inject.service('modal-service'),
    authorityCheckerService: Ember.inject.service('authority.authority-checker-service'),
    deliveryService: Ember.inject.service('delivery-service'),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    deliveryNumberDisabled: false,

    async init() {
      this._super(...arguments);

      if (await this.get('delivery.deliveryNumber')) {
        this.set('deliveryNumberDisabled', true);
      }
    },

    incoterms: Ember.A(['EXW', 'FCA', 'FAS', 'FOB', 'CFR', 'CIF', 'CPT', 'CIP', 'DAF', 'DES', 'DDU', 'DDP', 'DAT', 'DAP']),
    showDeliveryNumber: Ember.computed('transportType.id', function () {
      return this.authorityCheckerService.findPositiveAuthorityRestrictionsByActionType('ROLE_ACCESS_DELIVERY_DELIVERY_NUMBER', this.get('transportType.id'));
    }),
    showDeliveryInvoiceNumber: Ember.computed('transportType.id', function () {
      return this.authorityCheckerService.findPositiveAuthorityRestrictionsByActionType('ROLE_ACCESS_DELIVERY_INVOICE_NUMBER', this.get('transportType.id'));
    }),
    transport: Ember.computed('delivery.transport', function () {
      return this.get('delivery.transport');
    }),
    actions: {
      close() {
        const transport = this.get('delivery.transport');

        if (this.get('delivery.id')) {
          this.get('delivery').rollbackAttributes();
        } else {
          this.get('delivery').destroyRecord();
        }

        this.get('modalService').hideModal();
        this.get('modalService').showModal('transports.modals.create-transport', transport);
      },

      async saveDeliveryFromTransport() {
        const self = this;
        const delivery = this.get('delivery');
        const transport = delivery.get('transport');

        if (!delivery.validate()) {
          console.error('Walidacja Delivery zwróciła błąd/błędy');
          delivery.errors.forEach(error => console.error(`${error.attribute} ${error.message}`));
          return;
        }

        this.deliveryService.handleDeliveryCustomFields(delivery, (await this.get('customFieldsList')));
        this.apolloApiService.callApolloApi(this.apolloApiService.APOLLO_API.DELIVERY.CREATE_DELIVERY_FROM_TRANSPORT, null, {
          body: JSON.stringify({
            delivery: delivery.serialize(),
            transportId: transport.get('id')
          })
        }).then(response => {
          delivery.setProperties(response);
          self.get('deliveryService').handleUpdatedDeliveryPackaging(delivery);
          self.get('modalService').hideModal();
          self.get('modalService').refreshModel();
          self.get('modalService').showModal('transports.modals.create-transport', transport);
        }).catch(e => {
          self.set('errors', e.errors);
          console.error(`Błąd podczas zapisywania delivery: ${e}`);
        });
      }

    }
  });

  _exports.default = _default;
});