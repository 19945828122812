define("apollo/pods/components/transports/transports-table/column-names/component", ["exports", "apollo/mixins/form-utils-mixin"], function (_exports, _formUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formUtilsMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    // Używane w hbs.
    tagName: 'div',
    classNames: Ember.A(['column-names', 'row', 'flex-position-start']),
    getTransportSupervisorTranslationKey: Ember.computed('transportType', function () {
      return this.get('transportType.transportSupervisorTranslationKey');
    }),
    getWorkerTranslationKey: Ember.computed('transportType', function () {
      return this.get('transportType.workerFieldTranslationKey');
    }),
    canMultiArchive: Ember.computed('transport', function () {
      return this.get('sessionAccount').hasRole('ROLE_CAN_MULTI_ARCHIVE_TRANSPORTS');
    }),
    actions: {}
  });

  _exports.default = _default;
});