define("apollo/routes/application", ["exports", "jquery", "apollo/config/environment", "apollo/mixins/touch-mixin", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _jquery, _environment, _touchMixin, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _touchMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    freshWidget: Ember.inject.service('fresh-widget'),
    intl: Ember.inject.service(),
    routesAsPrintView: Ember.A(['transports.print', 'warehouses.hd']),
    TAB_ROLE_MAP: Object.freeze({
      ANNOUNCEMENTS_TAB: 'ROLE_ANNOUNCEMENTS_TAB',
      TRANSPORT_TAB: 'ROLE_TRANSPORT_TABLE',
      ARCHIVE_TAB: 'ROLE_ARCHIVE_TAB',
      WAREHOUSE_TAB: 'ROLE_WAREHOUSE_TAB',
      PRODUCTS_TAB: 'ROLE_PRODUCTS_TAB',
      USERS_TAB: 'ROLE_USERS_TAB',
      COMPANIES_TAB: 'ROLE_COMPANIES_TAB',
      OWN_COMPANY_TAB: 'ROLE_OWN_COMPANY_TAB',
      MODE_OF_TRANSPORTATION_TAB: 'ROLE_MODE_OF_TRANSPORTATION_TAB',
      CARRIERS_TAB: 'ROLE_CARRIERS_TAB',
      CLASSIFICATION_TAB: 'ROLE_CLASSIFICATION_TAB',
      QUEUE_STATUS_TAB: 'ROLE_QUEUE_STATUS_TAB',
      ROLE_QUEUES_TAB: 'ROLE_QUEUES_TAB',
      SETTINGS_TAB: 'ROLE_SETTINGS_TAB',
      INVITATIONS_TAB: 'ROLE_INVITATIONS_TAB',
      SUPER_USER_TAB: 'ROLE_SUPER_USER',
      STATISTICS_REPORT_TAB: 'ROLE_STATISTICS_REPORT_TAB'
    }),
    ROUTES_MAP: Object.freeze({
      ANNOUNCEMENTS_TAB: 'announcements',
      TRANSPORT_TAB: 'transports.index',
      ARCHIVE_TAB: 'transports.archive',
      WAREHOUSE_TAB: 'warehouses.schedule',
      PRODUCTS_TAB: 'products.index',
      USERS_TAB: 'users.index',
      COMPANIES_TAB: 'companies',
      OWN_COMPANY_TAB: 'companies.show',
      MODE_OF_TRANSPORTATION_TAB: 'mode-of-transportation.index',
      CARRIERS_TAB: 'carriers.index',
      CLASSIFICATION_TAB: 'classification-resources.index',
      QUEUE_STATUS_TAB: 'queue-status',
      ROLE_QUEUES_TAB: 'queues.index',
      SETTINGS_TAB: 'settings',
      INVITATIONS_TAB: 'invitations',
      SUPER_USER_TAB: 'super-admin.index',
      STATISTICS_REPORT_TAB: 'statistics-report',
      FILES_TAB: 'files.index'
    }),

    beforeModel() {
      if (this.get('beforeModelFired')) {
        console.debug('beforeModel already fired; returning..');
        return true;
      }

      this.set('beforeModelFired', true);
      let language;

      try {
        language = (window.navigator.languages || [window.navigator.language || window.navigator.userLanguage])[0].split('-')[0];
      } catch (e) {
        language = (navigator.language || navigator.browserLanguage).split('-')[0];
      }

      const availableLocales = ['pl', 'en', 'cs', 'de'];

      if ($.inArray(language, availableLocales) < 0) {
        language = 'en';
      }

      console.debug(`Language: ${language}.`);
      this.set('intl.locale', [language, 'en']);
      this.get('sessionAccount').set('language', language);
      moment.locale(language);

      if (this.get('session.isAuthenticated') && !this.get('sessionAccount.currentUser')) {
        return this._loadCurrentUser().then(user => {
          console.log(`User ${user.get('username')} logged-in.`);
        });
      }
    },

    afterModel() {
      if (this.get('session.isAuthenticated') && !this.get('sessionAccount.currentUser.statuteAccepted')) {
        const username = this.get('sessionAccount.currentUser').get('username');
        console.log(`User ${username} did not accept Terms; redirecting to review route..`);
        this.transitionTo('terms.review');
      }

      if (!this.isTouchDevice) {
        this.loadSupportWidget();
      }
    },

    sessionAuthenticated() {
      console.debug('Session has been authenticated.');

      this._super(...arguments);

      const self = this;

      this._loadCurrentUser().then(loggedUser => {
        if (!loggedUser.get('resetPasswordIsDisabled') && new Date(loggedUser.get('resetPasswordDate')) < new Date()) {
          self.get('sessionAccount').set('passwordExpired', true);
          self.transitionTo('reset-password');
        } else {
          loggedUser.get('company').then(() => {
            self.send('afterLogin');
          });
        }
      });
    },

    loadSupportWidget() {
      if (this.get('routesAsPrintView').includes(this.get('controller.currentRouteName'))) {
        return;
      }

      this.get('freshWidget').loadWidget();
    },

    shouldDisplayAllTypes: Ember.computed('', function () {
      return this.get('sessionAccount').getSettingValue('SHOULD_RENDER_ALL_TRANSPORT_TYPES') === 'true' || this.get('sessionAccount.currentUser.roleGroup.isGuard');
    }),

    /**
     * Ustawia się w momencie zalogowania użytkownika
     */
    transportTypeModeToRedirectTo: Ember.computed('transportTypeMode', function () {
      if (this.get('transportTypeMode')) {
        return this.get('transportTypeMode');
      } // Po zalogowaniu przenosimy użytkownika do ostatniego wybranego przez niego Typu Transportu jeśli ma dostęp(w to wlicza się równiesz "Wszystkie")


      const transportTypes = this.get('store').peekAll('transportType');
      const transportTypeFromLocalStorage = localStorage.getItem('LastVisitedTransportType');
      const isTransportTypeFromLocalStorageInAvalibleTransportTypes = transportTypes.filterBy('canBeSeenByRoleGroup').any(tt => tt.alias === transportTypeFromLocalStorage);

      if (isTransportTypeFromLocalStorageInAvalibleTransportTypes || transportTypeFromLocalStorage === 'ALL') {
        return transportTypeFromLocalStorage;
      } // Jeśli użytkownik nie ma zapisanego Typu Transportu (loguje się pierwszy raz lub czyścił cache) to przeniosimy go do pierwszego Typu z listy


      return this.get('shouldDisplayAllTypes') ? 'ALL' : this.get('store').peekAll('transportType').get('firstObject.alias');
    }),
    shouldRedirectToApply: Ember.computed('sessionAccount.currentCompany', function () {
      return this.get('sessionAccount').getSettingValue('HAS_ACCESS_TO_APPLIES') === 'true' && this.get('sessionAccount.currentCompany.isCarrier') && (!this.get('sessionAccount.currentCompany.partnershipWithPrincipal.registrationCompleted') && !this.get('sessionAccount.currentCompany.partnershipWithPrincipal.verificationRequestSent') || this.get('sessionAccount.currentCompany.partnershipWithPrincipal.status') === 'BLOCKED');
    }),
    defaultTabAfterLogin: Ember.computed(function () {
      const defaultTab = this.get('sessionAccount').getSettingValue('DEFAULT_TAB_AFTER_LOGIN'); // zakładka z transportami jest obsługiwana w szczególny sposób

      if (!defaultTab || defaultTab === 'TRANSPORT_TAB') {
        return null;
      }

      if (!this.get('sessionAccount').hasRole(this.TAB_ROLE_MAP[defaultTab])) {
        console.log('Użytkownik nie ma uprawnień do zakładki ' + defaultTab);
        return null;
      }

      if (!this.ROUTES_MAP[defaultTab]) {
        console.log('Nie wiemy, jaka jest ścieżka do route ' + defaultTab);
        return null;
      }

      return this.findProperTransition(defaultTab);
    }),

    findProperTransition(defaultTab) {
      // eslint-disable-next-line ember/no-controller-access-in-routes
      const applicationController = this.controllerFor('application');

      switch (defaultTab) {
        case 'ARCHIVE_TAB':
          {
            const transportTypeId = applicationController.get('transportTypeAvailableInArchive.id');
            return this.transitionTo(this.ROUTES_MAP[defaultTab], transportTypeId);
          }

        case 'WAREHOUSE_TAB':
          {
            const warehouseId = applicationController.get('defaultWarehouse.id');
            const date = moment(applicationController.get('warehouseDate')).format('YYYY-MM-DD');
            const numberOfDaysInSchedule = applicationController.get('numberOfDaysInSchedule');
            return this.transitionTo(this.ROUTES_MAP[defaultTab], warehouseId, date, numberOfDaysInSchedule);
          }

        case 'OWN_COMPANY_TAB':
          {
            const companyId = this.get('sessionAccount.currentUser.company.id');
            return this.transitionTo(this.ROUTES_MAP[defaultTab], companyId);
          }

        case 'USERS_TAB':
        case 'ANNOUNCEMENTS_TAB':
          {
            const queryParams = {
              max: 10,
              page: 1
            };
            return this.transitionTo(this.ROUTES_MAP[defaultTab], {
              queryParams
            });
          }

        default:
          return this.transitionTo(this.ROUTES_MAP[defaultTab]);
      }
    },

    actions: {
      afterLogin() {
        // eslint-disable-next-line ember/no-controller-access-in-routes
        this.controllerFor('application').initAnnouncementCounterUpdates();
        const loggedUser = this.get('sessionAccount').get('currentUser');

        if (!loggedUser.get('statuteAccepted')) {
          const username = loggedUser.get('username');
          console.log(`User ${username} did not accept Terms; redirecting to review route..`);
          this.transitionTo('terms.review');
        } else if (this.get('shouldRedirectToApply')) {
          this.transitionTo('companies.show', this.get('sessionAccount.currentUser.company.id'));
        } else if (this.get('defaultTabAfterLogin')) {
          return this.get('defaultTabAfterLogin');
        } else if (!this.get('sessionAccount').get('canAccessAnyTransportType')) {
          return this.transitionTo('no-accessible-transport-types');
        } else if (!this.get('sessionAccount').hasRole('ROLE_TRANSPORT_TABLE')) {
          if (this.get('sessionAccount').hasRole('ROLE_CLASSIFICATION_TAB')) {
            return this.transitionTo('classification-resources.index');
          } else if (this.get('sessionAccount').hasRole('ROLE_QUEUE_STATUS_TAB')) {
            return this.transitionTo('queue-status.index');
          } else if (this.get('sessionAccount').hasRole('ROLE_QUEUES_TAB')) {
            return this.transitionTo('queues.index');
          }

          const warehouseOfCurrentPrincipal = this.get('sessionAccount.currentUser.warehouses.firstObject');
          const currentDate = moment().format('YYYY-MM-DD');
          const scheduleSortingValue = 7;
          this.transitionTo('warehouses.schedule', warehouseOfCurrentPrincipal.get('id'), currentDate, scheduleSortingValue);
        } else {
          this.transitionTo('transports', this.get('transportTypeModeToRedirectTo'));
        }
      },

      /**
       * Wyświetla okienko typu 'modal'.
       *
       * <p/>
       * Po wczytaniu na stronę (`this.render...`), wykona się akcja `didInsertElement` komponentu `custom-modal/controller.js`.
       * Ta akcja będzie odpowiedzialna za wykonanie metody `.modal` oraz faktyczne wyświetlenie okienka.
       *
       * @param name ścieżka do template, który ma zostać wyświetlony, np. `sections.modals.rejection-modal`
       * @param model obiekt, który będzie dostępny w komponencie (np. `offer-form/controller.js`) jako `model`
       * @param outletName
       */
      showModal: function (name, model, outletName = 'main-modal') {
        console.debug(`Pokazujemy modal ${name}, ${outletName}...`);

        if (model && model.set) {
          model.set('modelOpenTime', new Date().getTime());
        } else if (model && model.transport && model.transport.set) {
          model.transport.set('modelOpenTime', new Date().getTime());
        }

        this.render(name, {
          into: 'application',
          outlet: outletName,
          model
        });
      },

      /**
       * Ukrywa widoczny na ekranie `modal` oraz usuwa ze strony `template` wczytany w {@link showModal}.
       *
       * </p>
       * Usunięcie `template` jest konieczne, żeby przy kolejnym włączeniu okienka wykonały się operacje `didInsertElement`
       * wybranego komponentu.
       */
      hideModal(outletName = 'main-modal') {
        console.debug(`Ukrywamy i usuwamy modal #${outletName} (application)...`);
        (0, _jquery.default)(`#${outletName}`).modal('hide');
        (0, _jquery.default)('.modal-backdrop').remove();
      },

      refreshModel() {
        this.refresh();
      },

      showPopupDialog(name, model) {
        this.render(name, {
          into: 'application',
          outlet: 'popup-dialog',
          model
        });
      },

      removePopupDialog() {
        const $dialog = $('.popup-dialog');
        $dialog.fadeTo('fast', 0.5, function () {
          $dialog.dialog('close');
        });
        this.disconnectOutlet({
          outlet: 'popup-dialog',
          parentView: 'application'
        });
      },

      removePopupMessage() {
        const self = this;
        const $messageDiv = $('.popup-message');
        $messageDiv.hide();
        self.disconnectOutlet({
          outlet: 'popup-dialog',
          parentView: 'application'
        });
      },

      willTransition() {
        // eslint-disable-next-line ember/no-controller-access-in-routes
        this.get('controller').setProperties({
          hdMode: false
        });
        this.send('removePopupDialog');
        return true;
      },

      error: function (error) {
        const status = error.errors ? error.errors[0].status : null;

        if (status === 0) {
          console.log('Sorry, but we\'re having trouble connecting to the server. ' + 'This problem is usually the result of a broken Internet connection. ' + 'You can try refreshing this page.');
        } else if (status === '403' || status === '401' || status === '404') {
          this.transitionTo('forbidden');
        }
      },

      goBack() {
        window.history.back();
      },

      allowModelFiring() {
        this.set('beforeModelFired', false);
      },

      setTransportTypeModeSelection(mode) {
        // eslint-disable-next-line ember/no-controller-access-in-routes
        this.get('controller').set('transportTypeMode', mode);
      },

      goToTab(tab) {
        const url = window.location.href;
        const arr = url.split('/');
        const result = arr[0] + '//' + arr[2] + '/#/' + tab;
        console.log(`Przenosimy się do ${result}..`);
        window.location = result;
        location.reload();
      }

    },

    redirect(model, transition) {
      const unauthenticatedRoutes = _environment.default['ember-simple-auth'].unauthenticatedRoutes;

      if (!unauthenticatedRoutes.includes(transition.targetName) && !this.get('session.isAuthenticated')) {
        console.log(`User is unauthenticated - redirecting to auth route (and saving transition to ${transition.targetName}) ..`);
        this.set('session.attemptedTransition', transition);
        const principalId = localStorage.getItem('principalId');
        this.transitionTo('auth.login', {
          queryParams: {
            principalId: principalId || ''
          }
        });
      } else {
        console.debug('session.isAuthenticated');
      }
    },

    _loadCurrentUser() {
      if (!this.get('session.isAuthenticated')) {
        return;
      }

      return this.get('sessionAccount').loadCurrentUser();
    },

    setupController: function (controller) {
      controller.set('browserUnsupported', (0, _jquery.default)('html').is('.unsupported'));
      controller.set('browserOld', (0, _jquery.default)('html').is('.old'));
      controller.set('routesAsPrintView', this.get('routesAsPrintView'));
      controller.initAnnouncementCounterUpdates();
    }
  });

  _exports.default = _default;
});