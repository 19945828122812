define("apollo/pods/delivery-packaging/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend(_modelValidator.default, {
    rounding: Ember.inject.service('rounding-service'),
    quantity: attr('number'),
    packageType: belongsTo('packageType'),
    enabled: attr('boolean'),
    loadStep: belongsTo('step'),
    unloadStep: belongsTo('step'),
    length: attr('number'),
    width: attr('number'),
    height: attr('number'),
    weight: attr('number'),
    description: attr('string'),
    stackingFactor: attr('number'),
    ldm: attr('number'),
    packagingLdm: Ember.computed('ldm', function () {
      return this.rounding.roundToTwoDecimals(this.get('ldm'));
    }),
    validations: {
      packageType: {
        presence: {
          message: 'blankSelect'
        }
      },
      length: {
        custom: [{
          validation: function (key, value, model) {
            if (!value) {
              return false;
            }

            const formattedValue = Number(value.toString().replace(',', '.'));

            if (formattedValue) {
              value = formattedValue;
              model.set(key, value);
            }

            if (value < 1 || value > 9999999) {
              return false;
            }

            return /^[0-9]*[.|,]?[0-9]*$/.test(value);
          },
          message: 'positiveNumber'
        }]
      },
      width: {
        custom: [{
          validation: function (key, value, model) {
            if (!value) {
              return false;
            }

            const formattedValue = Number(value.toString().replace(',', '.'));

            if (formattedValue) {
              value = formattedValue;
              model.set(key, value);
            }

            if (value < 1 || value > 9999999) {
              return false;
            }

            return /^[0-9]*[.|,]?[0-9]*$/.test(value);
          },
          message: 'positiveNumber'
        }]
      },
      height: {
        custom: [{
          validation: function (key, value, model) {
            if (!value) {
              return false;
            }

            const formattedValue = Number(value.toString().replace(',', '.'));

            if (formattedValue) {
              value = formattedValue;
              model.set(key, value);
            }

            if (value < 1 || value > 9999999) {
              return false;
            }

            return /^[0-9]*[.|,]?[0-9]*$/.test(value);
          },
          message: 'positiveNumber'
        }]
      },
      weight: {
        custom: [{
          validation: function (key, value, model) {
            if (!value) {
              return false;
            }

            const formattedValue = Number(value.toString().replace(',', '.'));

            if (formattedValue) {
              value = formattedValue;
              model.set(key, value);
            }

            if (value < 0.001 || value > 999999999999) {
              return false;
            }

            return /^[0-9]*[.|,]?[0-9]*$/.test(value);
          },
          message: 'positiveNumber'
        }]
      },
      description: {
        custom: [{
          validation: function (key, value) {
            return value;
          },
          message: 'blank'
        }]
      },
      quantity: {
        custom: [{
          validation: function (key, value, model) {
            if (!value) {
              return false;
            }

            const formattedValue = Number(value.toString().replace(',', '.'));

            if (formattedValue) {
              value = formattedValue;
              model.set(key, value);
            }

            if (value < 1 || value > 9999999) {
              return false;
            }

            return /^\d+$/.test(value);
          },
          message: 'positiveInteger'
        }]
      },
      stackingFactor: {
        custom: [{
          validation: function (key, value) {
            return !!value;
          },
          message: 'blankSelect'
        }]
      }
    }
  });

  _exports.default = _default;
});