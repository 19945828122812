define("apollo/pods/transaction/modals/choose-carrier/transaction-prices-section/delivery-price-labels/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ExDttopM",
    "block": "{\"symbols\":[\"delivery\"],\"statements\":[[4,\"each\",[[23,[\"transaction\",\"transport\",\"deliveries\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"transaction/modals/choose-carrier/transaction-prices-section/price-label\",null,[[\"errorModel\",\"errorFieldName\",\"error\",\"label\",\"price\",\"deliveryNumber\",\"isPriceDisabled\",\"selectedCurrency\",\"currencies\",\"isCurrencyDisabled\"],[[22,1,[]],\"freightCost\",[22,1,[\"errors\",\"freightCost\"]],[22,1,[\"getPriceLabel\"]],[22,1,[\"freightCost\"]],[22,1,[\"deliveryNumber\"]],false,[23,[\"transaction\",\"currency\"]],[23,[\"\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/transaction/modals/choose-carrier/transaction-prices-section/delivery-price-labels/template.hbs"
    }
  });

  _exports.default = _default;
});