define("apollo/pods/planning/modals/create-from-transport/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    deliveryService: Ember.inject.service('delivery-service'),
    modalTitle: Ember.computed('model.delivery.id', function () {
      return this.get('model.delivery.id') ? this.get('intl').t('delivery.edit') : this.get('intl').t('delivery.create');
    }),
    packageTypes: Ember.computed('', function () {
      return this.get('store').peekAll('packageType');
    }),
    customFieldsList: Ember.computed('model.customFields', function () {
      return this.deliveryService.findCustomFieldPairs(this.get('model.customFields').filterBy('id').filterBy('enabled').filterBy('visibleInDelivery').sortBy('idx'));
    }),
    actions: {
      cancel() {
        this.get('model.delivery').rollbackAttributes();
        this.send('hideModal');
      }

    }
  });

  _exports.default = _default;
});