define("apollo/services/truck-type-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    getTruckTypesByTransportTypes: async function () {
      const principalTransportTypes = await this.store.findAll('transportType');
      const principalTransportTypeIds = new Set(principalTransportTypes.map(tt => tt.id));
      const truckTypes = await this.store.findAll('truckType');
      return truckTypes.filter(truckType => {
        const transportTypes = truckType.transportTypes.toArray();
        return transportTypes.some(tt => principalTransportTypeIds.has(tt.id));
      });
    }
  });

  _exports.default = _default;
});